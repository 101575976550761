import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import {
    createRewardApplicationAction,
    deleteRewardApplicationAction,
    fetchRewardsAction,
    updateRewardApplicationAction,
} from '../../../actions/rewardActions';
import { RewardApplication } from '../../../API';
import Context from '../../../Context/context';
import useAsync from '../../../hooks/useAsync';
import { RewardSelection } from '../../../ressources/types/game';
import { ActionTypes } from '../../../ressources/types/state';
import { listRewardApplications, saveRewardChanges } from '../../../utils/gameHelpers';
import SetupRewardsModalList from './SetupRewardsModalList';

interface SetupRewardModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
}

function SetupRewardsModal(props: SetupRewardModalProps) {
    const { state, dispatch } = useContext(Context);
    const [selectedRewards, setSelectedRewards] = useState<RewardSelection[]>([]);
    const [loading, setLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (!state.rewards) {
            execute(() => fetchRewardsAction(dispatch));
        } else {
            setSelectedRewards(getCurrentRewards());
        }
    }, [state.rewards]);

    function getRewardSelection(rewardApplications: RewardApplication[]) {
        return rewardApplications.map((rewardApplication) => {
            return {
                rewardId: rewardApplication.rewardId,
                rewardApplicationId: rewardApplication.id,
                order: rewardApplication.order || 1,
                amount: rewardApplication.amount || 1,
            };
        });
    }

    function getCurrentRewards() {
        if (state.newGame) {
            return getRewardSelection(listRewardApplications(state.newGame));
        }
        if (state.activeGame) {
            return getRewardSelection(listRewardApplications(state.activeGame));
        }
        return [];
    }

    async function handleSave() {
        const gameId = state.newGame?.id || state.activeGame?.id;

        if (gameId) {
            setLoading(true);
            await execute(() =>
                saveRewardChanges(
                    getCurrentRewards(),
                    selectedRewards,
                    gameId,
                    createRewardApplicationAction,
                    updateRewardApplicationAction,
                    deleteRewardApplicationAction,
                    dispatch
                )
            );
            setLoading(false);
            props.setOpen(false);
        }
    }

    return (
        <Modal className='edit-rewards-modal list-modal' onClose={() => props.setOpen(false)} onOpen={() => props.setOpen(true)} open={props.open}>
            <Modal.Header>Preise bearbeiten</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    {state.rewards && (
                        <SetupRewardsModalList rewards={state.rewards} selectedRewards={selectedRewards} setSelectedRewards={setSelectedRewards} />
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)}>Abbrechen</Button>
                <Button loading={loading} className='secondary' onClick={() => handleSave()}>
                    Speichern
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SetupRewardsModal;
