/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNewsletterSubscription = /* GraphQL */ `
  query GetNewsletterSubscription($id: ID!) {
    getNewsletterSubscription(id: $id) {
      id
      owner
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const listNewsletterSubscriptions = /* GraphQL */ `
  query ListNewsletterSubscriptions(
    $filter: ModelNewsletterSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletterSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        email
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChallenge = /* GraphQL */ `
  query GetChallenge($id: ID!) {
    getChallenge(id: $id) {
      id
      description
      question
      points
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      stadiumOnly
      archived
      applications {
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const listChallenges = /* GraphQL */ `
  query ListChallenges(
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallenges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      firstname
      lastname
      position
      number
      image
      games {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        position
        number
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      description
      startTimestamp
      endTimestamp
      evaluationTimestamp
      automaticChallenges
      challenges {
        nextToken
      }
      rewards {
        nextToken
      }
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      lineup {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChallengeApplication = /* GraphQL */ `
  query GetChallengeApplication($id: ID!) {
    getChallengeApplication(id: $id) {
      id
      startTimestamp
      endTimestamp
      challengeId
      challenge {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      tipOptions
      solution
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      challengeTips {
        nextToken
      }
      rewards {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChallengeApplications = /* GraphQL */ `
  query ListChallengeApplications(
    $filter: ModelChallengeApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallengeApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChallengeTip = /* GraphQL */ `
  query GetChallengeTip($id: ID!) {
    getChallengeTip(id: $id) {
      id
      challengeApplicationId
      challengeApplication {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      owner
      answer
      points
      createdAt
      updatedAt
    }
  }
`;
export const listChallengeTips = /* GraphQL */ `
  query ListChallengeTips(
    $filter: ModelChallengeTipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallengeTips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        challengeApplicationId
        owner
        answer
        points
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      nickname
      owner
      score
      visibility
      rank
      stats {
        participatedGames
        participatedChallengeTips
        successfulChallengeTips
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nickname
        owner
        score
        visibility
        rank
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfileByOwner = /* GraphQL */ `
  query GetProfileByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProfileByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nickname
        owner
        score
        visibility
        rank
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRanking = /* GraphQL */ `
  query GetRanking(
    $visibility: ProfileVisibility!
    $score: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRanking(
      visibility: $visibility
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nickname
        owner
        score
        visibility
        rank
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSponsor = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsor(id: $id) {
      id
      name
      website
      tagline
      games {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      challenges2 {
        nextToken
      }
      rewards {
        nextToken
      }
      archived
      image
      createdAt
      updatedAt
    }
  }
`;
export const listSponsors = /* GraphQL */ `
  query ListSponsors(
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRewardApplication = /* GraphQL */ `
  query GetRewardApplication($id: ID!) {
    getRewardApplication(id: $id) {
      id
      amount
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const listRewardApplications = /* GraphQL */ `
  query ListRewardApplications(
    $filter: ModelRewardApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRewardApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        gameId
        rewardId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChallengeRewardApplication = /* GraphQL */ `
  query GetChallengeRewardApplication($id: ID!) {
    getChallengeRewardApplication(id: $id) {
      id
      amount
      challengeApplicationId
      challengeApplication {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const listChallengeRewardApplications = /* GraphQL */ `
  query ListChallengeRewardApplications(
    $filter: ModelChallengeRewardApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallengeRewardApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        challengeApplicationId
        rewardId
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReward = /* GraphQL */ `
  query GetReward($id: ID!) {
    getReward(id: $id) {
      id
      name
      value
      applications {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      issues {
        nextToken
      }
      archived
      redemptionInfo
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRewards = /* GraphQL */ `
  query ListRewards(
    $filter: ModelRewardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIssuedReward = /* GraphQL */ `
  query GetIssuedReward($id: ID!) {
    getIssuedReward(id: $id) {
      id
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      owner
      redemptionTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listIssuedRewards = /* GraphQL */ `
  query ListIssuedRewards(
    $filter: ModelIssuedRewardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIssuedRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rewardId
        owner
        redemptionTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getObserver = /* GraphQL */ `
  query GetObserver($id: ID!) {
    getObserver(id: $id) {
      id
      topic
      subscription
      createdAt
      updatedAt
    }
  }
`;
export const listObservers = /* GraphQL */ `
  query ListObservers(
    $filter: ModelObserverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObservers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        topic
        subscription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getObserverBySubscription = /* GraphQL */ `
  query GetObserverBySubscription(
    $subscription: String!
    $sortDirection: ModelSortDirection
    $filter: ModelObserverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getObserverBySubscription(
      subscription: $subscription
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topic
        subscription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGamePlayer = /* GraphQL */ `
  query GetGamePlayer($id: ID!) {
    getGamePlayer(id: $id) {
      id
      playerID
      gameID
      player {
        id
        firstname
        lastname
        position
        number
        image
        createdAt
        updatedAt
      }
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGamePlayers = /* GraphQL */ `
  query ListGamePlayers(
    $filter: ModelGamePlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGamePlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playerID
        gameID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
