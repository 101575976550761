import React from 'react';
import { Loader, SemanticSIZES } from 'semantic-ui-react';

interface CustomLoaderProps {
    label?: string;
    active?: boolean;
    inline?: boolean;
    size?: SemanticSIZES;
}

function CustomLoader(props: CustomLoaderProps) {
    return (
        <div className='loader-wrapper'>
            <Loader
                active={props.active === undefined || props.active}
                inline={props.inline === undefined || props.inline}
                size={props.size || 'huge'}
            />
            {props.label && <p className='placeholder-text'>{props.label}</p>}
        </div>
    );
}

export default CustomLoader;
