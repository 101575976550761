import { API, Auth } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { createAnonymousUser } from './userHelpers';

export class GraphqlInterceptor {
    static async graphql<T>(options: GraphQLOptions) {
        try {
            await Auth.currentAuthenticatedUser();
        } catch {
            try {
                await createAnonymousUser();
            } catch {
                throw new Error('Authentifizierung fehlgeschlagen');
            }
        }
        try {
            if (options.variables) {
                const variables = options.variables as any;
                variables.limit = variables.limit ? variables.limit : process.env.REACT_APP_LIST_QUERIES_LIMIT;
                options.variables = variables;
            } else {
                options = { ...options, variables: { limit: process.env.REACT_APP_LIST_QUERIES_LIMIT } };
            }
            const request = API.graphql<T>(options);
            const promise = request as Promise<GraphQLResult<T>>;
            const result = await promise;
            if (result.errors) {
                throw new Error('Es ist ein Fehler aufgetreten');
            }
            return result;
        } catch (error: any) {
            if (error.errors[0].errorType === 'Unauthorized') {
                throw new Error('Keine Berechtigung');
            } else {
                throw new Error('Es ist ein Fehler aufgetreten');
            }
        }
    }
}
