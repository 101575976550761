import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './adminHeader.scss';
import { Icon } from 'semantic-ui-react';
import { AdminRoutes } from '../../ressources/types/routes';

const ADMIN_MANUAL_FILE = '/docs/admin-manual.pdf';

function AdminHeader() {
    const [responsive, setResponsive] = useState(false);
    const [responsiveNavOpen, setResponsiveNavOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    function handleResize() {
        if (window.innerWidth <= 650) {
            setResponsiveNavOpen(false);
            setResponsive(true);
        } else {
            setResponsive(false);
        }
    }

    function isActive(route: string) {
        return location.pathname === `/admin/${route}` ? 'active' : '';
    }

    return (
        <header id='admin-header' className={`${responsive && 'responsive '} ${responsiveNavOpen && 'open'}`}>
            <div className='admin-header-bg'>
                <a href={ADMIN_MANUAL_FILE} target='_blank' rel='noopener noreferrer'>
                    <Icon name='question circle outline' />
                </a>
            </div>

            <nav>
                <ul onClick={() => setResponsiveNavOpen(!responsiveNavOpen)}>
                    <li className={isActive(AdminRoutes.CHALLENGES)} onClick={() => navigate(AdminRoutes.CHALLENGES)}>
                        Challenges
                    </li>
                    <li className={isActive(AdminRoutes.SPONSORS)} onClick={() => navigate(AdminRoutes.SPONSORS)}>
                        Sponsoren
                    </li>
                    <li className={isActive(AdminRoutes.REWARDS)} onClick={() => navigate(AdminRoutes.REWARDS)}>
                        Preise
                    </li>
                    <li className={isActive(AdminRoutes.SQUAD)} onClick={() => navigate(AdminRoutes.SQUAD)}>
                        Kader
                    </li>
                    <li className={isActive(AdminRoutes.GAME)} onClick={() => navigate(AdminRoutes.GAME)}>
                        Tippspiel
                    </li>
                    <li className='bars'>
                        <Icon name='bars'></Icon>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default AdminHeader;
