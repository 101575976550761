import React, { useContext, useEffect, useState } from 'react';
import { setChallengeSolutionAction } from '../../../../actions/gameActions';
import Context from '../../../../Context/context';
import useAsync from '../../../../hooks/useAsync';
import { ActionTypes } from '../../../../ressources/types/state';
import './solutionModal.scss';
import { ChallengeType } from '../../../../API';
import OnePlayerSolutionModalDetails from './OnePlayerSolutionModalDetails';
import ResultModal from '../../../ResultModal/ResultModal';
import EstimationModal from '../../../EstimationModal/EstimationModal';

interface SolutionModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
    challengeId: string;
    challengeType: ChallengeType;
}

function SolutionModal(props: SolutionModalProps) {
    const { state, dispatch } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    function cancel() {
        setErrorMessage(undefined);
        props.setOpen(false);
    }

    async function submitSolution(solution: string) {
        setErrorMessage(undefined);
        if (state.user) {
            setLoading(true);
            if (solution) {
                await execute(() => setChallengeSolutionAction(dispatch, solution, props.challengeId)).then(() => {
                    props.setOpen(false);
                });
            }
            setLoading(false);
        }
    }

    function renderSolutionModalDetails(challengeType: ChallengeType) {
        switch (challengeType) {
            case ChallengeType.onePlayerChallenge:
                return (
                    <OnePlayerSolutionModalDetails
                        loading={loading}
                        submitSolution={submitSolution}
                        cancel={cancel}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        open={props.open}
                        setOpen={props.setOpen}
                    />
                );
            case ChallengeType.estimationChallenge:
                return (
                    <EstimationModal
                        loading={loading}
                        heading={'Lösungszahl'}
                        submitResultText={'Auflösen'}
                        open={props.open}
                        setOpen={props.setOpen}
                        submitEstimation={submitSolution}
                        cancel={cancel}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                    />
                );
            case ChallengeType.resultChallenge:
                return (
                    <ResultModal
                        loading={loading}
                        heading={'Lösungsresultat'}
                        submitResultText={'Auflösen'}
                        open={props.open}
                        setOpen={props.setOpen}
                        submitResult={submitSolution}
                        cancel={cancel}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                    />
                );
        }
    }

    return <div>{renderSolutionModalDetails(props.challengeType)}</div>;
}

export default SolutionModal;
