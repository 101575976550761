/* eslint-disable no-unused-vars */

import { Player, PlayerPosition } from '../../API';

export interface IPlayer extends Player {
    s3url: string;
}

export interface Nobody extends IPlayer {}

export const nobody: Nobody = {
    __typename: 'Player',
    id: 'nobody',
    firstname: '',
    lastname: '',
    position: PlayerPosition.defence,
    s3url: '',
    number: 0,
    image: '',
    createdAt: '',
    updatedAt: '',
};
