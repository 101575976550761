import { IssuedReward } from '../API';

export function getAvailableRewards(rewards: IssuedReward[]) {
    return rewards.filter((reward: IssuedReward) => reward.redemptionTimestamp === null);
}

export function getRedeemedRewards(rewards: IssuedReward[]) {
    return rewards.filter((reward: IssuedReward) => reward.redemptionTimestamp !== null);
}

export function generateCode(id: string) {
    return id.substring(0, 6);
}
