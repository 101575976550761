import React from 'react';

function Contact() {
    return (
        <div id='terms-and-conditions' className='text-content'>
            <h1>Kontakt</h1>
            <p>
                Über dein Feedback würden wir uns sehr freuen. Seien es aufgetretene Fehler, Unklarheiten der App, oder auch Ideen zur
                Weiterentwicklung. Du kannst uns unter dieser E-Mail Adresse erreichen:
            </p>
            <p className='center'>
                <a href='&#109;&#97;&#105;&#108;&#116;&#111;&#58;%69%6E%66%6F%40%6C%61%6B%65%72%73%2E%63%68'>info@lakers.ch</a>
            </p>
        </div>
    );
}

export default Contact;
