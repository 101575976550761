import React from 'react';
import './liveIndicator.scss';

function LiveIndicator() {
    return (
        <div className='livenow'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default LiveIndicator;
