import React from 'react';
import './sponsor.scss';

interface SponsorProps {
    onClick: () => void;
    sponsorImageUrl: string;
}

function Sponsor(props: SponsorProps) {
    return <div onClick={() => props.onClick()} style={{ backgroundImage: `url("${props.sponsorImageUrl}")` }} className='sponsor-img-wrapper'></div>;
}

export default Sponsor;
