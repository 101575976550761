import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import React, { useContext, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { setAnonymousUserPool, setDefaultUserPool } from '../../utils/userHelpers';
import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import './authenticator.scss';
import { federatedSignInAction, signOutAction } from '../../actions/userActions';
import Context from '../../Context/context';
import CustomLoader from '../CustomLoader/CustomLoader';

function Authenticator() {
    const { dispatch } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [mailSent, setMailSent] = useState(false);
    const [loadingLabel, setLoadingLabel] = useState('');

    async function signInWithSocialProvider(provider: CognitoHostedUIIdentityProvider) {
        setLoadingLabel(provider);
        setLoading(true);
        setAnonymousUserPool();
        signOutAction(dispatch).then(async () => {
            setDefaultUserPool();
            federatedSignInAction(dispatch, provider);
        });
    }

    function renderSocialProviders() {
        return (
            <div className='authenticator-social-providers'>
                <div className='social-provider' onClick={() => signInWithSocialProvider(CognitoHostedUIIdentityProvider.Google)}>
                    <img src='/img/logo_google_75.png' alt='Sign in with Google' />
                    <p>Mit Google anmelden</p>
                </div>

                <div className='social-provider' onClick={() => signInWithSocialProvider(CognitoHostedUIIdentityProvider.Facebook)}>
                    <img src='/img/logo_facebook_75.png' alt='Sign in with Facebook' />
                    <p>Mit Facebook anmelden</p>
                </div>
                {/* <div className='social-provider' onClick={() => signInWithSocialProvider(CognitoHostedUIIdentityProvider.Apple)}>
                    <img src='/img/logo_apple_75.png' alt='Sign in with Apple' />
                    <p>Mit Apple-ID anmelden</p>
                </div> */}
            </div>
        );
    }

    if (loading) {
        return <CustomLoader label={`Wartet auf ${loadingLabel}...`} />;
    }

    if (mailSent) {
        return (
            <div className='mail-sent-confirmation'>
                <Icon name='paper plane' />
                <p className='placeholder-text'>Dir wurde ein Email mit dem Anmeldelink gesendet.</p>
            </div>
        );
    }

    return (
        <div className='authenticator'>
            {email ? (
                <>
                    <h1>Account erstellen</h1>
                    <SignUp setEmail={setEmail} email={email} setMailSent={setMailSent} />
                </>
            ) : (
                <>
                    {renderSocialProviders()}
                    <p className='divider'>oder</p>
                    <SignIn setEmail={setEmail} setMailSent={setMailSent} />
                </>
            )}
        </div>
    );
}

export default Authenticator;
