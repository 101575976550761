import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Context from '../../../Context/context';
import { createNewGameAction, fetchActiveGameWithoutTipsAction, fetchNewGameAction } from '../../../actions/gameActions';
import GameSettingDetails from '../../../components/GameSettingDetails/GameSettingDetails';
import useAction from '../../../hooks/useAction';
import CustomLoader from '../../../components/CustomLoader/CustomLoader';
import useAsync from '../../../hooks/useAsync';
import { ActionTypes } from '../../../ressources/types/state';

function GameSettings() {
    const { state, dispatch } = useContext(Context);
    const [btnLoading, setBtnLoading] = useState(false);

    const loadingNewGame = useAction({ action: fetchNewGameAction, dispatch, condition: !state.newGame }).loading;
    const loadingActiveGame = useAction({ action: fetchActiveGameWithoutTipsAction, dispatch, condition: !state.activeGame }).loading;

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    async function createNewGame() {
        setBtnLoading(true);
        execute(() => createNewGameAction(dispatch)).then(() => {
            setBtnLoading(false);
        });
    }

    if (loadingNewGame || loadingActiveGame) {
        return <CustomLoader label='Spiel wird geladen' />;
    }

    if (state.newGame) {
        return <GameSettingDetails game={state.newGame} />;
    }
    if (state.activeGame) {
        return <GameSettingDetails game={state.activeGame} />;
    }

    return (
        <Button loading={btnLoading} className='secondary' onClick={createNewGame}>
            <Icon name='plus'></Icon>
            Spiel erstellen
        </Button>
    );
}

export default GameSettings;
