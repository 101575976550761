import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../ressources/styles/themes/theme.scss';
import { Outlet } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import Header from '../../components/Header/Header';
import Navigation from '../../components/Navigation/Navigation';
import Context from '../../Context/context';
import {
    getChallengeApplicationEvaluationSubscription,
    getChallengeApplicationUpdateSubscription,
    getCreateChallengeApplicationSubscription,
    getDeleteChallengeApplicationSubscription,
    getGameUpdateSubscription,
} from '../../utils/subscriptionHelpers';
import { ActionTypes } from '../../ressources/types/state';

function Theme() {
    const { state, dispatch } = useContext(Context);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigation = useRef<HTMLElement>(null);

    let subscriptions: (ZenObservable.Subscription | undefined)[] = [];

    useEffect(() => {
        if (state.error) {
            setErrorMessage(state.error.message);
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
                dispatch({ type: ActionTypes.RESET_ERROR });
            }, 10000);
        } else {
            setHasError(false);
        }
    }, [state.error]);

    useEffect(() => {
        if (state.user) {
            registerSubscriptions();
            const healthCheck = setInterval(() => {
                checkSubscriptions();
            }, 5000);
            return () => {
                subscriptions.forEach((subscription) => {
                    subscription?.unsubscribe();
                });
                clearInterval(healthCheck);
            };
        }
    }, [state.user]);

    function registerSubscriptions() {
        if (state.user) {
            subscriptions = [
                getCreateChallengeApplicationSubscription(dispatch),
                getDeleteChallengeApplicationSubscription(dispatch),
                getChallengeApplicationUpdateSubscription(dispatch),
                getChallengeApplicationEvaluationSubscription(dispatch, state.user),
                getGameUpdateSubscription(dispatch),
            ];
        }
    }

    function checkSubscriptions() {
        if (!subscriptions.length) {
            registerSubscriptions();
            return;
        }

        for (const subscription of subscriptions) {
            if (subscription && subscription.closed) {
                registerSubscriptions();
                return;
            }
        }
    }

    return (
        <div className='app app-theme'>
            <Header />
            <section id='content'>
                <Outlet />
            </section>
            <Popup className='error-popup' context={navigation} content={errorMessage} position='top center' open={hasError} />
            <Navigation errorRef={navigation} />
        </div>
    );
}

export default Theme;
