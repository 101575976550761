/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ChallengeTip = {
  __typename: "ChallengeTip",
  id: string,
  challengeApplicationId: string,
  challengeApplication: ChallengeApplication,
  owner: string,
  answer: string,
  points?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ChallengeApplication = {
  __typename: "ChallengeApplication",
  id: string,
  startTimestamp?: string | null,
  endTimestamp?: string | null,
  challengeId: string,
  challenge: Challenge,
  gameId: string,
  game: Game,
  tipOptions?: Array< string | null > | null,
  solution?: string | null,
  sponsorId?: string | null,
  sponsor?: Sponsor | null,
  challengeTips?: ModelChallengeTipConnection | null,
  rewards?: ModelChallengeRewardApplicationConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Challenge = {
  __typename: "Challenge",
  id: string,
  description?: string | null,
  question: string,
  points: number,
  sponsorId?: string | null,
  sponsor?: Sponsor | null,
  stadiumOnly?: boolean | null,
  archived?: boolean | null,
  applications?: ModelChallengeApplicationConnection | null,
  type: ChallengeType,
  createdAt: string,
  updatedAt: string,
};

export type Sponsor = {
  __typename: "Sponsor",
  id: string,
  name: string,
  website: string,
  tagline?: string | null,
  games?: ModelGameConnection | null,
  challengeApplications?: ModelChallengeApplicationConnection | null,
  challenges2?: ModelChallengeConnection | null,
  rewards?: ModelRewardConnection | null,
  archived?: boolean | null,
  image: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelGameConnection = {
  __typename: "ModelGameConnection",
  items:  Array<Game | null >,
  nextToken?: string | null,
};

export type Game = {
  __typename: "Game",
  id: string,
  description?: string | null,
  startTimestamp?: string | null,
  endTimestamp?: string | null,
  evaluationTimestamp?: string | null,
  automaticChallenges?: boolean | null,
  challenges?: ModelChallengeApplicationConnection | null,
  rewards?: ModelRewardApplicationConnection | null,
  sponsorId?: string | null,
  sponsor?: Sponsor | null,
  lineup?: ModelGamePlayerConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelChallengeApplicationConnection = {
  __typename: "ModelChallengeApplicationConnection",
  items:  Array<ChallengeApplication | null >,
  nextToken?: string | null,
};

export type ModelRewardApplicationConnection = {
  __typename: "ModelRewardApplicationConnection",
  items:  Array<RewardApplication | null >,
  nextToken?: string | null,
};

export type RewardApplication = {
  __typename: "RewardApplication",
  id: string,
  amount?: number | null,
  gameId: string,
  game: Game,
  rewardId: string,
  reward: Reward,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type Reward = {
  __typename: "Reward",
  id: string,
  name: string,
  value?: number | null,
  applications?: ModelRewardApplicationConnection | null,
  challengeApplications?: ModelChallengeRewardApplicationConnection | null,
  issues?: ModelIssuedRewardConnection | null,
  archived?: boolean | null,
  redemptionInfo: string,
  sponsorId?: string | null,
  sponsor?: Sponsor | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelChallengeRewardApplicationConnection = {
  __typename: "ModelChallengeRewardApplicationConnection",
  items:  Array<ChallengeRewardApplication | null >,
  nextToken?: string | null,
};

export type ChallengeRewardApplication = {
  __typename: "ChallengeRewardApplication",
  id: string,
  amount?: number | null,
  challengeApplicationId: string,
  challengeApplication: ChallengeApplication,
  rewardId: string,
  reward: Reward,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelIssuedRewardConnection = {
  __typename: "ModelIssuedRewardConnection",
  items:  Array<IssuedReward | null >,
  nextToken?: string | null,
};

export type IssuedReward = {
  __typename: "IssuedReward",
  id: string,
  rewardId: string,
  reward: Reward,
  owner: string,
  redemptionTimestamp?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelGamePlayerConnection = {
  __typename: "ModelGamePlayerConnection",
  items:  Array<GamePlayer | null >,
  nextToken?: string | null,
};

export type GamePlayer = {
  __typename: "GamePlayer",
  id: string,
  playerID: string,
  gameID: string,
  player: Player,
  game: Game,
  createdAt: string,
  updatedAt: string,
};

export type Player = {
  __typename: "Player",
  id: string,
  firstname: string,
  lastname: string,
  position: PlayerPosition,
  number: number,
  image: string,
  games?: ModelGamePlayerConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum PlayerPosition {
  goalkeeper = "goalkeeper",
  defence = "defence",
  offence = "offence",
}


export type ModelChallengeConnection = {
  __typename: "ModelChallengeConnection",
  items:  Array<Challenge | null >,
  nextToken?: string | null,
};

export type ModelRewardConnection = {
  __typename: "ModelRewardConnection",
  items:  Array<Reward | null >,
  nextToken?: string | null,
};

export enum ChallengeType {
  onePlayerChallenge = "onePlayerChallenge",
  oneOfManyChallenge = "oneOfManyChallenge",
  estimationChallenge = "estimationChallenge",
  resultChallenge = "resultChallenge",
}


export type ModelChallengeTipConnection = {
  __typename: "ModelChallengeTipConnection",
  items:  Array<ChallengeTip | null >,
  nextToken?: string | null,
};

export type CreateGameInput = {
  id?: string | null,
  description?: string | null,
  startTimestamp?: string | null,
  endTimestamp?: string | null,
  evaluationTimestamp?: string | null,
  automaticChallenges?: boolean | null,
  sponsorId?: string | null,
};

export type ModelGameConditionInput = {
  description?: ModelStringInput | null,
  startTimestamp?: ModelStringInput | null,
  endTimestamp?: ModelStringInput | null,
  evaluationTimestamp?: ModelStringInput | null,
  automaticChallenges?: ModelBooleanInput | null,
  sponsorId?: ModelIDInput | null,
  and?: Array< ModelGameConditionInput | null > | null,
  or?: Array< ModelGameConditionInput | null > | null,
  not?: ModelGameConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateGameInput = {
  id: string,
  description?: string | null,
  startTimestamp?: string | null,
  endTimestamp?: string | null,
  evaluationTimestamp?: string | null,
  automaticChallenges?: boolean | null,
  sponsorId?: string | null,
};

export type CreateChallengeApplicationInput = {
  id?: string | null,
  startTimestamp?: string | null,
  endTimestamp?: string | null,
  challengeId: string,
  gameId: string,
  tipOptions?: Array< string | null > | null,
  solution?: string | null,
  sponsorId?: string | null,
};

export type ModelChallengeApplicationConditionInput = {
  startTimestamp?: ModelStringInput | null,
  endTimestamp?: ModelStringInput | null,
  challengeId?: ModelIDInput | null,
  gameId?: ModelIDInput | null,
  tipOptions?: ModelStringInput | null,
  solution?: ModelStringInput | null,
  sponsorId?: ModelIDInput | null,
  and?: Array< ModelChallengeApplicationConditionInput | null > | null,
  or?: Array< ModelChallengeApplicationConditionInput | null > | null,
  not?: ModelChallengeApplicationConditionInput | null,
};

export type UpdateChallengeApplicationInput = {
  id: string,
  startTimestamp?: string | null,
  endTimestamp?: string | null,
  challengeId?: string | null,
  gameId?: string | null,
  tipOptions?: Array< string | null > | null,
  solution?: string | null,
  sponsorId?: string | null,
};

export type ModelGameFilterInput = {
  id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  startTimestamp?: ModelStringInput | null,
  endTimestamp?: ModelStringInput | null,
  evaluationTimestamp?: ModelStringInput | null,
  automaticChallenges?: ModelBooleanInput | null,
  sponsorId?: ModelIDInput | null,
  and?: Array< ModelGameFilterInput | null > | null,
  or?: Array< ModelGameFilterInput | null > | null,
  not?: ModelGameFilterInput | null,
};

export enum ProfileVisibility {
  private = "private",
  public = "public",
}


export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelProfileFilterInput = {
  id?: ModelIDInput | null,
  nickname?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  score?: ModelIntInput | null,
  visibility?: ModelProfileVisibilityInput | null,
  rank?: ModelIntInput | null,
  and?: Array< ModelProfileFilterInput | null > | null,
  or?: Array< ModelProfileFilterInput | null > | null,
  not?: ModelProfileFilterInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelProfileVisibilityInput = {
  eq?: ProfileVisibility | null,
  ne?: ProfileVisibility | null,
};

export type ModelProfileConnection = {
  __typename: "ModelProfileConnection",
  items:  Array<Profile | null >,
  nextToken?: string | null,
};

export type Profile = {
  __typename: "Profile",
  id: string,
  nickname?: string | null,
  owner?: string | null,
  score: number,
  visibility: ProfileVisibility,
  rank?: number | null,
  stats: ProfileStats,
  createdAt: string,
  updatedAt: string,
};

export type ProfileStats = {
  __typename: "ProfileStats",
  participatedGames: number,
  participatedChallengeTips: number,
  successfulChallengeTips: number,
};

export type ModelIssuedRewardFilterInput = {
  id?: ModelIDInput | null,
  rewardId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  redemptionTimestamp?: ModelStringInput | null,
  and?: Array< ModelIssuedRewardFilterInput | null > | null,
  or?: Array< ModelIssuedRewardFilterInput | null > | null,
  not?: ModelIssuedRewardFilterInput | null,
};

export type ModelRewardFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  value?: ModelIntInput | null,
  archived?: ModelBooleanInput | null,
  redemptionInfo?: ModelStringInput | null,
  sponsorId?: ModelIDInput | null,
  and?: Array< ModelRewardFilterInput | null > | null,
  or?: Array< ModelRewardFilterInput | null > | null,
  not?: ModelRewardFilterInput | null,
};

export type ModelChallengeFilterInput = {
  id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  question?: ModelStringInput | null,
  points?: ModelIntInput | null,
  sponsorId?: ModelIDInput | null,
  stadiumOnly?: ModelBooleanInput | null,
  archived?: ModelBooleanInput | null,
  type?: ModelChallengeTypeInput | null,
  and?: Array< ModelChallengeFilterInput | null > | null,
  or?: Array< ModelChallengeFilterInput | null > | null,
  not?: ModelChallengeFilterInput | null,
};

export type ModelChallengeTypeInput = {
  eq?: ChallengeType | null,
  ne?: ChallengeType | null,
};

export type ModelSponsorFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  website?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelSponsorFilterInput | null > | null,
  or?: Array< ModelSponsorFilterInput | null > | null,
  not?: ModelSponsorFilterInput | null,
};

export type ModelSponsorConnection = {
  __typename: "ModelSponsorConnection",
  items:  Array<Sponsor | null >,
  nextToken?: string | null,
};

export type CreateNewsletterSubscriptionInput = {
  id?: string | null,
  owner: string,
  email: string,
  status: NewsletterSubscriptionStatus,
};

export enum NewsletterSubscriptionStatus {
  Pending = "Pending",
  Confirmed = "Confirmed",
  Done = "Done",
}


export type ModelNewsletterSubscriptionConditionInput = {
  owner?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelNewsletterSubscriptionStatusInput | null,
  and?: Array< ModelNewsletterSubscriptionConditionInput | null > | null,
  or?: Array< ModelNewsletterSubscriptionConditionInput | null > | null,
  not?: ModelNewsletterSubscriptionConditionInput | null,
};

export type ModelNewsletterSubscriptionStatusInput = {
  eq?: NewsletterSubscriptionStatus | null,
  ne?: NewsletterSubscriptionStatus | null,
};

export type NewsletterSubscription = {
  __typename: "NewsletterSubscription",
  id: string,
  owner: string,
  email: string,
  status: NewsletterSubscriptionStatus,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNewsletterSubscriptionInput = {
  id: string,
  owner?: string | null,
  email?: string | null,
  status?: NewsletterSubscriptionStatus | null,
};

export type DeleteNewsletterSubscriptionInput = {
  id: string,
};

export type CreateChallengeInput = {
  id?: string | null,
  description?: string | null,
  question: string,
  points: number,
  sponsorId?: string | null,
  stadiumOnly?: boolean | null,
  archived?: boolean | null,
  type: ChallengeType,
};

export type ModelChallengeConditionInput = {
  description?: ModelStringInput | null,
  question?: ModelStringInput | null,
  points?: ModelIntInput | null,
  sponsorId?: ModelIDInput | null,
  stadiumOnly?: ModelBooleanInput | null,
  archived?: ModelBooleanInput | null,
  type?: ModelChallengeTypeInput | null,
  and?: Array< ModelChallengeConditionInput | null > | null,
  or?: Array< ModelChallengeConditionInput | null > | null,
  not?: ModelChallengeConditionInput | null,
};

export type UpdateChallengeInput = {
  id: string,
  description?: string | null,
  question?: string | null,
  points?: number | null,
  sponsorId?: string | null,
  stadiumOnly?: boolean | null,
  archived?: boolean | null,
  type?: ChallengeType | null,
};

export type DeleteChallengeInput = {
  id: string,
};

export type CreatePlayerInput = {
  id?: string | null,
  firstname: string,
  lastname: string,
  position: PlayerPosition,
  number: number,
  image: string,
};

export type ModelPlayerConditionInput = {
  firstname?: ModelStringInput | null,
  lastname?: ModelStringInput | null,
  position?: ModelPlayerPositionInput | null,
  number?: ModelIntInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelPlayerConditionInput | null > | null,
  or?: Array< ModelPlayerConditionInput | null > | null,
  not?: ModelPlayerConditionInput | null,
};

export type ModelPlayerPositionInput = {
  eq?: PlayerPosition | null,
  ne?: PlayerPosition | null,
};

export type UpdatePlayerInput = {
  id: string,
  firstname?: string | null,
  lastname?: string | null,
  position?: PlayerPosition | null,
  number?: number | null,
  image?: string | null,
};

export type DeletePlayerInput = {
  id: string,
};

export type DeleteGameInput = {
  id: string,
};

export type DeleteChallengeApplicationInput = {
  id: string,
};

export type CreateSponsorInput = {
  id?: string | null,
  name: string,
  website: string,
  tagline?: string | null,
  archived?: boolean | null,
  image: string,
};

export type ModelSponsorConditionInput = {
  name?: ModelStringInput | null,
  website?: ModelStringInput | null,
  tagline?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelSponsorConditionInput | null > | null,
  or?: Array< ModelSponsorConditionInput | null > | null,
  not?: ModelSponsorConditionInput | null,
};

export type UpdateSponsorInput = {
  id: string,
  name?: string | null,
  website?: string | null,
  tagline?: string | null,
  archived?: boolean | null,
  image?: string | null,
};

export type DeleteSponsorInput = {
  id: string,
};

export type CreateRewardApplicationInput = {
  id?: string | null,
  amount?: number | null,
  gameId: string,
  rewardId: string,
  order?: number | null,
};

export type ModelRewardApplicationConditionInput = {
  amount?: ModelIntInput | null,
  gameId?: ModelIDInput | null,
  rewardId?: ModelIDInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelRewardApplicationConditionInput | null > | null,
  or?: Array< ModelRewardApplicationConditionInput | null > | null,
  not?: ModelRewardApplicationConditionInput | null,
};

export type UpdateRewardApplicationInput = {
  id: string,
  amount?: number | null,
  gameId?: string | null,
  rewardId?: string | null,
  order?: number | null,
};

export type DeleteRewardApplicationInput = {
  id: string,
};

export type CreateChallengeRewardApplicationInput = {
  id?: string | null,
  amount?: number | null,
  challengeApplicationId: string,
  rewardId: string,
  order?: number | null,
};

export type ModelChallengeRewardApplicationConditionInput = {
  amount?: ModelIntInput | null,
  challengeApplicationId?: ModelIDInput | null,
  rewardId?: ModelIDInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelChallengeRewardApplicationConditionInput | null > | null,
  or?: Array< ModelChallengeRewardApplicationConditionInput | null > | null,
  not?: ModelChallengeRewardApplicationConditionInput | null,
};

export type UpdateChallengeRewardApplicationInput = {
  id: string,
  amount?: number | null,
  challengeApplicationId?: string | null,
  rewardId?: string | null,
  order?: number | null,
};

export type DeleteChallengeRewardApplicationInput = {
  id: string,
};

export type CreateRewardInput = {
  id?: string | null,
  name: string,
  value?: number | null,
  archived?: boolean | null,
  redemptionInfo: string,
  sponsorId?: string | null,
};

export type ModelRewardConditionInput = {
  name?: ModelStringInput | null,
  value?: ModelIntInput | null,
  archived?: ModelBooleanInput | null,
  redemptionInfo?: ModelStringInput | null,
  sponsorId?: ModelIDInput | null,
  and?: Array< ModelRewardConditionInput | null > | null,
  or?: Array< ModelRewardConditionInput | null > | null,
  not?: ModelRewardConditionInput | null,
};

export type UpdateRewardInput = {
  id: string,
  name?: string | null,
  value?: number | null,
  archived?: boolean | null,
  redemptionInfo?: string | null,
  sponsorId?: string | null,
};

export type DeleteRewardInput = {
  id: string,
};

export type CreateIssuedRewardInput = {
  id?: string | null,
  rewardId: string,
  owner: string,
  redemptionTimestamp?: string | null,
};

export type ModelIssuedRewardConditionInput = {
  rewardId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  redemptionTimestamp?: ModelStringInput | null,
  and?: Array< ModelIssuedRewardConditionInput | null > | null,
  or?: Array< ModelIssuedRewardConditionInput | null > | null,
  not?: ModelIssuedRewardConditionInput | null,
};

export type UpdateIssuedRewardInput = {
  id: string,
  rewardId?: string | null,
  owner?: string | null,
  redemptionTimestamp?: string | null,
};

export type DeleteIssuedRewardInput = {
  id: string,
};

export type CreateObserverInput = {
  id?: string | null,
  topic: ObserverTopic,
  subscription: string,
};

export enum ObserverTopic {
  GameStart = "GameStart",
}


export type ModelObserverConditionInput = {
  topic?: ModelObserverTopicInput | null,
  subscription?: ModelStringInput | null,
  and?: Array< ModelObserverConditionInput | null > | null,
  or?: Array< ModelObserverConditionInput | null > | null,
  not?: ModelObserverConditionInput | null,
};

export type ModelObserverTopicInput = {
  eq?: ObserverTopic | null,
  ne?: ObserverTopic | null,
};

export type Observer = {
  __typename: "Observer",
  id: string,
  topic: ObserverTopic,
  subscription: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateObserverInput = {
  id: string,
  topic?: ObserverTopic | null,
  subscription?: string | null,
};

export type DeleteObserverInput = {
  id: string,
};

export type CreateGamePlayerInput = {
  id?: string | null,
  playerID: string,
  gameID: string,
};

export type ModelGamePlayerConditionInput = {
  playerID?: ModelIDInput | null,
  gameID?: ModelIDInput | null,
  and?: Array< ModelGamePlayerConditionInput | null > | null,
  or?: Array< ModelGamePlayerConditionInput | null > | null,
  not?: ModelGamePlayerConditionInput | null,
};

export type UpdateGamePlayerInput = {
  id: string,
  playerID?: string | null,
  gameID?: string | null,
};

export type DeleteGamePlayerInput = {
  id: string,
};

export type ModelNewsletterSubscriptionFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelNewsletterSubscriptionStatusInput | null,
  and?: Array< ModelNewsletterSubscriptionFilterInput | null > | null,
  or?: Array< ModelNewsletterSubscriptionFilterInput | null > | null,
  not?: ModelNewsletterSubscriptionFilterInput | null,
};

export type ModelNewsletterSubscriptionConnection = {
  __typename: "ModelNewsletterSubscriptionConnection",
  items:  Array<NewsletterSubscription | null >,
  nextToken?: string | null,
};

export type ModelPlayerFilterInput = {
  id?: ModelIDInput | null,
  firstname?: ModelStringInput | null,
  lastname?: ModelStringInput | null,
  position?: ModelPlayerPositionInput | null,
  number?: ModelIntInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelPlayerFilterInput | null > | null,
  or?: Array< ModelPlayerFilterInput | null > | null,
  not?: ModelPlayerFilterInput | null,
};

export type ModelPlayerConnection = {
  __typename: "ModelPlayerConnection",
  items:  Array<Player | null >,
  nextToken?: string | null,
};

export type ModelChallengeApplicationFilterInput = {
  id?: ModelIDInput | null,
  startTimestamp?: ModelStringInput | null,
  endTimestamp?: ModelStringInput | null,
  challengeId?: ModelIDInput | null,
  gameId?: ModelIDInput | null,
  tipOptions?: ModelStringInput | null,
  solution?: ModelStringInput | null,
  sponsorId?: ModelIDInput | null,
  and?: Array< ModelChallengeApplicationFilterInput | null > | null,
  or?: Array< ModelChallengeApplicationFilterInput | null > | null,
  not?: ModelChallengeApplicationFilterInput | null,
};

export type ModelChallengeTipFilterInput = {
  id?: ModelIDInput | null,
  challengeApplicationId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  points?: ModelIntInput | null,
  and?: Array< ModelChallengeTipFilterInput | null > | null,
  or?: Array< ModelChallengeTipFilterInput | null > | null,
  not?: ModelChallengeTipFilterInput | null,
};

export type ModelRewardApplicationFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  gameId?: ModelIDInput | null,
  rewardId?: ModelIDInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelRewardApplicationFilterInput | null > | null,
  or?: Array< ModelRewardApplicationFilterInput | null > | null,
  not?: ModelRewardApplicationFilterInput | null,
};

export type ModelChallengeRewardApplicationFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  challengeApplicationId?: ModelIDInput | null,
  rewardId?: ModelIDInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelChallengeRewardApplicationFilterInput | null > | null,
  or?: Array< ModelChallengeRewardApplicationFilterInput | null > | null,
  not?: ModelChallengeRewardApplicationFilterInput | null,
};

export type ModelObserverFilterInput = {
  id?: ModelIDInput | null,
  topic?: ModelObserverTopicInput | null,
  subscription?: ModelStringInput | null,
  and?: Array< ModelObserverFilterInput | null > | null,
  or?: Array< ModelObserverFilterInput | null > | null,
  not?: ModelObserverFilterInput | null,
};

export type ModelObserverConnection = {
  __typename: "ModelObserverConnection",
  items:  Array<Observer | null >,
  nextToken?: string | null,
};

export type ModelGamePlayerFilterInput = {
  id?: ModelIDInput | null,
  playerID?: ModelIDInput | null,
  gameID?: ModelIDInput | null,
  and?: Array< ModelGamePlayerFilterInput | null > | null,
  or?: Array< ModelGamePlayerFilterInput | null > | null,
  not?: ModelGamePlayerFilterInput | null,
};

export type SetChallengeTip1MutationVariables = {
  applicationId: string,
  answer: string,
};

export type SetChallengeTip1Mutation = {
  setChallengeTip?:  {
    __typename: "ChallengeTip",
    id: string,
    challengeApplicationId: string,
    owner: string,
    answer: string,
    points?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGameWithRequiredDepthMutationVariables = {
  input: CreateGameInput,
  condition?: ModelGameConditionInput | null,
};

export type CreateGameWithRequiredDepthMutation = {
  createGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      items:  Array< {
        __typename: "ChallengeApplication",
        id: string,
        startTimestamp?: string | null,
        endTimestamp?: string | null,
        challengeId: string,
        challenge:  {
          __typename: "Challenge",
          id: string,
          description?: string | null,
          question: string,
          points: number,
          stadiumOnly?: boolean | null,
          archived?: boolean | null,
          type: ChallengeType,
          createdAt: string,
          updatedAt: string,
        },
        sponsor?:  {
          __typename: "Sponsor",
          id: string,
          name: string,
          website: string,
          tagline?: string | null,
          image: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sponsorId?: string | null,
        tipOptions?: Array< string | null > | null,
        solution?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      items:  Array< {
        __typename: "RewardApplication",
        id: string,
        amount?: number | null,
        gameId: string,
        rewardId: string,
        reward:  {
          __typename: "Reward",
          id: string,
          name: string,
          value?: number | null,
          archived?: boolean | null,
          redemptionInfo: string,
          sponsor?:  {
            __typename: "Sponsor",
            id: string,
            name: string,
            website: string,
            tagline?: string | null,
            image: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          sponsorId?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      items:  Array< {
        __typename: "GamePlayer",
        id: string,
        playerID: string,
        gameID: string,
        player:  {
          __typename: "Player",
          id: string,
          firstname: string,
          lastname: string,
          position: PlayerPosition,
          number: number,
          image: string,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGameWithRequiredDepthMutationVariables = {
  input: UpdateGameInput,
  condition?: ModelGameConditionInput | null,
};

export type UpdateGameWithRequiredDepthMutation = {
  updateGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      items:  Array< {
        __typename: "ChallengeApplication",
        id: string,
        startTimestamp?: string | null,
        endTimestamp?: string | null,
        challengeId: string,
        challenge:  {
          __typename: "Challenge",
          id: string,
          description?: string | null,
          question: string,
          points: number,
          stadiumOnly?: boolean | null,
          archived?: boolean | null,
          type: ChallengeType,
          createdAt: string,
          updatedAt: string,
        },
        sponsor?:  {
          __typename: "Sponsor",
          id: string,
          name: string,
          website: string,
          tagline?: string | null,
          image: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sponsorId?: string | null,
        rewards?:  {
          __typename: "ModelChallengeRewardApplicationConnection",
          items:  Array< {
            __typename: "ChallengeRewardApplication",
            id: string,
            amount?: number | null,
            challengeApplicationId: string,
            rewardId: string,
            reward:  {
              __typename: "Reward",
              id: string,
              name: string,
              value?: number | null,
              archived?: boolean | null,
              redemptionInfo: string,
              sponsor?:  {
                __typename: "Sponsor",
                id: string,
                name: string,
                website: string,
                tagline?: string | null,
                image: string,
                createdAt: string,
                updatedAt: string,
              } | null,
              sponsorId?: string | null,
              createdAt: string,
              updatedAt: string,
            },
            order?: number | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
        } | null,
        tipOptions?: Array< string | null > | null,
        solution?: string | null,
        challengeTips?:  {
          __typename: "ModelChallengeTipConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      items:  Array< {
        __typename: "RewardApplication",
        id: string,
        amount?: number | null,
        gameId: string,
        rewardId: string,
        reward:  {
          __typename: "Reward",
          id: string,
          name: string,
          value?: number | null,
          archived?: boolean | null,
          redemptionInfo: string,
          createdAt: string,
          updatedAt: string,
        },
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      items:  Array< {
        __typename: "GamePlayer",
        id: string,
        playerID: string,
        gameID: string,
        player:  {
          __typename: "Player",
          id: string,
          firstname: string,
          lastname: string,
          position: PlayerPosition,
          number: number,
          image: string,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateChallengeApplicationWithRequiredDepthMutationVariables = {
  input: CreateChallengeApplicationInput,
  condition?: ModelChallengeApplicationConditionInput | null,
};

export type CreateChallengeApplicationWithRequiredDepthMutation = {
  createChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      applications?:  {
        __typename: "ModelChallengeApplicationConnection",
        items:  Array< {
          __typename: "ChallengeApplication",
          id: string,
          startTimestamp?: string | null,
          endTimestamp?: string | null,
          challengeId: string,
          gameId: string,
          tipOptions?: Array< string | null > | null,
          solution?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    sponsorId?: string | null,
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      challenges?:  {
        __typename: "ModelChallengeApplicationConnection",
        items:  Array< {
          __typename: "ChallengeApplication",
          id: string,
          startTimestamp?: string | null,
          endTimestamp?: string | null,
          challengeId: string,
          gameId: string,
          tipOptions?: Array< string | null > | null,
          solution?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rewards?:  {
        __typename: "ModelRewardApplicationConnection",
        items:  Array< {
          __typename: "RewardApplication",
          id: string,
          amount?: number | null,
          gameId: string,
          rewardId: string,
          order?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      sponsorId?: string | null,
      sponsor?:  {
        __typename: "Sponsor",
        id: string,
        name: string,
        website: string,
        tagline?: string | null,
        games?:  {
          __typename: "ModelGameConnection",
          nextToken?: string | null,
        } | null,
        image: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      lineup?:  {
        __typename: "ModelGamePlayerConnection",
        items:  Array< {
          __typename: "GamePlayer",
          id: string,
          playerID: string,
          gameID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      items:  Array< {
        __typename: "ChallengeTip",
        id: string,
        challengeApplicationId: string,
        challengeApplication:  {
          __typename: "ChallengeApplication",
          id: string,
          startTimestamp?: string | null,
          endTimestamp?: string | null,
          challengeId: string,
          gameId: string,
          tipOptions?: Array< string | null > | null,
          solution?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        owner: string,
        answer: string,
        points?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      items:  Array< {
        __typename: "ChallengeRewardApplication",
        id: string,
        amount?: number | null,
        challengeApplicationId: string,
        rewardId: string,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateChallengeApplicationWithRewardsMutationVariables = {
  input: UpdateChallengeApplicationInput,
  condition?: ModelChallengeApplicationConditionInput | null,
};

export type UpdateChallengeApplicationWithRewardsMutation = {
  updateChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      items:  Array< {
        __typename: "ChallengeRewardApplication",
        id: string,
        amount?: number | null,
        challengeApplicationId: string,
        rewardId: string,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type evaluateChallengeApplicationWithRewardsAndTipsMutationVariables = {
  applicationId: string,
  solution: string,
};

export type evaluateChallengeApplicationWithRewardsAndTipsMutation = {
  evaluateChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      items:  Array< {
        __typename: "ChallengeTip",
        id: string,
        challengeApplicationId: string,
        owner: string,
        answer: string,
        points?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      items:  Array< {
        __typename: "ChallengeRewardApplication",
        id: string,
        amount?: number | null,
        challengeApplicationId: string,
        rewardId: string,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type listGamesWithTipsQueryVariables = {
  filter?: ModelGameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listGamesWithTipsQuery = {
  listGames?:  {
    __typename: "ModelGameConnection",
    items:  Array< {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsor?:  {
        __typename: "Sponsor",
        id: string,
        name: string,
        website: string,
        tagline?: string | null,
        image: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      lineup?:  {
        __typename: "ModelGamePlayerConnection",
        items:  Array< {
          __typename: "GamePlayer",
          id: string,
          playerID: string,
          player:  {
            __typename: "Player",
            id: string,
            firstname: string,
            lastname: string,
            position: PlayerPosition,
            number: number,
            image: string,
            createdAt: string,
            updatedAt: string,
          },
          gameID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
      } | null,
      rewards?:  {
        __typename: "ModelRewardApplicationConnection",
        items:  Array< {
          __typename: "RewardApplication",
          id: string,
          amount?: number | null,
          rewardId: string,
          reward:  {
            __typename: "Reward",
            id: string,
            name: string,
            value?: number | null,
            redemptionInfo: string,
            createdAt: string,
            updatedAt: string,
          },
          order?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      challenges?:  {
        __typename: "ModelChallengeApplicationConnection",
        items:  Array< {
          __typename: "ChallengeApplication",
          id: string,
          startTimestamp?: string | null,
          endTimestamp?: string | null,
          challengeId: string,
          challenge:  {
            __typename: "Challenge",
            id: string,
            description?: string | null,
            question: string,
            points: number,
            stadiumOnly?: boolean | null,
            archived?: boolean | null,
            type: ChallengeType,
            createdAt: string,
            updatedAt: string,
          },
          sponsor?:  {
            __typename: "Sponsor",
            id: string,
            name: string,
            website: string,
            tagline?: string | null,
            image: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          sponsorId?: string | null,
          tipOptions?: Array< string | null > | null,
          solution?: string | null,
          challengeTips?:  {
            __typename: "ModelChallengeTipConnection",
            items:  Array< {
              __typename: "ChallengeTip",
              answer: string,
              createdAt: string,
              id: string,
              owner: string,
              points?: number | null,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          rewards?:  {
            __typename: "ModelChallengeRewardApplicationConnection",
            items:  Array< {
              __typename: "ChallengeRewardApplication",
              id: string,
              amount?: number | null,
              challengeApplicationId: string,
              rewardId: string,
              reward:  {
                __typename: "Reward",
                id: string,
                name: string,
                value?: number | null,
                redemptionInfo: string,
                sponsor?:  {
                  __typename: "Sponsor",
                  id: string,
                  name: string,
                  website: string,
                  tagline?: string | null,
                  image: string,
                  createdAt: string,
                  updatedAt: string,
                } | null,
                sponsorId?: string | null,
                createdAt: string,
                updatedAt: string,
              },
              order?: number | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGamesWithoutTipsQueryVariables = {
  filter?: ModelGameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGamesWithoutTipsQuery = {
  listGames?:  {
    __typename: "ModelGameConnection",
    items:  Array< {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsor?:  {
        __typename: "Sponsor",
        id: string,
        name: string,
        website: string,
        tagline?: string | null,
        image: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      lineup?:  {
        __typename: "ModelGamePlayerConnection",
        items:  Array< {
          __typename: "GamePlayer",
          id: string,
          playerID: string,
          player:  {
            __typename: "Player",
            id: string,
            firstname: string,
            lastname: string,
            position: PlayerPosition,
            number: number,
            image: string,
            createdAt: string,
            updatedAt: string,
          },
          gameID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
      } | null,
      rewards?:  {
        __typename: "ModelRewardApplicationConnection",
        items:  Array< {
          __typename: "RewardApplication",
          id: string,
          amount?: number | null,
          rewardId: string,
          reward:  {
            __typename: "Reward",
            id: string,
            name: string,
            value?: number | null,
            redemptionInfo: string,
            createdAt: string,
            updatedAt: string,
          },
          order?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      challenges?:  {
        __typename: "ModelChallengeApplicationConnection",
        items:  Array< {
          __typename: "ChallengeApplication",
          id: string,
          startTimestamp?: string | null,
          endTimestamp?: string | null,
          challengeId: string,
          challenge:  {
            __typename: "Challenge",
            id: string,
            description?: string | null,
            question: string,
            points: number,
            stadiumOnly?: boolean | null,
            archived?: boolean | null,
            type: ChallengeType,
            createdAt: string,
            updatedAt: string,
          },
          sponsor?:  {
            __typename: "Sponsor",
            id: string,
            name: string,
            website: string,
            tagline?: string | null,
            image: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          sponsorId?: string | null,
          rewards?:  {
            __typename: "ModelChallengeRewardApplicationConnection",
            items:  Array< {
              __typename: "ChallengeRewardApplication",
              id: string,
              amount?: number | null,
              challengeApplicationId: string,
              rewardId: string,
              reward:  {
                __typename: "Reward",
                id: string,
                name: string,
                value?: number | null,
                redemptionInfo: string,
                sponsor?:  {
                  __typename: "Sponsor",
                  id: string,
                  name: string,
                  website: string,
                  tagline?: string | null,
                  image: string,
                  createdAt: string,
                  updatedAt: string,
                } | null,
                sponsorId?: string | null,
                createdAt: string,
                updatedAt: string,
              },
              order?: number | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tipOptions?: Array< string | null > | null,
          solution?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getActiveGameQuery = {
  listGames?:  {
    __typename: "ModelGameConnection",
    nextToken?: string | null,
    items:  Array< {
      __typename: "Game",
      createdAt: string,
      description?: string | null,
      challenges?:  {
        __typename: "ModelChallengeApplicationConnection",
        items:  Array< {
          __typename: "ChallengeApplication",
          challengeId: string,
          createdAt: string,
          id: string,
        } | null >,
      } | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
    } | null >,
  } | null,
};

export type getRankingWithoutOwnerQueryVariables = {
  visibility: ProfileVisibility,
  score?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type getRankingWithoutOwnerQuery = {
  getRanking?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      nickname?: string | null,
      score: number,
      visibility: ProfileVisibility,
      rank?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIssuedRewardsWithRewardQueryVariables = {
  filter?: ModelIssuedRewardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIssuedRewardsWithRewardQuery = {
  listIssuedRewards?:  {
    __typename: "ModelIssuedRewardConnection",
    items:  Array< {
      __typename: "IssuedReward",
      id: string,
      rewardId: string,
      reward:  {
        __typename: "Reward",
        id: string,
        name: string,
        value?: number | null,
        archived?: boolean | null,
        redemptionInfo: string,
        sponsorId?: string | null,
        sponsor?:  {
          __typename: "Sponsor",
          id: string,
          name: string,
          website: string,
          tagline?: string | null,
          image: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      owner: string,
      redemptionTimestamp?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfileByOwnerWithStatsQueryVariables = {
  owner: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProfileByOwnerWithStatsQuery = {
  getProfileByOwner?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      nickname?: string | null,
      owner?: string | null,
      score: number,
      visibility: ProfileVisibility,
      rank?: number | null,
      stats:  {
        __typename: "ProfileStats",
        participatedGames: number,
        participatedChallengeTips: number,
        successfulChallengeTips: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listRewardsWithSponsorQueryVariables = {
  filter?: ModelRewardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listRewardsWithSponsorQuery = {
  listRewards?:  {
    __typename: "ModelRewardConnection",
    items:  Array< {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      sponsor?:  {
        __typename: "Sponsor",
        id: string,
        name: string,
        website: string,
        tagline?: string | null,
        image: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListChallengesWithSponsorQueryVariables = {
  filter?: ModelChallengeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChallengesWithSponsorQuery = {
  listChallenges?:  {
    __typename: "ModelChallengeConnection",
    items:  Array< {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
      sponsorId?: string | null,
      sponsor?:  {
        __typename: "Sponsor",
        id: string,
        name: string,
        website: string,
        tagline?: string | null,
        image: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listSponsorsWithChallengesAndRewardsQueryVariables = {
  filter?: ModelSponsorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listSponsorsWithChallengesAndRewardsQuery = {
  listSponsors?:  {
    __typename: "ModelSponsorConnection",
    items:  Array< {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
      challenges2?:  {
        __typename: "ModelChallengeConnection",
        items:  Array< {
          __typename: "Challenge",
          id: string,
          description?: string | null,
          question: string,
          points: number,
          stadiumOnly?: boolean | null,
          archived?: boolean | null,
          type: ChallengeType,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      rewards?:  {
        __typename: "ModelRewardConnection",
        items:  Array< {
          __typename: "Reward",
          id: string,
          name: string,
          value?: number | null,
          redemptionInfo: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type onUpdateGameWithChallengeSubscription = {
  onUpdateGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      items:  Array< {
        __typename: "ChallengeApplication",
        id: string,
        startTimestamp?: string | null,
        endTimestamp?: string | null,
        challengeId: string,
        challenge:  {
          __typename: "Challenge",
          id: string,
          description?: string | null,
          question: string,
          points: number,
          stadiumOnly?: boolean | null,
          archived?: boolean | null,
          type: ChallengeType,
          createdAt: string,
          updatedAt: string,
        },
        sponsor?:  {
          __typename: "Sponsor",
          id: string,
          name: string,
          website: string,
          tagline?: string | null,
          image: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        sponsorId?: string | null,
        gameId: string,
        tipOptions?: Array< string | null > | null,
        solution?: string | null,
        challengeTips?:  {
          __typename: "ModelChallengeTipConnection",
          items:  Array< {
            __typename: "ChallengeTip",
            id: string,
            challengeApplicationId: string,
            owner: string,
            answer: string,
            points?: number | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      items:  Array< {
        __typename: "RewardApplication",
        id: string,
        amount?: number | null,
        gameId: string,
        rewardId: string,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type onEvaluateChallengeApplicationWithTipsSubscription = {
  onEvaluateChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    sponsorId?: string | null,
    gameId: string,
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      items:  Array< {
        __typename: "ChallengeTip",
        id: string,
        owner: string,
        answer: string,
        points?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIssuedRewardWithRewardSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateIssuedRewardWithRewardSubscription = {
  onCreateIssuedReward?:  {
    __typename: "IssuedReward",
    id: string,
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    redemptionTimestamp?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateChallengeApplicationWithChallengeTipsSubscription = {
  onCreateChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    sponsorId?: string | null,
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      items:  Array< {
        __typename: "ChallengeTip",
        answer: string,
        challengeApplicationId: string,
        createdAt: string,
        id: string,
        owner: string,
        points?: number | null,
        updatedAt: string,
      } | null >,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNewsletterSubscriptionMutationVariables = {
  input: CreateNewsletterSubscriptionInput,
  condition?: ModelNewsletterSubscriptionConditionInput | null,
};

export type CreateNewsletterSubscriptionMutation = {
  createNewsletterSubscription?:  {
    __typename: "NewsletterSubscription",
    id: string,
    owner: string,
    email: string,
    status: NewsletterSubscriptionStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNewsletterSubscriptionMutationVariables = {
  input: UpdateNewsletterSubscriptionInput,
  condition?: ModelNewsletterSubscriptionConditionInput | null,
};

export type UpdateNewsletterSubscriptionMutation = {
  updateNewsletterSubscription?:  {
    __typename: "NewsletterSubscription",
    id: string,
    owner: string,
    email: string,
    status: NewsletterSubscriptionStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNewsletterSubscriptionMutationVariables = {
  input: DeleteNewsletterSubscriptionInput,
  condition?: ModelNewsletterSubscriptionConditionInput | null,
};

export type DeleteNewsletterSubscriptionMutation = {
  deleteNewsletterSubscription?:  {
    __typename: "NewsletterSubscription",
    id: string,
    owner: string,
    email: string,
    status: NewsletterSubscriptionStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateChallengeMutationVariables = {
  input: CreateChallengeInput,
  condition?: ModelChallengeConditionInput | null,
};

export type CreateChallengeMutation = {
  createChallenge?:  {
    __typename: "Challenge",
    id: string,
    description?: string | null,
    question: string,
    points: number,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stadiumOnly?: boolean | null,
    archived?: boolean | null,
    applications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    type: ChallengeType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateChallengeMutationVariables = {
  input: UpdateChallengeInput,
  condition?: ModelChallengeConditionInput | null,
};

export type UpdateChallengeMutation = {
  updateChallenge?:  {
    __typename: "Challenge",
    id: string,
    description?: string | null,
    question: string,
    points: number,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stadiumOnly?: boolean | null,
    archived?: boolean | null,
    applications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    type: ChallengeType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteChallengeMutationVariables = {
  input: DeleteChallengeInput,
  condition?: ModelChallengeConditionInput | null,
};

export type DeleteChallengeMutation = {
  deleteChallenge?:  {
    __typename: "Challenge",
    id: string,
    description?: string | null,
    question: string,
    points: number,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stadiumOnly?: boolean | null,
    archived?: boolean | null,
    applications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    type: ChallengeType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePlayerMutationVariables = {
  input: CreatePlayerInput,
  condition?: ModelPlayerConditionInput | null,
};

export type CreatePlayerMutation = {
  createPlayer?:  {
    __typename: "Player",
    id: string,
    firstname: string,
    lastname: string,
    position: PlayerPosition,
    number: number,
    image: string,
    games?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePlayerMutationVariables = {
  input: UpdatePlayerInput,
  condition?: ModelPlayerConditionInput | null,
};

export type UpdatePlayerMutation = {
  updatePlayer?:  {
    __typename: "Player",
    id: string,
    firstname: string,
    lastname: string,
    position: PlayerPosition,
    number: number,
    image: string,
    games?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePlayerMutationVariables = {
  input: DeletePlayerInput,
  condition?: ModelPlayerConditionInput | null,
};

export type DeletePlayerMutation = {
  deletePlayer?:  {
    __typename: "Player",
    id: string,
    firstname: string,
    lastname: string,
    position: PlayerPosition,
    number: number,
    image: string,
    games?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGameMutationVariables = {
  input: CreateGameInput,
  condition?: ModelGameConditionInput | null,
};

export type CreateGameMutation = {
  createGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGameMutationVariables = {
  input: UpdateGameInput,
  condition?: ModelGameConditionInput | null,
};

export type UpdateGameMutation = {
  updateGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGameMutationVariables = {
  input: DeleteGameInput,
  condition?: ModelGameConditionInput | null,
};

export type DeleteGameMutation = {
  deleteGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateChallengeApplicationMutationVariables = {
  input: CreateChallengeApplicationInput,
  condition?: ModelChallengeApplicationConditionInput | null,
};

export type CreateChallengeApplicationMutation = {
  createChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateChallengeApplicationMutationVariables = {
  input: UpdateChallengeApplicationInput,
  condition?: ModelChallengeApplicationConditionInput | null,
};

export type UpdateChallengeApplicationMutation = {
  updateChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteChallengeApplicationMutationVariables = {
  input: DeleteChallengeApplicationInput,
  condition?: ModelChallengeApplicationConditionInput | null,
};

export type DeleteChallengeApplicationMutation = {
  deleteChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSponsorMutationVariables = {
  input: CreateSponsorInput,
  condition?: ModelSponsorConditionInput | null,
};

export type CreateSponsorMutation = {
  createSponsor?:  {
    __typename: "Sponsor",
    id: string,
    name: string,
    website: string,
    tagline?: string | null,
    games?:  {
      __typename: "ModelGameConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    challenges2?:  {
      __typename: "ModelChallengeConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    image: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSponsorMutationVariables = {
  input: UpdateSponsorInput,
  condition?: ModelSponsorConditionInput | null,
};

export type UpdateSponsorMutation = {
  updateSponsor?:  {
    __typename: "Sponsor",
    id: string,
    name: string,
    website: string,
    tagline?: string | null,
    games?:  {
      __typename: "ModelGameConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    challenges2?:  {
      __typename: "ModelChallengeConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    image: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSponsorMutationVariables = {
  input: DeleteSponsorInput,
  condition?: ModelSponsorConditionInput | null,
};

export type DeleteSponsorMutation = {
  deleteSponsor?:  {
    __typename: "Sponsor",
    id: string,
    name: string,
    website: string,
    tagline?: string | null,
    games?:  {
      __typename: "ModelGameConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    challenges2?:  {
      __typename: "ModelChallengeConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    image: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRewardApplicationMutationVariables = {
  input: CreateRewardApplicationInput,
  condition?: ModelRewardApplicationConditionInput | null,
};

export type CreateRewardApplicationMutation = {
  createRewardApplication?:  {
    __typename: "RewardApplication",
    id: string,
    amount?: number | null,
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRewardApplicationMutationVariables = {
  input: UpdateRewardApplicationInput,
  condition?: ModelRewardApplicationConditionInput | null,
};

export type UpdateRewardApplicationMutation = {
  updateRewardApplication?:  {
    __typename: "RewardApplication",
    id: string,
    amount?: number | null,
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRewardApplicationMutationVariables = {
  input: DeleteRewardApplicationInput,
  condition?: ModelRewardApplicationConditionInput | null,
};

export type DeleteRewardApplicationMutation = {
  deleteRewardApplication?:  {
    __typename: "RewardApplication",
    id: string,
    amount?: number | null,
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateChallengeRewardApplicationMutationVariables = {
  input: CreateChallengeRewardApplicationInput,
  condition?: ModelChallengeRewardApplicationConditionInput | null,
};

export type CreateChallengeRewardApplicationMutation = {
  createChallengeRewardApplication?:  {
    __typename: "ChallengeRewardApplication",
    id: string,
    amount?: number | null,
    challengeApplicationId: string,
    challengeApplication:  {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateChallengeRewardApplicationMutationVariables = {
  input: UpdateChallengeRewardApplicationInput,
  condition?: ModelChallengeRewardApplicationConditionInput | null,
};

export type UpdateChallengeRewardApplicationMutation = {
  updateChallengeRewardApplication?:  {
    __typename: "ChallengeRewardApplication",
    id: string,
    amount?: number | null,
    challengeApplicationId: string,
    challengeApplication:  {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteChallengeRewardApplicationMutationVariables = {
  input: DeleteChallengeRewardApplicationInput,
  condition?: ModelChallengeRewardApplicationConditionInput | null,
};

export type DeleteChallengeRewardApplicationMutation = {
  deleteChallengeRewardApplication?:  {
    __typename: "ChallengeRewardApplication",
    id: string,
    amount?: number | null,
    challengeApplicationId: string,
    challengeApplication:  {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRewardMutationVariables = {
  input: CreateRewardInput,
  condition?: ModelRewardConditionInput | null,
};

export type CreateRewardMutation = {
  createReward?:  {
    __typename: "Reward",
    id: string,
    name: string,
    value?: number | null,
    applications?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    issues?:  {
      __typename: "ModelIssuedRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    redemptionInfo: string,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRewardMutationVariables = {
  input: UpdateRewardInput,
  condition?: ModelRewardConditionInput | null,
};

export type UpdateRewardMutation = {
  updateReward?:  {
    __typename: "Reward",
    id: string,
    name: string,
    value?: number | null,
    applications?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    issues?:  {
      __typename: "ModelIssuedRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    redemptionInfo: string,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRewardMutationVariables = {
  input: DeleteRewardInput,
  condition?: ModelRewardConditionInput | null,
};

export type DeleteRewardMutation = {
  deleteReward?:  {
    __typename: "Reward",
    id: string,
    name: string,
    value?: number | null,
    applications?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    issues?:  {
      __typename: "ModelIssuedRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    redemptionInfo: string,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIssuedRewardMutationVariables = {
  input: CreateIssuedRewardInput,
  condition?: ModelIssuedRewardConditionInput | null,
};

export type CreateIssuedRewardMutation = {
  createIssuedReward?:  {
    __typename: "IssuedReward",
    id: string,
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    redemptionTimestamp?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIssuedRewardMutationVariables = {
  input: UpdateIssuedRewardInput,
  condition?: ModelIssuedRewardConditionInput | null,
};

export type UpdateIssuedRewardMutation = {
  updateIssuedReward?:  {
    __typename: "IssuedReward",
    id: string,
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    redemptionTimestamp?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIssuedRewardMutationVariables = {
  input: DeleteIssuedRewardInput,
  condition?: ModelIssuedRewardConditionInput | null,
};

export type DeleteIssuedRewardMutation = {
  deleteIssuedReward?:  {
    __typename: "IssuedReward",
    id: string,
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    redemptionTimestamp?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateObserverMutationVariables = {
  input: CreateObserverInput,
  condition?: ModelObserverConditionInput | null,
};

export type CreateObserverMutation = {
  createObserver?:  {
    __typename: "Observer",
    id: string,
    topic: ObserverTopic,
    subscription: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateObserverMutationVariables = {
  input: UpdateObserverInput,
  condition?: ModelObserverConditionInput | null,
};

export type UpdateObserverMutation = {
  updateObserver?:  {
    __typename: "Observer",
    id: string,
    topic: ObserverTopic,
    subscription: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteObserverMutationVariables = {
  input: DeleteObserverInput,
  condition?: ModelObserverConditionInput | null,
};

export type DeleteObserverMutation = {
  deleteObserver?:  {
    __typename: "Observer",
    id: string,
    topic: ObserverTopic,
    subscription: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGamePlayerMutationVariables = {
  input: CreateGamePlayerInput,
  condition?: ModelGamePlayerConditionInput | null,
};

export type CreateGamePlayerMutation = {
  createGamePlayer?:  {
    __typename: "GamePlayer",
    id: string,
    playerID: string,
    gameID: string,
    player:  {
      __typename: "Player",
      id: string,
      firstname: string,
      lastname: string,
      position: PlayerPosition,
      number: number,
      image: string,
      createdAt: string,
      updatedAt: string,
    },
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGamePlayerMutationVariables = {
  input: UpdateGamePlayerInput,
  condition?: ModelGamePlayerConditionInput | null,
};

export type UpdateGamePlayerMutation = {
  updateGamePlayer?:  {
    __typename: "GamePlayer",
    id: string,
    playerID: string,
    gameID: string,
    player:  {
      __typename: "Player",
      id: string,
      firstname: string,
      lastname: string,
      position: PlayerPosition,
      number: number,
      image: string,
      createdAt: string,
      updatedAt: string,
    },
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGamePlayerMutationVariables = {
  input: DeleteGamePlayerInput,
  condition?: ModelGamePlayerConditionInput | null,
};

export type DeleteGamePlayerMutation = {
  deleteGamePlayer?:  {
    __typename: "GamePlayer",
    id: string,
    playerID: string,
    gameID: string,
    player:  {
      __typename: "Player",
      id: string,
      firstname: string,
      lastname: string,
      position: PlayerPosition,
      number: number,
      image: string,
      createdAt: string,
      updatedAt: string,
    },
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type SetChallengeTipMutationVariables = {
  applicationId: string,
  answer: string,
};

export type SetChallengeTipMutation = {
  setChallengeTip?:  {
    __typename: "ChallengeTip",
    id: string,
    challengeApplicationId: string,
    challengeApplication:  {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    answer: string,
    points?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type EvaluateChallengeApplicationMutationVariables = {
  applicationId: string,
  solution: string,
};

export type EvaluateChallengeApplicationMutation = {
  evaluateChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type EvaluateGameMutationVariables = {
  gameId: string,
};

export type EvaluateGameMutation = {
  evaluateGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type MigrateAnonymousUserMutationVariables = {
  accessToken: string,
};

export type MigrateAnonymousUserMutation = {
  migrateAnonymousUser?: string | null,
};

export type GenerateRankingMutation = {
  generateRanking?:  Array< {
    __typename: "Profile",
    id: string,
    nickname?: string | null,
    owner?: string | null,
    score: number,
    visibility: ProfileVisibility,
    rank?: number | null,
    stats:  {
      __typename: "ProfileStats",
      participatedGames: number,
      participatedChallengeTips: number,
      successfulChallengeTips: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type UpdateOwnProfileMutationVariables = {
  nickname?: string | null,
  visibility?: ProfileVisibility | null,
};

export type UpdateOwnProfileMutation = {
  updateOwnProfile?:  {
    __typename: "Profile",
    id: string,
    nickname?: string | null,
    owner?: string | null,
    score: number,
    visibility: ProfileVisibility,
    rank?: number | null,
    stats:  {
      __typename: "ProfileStats",
      participatedGames: number,
      participatedChallengeTips: number,
      successfulChallengeTips: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type RedeemIssuedRewardMutationVariables = {
  id: string,
};

export type RedeemIssuedRewardMutation = {
  redeemIssuedReward?:  {
    __typename: "IssuedReward",
    id: string,
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    redemptionTimestamp?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRegisteredUserMutation = {
  deleteRegisteredUser?: string | null,
};

export type SetNewsletterSubscriptionConfirmedMutation = {
  setNewsletterSubscriptionConfirmed?: string | null,
};

export type GetNewsletterSubscriptionQueryVariables = {
  id: string,
};

export type GetNewsletterSubscriptionQuery = {
  getNewsletterSubscription?:  {
    __typename: "NewsletterSubscription",
    id: string,
    owner: string,
    email: string,
    status: NewsletterSubscriptionStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNewsletterSubscriptionsQueryVariables = {
  filter?: ModelNewsletterSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNewsletterSubscriptionsQuery = {
  listNewsletterSubscriptions?:  {
    __typename: "ModelNewsletterSubscriptionConnection",
    items:  Array< {
      __typename: "NewsletterSubscription",
      id: string,
      owner: string,
      email: string,
      status: NewsletterSubscriptionStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChallengeQueryVariables = {
  id: string,
};

export type GetChallengeQuery = {
  getChallenge?:  {
    __typename: "Challenge",
    id: string,
    description?: string | null,
    question: string,
    points: number,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stadiumOnly?: boolean | null,
    archived?: boolean | null,
    applications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    type: ChallengeType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListChallengesQueryVariables = {
  filter?: ModelChallengeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChallengesQuery = {
  listChallenges?:  {
    __typename: "ModelChallengeConnection",
    items:  Array< {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlayerQueryVariables = {
  id: string,
};

export type GetPlayerQuery = {
  getPlayer?:  {
    __typename: "Player",
    id: string,
    firstname: string,
    lastname: string,
    position: PlayerPosition,
    number: number,
    image: string,
    games?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPlayersQueryVariables = {
  filter?: ModelPlayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlayersQuery = {
  listPlayers?:  {
    __typename: "ModelPlayerConnection",
    items:  Array< {
      __typename: "Player",
      id: string,
      firstname: string,
      lastname: string,
      position: PlayerPosition,
      number: number,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGameQueryVariables = {
  id: string,
};

export type GetGameQuery = {
  getGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGamesQueryVariables = {
  filter?: ModelGameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGamesQuery = {
  listGames?:  {
    __typename: "ModelGameConnection",
    items:  Array< {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChallengeApplicationQueryVariables = {
  id: string,
};

export type GetChallengeApplicationQuery = {
  getChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListChallengeApplicationsQueryVariables = {
  filter?: ModelChallengeApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChallengeApplicationsQuery = {
  listChallengeApplications?:  {
    __typename: "ModelChallengeApplicationConnection",
    items:  Array< {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChallengeTipQueryVariables = {
  id: string,
};

export type GetChallengeTipQuery = {
  getChallengeTip?:  {
    __typename: "ChallengeTip",
    id: string,
    challengeApplicationId: string,
    challengeApplication:  {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    answer: string,
    points?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListChallengeTipsQueryVariables = {
  filter?: ModelChallengeTipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChallengeTipsQuery = {
  listChallengeTips?:  {
    __typename: "ModelChallengeTipConnection",
    items:  Array< {
      __typename: "ChallengeTip",
      id: string,
      challengeApplicationId: string,
      owner: string,
      answer: string,
      points?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfileQueryVariables = {
  id: string,
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    id: string,
    nickname?: string | null,
    owner?: string | null,
    score: number,
    visibility: ProfileVisibility,
    rank?: number | null,
    stats:  {
      __typename: "ProfileStats",
      participatedGames: number,
      participatedChallengeTips: number,
      successfulChallengeTips: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProfilesQueryVariables = {
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesQuery = {
  listProfiles?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      nickname?: string | null,
      owner?: string | null,
      score: number,
      visibility: ProfileVisibility,
      rank?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProfileByOwnerQueryVariables = {
  owner: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetProfileByOwnerQuery = {
  getProfileByOwner?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      nickname?: string | null,
      owner?: string | null,
      score: number,
      visibility: ProfileVisibility,
      rank?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRankingQueryVariables = {
  visibility: ProfileVisibility,
  score?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetRankingQuery = {
  getRanking?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      nickname?: string | null,
      owner?: string | null,
      score: number,
      visibility: ProfileVisibility,
      rank?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSponsorQueryVariables = {
  id: string,
};

export type GetSponsorQuery = {
  getSponsor?:  {
    __typename: "Sponsor",
    id: string,
    name: string,
    website: string,
    tagline?: string | null,
    games?:  {
      __typename: "ModelGameConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    challenges2?:  {
      __typename: "ModelChallengeConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    image: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSponsorsQueryVariables = {
  filter?: ModelSponsorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSponsorsQuery = {
  listSponsors?:  {
    __typename: "ModelSponsorConnection",
    items:  Array< {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRewardApplicationQueryVariables = {
  id: string,
};

export type GetRewardApplicationQuery = {
  getRewardApplication?:  {
    __typename: "RewardApplication",
    id: string,
    amount?: number | null,
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRewardApplicationsQueryVariables = {
  filter?: ModelRewardApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRewardApplicationsQuery = {
  listRewardApplications?:  {
    __typename: "ModelRewardApplicationConnection",
    items:  Array< {
      __typename: "RewardApplication",
      id: string,
      amount?: number | null,
      gameId: string,
      rewardId: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChallengeRewardApplicationQueryVariables = {
  id: string,
};

export type GetChallengeRewardApplicationQuery = {
  getChallengeRewardApplication?:  {
    __typename: "ChallengeRewardApplication",
    id: string,
    amount?: number | null,
    challengeApplicationId: string,
    challengeApplication:  {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListChallengeRewardApplicationsQueryVariables = {
  filter?: ModelChallengeRewardApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChallengeRewardApplicationsQuery = {
  listChallengeRewardApplications?:  {
    __typename: "ModelChallengeRewardApplicationConnection",
    items:  Array< {
      __typename: "ChallengeRewardApplication",
      id: string,
      amount?: number | null,
      challengeApplicationId: string,
      rewardId: string,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRewardQueryVariables = {
  id: string,
};

export type GetRewardQuery = {
  getReward?:  {
    __typename: "Reward",
    id: string,
    name: string,
    value?: number | null,
    applications?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    issues?:  {
      __typename: "ModelIssuedRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    redemptionInfo: string,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRewardsQueryVariables = {
  filter?: ModelRewardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRewardsQuery = {
  listRewards?:  {
    __typename: "ModelRewardConnection",
    items:  Array< {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIssuedRewardQueryVariables = {
  id: string,
};

export type GetIssuedRewardQuery = {
  getIssuedReward?:  {
    __typename: "IssuedReward",
    id: string,
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    redemptionTimestamp?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIssuedRewardsQueryVariables = {
  filter?: ModelIssuedRewardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIssuedRewardsQuery = {
  listIssuedRewards?:  {
    __typename: "ModelIssuedRewardConnection",
    items:  Array< {
      __typename: "IssuedReward",
      id: string,
      rewardId: string,
      owner: string,
      redemptionTimestamp?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetObserverQueryVariables = {
  id: string,
};

export type GetObserverQuery = {
  getObserver?:  {
    __typename: "Observer",
    id: string,
    topic: ObserverTopic,
    subscription: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListObserversQueryVariables = {
  filter?: ModelObserverFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListObserversQuery = {
  listObservers?:  {
    __typename: "ModelObserverConnection",
    items:  Array< {
      __typename: "Observer",
      id: string,
      topic: ObserverTopic,
      subscription: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetObserverBySubscriptionQueryVariables = {
  subscription: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelObserverFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetObserverBySubscriptionQuery = {
  getObserverBySubscription?:  {
    __typename: "ModelObserverConnection",
    items:  Array< {
      __typename: "Observer",
      id: string,
      topic: ObserverTopic,
      subscription: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGamePlayerQueryVariables = {
  id: string,
};

export type GetGamePlayerQuery = {
  getGamePlayer?:  {
    __typename: "GamePlayer",
    id: string,
    playerID: string,
    gameID: string,
    player:  {
      __typename: "Player",
      id: string,
      firstname: string,
      lastname: string,
      position: PlayerPosition,
      number: number,
      image: string,
      createdAt: string,
      updatedAt: string,
    },
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGamePlayersQueryVariables = {
  filter?: ModelGamePlayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGamePlayersQuery = {
  listGamePlayers?:  {
    __typename: "ModelGamePlayerConnection",
    items:  Array< {
      __typename: "GamePlayer",
      id: string,
      playerID: string,
      gameID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateNewsletterSubscriptionSubscription = {
  onCreateNewsletterSubscription?:  {
    __typename: "NewsletterSubscription",
    id: string,
    owner: string,
    email: string,
    status: NewsletterSubscriptionStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNewsletterSubscriptionSubscription = {
  onUpdateNewsletterSubscription?:  {
    __typename: "NewsletterSubscription",
    id: string,
    owner: string,
    email: string,
    status: NewsletterSubscriptionStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNewsletterSubscriptionSubscription = {
  onDeleteNewsletterSubscription?:  {
    __typename: "NewsletterSubscription",
    id: string,
    owner: string,
    email: string,
    status: NewsletterSubscriptionStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateChallengeSubscription = {
  onCreateChallenge?:  {
    __typename: "Challenge",
    id: string,
    description?: string | null,
    question: string,
    points: number,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stadiumOnly?: boolean | null,
    archived?: boolean | null,
    applications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    type: ChallengeType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateChallengeSubscription = {
  onUpdateChallenge?:  {
    __typename: "Challenge",
    id: string,
    description?: string | null,
    question: string,
    points: number,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stadiumOnly?: boolean | null,
    archived?: boolean | null,
    applications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    type: ChallengeType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteChallengeSubscription = {
  onDeleteChallenge?:  {
    __typename: "Challenge",
    id: string,
    description?: string | null,
    question: string,
    points: number,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stadiumOnly?: boolean | null,
    archived?: boolean | null,
    applications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    type: ChallengeType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePlayerSubscription = {
  onCreatePlayer?:  {
    __typename: "Player",
    id: string,
    firstname: string,
    lastname: string,
    position: PlayerPosition,
    number: number,
    image: string,
    games?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePlayerSubscription = {
  onUpdatePlayer?:  {
    __typename: "Player",
    id: string,
    firstname: string,
    lastname: string,
    position: PlayerPosition,
    number: number,
    image: string,
    games?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePlayerSubscription = {
  onDeletePlayer?:  {
    __typename: "Player",
    id: string,
    firstname: string,
    lastname: string,
    position: PlayerPosition,
    number: number,
    image: string,
    games?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGameSubscription = {
  onCreateGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGameSubscription = {
  onUpdateGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGameSubscription = {
  onDeleteGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateChallengeApplicationSubscription = {
  onCreateChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateChallengeApplicationSubscription = {
  onUpdateChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteChallengeApplicationSubscription = {
  onDeleteChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSponsorSubscription = {
  onCreateSponsor?:  {
    __typename: "Sponsor",
    id: string,
    name: string,
    website: string,
    tagline?: string | null,
    games?:  {
      __typename: "ModelGameConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    challenges2?:  {
      __typename: "ModelChallengeConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    image: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSponsorSubscription = {
  onUpdateSponsor?:  {
    __typename: "Sponsor",
    id: string,
    name: string,
    website: string,
    tagline?: string | null,
    games?:  {
      __typename: "ModelGameConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    challenges2?:  {
      __typename: "ModelChallengeConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    image: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSponsorSubscription = {
  onDeleteSponsor?:  {
    __typename: "Sponsor",
    id: string,
    name: string,
    website: string,
    tagline?: string | null,
    games?:  {
      __typename: "ModelGameConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    challenges2?:  {
      __typename: "ModelChallengeConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    image: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRewardApplicationSubscription = {
  onCreateRewardApplication?:  {
    __typename: "RewardApplication",
    id: string,
    amount?: number | null,
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRewardApplicationSubscription = {
  onUpdateRewardApplication?:  {
    __typename: "RewardApplication",
    id: string,
    amount?: number | null,
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRewardApplicationSubscription = {
  onDeleteRewardApplication?:  {
    __typename: "RewardApplication",
    id: string,
    amount?: number | null,
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateChallengeRewardApplicationSubscription = {
  onCreateChallengeRewardApplication?:  {
    __typename: "ChallengeRewardApplication",
    id: string,
    amount?: number | null,
    challengeApplicationId: string,
    challengeApplication:  {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateChallengeRewardApplicationSubscription = {
  onUpdateChallengeRewardApplication?:  {
    __typename: "ChallengeRewardApplication",
    id: string,
    amount?: number | null,
    challengeApplicationId: string,
    challengeApplication:  {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteChallengeRewardApplicationSubscription = {
  onDeleteChallengeRewardApplication?:  {
    __typename: "ChallengeRewardApplication",
    id: string,
    amount?: number | null,
    challengeApplicationId: string,
    challengeApplication:  {
      __typename: "ChallengeApplication",
      id: string,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      challengeId: string,
      gameId: string,
      tipOptions?: Array< string | null > | null,
      solution?: string | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRewardSubscription = {
  onCreateReward?:  {
    __typename: "Reward",
    id: string,
    name: string,
    value?: number | null,
    applications?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    issues?:  {
      __typename: "ModelIssuedRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    redemptionInfo: string,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRewardSubscription = {
  onUpdateReward?:  {
    __typename: "Reward",
    id: string,
    name: string,
    value?: number | null,
    applications?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    issues?:  {
      __typename: "ModelIssuedRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    redemptionInfo: string,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRewardSubscription = {
  onDeleteReward?:  {
    __typename: "Reward",
    id: string,
    name: string,
    value?: number | null,
    applications?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    challengeApplications?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    issues?:  {
      __typename: "ModelIssuedRewardConnection",
      nextToken?: string | null,
    } | null,
    archived?: boolean | null,
    redemptionInfo: string,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIssuedRewardSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateIssuedRewardSubscription = {
  onCreateIssuedReward?:  {
    __typename: "IssuedReward",
    id: string,
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    redemptionTimestamp?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIssuedRewardSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateIssuedRewardSubscription = {
  onUpdateIssuedReward?:  {
    __typename: "IssuedReward",
    id: string,
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    redemptionTimestamp?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIssuedRewardSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteIssuedRewardSubscription = {
  onDeleteIssuedReward?:  {
    __typename: "IssuedReward",
    id: string,
    rewardId: string,
    reward:  {
      __typename: "Reward",
      id: string,
      name: string,
      value?: number | null,
      archived?: boolean | null,
      redemptionInfo: string,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner: string,
    redemptionTimestamp?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateObserverSubscription = {
  onCreateObserver?:  {
    __typename: "Observer",
    id: string,
    topic: ObserverTopic,
    subscription: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateObserverSubscription = {
  onUpdateObserver?:  {
    __typename: "Observer",
    id: string,
    topic: ObserverTopic,
    subscription: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteObserverSubscription = {
  onDeleteObserver?:  {
    __typename: "Observer",
    id: string,
    topic: ObserverTopic,
    subscription: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGamePlayerSubscription = {
  onCreateGamePlayer?:  {
    __typename: "GamePlayer",
    id: string,
    playerID: string,
    gameID: string,
    player:  {
      __typename: "Player",
      id: string,
      firstname: string,
      lastname: string,
      position: PlayerPosition,
      number: number,
      image: string,
      createdAt: string,
      updatedAt: string,
    },
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGamePlayerSubscription = {
  onUpdateGamePlayer?:  {
    __typename: "GamePlayer",
    id: string,
    playerID: string,
    gameID: string,
    player:  {
      __typename: "Player",
      id: string,
      firstname: string,
      lastname: string,
      position: PlayerPosition,
      number: number,
      image: string,
      createdAt: string,
      updatedAt: string,
    },
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGamePlayerSubscription = {
  onDeleteGamePlayer?:  {
    __typename: "GamePlayer",
    id: string,
    playerID: string,
    gameID: string,
    player:  {
      __typename: "Player",
      id: string,
      firstname: string,
      lastname: string,
      position: PlayerPosition,
      number: number,
      image: string,
      createdAt: string,
      updatedAt: string,
    },
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnEvaluateChallengeApplicationSubscription = {
  onEvaluateChallengeApplication?:  {
    __typename: "ChallengeApplication",
    id: string,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    challengeId: string,
    challenge:  {
      __typename: "Challenge",
      id: string,
      description?: string | null,
      question: string,
      points: number,
      sponsorId?: string | null,
      stadiumOnly?: boolean | null,
      archived?: boolean | null,
      type: ChallengeType,
      createdAt: string,
      updatedAt: string,
    },
    gameId: string,
    game:  {
      __typename: "Game",
      id: string,
      description?: string | null,
      startTimestamp?: string | null,
      endTimestamp?: string | null,
      evaluationTimestamp?: string | null,
      automaticChallenges?: boolean | null,
      sponsorId?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    tipOptions?: Array< string | null > | null,
    solution?: string | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    challengeTips?:  {
      __typename: "ModelChallengeTipConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelChallengeRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnEvaluateGameSubscription = {
  onEvaluateGame?:  {
    __typename: "Game",
    id: string,
    description?: string | null,
    startTimestamp?: string | null,
    endTimestamp?: string | null,
    evaluationTimestamp?: string | null,
    automaticChallenges?: boolean | null,
    challenges?:  {
      __typename: "ModelChallengeApplicationConnection",
      nextToken?: string | null,
    } | null,
    rewards?:  {
      __typename: "ModelRewardApplicationConnection",
      nextToken?: string | null,
    } | null,
    sponsorId?: string | null,
    sponsor?:  {
      __typename: "Sponsor",
      id: string,
      name: string,
      website: string,
      tagline?: string | null,
      archived?: boolean | null,
      image: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    lineup?:  {
      __typename: "ModelGamePlayerConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
