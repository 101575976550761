import React, { MouseEvent, useEffect, useState } from 'react';
import { Button, Form, Input, Message, Modal } from 'semantic-ui-react';

interface EstimationModalProps {
    loading: boolean;
    open: boolean;
    setOpen: (state: boolean) => void;
    answer?: string;
    heading: string;
    submitResultText: string;
    submitEstimation: (result: string) => void;
    setErrorMessage: (text: string | undefined) => void;
    errorMessage: string | undefined;
    cancel: () => void;
}

function EstimationModal(props: EstimationModalProps) {
    const [estimation, setEstimation] = useState<number | undefined>();

    useEffect(() => {
        if (props.answer) {
            setEstimation(Number(props.answer));
        } else {
            setEstimation(undefined);
        }
    }, [props.open]);

    async function submitEstimation(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        if (estimation === undefined) {
            props.setErrorMessage('Schätzung ist ungültig.');
            return;
        }

        await props.submitEstimation(estimation.toString());
    }

    return (
        <Modal className='edit-challenges-modal' onClose={props.cancel} onOpen={() => props.setOpen(true)} open={props.open}>
            <Modal.Header>{props.heading}</Modal.Header>
            <Modal.Content>
                <Form error={!!props.errorMessage}>
                    <Form.Field required>
                        <label>Schätzung</label>
                        <Input
                            type='number'
                            value={estimation ?? ''}
                            placeholder='Schätzung als Zahl'
                            onChange={(e) => setEstimation(isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                            name='points'
                        />
                    </Form.Field>
                    <Message error content={props.errorMessage} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={props.cancel}>Abbrechen</Button>
                <Button loading={props.loading} disabled={estimation === undefined} className='secondary' onClick={submitEstimation}>
                    {props.submitResultText}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default EstimationModal;
