/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNewsletterSubscription = /* GraphQL */ `
  subscription OnCreateNewsletterSubscription {
    onCreateNewsletterSubscription {
      id
      owner
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNewsletterSubscription = /* GraphQL */ `
  subscription OnUpdateNewsletterSubscription {
    onUpdateNewsletterSubscription {
      id
      owner
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNewsletterSubscription = /* GraphQL */ `
  subscription OnDeleteNewsletterSubscription {
    onDeleteNewsletterSubscription {
      id
      owner
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChallenge = /* GraphQL */ `
  subscription OnCreateChallenge {
    onCreateChallenge {
      id
      description
      question
      points
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      stadiumOnly
      archived
      applications {
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChallenge = /* GraphQL */ `
  subscription OnUpdateChallenge {
    onUpdateChallenge {
      id
      description
      question
      points
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      stadiumOnly
      archived
      applications {
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChallenge = /* GraphQL */ `
  subscription OnDeleteChallenge {
    onDeleteChallenge {
      id
      description
      question
      points
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      stadiumOnly
      archived
      applications {
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePlayer = /* GraphQL */ `
  subscription OnCreatePlayer {
    onCreatePlayer {
      id
      firstname
      lastname
      position
      number
      image
      games {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePlayer = /* GraphQL */ `
  subscription OnUpdatePlayer {
    onUpdatePlayer {
      id
      firstname
      lastname
      position
      number
      image
      games {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePlayer = /* GraphQL */ `
  subscription OnDeletePlayer {
    onDeletePlayer {
      id
      firstname
      lastname
      position
      number
      image
      games {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGame = /* GraphQL */ `
  subscription OnCreateGame {
    onCreateGame {
      id
      description
      startTimestamp
      endTimestamp
      evaluationTimestamp
      automaticChallenges
      challenges {
        nextToken
      }
      rewards {
        nextToken
      }
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      lineup {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGame = /* GraphQL */ `
  subscription OnUpdateGame {
    onUpdateGame {
      id
      description
      startTimestamp
      endTimestamp
      evaluationTimestamp
      automaticChallenges
      challenges {
        nextToken
      }
      rewards {
        nextToken
      }
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      lineup {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGame = /* GraphQL */ `
  subscription OnDeleteGame {
    onDeleteGame {
      id
      description
      startTimestamp
      endTimestamp
      evaluationTimestamp
      automaticChallenges
      challenges {
        nextToken
      }
      rewards {
        nextToken
      }
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      lineup {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChallengeApplication = /* GraphQL */ `
  subscription OnCreateChallengeApplication {
    onCreateChallengeApplication {
      id
      startTimestamp
      endTimestamp
      challengeId
      challenge {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      tipOptions
      solution
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      challengeTips {
        nextToken
      }
      rewards {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChallengeApplication = /* GraphQL */ `
  subscription OnUpdateChallengeApplication {
    onUpdateChallengeApplication {
      id
      startTimestamp
      endTimestamp
      challengeId
      challenge {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      tipOptions
      solution
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      challengeTips {
        nextToken
      }
      rewards {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChallengeApplication = /* GraphQL */ `
  subscription OnDeleteChallengeApplication {
    onDeleteChallengeApplication {
      id
      startTimestamp
      endTimestamp
      challengeId
      challenge {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      tipOptions
      solution
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      challengeTips {
        nextToken
      }
      rewards {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSponsor = /* GraphQL */ `
  subscription OnCreateSponsor {
    onCreateSponsor {
      id
      name
      website
      tagline
      games {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      challenges2 {
        nextToken
      }
      rewards {
        nextToken
      }
      archived
      image
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSponsor = /* GraphQL */ `
  subscription OnUpdateSponsor {
    onUpdateSponsor {
      id
      name
      website
      tagline
      games {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      challenges2 {
        nextToken
      }
      rewards {
        nextToken
      }
      archived
      image
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSponsor = /* GraphQL */ `
  subscription OnDeleteSponsor {
    onDeleteSponsor {
      id
      name
      website
      tagline
      games {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      challenges2 {
        nextToken
      }
      rewards {
        nextToken
      }
      archived
      image
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRewardApplication = /* GraphQL */ `
  subscription OnCreateRewardApplication {
    onCreateRewardApplication {
      id
      amount
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRewardApplication = /* GraphQL */ `
  subscription OnUpdateRewardApplication {
    onUpdateRewardApplication {
      id
      amount
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRewardApplication = /* GraphQL */ `
  subscription OnDeleteRewardApplication {
    onDeleteRewardApplication {
      id
      amount
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChallengeRewardApplication = /* GraphQL */ `
  subscription OnCreateChallengeRewardApplication {
    onCreateChallengeRewardApplication {
      id
      amount
      challengeApplicationId
      challengeApplication {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChallengeRewardApplication = /* GraphQL */ `
  subscription OnUpdateChallengeRewardApplication {
    onUpdateChallengeRewardApplication {
      id
      amount
      challengeApplicationId
      challengeApplication {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChallengeRewardApplication = /* GraphQL */ `
  subscription OnDeleteChallengeRewardApplication {
    onDeleteChallengeRewardApplication {
      id
      amount
      challengeApplicationId
      challengeApplication {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReward = /* GraphQL */ `
  subscription OnCreateReward {
    onCreateReward {
      id
      name
      value
      applications {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      issues {
        nextToken
      }
      archived
      redemptionInfo
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReward = /* GraphQL */ `
  subscription OnUpdateReward {
    onUpdateReward {
      id
      name
      value
      applications {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      issues {
        nextToken
      }
      archived
      redemptionInfo
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReward = /* GraphQL */ `
  subscription OnDeleteReward {
    onDeleteReward {
      id
      name
      value
      applications {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      issues {
        nextToken
      }
      archived
      redemptionInfo
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateIssuedReward = /* GraphQL */ `
  subscription OnCreateIssuedReward($owner: String) {
    onCreateIssuedReward(owner: $owner) {
      id
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      owner
      redemptionTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateIssuedReward = /* GraphQL */ `
  subscription OnUpdateIssuedReward($owner: String) {
    onUpdateIssuedReward(owner: $owner) {
      id
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      owner
      redemptionTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteIssuedReward = /* GraphQL */ `
  subscription OnDeleteIssuedReward($owner: String) {
    onDeleteIssuedReward(owner: $owner) {
      id
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      owner
      redemptionTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateObserver = /* GraphQL */ `
  subscription OnCreateObserver {
    onCreateObserver {
      id
      topic
      subscription
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateObserver = /* GraphQL */ `
  subscription OnUpdateObserver {
    onUpdateObserver {
      id
      topic
      subscription
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteObserver = /* GraphQL */ `
  subscription OnDeleteObserver {
    onDeleteObserver {
      id
      topic
      subscription
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGamePlayer = /* GraphQL */ `
  subscription OnCreateGamePlayer {
    onCreateGamePlayer {
      id
      playerID
      gameID
      player {
        id
        firstname
        lastname
        position
        number
        image
        createdAt
        updatedAt
      }
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGamePlayer = /* GraphQL */ `
  subscription OnUpdateGamePlayer {
    onUpdateGamePlayer {
      id
      playerID
      gameID
      player {
        id
        firstname
        lastname
        position
        number
        image
        createdAt
        updatedAt
      }
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGamePlayer = /* GraphQL */ `
  subscription OnDeleteGamePlayer {
    onDeleteGamePlayer {
      id
      playerID
      gameID
      player {
        id
        firstname
        lastname
        position
        number
        image
        createdAt
        updatedAt
      }
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onEvaluateChallengeApplication = /* GraphQL */ `
  subscription OnEvaluateChallengeApplication {
    onEvaluateChallengeApplication {
      id
      startTimestamp
      endTimestamp
      challengeId
      challenge {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      tipOptions
      solution
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      challengeTips {
        nextToken
      }
      rewards {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onEvaluateGame = /* GraphQL */ `
  subscription OnEvaluateGame {
    onEvaluateGame {
      id
      description
      startTimestamp
      endTimestamp
      evaluationTimestamp
      automaticChallenges
      challenges {
        nextToken
      }
      rewards {
        nextToken
      }
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      lineup {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
