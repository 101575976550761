import { API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';

import { CreateObserverInput, CreateObserverMutation, GetObserverBySubscriptionQuery, ObserverTopic } from '../API';
import { createObserver } from '../graphql/mutations';
import { getObserverBySubscription } from '../graphql/queries';

export async function checkNotificationObserver() {
    navigator.serviceWorker.getRegistration().then((reg) => {
        reg?.pushManager.getSubscription().then(async (sub) => {
            if (sub) {
                const endpoint = JSON.stringify(sub);
                const observer = await API.graphql<GraphQLQuery<GetObserverBySubscriptionQuery>>({
                    query: getObserverBySubscription,
                    variables: { subscription: endpoint },
                    authMode: 'AWS_IAM',
                });
                if (!observer.data?.getObserverBySubscription?.items) {
                    createNotificationObserver();
                }
            }
        });
    });
}

export function updateNotificationPermission() {
    navigator.permissions.query({ name: 'notifications' }).then((permission) => {
        permission.onchange = () => {
            if (permission.state === 'granted') {
                createNotificationObserver();
            }
        };
    });
}

export async function askForNotificationPermission() {
    navigator.serviceWorker.ready.then((reg) => {
        reg.pushManager.getSubscription().then((sub) => {
            if (!sub) {
                Notification.requestPermission();
            }
        });
    });
}

function createNotificationObserver() {
    navigator.serviceWorker.getRegistration().then((reg) => {
        reg?.pushManager.getSubscription().then(async (sub) => {
            await sub?.unsubscribe();
            reg?.pushManager
                .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: process.env.REACT_APP_NOTIFICATION_PUBLIC_KEY,
                })
                .then((sub) => {
                    const topic: ObserverTopic = ObserverTopic.GameStart;
                    const input: CreateObserverInput = {
                        subscription: JSON.stringify(sub),
                        topic,
                    };

                    API.graphql<GraphQLQuery<CreateObserverMutation>>({
                        query: createObserver,
                        variables: { input },
                    });
                });
        });
    });
}
