import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Icon, Input, Message } from 'semantic-ui-react';
import { deleteRegisteredUserAction, updateProfileAction } from '../../../actions/userActions';
import { ProfileVisibility } from '../../../API';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import Context from '../../../Context/context';
import { getNickname, toggleVisibility } from '../../../utils/userHelpers';
import './userSettings.scss';
import BrowserNicknameValidator from '../../../utils/browser-nickname-validator';

function UserSettings() {
    const { state, dispatch } = useContext(Context);
    const [nickname, setNickname] = useState('');
    const [visibility, setVisibility] = useState<ProfileVisibility>(ProfileVisibility.private);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [btnLoading, setBtnLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (state.user?.profile) {
            setVisibility(state.user.profile?.visibility);
            setNickname(state.user.profile.nickname || '');
        }
    }, [state.user]);

    async function handleSubmit(e: { preventDefault: () => void }) {
        e.preventDefault();

        setError(undefined);
        if (!(await new BrowserNicknameValidator().isValidAsync(nickname))) {
            setError('Der Benutzername muss 3-20 Zeichen lang sein und darf keine Kontaktdaten oder vulgären Ausdrücke enthalten');
        } else {
            setBtnLoading(true);
            state.user?.profile &&
                updateProfileAction(dispatch, nickname, visibility).then(() => {
                    setBtnLoading(false);
                    navigate('/user');
                });
        }
    }

    async function handleDelete() {
        if (state.user) {
            setDeleteLoading(true);
            deleteRegisteredUserAction(dispatch).then(() => {
                setDeleteLoading(false);
                navigate('/');
            });
        }
    }

    return (
        <div id='user-settings'>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                action={() => handleDelete()}
                title='Account löschen'
                description='Möchtest du deinen Account wirklich löschen?'
            />
            {state.user && state.user?.pool.userPoolId === process.env.REACT_APP_REGISTERED_USER_POOL_ID ? (
                <>
                    <h1>{state.user.profile?.nickname && getNickname(state.user.profile.nickname)}</h1>
                    <Button loading={deleteLoading} className='secondary' onClick={() => setConfirmationOpen(true)}>
                        <Icon name='trash' />
                        Account löschen
                    </Button>
                    <h2>Benutzername:</h2>
                    <Form error={!!error}>
                        <Form.Field>
                            <Input value={nickname} onChange={(e) => setNickname(e.target.value)} name='username' />
                            <Form.Checkbox
                                checked={visibility === ProfileVisibility.public}
                                label='Mich in der öffentlichen Rangliste anzeigen'
                                onChange={() => setVisibility(toggleVisibility)}
                                name='visibility'
                            />
                        </Form.Field>
                        <Button loading={btnLoading} type='submit' content='Speichern' onClick={handleSubmit} />
                        <Message error content={error} />
                    </Form>
                </>
            ) : (
                <p></p>
            )}
        </div>
    );
}

export default UserSettings;
