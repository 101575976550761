/* eslint-disable no-unused-vars */

export enum ChallengeApplicationState {
    HIDDEN,
    OPEN,
    CLOSED,
    RESOLVED,
}

export type RewardSelection = {
    rewardId: string;
    rewardApplicationId?: string;
    order: number;
    amount: number;
};

export type ChallengeSelection = {
    challengeId: string;
    challengeApplicationId?: string;
    sponsorId?: string;
};
