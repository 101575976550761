export const onUpdateGameWithChallenge = /* GraphQL */ `
    subscription onUpdateGameWithChallenge {
        onUpdateGame {
            id
            description
            startTimestamp
            endTimestamp
            evaluationTimestamp
            automaticChallenges
            challenges {
                items {
                    id
                    startTimestamp
                    endTimestamp
                    challengeId
                    challenge {
                        id
                        description
                        question
                        points
                        stadiumOnly
                        archived
                        type
                        createdAt
                        updatedAt
                    }
                    sponsor {
                        id
                        name
                        website
                        tagline
                        image
                        createdAt
                        updatedAt
                    }
                    sponsorId
                    gameId
                    tipOptions
                    solution
                    challengeTips {
                        items {
                            id
                            challengeApplicationId
                            owner
                            answer
                            points
                            createdAt
                            updatedAt
                        }
                        nextToken
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            rewards {
                items {
                    id
                    amount
                    gameId
                    rewardId
                    order
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;

export const onEvaluateChallengeApplicationWithTips = /* GraphQL */ `
    subscription onEvaluateChallengeApplicationWithTips {
        onEvaluateChallengeApplication {
            id
            startTimestamp
            endTimestamp
            challengeId
            challenge {
                id
                description
                question
                points
                stadiumOnly
                archived
                type
                createdAt
                updatedAt
            }
            sponsor {
                id
                name
                website
                tagline
                image
                createdAt
                updatedAt
            }
            sponsorId
            gameId
            tipOptions
            solution
            challengeTips {
                items {
                    id
                    owner
                    answer
                    points
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;

export const onCreateIssuedRewardWithReward = /* GraphQL */ `
    subscription OnCreateIssuedRewardWithReward($owner: String) {
        onCreateIssuedReward(owner: $owner) {
            id
            rewardId
            reward {
                id
                name
                value
                archived
                redemptionInfo
                createdAt
                updatedAt
            }
            owner
            redemptionTimestamp
            createdAt
            updatedAt
        }
    }
`;
export const onCreateChallengeApplicationWithChallengeTips = /* GraphQL */ `
    subscription OnCreateChallengeApplicationWithChallengeTips {
        onCreateChallengeApplication {
            id
            startTimestamp
            endTimestamp
            challengeId
            challenge {
                id
                description
                question
                points
                stadiumOnly
                archived
                type
                createdAt
                updatedAt
            }
            sponsor {
                id
                name
                website
                tagline
                image
                createdAt
                updatedAt
            }
            sponsorId
            gameId
            game {
                id
                description
                startTimestamp
                endTimestamp
                evaluationTimestamp
                automaticChallenges
                sponsorId
                createdAt
                updatedAt
            }
            tipOptions
            solution
            challengeTips {
                items {
                    answer
                    challengeApplicationId
                    createdAt
                    id
                    owner
                    points
                    updatedAt
                }
            }
            createdAt
            updatedAt
        }
    }
`;
