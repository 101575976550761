import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { setNewsletterSubscriptionConfirmedAction, updateProfileAction } from '../../../../actions/userActions';
import { ProfileVisibility } from '../../../../API';
import Context from '../../../../Context/context';
import { toggleVisibility } from '../../../../utils/userHelpers';
import BrowserNicknameValidator from '../../../../utils/browser-nickname-validator';

function FinishSocialSignUp() {
    const { dispatch } = useContext(Context);
    const [nickname, setNickname] = useState('');
    const [visibility, setVisibility] = useState(ProfileVisibility.private);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [newsletterSubscription, setNewsletterSubscription] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    async function handleSubmit(e: { preventDefault: () => void }) {
        e.preventDefault();
        setError(undefined);
        setBtnLoading(true);
        const tasks = [];
        switch (false) {
            case await new BrowserNicknameValidator().isValidAsync(nickname):
                setError('Der Benutzername muss 3-20 Zeichen lang sein und darf keine Kontaktdaten oder vulgären Ausdrücke enthalten');
                setBtnLoading(false);
                break;
            case termsAndConditions:
                setBtnLoading(false);
                setError('Bitte akzeptieren Sie die Nutzungsbedingungen');
                break;
            default:
                tasks.push(updateProfileAction(dispatch, nickname, visibility));
                if (newsletterSubscription) {
                    tasks.push(setNewsletterSubscriptionConfirmedAction());
                }
                Promise.all(tasks)
                    .then(() => {
                        navigate('/');
                    })
                    .catch(() => {
                        setBtnLoading(false);
                        setError('Beim Abschluss der Kontoeröffnung ist ein Fehler aufgetreten.');
                    });
        }
    }

    return (
        <div>
            <h1>Registrierung abschliessen</h1>
            <Form error={!!error}>
                <Form.Field>
                    <label>Benutzername:</label>
                    <Input placeholder='Benutzername' onChange={(e) => setNickname(e.target.value)} name='nickname' />
                </Form.Field>

                <Form.Checkbox
                    label='Mich in der öffentlichen Rangliste anzeigen'
                    onChange={() => setVisibility(toggleVisibility)}
                    name='visibility'
                />
                <Form.Checkbox
                    label='Newsletter abonnieren'
                    onChange={() => setNewsletterSubscription(!newsletterSubscription)}
                    name='newsletterSubscription'
                />
                <Form.Checkbox
                    label={
                        <label>
                            Ich erkläre mich mit den{' '}
                            <Link to='/info/agb' target='_blank'>
                                Nutzungsbedingungen
                            </Link>{' '}
                            der SCRJ Lakers einverstanden
                        </label>
                    }
                    onChange={() => setTermsAndConditions(!termsAndConditions)}
                    name='termsAndConditions'
                    required
                />
                <Button loading={btnLoading} type='submit' onClick={handleSubmit}>
                    Registrierung abschliessen
                </Button>
                <Message error content={error} />
            </Form>
        </div>
    );
}

export default FinishSocialSignUp;
