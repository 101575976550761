import React, { useState } from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import { ChallengeApplication } from '../../API';
import TextPlaceholder from '../TextPlaceholder/TextPlaceholder';
import AnswerModal from '../AnswerModal/AnswerModal';
import './answerSelect.scss';

interface AnswerSelectProps {
    challenge: ChallengeApplication;
    answer?: string;
    disabled?: boolean;
    icon?: SemanticICONS;
    noAnswerYetText: string;
    noAnswerGivenText: string;
    heading?: string;
    solutionInfo?: string;
    maxPointsInfo: string;
}

function AnswerSelect(props: AnswerSelectProps) {
    const [open, setOpen] = useState(false);

    function handleClick() {
        if (!props.disabled) {
            setOpen(true);
        }
    }

    function getMainText() {
        if (props.answer) {
            return props.answer;
        }

        return props.disabled ? props.noAnswerGivenText : props.noAnswerYetText;
    }

    function getFooter() {
        if (!props.disabled && props.answer) {
            return (
                <div className='footer-select-actions'>
                    <p className={'link'} onClick={() => handleClick()}>
                        Auswahl ändern
                    </p>
                </div>
            );
        }

        if (props.disabled && props.solutionInfo) {
            return (
                <div className='select-footer'>
                    <div>{props.solutionInfo}</div>
                    <div>{props.maxPointsInfo}</div>
                </div>
            );
        }
    }

    return (
        <>
            <AnswerModal
                open={open}
                answer={props.answer}
                setOpen={setOpen}
                challengeId={props.challenge.id}
                challengeType={props.challenge.challenge.type}
            />

            <div className='user-selection'>
                <h2>{props.heading ?? 'Dein aktueller Tipp'}</h2>
                <div className={`main-select ${props.disabled ? 'disabled' : 'active'}`}>
                    <TextPlaceholder placeholderText={getMainText()} onClick={handleClick} icon={props.icon} />
                </div>
                {getFooter()}
            </div>
        </>
    );
}

export default AnswerSelect;
