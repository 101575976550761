export const listGamesWithTips = /* GraphQL */ `
    query listGamesWithTips($filter: ModelGameFilterInput, $limit: Int, $nextToken: String) {
        listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                description
                startTimestamp
                endTimestamp
                automaticChallenges
                sponsor {
                    id
                    name
                    website
                    tagline
                    image
                    createdAt
                    updatedAt
                }
                lineup {
                    items {
                        id
                        playerID
                        player {
                            id
                            firstname
                            lastname
                            position
                            number
                            image
                            createdAt
                            updatedAt
                        }
                        gameID
                        createdAt
                        updatedAt
                    }
                }
                rewards {
                    items {
                        id
                        amount
                        rewardId
                        reward {
                            id
                            name
                            value
                            redemptionInfo
                            createdAt
                            updatedAt
                        }
                        order
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                challenges {
                    items {
                        id
                        startTimestamp
                        endTimestamp
                        challengeId
                        challenge {
                            id
                            description
                            question
                            points
                            stadiumOnly
                            archived
                            type
                            createdAt
                            updatedAt
                        }
                        sponsor {
                            id
                            name
                            website
                            tagline
                            image
                            createdAt
                            updatedAt
                        }
                        sponsorId
                        tipOptions
                        solution
                        challengeTips {
                            items {
                                answer
                                createdAt
                                id
                                owner
                                points
                                updatedAt
                            }
                            nextToken
                        }
                        rewards {
                            items {
                                id
                                amount
                                challengeApplicationId
                                rewardId
                                reward {
                                    id
                                    name
                                    value
                                    redemptionInfo
                                    sponsor {
                                        id
                                        name
                                        website
                                        tagline
                                        image
                                        createdAt
                                        updatedAt
                                    }
                                    sponsorId
                                    createdAt
                                    updatedAt
                                }
                                order
                                createdAt
                                updatedAt
                            }
                            nextToken
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listGamesWithoutTips = /* GraphQL */ `
    query ListGamesWithoutTips($filter: ModelGameFilterInput, $limit: Int, $nextToken: String) {
        listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                description
                startTimestamp
                endTimestamp
                automaticChallenges
                sponsor {
                    id
                    name
                    website
                    tagline
                    image
                    createdAt
                    updatedAt
                }
                lineup {
                    items {
                        id
                        playerID
                        player {
                            id
                            firstname
                            lastname
                            position
                            number
                            image
                            createdAt
                            updatedAt
                        }
                        gameID
                        createdAt
                        updatedAt
                    }
                }
                rewards {
                    items {
                        id
                        amount
                        rewardId
                        reward {
                            id
                            name
                            value
                            redemptionInfo
                            createdAt
                            updatedAt
                        }
                        order
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                challenges {
                    items {
                        id
                        startTimestamp
                        endTimestamp
                        challengeId
                        challenge {
                            id
                            description
                            question
                            points
                            stadiumOnly
                            archived
                            type
                            createdAt
                            updatedAt
                        }
                        sponsor {
                            id
                            name
                            website
                            tagline
                            image
                            createdAt
                            updatedAt
                        }
                        sponsorId
                        rewards {
                            items {
                                id
                                amount
                                challengeApplicationId
                                rewardId
                                reward {
                                    id
                                    name
                                    value
                                    redemptionInfo
                                    sponsor {
                                        id
                                        name
                                        website
                                        tagline
                                        image
                                        createdAt
                                        updatedAt
                                    }
                                    sponsorId
                                    createdAt
                                    updatedAt
                                }
                                order
                                createdAt
                                updatedAt
                            }
                            nextToken
                        }
                        tipOptions
                        solution
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const getActiveGame = /* GraphQL */ `
    query getActiveGame {
        listGames(filter: { endTimestamp: { notContains: "T" } }) {
            nextToken
            items {
                createdAt
                description
                challenges {
                    items {
                        challengeId
                        createdAt
                        id
                    }
                }
                startTimestamp
                endTimestamp
            }
        }
    }
`;
export const getRankingWithoutOwner = /* GraphQL */ `
    query getRankingWithoutOwner(
        $visibility: ProfileVisibility!
        $score: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelProfileFilterInput
        $limit: Int
        $nextToken: String
    ) {
        getRanking(visibility: $visibility, score: $score, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                nickname
                score
                visibility
                rank
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listIssuedRewardsWithReward = /* GraphQL */ `
    query ListIssuedRewardsWithReward($filter: ModelIssuedRewardFilterInput, $limit: Int, $nextToken: String) {
        listIssuedRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                rewardId
                reward {
                    id
                    name
                    value
                    archived
                    redemptionInfo
                    sponsorId
                    sponsor {
                        id
                        name
                        website
                        tagline
                        image
                        createdAt
                        updatedAt
                    }
                    createdAt
                    updatedAt
                }
                owner
                redemptionTimestamp
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const getProfileByOwnerWithStats = /* GraphQL */ `
    query GetProfileByOwnerWithStats(
        $owner: String!
        $sortDirection: ModelSortDirection
        $filter: ModelProfileFilterInput
        $limit: Int
        $nextToken: String
    ) {
        getProfileByOwner(owner: $owner, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                nickname
                owner
                score
                visibility
                rank
                stats {
                    participatedGames
                    participatedChallengeTips
                    successfulChallengeTips
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const listRewardsWithSponsor = /* GraphQL */ `
    query listRewardsWithSponsor($filter: ModelRewardFilterInput, $limit: Int, $nextToken: String) {
        listRewards(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                value
                archived
                redemptionInfo
                sponsorId
                sponsor {
                    id
                    name
                    website
                    tagline
                    image
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listChallengesWithSponsor = /* GraphQL */ `
    query ListChallengesWithSponsor($filter: ModelChallengeFilterInput, $limit: Int, $nextToken: String) {
        listChallenges(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                description
                question
                points
                stadiumOnly
                archived
                type
                createdAt
                updatedAt
                sponsorId
                sponsor {
                    id
                    name
                    website
                    tagline
                    image
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
export const listSponsorsWithChallengesAndRewards = /* GraphQL */ `
    query listSponsorsWithChallengesAndRewards($filter: ModelSponsorFilterInput, $limit: Int, $nextToken: String) {
        listSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                website
                tagline
                archived
                image
                createdAt
                updatedAt
                challenges2 {
                    items {
                        id
                        description
                        question
                        points
                        stadiumOnly
                        archived
                        type
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                rewards {
                    items {
                        id
                        name
                        value
                        redemptionInfo
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
            }
            nextToken
        }
    }
`;
