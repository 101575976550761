import React, { MouseEvent, useEffect, useState } from 'react';
import { Button, Form, Input, Message, Modal } from 'semantic-ui-react';
import './resultModal.scss';

interface ResultModalProps {
    loading: boolean;
    open: boolean;
    setOpen: (state: boolean) => void;
    answer?: string;
    heading: string;
    submitResultText: string;
    submitResult: (result: string) => void;
    setErrorMessage: (text: string | undefined) => void;
    errorMessage: string | undefined;
    cancel: () => void;
}

function ResultModal(props: ResultModalProps) {
    const [homeScore, setHomeScore] = useState<number | undefined>();
    const [awayScore, setAwayScore] = useState<number | undefined>();

    useEffect(() => {
        if (props.answer) {
            setHomeScore(getScore(props.answer, 'home'));
            setAwayScore(getScore(props.answer, 'away'));
        } else {
            setHomeScore(undefined);
            setAwayScore(undefined);
        }
    }, [props.open]);

    function getScore(value: string, team: 'home' | 'away'): number {
        const regex = /(?<home>\d*)\D*(?<away>\d*)/;

        const match = value.match(regex);

        const score = match?.groups?.[team];

        if (!score) {
            throw TypeError(`The string ${value} doesn't fulfills the required format to use this evaluator type`);
        }

        return parseInt(score, 10);
    }

    function isValidScore(score?: number) {
        return score !== undefined && !isNaN(score) && score >= 0;
    }

    function isFormValid() {
        return isValidScore(homeScore) && isValidScore(awayScore);
    }

    async function submitResult(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        if (!isFormValid()) {
            props.setErrorMessage('Eingabe ist ungültig.');
            return;
        }

        await props.submitResult(`${homeScore} : ${awayScore}`);
    }

    return (
        <Modal className='edit-challenges-modal' onClose={props.cancel} onOpen={() => props.setOpen(true)} open={props.open}>
            <Modal.Header>{props.heading}</Modal.Header>
            <Modal.Content>
                <Form error={!!props.errorMessage}>
                    <Form.Group widths={'equal'} unstackable>
                        <Form.Field required className='left' width={2}>
                            <label>Heimteam</label>
                            <Input
                                type='number'
                                value={homeScore ?? ''}
                                placeholder='Anzahl Tore'
                                onChange={(e) => setHomeScore(e.target.valueAsNumber)}
                                name='points'
                            />
                        </Form.Field>
                        <div className='modal-result-tip-divider'>:</div>
                        <Form.Field required className='right' width={2}>
                            <label>Gastteam</label>
                            <Input
                                type='number'
                                value={awayScore ?? ''}
                                placeholder='Anzahl Tore'
                                onChange={(e) => setAwayScore(e.target.valueAsNumber)}
                                name='points'
                            />
                        </Form.Field>
                    </Form.Group>
                    <Message error content={props.errorMessage} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={props.cancel}>Abbrechen</Button>
                <Button loading={props.loading} disabled={!isFormValid()} className='secondary' onClick={submitResult}>
                    {props.submitResultText}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ResultModal;
