import React, { useContext, useEffect, useState } from 'react';
import { ChallengeApplication, ChallengeTip, ChallengeType } from '../../../../API';
import { getChallengeApplicationState, getTipResultLevel, TipResultLevel } from '../../../../utils/gameHelpers';
import { ChallengeApplicationState } from '../../../../ressources/types/game';
import Context from '../../../../Context/context';
import { setChallengeSponsorUrl, setGameSponsorUrl } from '../../../../actions/sponsorActions';
import './challengeDetails.scss';
import Sponsor from '../../../../components/Sponsor/Sponsor';
import ChallengeStatusMessage from '../../../../components/ChallengeStatusMessage/ChallengeStatusMessage';
import PlayerSelect from '../../../../components/PlayerSelect/PlayerSelect';
import AnswerSelect from '../../../../components/PlayerSelect/AnswerSelect';
import StyleMood from '../../../../utils/styleMood';
import { getSponsor } from '../../../../utils/sponsorHelpers';

interface ChallengeDetailsProps {
    challenge: ChallengeApplication;
}

function ChallengeDetails(props: ChallengeDetailsProps) {
    const { state, dispatch } = useContext(Context);
    const [tippingDisabled, setTippingDisabled] = useState(false);
    const [currentTip, setCurrentTip] = useState<ChallengeTip | null | undefined>(null);

    useEffect(() => {
        if (
            props.challenge.sponsor &&
            !state.imageSources.challengeSponsors.find((challenge) => challenge.sponsorId === props.challenge.sponsorId)?.url
        ) {
            setChallengeSponsorUrl(dispatch, props.challenge.sponsor);
        }

        if (state.activeGame?.sponsor && state.imageSources.gameSponsor === '') {
            setGameSponsorUrl(dispatch, state.activeGame.sponsor);
        }
    }, []);

    useEffect(() => {
        setTippingDisabled(props.challenge !== null && getChallengeApplicationState(props.challenge) !== ChallengeApplicationState.OPEN);
        if (props.challenge?.challengeTips && props.challenge.challengeTips.items.length !== 0) {
            setCurrentTip(props.challenge?.challengeTips.items[0]);
        }
    }, [props.challenge, props.challenge.challengeTips, props.challenge.challengeTips?.items, props.challenge.challengeTips?.items[0]]);

    function renderChallengeStatusMessage() {
        if (getChallengeApplicationState(props.challenge) === ChallengeApplicationState.RESOLVED) {
            if (!currentTip) {
                return <p className={`${StyleMood.negative}`}>Du hast keinen Tipp abgegeben</p>;
            }
            switch (getTipResultLevel(props.challenge, currentTip)) {
                case TipResultLevel.Exact:
                    return (
                        <ChallengeStatusMessage
                            text='Du hast korrekt getippt!'
                            highlight={`+${currentTip.points} Punkte`}
                            styleMood={StyleMood.positive}
                        />
                    );
                case TipResultLevel.Partial:
                    return (
                        <ChallengeStatusMessage
                            text='Dein Tipp ist nahe dran!'
                            highlight={`+${currentTip.points} Punkte`}
                            styleMood={StyleMood.partialPositive}
                        />
                    );
                case TipResultLevel.Wrong:
                    return <ChallengeStatusMessage text='Du hast leider falsch getippt.' styleMood={StyleMood.negative} />;
            }
        }
        if (tippingDisabled) {
            return <ChallengeStatusMessage text='Die Abstimmung wurde geschlossen' styleMood={StyleMood.negative} />;
        }
        return <p>{props.challenge.challenge.description ? props.challenge.challenge.description : 'Die Abstimmung schliesst in Kürze'}</p>;
    }

    function getSelectIcon() {
        if (props.challenge.solution && currentTip) {
            return getTipResultLevel(props.challenge, currentTip) !== TipResultLevel.Wrong ? 'check' : 'close';
        }
        if (tippingDisabled) {
            return 'lock';
        }
        return 'edit';
    }

    function getChallengeSelection(challenge: ChallengeApplication | null, tippingDisabled: boolean = false) {
        switch (challenge?.challenge.type) {
            case ChallengeType.onePlayerChallenge:
                return <PlayerSelect icon={getSelectIcon()} answer={currentTip?.answer} disabled={tippingDisabled} challenge={challenge} />;
            case ChallengeType.estimationChallenge:
                return (
                    <AnswerSelect
                        icon={getSelectIcon()}
                        answer={currentTip?.answer}
                        disabled={tippingDisabled}
                        challenge={challenge}
                        maxPointsInfo={`Beste Schätzung: ${props.challenge.challenge.points} Punkte`}
                        noAnswerGivenText={'Keine Schätzung'}
                        noAnswerYetText={'Schätzung angeben'}
                        solutionInfo={props.challenge.solution ? `Exakte Lösung: ${props.challenge.solution}` : undefined}
                    />
                );
            case ChallengeType.resultChallenge:
                return (
                    <AnswerSelect
                        icon={getSelectIcon()}
                        answer={currentTip?.answer}
                        disabled={tippingDisabled}
                        challenge={challenge}
                        maxPointsInfo={`Richtiges Resultat: ${props.challenge.challenge.points} Punkte`}
                        noAnswerGivenText={'Kein Resultat'}
                        noAnswerYetText={'Resultat angeben'}
                        solutionInfo={props.challenge.solution ? `Exakte Lösung: ${props.challenge.solution}` : undefined}
                    />
                );
        }
    }

    function renderSponsor() {
        const sponsor = getSponsor(state, props.challenge);
        if (!sponsor || !sponsor.image) {
            return null;
        }

        return (
            <>
                <div className='challenge-sponsor'>
                    <Sponsor onClick={() => window.open(`https://${sponsor.website}`)} sponsorImageUrl={sponsor.image} />
                    <span className='caption'>präsentiert</span>
                </div>
            </>
        );
    }

    return (
        <>
            {renderSponsor()}

            <h1>{props.challenge.challenge.question}</h1>
            {renderChallengeStatusMessage()}
            {getChallengeSelection(props.challenge, tippingDisabled)}
        </>
    );
}

export default ChallengeDetails;
