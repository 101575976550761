import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';
import { evaluateGameAction, startGameAction, updateGameAction } from '../../actions/gameActions';
import { ChallengeApplication as ChallengeApplicationType, Game, UpdateGameInput, UpdateGameMutationVariables } from '../../API';
import Context from '../../Context/context';
import {
    getClosedChallengeApplications,
    getHiddenChallengeApplications,
    getOpenChallengeApplications,
    getResolvedChallengeApplications,
} from '../../utils/gameHelpers';
import SetupChallengeModal from './SetupChallengeModal/SetupChallengeModal';
import SetupRewardModal from './SetupRewardsModal/SetupRewardsModal';
import SetupSponsorModal from './SetupSponsorModal/SetupSponsorModal';
import './gameSettingsDetails.scss';
import SetupPlayersModal from './SetupPlayersModal/SetupPlayersModal';
import useAsync from '../../hooks/useAsync';
import { ActionTypes } from '../../ressources/types/state';
import CustomLoader from '../CustomLoader/CustomLoader';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ErrorModal from '../ErrorModal/ErrorModal';
import LiveIndicator from '../LiveIndicator/LiveIndicator';
import ChallengeApplication from './ChallengeApplication/ChallengeApplication';

interface GameSettingDetailsProps {
    game: Game;
}

function hasStarted(game: Game) {
    return game.startTimestamp && Date.parse(game.startTimestamp) <= Date.now();
}

function GameSettingDetails(props: GameSettingDetailsProps) {
    const { state, dispatch } = useContext(Context);
    const [challengeModalOpen, setChallengeModalOpen] = useState(false);
    const [sponsorModalOpen, setSponsorModalOpen] = useState(false);
    const [rewardModalOpen, setRewardModalOpen] = useState(false);
    const [playersModalOpen, setPlayersModalOpen] = useState(false);
    const [gameDescription, setGameDescription] = useState('');
    const [hiddenChallenges, setHiddenChallenges] = useState<(ChallengeApplicationType | null)[]>([]);
    const [openChallenges, setOpenChallenges] = useState<(ChallengeApplicationType | null)[]>([]);
    const [closedChallenges, setClosedChallenges] = useState<(ChallengeApplicationType | null)[]>([]);
    const [resolvedChallenges, setResolvedChallenges] = useState<(ChallengeApplicationType | null)[]>([]);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmatioTitle, setConfirmationTitle] = useState('');
    const [confirmationDescription, setConfirmationDescription] = useState('');
    const [confirmationWarnings, setConfirmationWarnings] = useState<string[]>([]);
    const [confirmationAction, setConfirmationAction] = useState<() => void>();
    const [confirmationPositiveLabel, setConfirmationPositiveLabel] = useState('');
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (state.newGame?.challenges) {
            splitChallengesBystate(state.newGame.challenges.items);
        } else if (state.activeGame?.challenges) {
            splitChallengesBystate(state.activeGame.challenges.items);
        }
    }, [state]);

    async function startGame() {
        if (!state.newGame?.lineup?.items.length) {
            setErrorModalOpen(true);
            return;
        }
        setConfirmationTitle('Tippspiel starten');
        setConfirmationDescription('Möchtest du das Tippspiel wirklich starten? Du kannst das Aufgebot anschliessend nicht mehr ändern.');
        setConfirmationAction(() => startGameConfirmationAction);
        setConfirmationPositiveLabel('Spiel starten');
        const warnings: string[] = [];
        !state.newGame.sponsor && warnings.push('Keinen Sponsor ausgewählt');
        !state.newGame.rewards?.items.length && warnings.push('Keine Preise ausgewählt');
        setConfirmationWarnings(warnings);
        setConfirmationOpen(true);
    }

    async function startGameConfirmationAction() {
        const gameId = state.newGame?.id;
        if (gameId) {
            setBtnLoading(true);
            execute(() => startGameAction(dispatch, gameId)).then(() => {
                setBtnLoading(false);
            });
        }
    }

    function endGame() {
        setConfirmationTitle('Tippspiel beenden und auswerten');
        setConfirmationDescription('Möchtest du das Tippspiel wirklich beenden? Die Preise werden damit verlost.');
        setConfirmationAction(() => endGameConfirmationAction);
        setConfirmationPositiveLabel('Spiel beenden und auswerten');
        const warnings: string[] = [];
        !!hiddenChallenges.length && warnings.push('Es gibt noch versteckte Challenges');
        !!openChallenges.length && warnings.push('Es gibt noch offene Challenges');
        !!closedChallenges.length && warnings.push('Es gibt noch geschlossene Challenges');
        setConfirmationWarnings(warnings);
        setConfirmationOpen(true);
    }

    function endGameConfirmationAction() {
        const gameId = state.activeGame?.id;
        if (gameId) {
            setBtnLoading(true);
            execute(() => evaluateGameAction(dispatch, gameId)).then(() => {
                setBtnLoading(false);
            });
        }
    }

    function splitChallengesBystate(challenges: (ChallengeApplicationType | null)[]) {
        setHiddenChallenges(challenges.length ? getHiddenChallengeApplications(challenges) : []);
        setOpenChallenges(challenges.length ? getOpenChallengeApplications(challenges) : []);
        setClosedChallenges(challenges.length ? getClosedChallengeApplications(challenges) : []);
        setResolvedChallenges(challenges.length ? getResolvedChallengeApplications(challenges) : []);
    }

    function handleSetupChallenge() {
        setChallengeModalOpen(true);
    }

    function handleSetupSponsor() {
        setSponsorModalOpen(true);
    }

    function handleSetupRewards() {
        setRewardModalOpen(true);
    }

    function handleSetupPlayers() {
        setPlayersModalOpen(true);
    }

    function renderChallengeApplications(challengeApplications: (ChallengeApplicationType | null)[]) {
        const challengeApplicationList: JSX.Element[] = [];
        challengeApplications.forEach((challengeApplication) => {
            if (challengeApplication && state.user) {
                challengeApplicationList.push(
                    <ChallengeApplication key={challengeApplication.id} challenge={challengeApplication} dispatch={dispatch} />
                );
            }
        });

        return challengeApplicationList.length ? challengeApplicationList : <p className='placeholder-text'>Zurzeit keine Challenge</p>;
    }

    function updateGame(gameInput: Partial<UpdateGameInput>) {
        if (state.newGame) {
            const variables: UpdateGameMutationVariables = {
                input: {
                    id: state.newGame.id,
                    ...gameInput,
                },
            };
            execute(() => updateGameAction(dispatch, variables));
        } else if (state.activeGame) {
            const variables: UpdateGameMutationVariables = {
                input: {
                    id: state.activeGame.id,
                    ...gameInput,
                },
            };
            execute(() => updateGameAction(dispatch, variables));
        }
    }

    function updateGameDescription(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        updateGame({ description: gameDescription });
    }

    function renderGameStateActions() {
        if (state.newGame) {
            return (
                <Button loading={btnLoading} className='secondary' onClick={() => startGame()}>
                    <Icon name='play'></Icon>
                    Spiel starten
                </Button>
            );
        }
        if (state.activeGame) {
            return (
                <Button loading={btnLoading} className='secondary' onClick={() => endGame()}>
                    <Icon name='stop'></Icon>
                    Spiel beenden und auswerten
                </Button>
            );
        }
    }

    if (!state.user) {
        return <CustomLoader />;
    }

    return (
        <div id='game-settings-details'>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                action={confirmationAction || (() => {})}
                title={confirmatioTitle}
                description={confirmationDescription}
                warningElements={confirmationWarnings}
                positiveLabel={confirmationPositiveLabel}
            />
            <ErrorModal
                open={errorModalOpen}
                setOpen={setErrorModalOpen}
                action={() => setPlayersModalOpen(true)}
                title='Einen Moment...'
                description='Du hast das Aufgebot noch nicht konfiguriert'
                positiveLabel='Aufgebot bearbeiten'
            />
            <SetupChallengeModal open={challengeModalOpen} setOpen={setChallengeModalOpen} />
            <SetupSponsorModal open={sponsorModalOpen} setOpen={setSponsorModalOpen} />
            <SetupRewardModal open={rewardModalOpen} setOpen={setRewardModalOpen} />
            <SetupPlayersModal open={playersModalOpen} setOpen={setPlayersModalOpen} />

            <div className='game-settings-header'>
                <div className='game-description'>
                    <Form>
                        <Form.Field>
                            <Input
                                placeholder={state.newGame?.description || state.activeGame?.description || 'Spielbeschreibung...'}
                                onChange={(e) => setGameDescription(e.target.value)}
                            />
                            <Button type='submit' content='Speichern' onClick={updateGameDescription} />
                        </Form.Field>
                    </Form>
                </div>
                <div className='game-settings-title'>
                    {hasStarted(props.game) ? (
                        <>
                            <h1>Aktives Tippspiel</h1>
                            <LiveIndicator />
                        </>
                    ) : (
                        <h1>Tippspiel vorbereiten</h1>
                    )}
                </div>
                <div className='game-state-actions'>{renderGameStateActions()}</div>
            </div>

            <div className='game-settings-setup-actions'>
                <Button onClick={handleSetupChallenge}>
                    <Icon name='pencil'></Icon>
                    Challenges aus-/abwählen
                </Button>
                <Button onClick={handleSetupSponsor}>
                    <Icon name='pencil'></Icon>
                    Sponsor bearbeiten
                </Button>
                <Button onClick={handleSetupRewards}>
                    <Icon name='pencil'></Icon>
                    Preise bearbeiten
                </Button>
                <Button disabled={!!state.activeGame} onClick={handleSetupPlayers}>
                    <Icon name='pencil'></Icon>
                    Aufgebot bearbeiten
                </Button>
            </div>

            <div className='challenge-applications-list'>
                <div className='challenge-applications-state'>
                    <h2>
                        <Icon name='hide' />
                        Versteckt
                    </h2>
                    <div className='challenge-applications hidden'>{renderChallengeApplications(hiddenChallenges)}</div>
                </div>
                <div className='challenge-applications-state'>
                    <h2>
                        <Icon name='lock open' />
                        Offen
                    </h2>
                    <div className='challenge-applications open'>{renderChallengeApplications(openChallenges)}</div>
                </div>
                <div className='challenge-applications-state'>
                    <h2>
                        <Icon name='lock' />
                        Geschlossen
                    </h2>
                    <div className='challenge-applications closed'>{renderChallengeApplications(closedChallenges)}</div>
                </div>
                <div className='challenge-applications-state'>
                    <h2>
                        <Icon name='check' />
                        Aufgelöst
                    </h2>
                    <div className='challenge-applications resolved'>{renderChallengeApplications(resolvedChallenges)}</div>
                </div>
            </div>
        </div>
    );
}

export default GameSettingDetails;
