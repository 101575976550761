import React from 'react';
import { Link } from 'react-router-dom';

function TermsAndConditions() {
    return (
        <div id='terms-and-conditions' className='text-content'>
            <h1>Allgemeine Nutzungsbedingungen</h1>
            <p>
                Durch die Nutzung dieser Plattform erklären Sie sich mit den Nutzungsbedingungen der Plattform sowie aller darin enthaltenen Elemente
                einverstanden und bestätigen, dass Sie diese verstanden haben und anerkennen.
            </p>
            <h2>Betreiber</h2>
            <p>
                Der Betreiber dieser Plattform ist die Lakers Sport AG. Weitere Details entnehmen Sie dem <Link to='../impressum'>Impressum</Link>.
            </p>
            <h2>Verfügbarkeit</h2>
            <p>
                Auch bei aller Sorgfalt können Ausfallzeiten nicht ausgeschlossen werden. Jegliche Haftung der Lakers Sport AG bei Unterbrechungen
                oder anderweitigen Störungen ist ausgeschlossen. Die Lakers Sport AG muss sich das Recht vorbehalten, die Plattform jederzeit
                anzupassen oder ohne Vorankündigung einzustellen. Die Lakers Sport AG kann nicht verantwortlich gemacht werden wenn gewisse
                Informationen dann nicht mehr oder nicht mehr in der bisherigen Form zur Verfügung stehen.
            </p>
            <h2>Inhalte</h2>
            <p>
                Die Lakers Sport AG bemüht sich um die Genauigkeit und Vollständigkeit der angebotenen Inhalte, kann diesbezüglich jedoch keine
                Haftung übernehmen. Soweit Inhalte von Dritten geliefert beziehungsweise bereitgestellt werden, sind ausschliesslich diese Dritten für
                die Inhalte verantwortlich, die Lakers Sport AG übernimmt auch insoweit keine Haftung, insbesondere was die Wahrung von
                Immaterialgüter- und Urheberrechten betrifft.
            </p>
            <h3>Gewinnspiel-Teilnahmen</h3>
            <p>
                Zur Teilnahme berechtigt sind alle Personen mit Wohnsitz in der Schweiz. Davon ausgenommen sind Mitarbeitende der Lakers Sport AG und
                deren angeschlossenen Organisationen. Ein möglicher Gewinn wird in der Plattform angezeigt. Über das Gewinnspiel wird keine
                Korrespondenz geführt. Eine Barauszahlung ist nicht möglich. Der Rechtsweg ist ausgeschlossen. Die Teilnahme an der Verlosung erfolgt
                unabhängig von einer Bestellung beziehungsweise einem Kauf. Der Zeitraum für eine Gewinnspielteilnahme wird im Zusammenhang mit dem
                jeweiligen Gewinnspiel bekanntgegeben.
            </p>
            <p>
                Gewinne, die sich auf die Teilnahme an einem Anlass beziehen, gelten auf den Namen der teilnehmenden Person und sind nicht auf
                Drittpersonen übertragbar.
            </p>
            <h2>Datenschutz</h2>
            <p>
                Die Lakers Sport AG misst dem Schutz Ihrer persönlichen Daten und Ihrer Privatsphäre hohe Bedeutung bei und behandelt sie vertraulich
                gemäss der schweizerischen Gesetzgebung zum Datenschutz. Details können Sie der geltenden{' '}
                <Link to='../privacy'>Datenschutzerklärung</Link> entnehmen.
            </p>
            <h2>Beendigung der Nutzung</h2>
            <p>
                Zur Beendigung der Nutzung genügt die Löschung Ihres Kontos beziehungsweise Profils. Ohne persönlichen Account genügt die
                Deinstallation oder Entfernung der Plattform vom Endgerät.
            </p>
            <p>
                Die Lakers Sport AG ist berechtigt, Ihren Zugang mit sofortiger Wirkung zu sperren bzw. Sie von der Nutzung von der Plattform
                auszuschliessen wenn Sie falsche Angaben gemacht haben, gegen diese Nutzungsbedingungen oder geltendes Recht verstossen oder Anlass zu
                der Vermutung besteht, dass Sie Ihren Zugang missbrauchen.
            </p>
            <h2>Änderung</h2>
            <p>Die Lakers Sport AG behält sich das Recht vor, diese Nutzungsbestimmungen jederzeit zu ändern.</p>
            <h2>Gerichtsstand und anwendbares Recht</h2>
            <p>
                Die Nutzung der App untersteht ausschliesslich Schweizerischem Recht. Ausschliesslich zuständig sind die Gerichte des Kantons St.
                Gallen, vorbehaltlich zwingender Gerichtsstände gemäss der Schweizerischen Zivilprozessordnung wie des Konsumentengerichtsstands (Art.
                32 ZPO).
            </p>
        </div>
    );
}

export default TermsAndConditions;
