import { Auth } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Dispatch } from 'react';
import {
    DeleteRegisteredUserMutation,
    GetProfileByOwnerQuery,
    GetProfileByOwnerQueryVariables,
    GetRankingQuery,
    ModelSortDirection,
    Profile,
    ProfileVisibility,
    SetNewsletterSubscriptionConfirmedMutation,
    UpdateOwnProfileMutation,
    UpdateOwnProfileMutationVariables,
} from '../API';
// eslint-disable-next-line import/no-self-import
import * as userActions from './userActions';
import { getProfileByOwnerWithStats, getRankingWithoutOwner } from '../graphql/custom-queries';
import { deleteRegisteredUser, setNewsletterSubscriptionConfirmed, updateOwnProfile } from '../graphql/mutations';
import { ActionTypes, AppStateAction } from '../ressources/types/state';
import { IUser } from '../ressources/types/user';
import { preSignIn, signIn, signUp } from '../utils/authenticationHelpers';
import { GraphqlInterceptor as API } from '../utils/graphqlInterceptor';
import { getUsername, setAnonymousUserPool, setDefaultUserPool } from '../utils/userHelpers';

export async function setCurrentUserAction(dispatch: Dispatch<AppStateAction>) {
    let user: IUser | null = null;
    try {
        setDefaultUserPool();
        user = await Auth.currentAuthenticatedUser();
    } catch {
        try {
            setAnonymousUserPool();
            user = await Auth.currentAuthenticatedUser();
        } catch (err) {
            setDefaultUserPool();
            throw new Error('user is not authenticated');
        }
    } finally {
        if (user) {
            dispatch({ type: ActionTypes.SET_CURRENT_USER, user });
            userActions.fetchProfileByOwnerAction(dispatch, user);
        }
    }
}

export async function signInAction(dispatch: Dispatch<AppStateAction>, username: string, password: string) {
    await userActions.signOutAction(dispatch);
    setDefaultUserPool();
    await Auth.signIn({
        password,
        username,
    });
    await userActions.setCurrentUserAction(dispatch);
}

export async function preSignInAction(email: string) {
    const migrationToken = localStorage.getItem('migrationToken');
    await Auth.signOut();
    setDefaultUserPool();
    await preSignIn(email, migrationToken);
}

export async function passwordlessSignInAction(dispatch: Dispatch<AppStateAction>, token: string) {
    localStorage.clear();
    setDefaultUserPool();
    await signIn(token);
    await userActions.setCurrentUserAction(dispatch);
}

export async function signOutAction(dispatch: Dispatch<AppStateAction>) {
    try {
        await Auth.signOut();
        dispatch({ type: ActionTypes.SIGN_OUT });
    } catch (error) {
        const err = error as Error;
        dispatch({ type: ActionTypes.SET_ERROR, error: err });
    }
}

export async function updateProfileAction(dispatch: Dispatch<AppStateAction>, nickname?: string, visibility?: ProfileVisibility) {
    const variables: UpdateOwnProfileMutationVariables = {
        nickname,
        visibility,
    };
    const profile = await API.graphql<GraphQLQuery<UpdateOwnProfileMutation>>({ query: updateOwnProfile, variables });
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
        nickname,
    });
    await userActions.fetchRankingAction(dispatch);
    dispatch({ type: ActionTypes.UPDATE_PROFILE, profile: profile?.data?.updateOwnProfile as Profile });
}

export async function setNewsletterSubscriptionConfirmedAction() {
    await API.graphql<GraphQLQuery<SetNewsletterSubscriptionConfirmedMutation>>({
        query: setNewsletterSubscriptionConfirmed,
    });
}

export async function passwordlessSignUpAction(email: string, nickname: string, visibility: ProfileVisibility, newsletterSubscription: string) {
    await Auth.signOut();
    setDefaultUserPool();
    await signUp(email, nickname, visibility, newsletterSubscription);
}

export async function verifySignUpAction(email: string, verificationCode: string) {
    await Auth.confirmSignUp(email, verificationCode);
}

export async function federatedSignInAction(dispatch: Dispatch<AppStateAction>, provider: CognitoHostedUIIdentityProvider) {
    try {
        setDefaultUserPool();
        await Auth.federatedSignIn({ provider });
    } catch (error) {
        const err = error as Error;
        dispatch({ type: ActionTypes.SET_ERROR, error: err });
    }
}

export async function fetchRankingAction(dispatch: Dispatch<AppStateAction>) {
    const response = await API.graphql<GraphQLQuery<GetRankingQuery>>({
        query: getRankingWithoutOwner,
        variables: {
            limit: 10,
            visibility: ProfileVisibility.public,
            filter: { nickname: { attributeExists: true } },
            sortDirection: ModelSortDirection.DESC,
        },
    });
    if (response) {
        dispatch({
            type: ActionTypes.UPDATE_RANKING,
            ranking: response.data?.getRanking?.items as Profile[],
        });
    }
}

export async function fetchProfileByOwnerAction(dispatch: Dispatch<AppStateAction>, user: IUser) {
    const variables: GetProfileByOwnerQueryVariables = {
        owner: getUsername(user),
    };
    const response = await API.graphql<GraphQLQuery<GetProfileByOwnerQuery>>({
        query: getProfileByOwnerWithStats,
        variables,
    });
    if (response) {
        dispatch({ type: ActionTypes.UPDATE_PROFILE, profile: (response.data?.getProfileByOwner?.items as Profile[])[0] });
    }
}

export async function deleteRegisteredUserAction(dispatch: Dispatch<AppStateAction>) {
    await API.graphql<GraphQLQuery<DeleteRegisteredUserMutation>>({
        query: deleteRegisteredUser,
    });
    userActions.signOutAction(dispatch);
}
