import NicknameValidator from './backend-modules/nickname-validator';

export default class BrowserNicknameValidator extends NicknameValidator {
    protected async getProfanityWordList(): Promise<string[]> {
        const files = ['de.txt', 'en.txt', 'emojis.txt'];
        const swearWordLists = await Promise.all(
            files.map(async (file) => {
                const data = await fetch(`/blacklists/${file}`);
                const x = await data.text();
                return x.replace(/\r\n/g, '\n').split('\n');
            })
        );
        return swearWordLists.flat(1);
    }
}
