import { DropdownProps, Form } from 'semantic-ui-react';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { fetchActiveSponsorsAction } from '../../../actions/sponsorActions';
import Context from '../../../Context/context';
import useAsync from '../../../hooks/useAsync';
import { ActionTypes } from '../../../ressources/types/state';

interface FormFieldSponsorProps {
    setSponsorId: (id: string) => void;
    sponsorId: string;
}

function FormFieldSelectSponsor(props: FormFieldSponsorProps) {
    const { state, dispatch } = useContext(Context);
    const { execute, error } = useAsync();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!state.sponsors) {
            setLoading(true);
            execute(() => fetchActiveSponsorsAction(dispatch));
        }

        setLoading(false);
    }, [state.sponsors]);

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    function handleSponsorChange(e: SyntheticEvent, data: DropdownProps) {
        e.preventDefault();
        props.setSponsorId(data.value as string);
    }

    function getSponsors() {
        if (!state.sponsors) {
            return [];
        }

        return [
            ...state.sponsors
                .filter((sponsor) => sponsor.archived !== true)
                .map((sponsor) => {
                    return { key: sponsor.id, value: sponsor.id, text: sponsor.name };
                }),
        ];
    }

    return (
        <Form.Field>
            <label>Sponsor:</label>
            <Form.Dropdown
                loading={loading}
                placeholder='Wähle Sponsor'
                fluid
                search
                selection
                clearable
                options={getSponsors()}
                onChange={handleSponsorChange}
                value={props.sponsorId}></Form.Dropdown>
        </Form.Field>
    );
}

export default FormFieldSelectSponsor;
