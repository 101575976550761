import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import {
    createChallengeApplicationAction,
    deleteChallengeApplicationAction,
    fetchChallengesAction,
    updateChallengeApplicationAction,
} from '../../../actions/gameActions';
import { Challenge } from '../../../API';
import Context from '../../../Context/context';
import useAsync from '../../../hooks/useAsync';
import { ChallengeApplicationState, ChallengeSelection } from '../../../ressources/types/game';
import { ActionTypes } from '../../../ressources/types/state';
import { getChallengeApplicationByState, getChallengeSelection, listChallengeApplicationIds, saveChangesChallenge } from '../../../utils/gameHelpers';
import './setupChallengeModal.scss';

interface SetupChallengeModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
}

function SetupChallengeModal(props: SetupChallengeModalProps) {
    const { state, dispatch } = useContext(Context);
    const [selectedChallenges, setSelectedChallenges] = useState<ChallengeSelection[]>([]);
    const [resolvedChallenges, setResolvedChallenges] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (!state.challenges?.length) {
            execute(() => fetchChallengesAction(dispatch));
        } else {
            setSelectedChallenges(getCurrentChallenges());
        }
    }, [state.challenges]);

    useEffect(() => {
        if (state.activeGame?.challenges?.items) {
            const resolvedList = getChallengeApplicationByState(state.activeGame.challenges.items, ChallengeApplicationState.RESOLVED).map(
                (challengeApplication) => {
                    return challengeApplication.challenge.id;
                }
            );
            setResolvedChallenges(resolvedList);
        }
    }, [state]);

    function toggleAddChallenge(challenge: Challenge) {
        if (resolvedChallenges.includes(challenge.id)) {
            dispatch({ type: ActionTypes.SET_ERROR, error: new Error('Challenge ist bereits aufgelöst') });
            return;
        }
        if (isChallengeSelected(challenge.id)) {
            setSelectedChallenges(selectedChallenges.filter((challengeSelection) => challengeSelection.challengeId !== challenge.id));
        } else {
            setSelectedChallenges([
                ...selectedChallenges,
                {
                    challengeId: challenge.id,
                    sponsorId: challenge.sponsorId || undefined,
                },
            ]);
        }
    }

    function isChallengeSelected(challengeId: string) {
        for (const selectedChallenge of selectedChallenges) {
            if (selectedChallenge.challengeId === challengeId) {
                return true;
            }
        }
        return false;
    }

    function getCurrentChallenges() {
        if (state.newGame) {
            return getChallengeSelection(listChallengeApplicationIds(state.newGame));
        }
        if (state.activeGame) {
            return getChallengeSelection(listChallengeApplicationIds(state.activeGame));
        }
        return [];
    }

    async function handleSave() {
        const gameId = state.newGame?.id || state.activeGame?.id;

        if (gameId) {
            setLoading(true);
            await execute(() =>
                saveChangesChallenge(
                    getCurrentChallenges(),
                    selectedChallenges,
                    gameId,
                    createChallengeApplicationAction,
                    updateChallengeApplicationAction,
                    deleteChallengeApplicationAction,
                    dispatch
                )
            );
            setLoading(false);
            props.setOpen(false);
        }
    }

    return (
        <Modal className='edit-challenges-modal list-modal' onClose={() => props.setOpen(false)} onOpen={() => props.setOpen(true)} open={props.open}>
            <Modal.Header>Challenges aus-/abwählen</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                    {state.challenges?.map((challenge: Challenge) => (
                        <div className='challenge list-modal-item' onClick={() => toggleAddChallenge(challenge)} key={challenge.id}>
                            <h3>{challenge.question}</h3>
                            <div className='list-modal-item-right'>
                                <p>{challenge.points} Punkte</p>
                                <Checkbox checked={isChallengeSelected(challenge.id)} disabled={resolvedChallenges.includes(challenge.id)} />
                            </div>
                        </div>
                    ))}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)}>Abbrechen</Button>
                <Button loading={loading} className='secondary' onClick={() => handleSave()}>
                    Speichern
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SetupChallengeModal;
