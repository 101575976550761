import React, { useEffect } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import './confirmationModal.scss';

function ConfirmationModal(props: {
    open: boolean;
    setOpen: (state: boolean) => void;
    action: () => void;
    title: string;
    description: string;
    warningElements?: string[];
    positiveLabel?: string;
    negativeLabel?: string;
}) {
    useEffect(() => {
        props.setOpen(props.open);
    }, [props]);

    function action() {
        props.action();
        props.setOpen(false);
    }

    return (
        <Modal basic onClose={() => props.setOpen(false)} open={props.open} size='tiny' className='confirmation-modal information-modal'>
            <Modal.Content>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
                {!!props.warningElements?.length && (
                    <>
                        <h3>Achtung: </h3>
                        <ul>
                            {props.warningElements.map((warning, index) => {
                                return <li key={index}>{warning}</li>;
                            })}
                        </ul>
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => props.setOpen(false)}>
                    <Icon name='remove' /> {props.negativeLabel ? props.negativeLabel : 'Abbrechen'}
                </Button>
                <Button color='green' onClick={() => action()}>
                    <Icon name='checkmark' /> {props.positiveLabel ? props.positiveLabel : 'Löschen'}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ConfirmationModal;
