import React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import './playerPlaceholder.scss';

interface PlayerPlaceholderProps {
    placeholderText: string;
    onClick?: () => void;
    selected?: boolean;
    icon?: SemanticICONS;
}

function PlayerPlaceholder(props: PlayerPlaceholderProps) {
    return (
        <div className={`player-placeholder ${props.selected && 'selected'}`} onClick={() => props.onClick && props.onClick()}>
            <div className='player-placeholder-image'>
                <Icon name='user outline' />
            </div>
            <div className='player-placeholder-content'>
                <span className='player-placeholder-text'>{props.placeholderText}</span>
            </div>
            {(props.selected || props.icon) && (
                <div className='player-icon'>
                    <Icon name={props.icon || 'check'} />
                </div>
            )}
        </div>
    );
}

export default PlayerPlaceholder;
