import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Message, Modal } from 'semantic-ui-react';
import { createRewardAction, updateRewardAction } from '../../actions/rewardActions';
import { Reward } from '../../API';
import Context from '../../Context/context';
import useAsync from '../../hooks/useAsync';
import { ActionTypes } from '../../ressources/types/state';
import FormFieldSelectSponsor from '../Form/FormFieldSelectSponsor/FormFieldSelectSponsor';

interface RewardDetailModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
    reward?: Reward;
}

function RewardDetailModal(props: RewardDetailModalProps) {
    const { dispatch } = useContext(Context);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [name, setName] = useState('');
    const [value, setValue] = useState<number | undefined>(undefined);
    const [sponsorId, setSponsorId] = useState('');
    const [redemptionInfo, setRedemptionInfo] = useState('');
    const [btnSafeLoading, setBtnSafeLoading] = useState(false);
    const [btnDeleteLoading, setBtnDeleteLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (props.reward) {
            setName(props.reward.name || '');
            setValue(props.reward.value || undefined);
            setRedemptionInfo(props.reward.redemptionInfo || '');
            setSponsorId(props.reward.sponsorId || '');
        } else {
            setName('');
            setValue(undefined);
            setRedemptionInfo('');
            setSponsorId('');
        }
    }, [props.open]);

    async function updateReward(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setErrorMessage(undefined);
        if (isFormValid()) {
            setBtnSafeLoading(true);
            if (props.reward) {
                const updatedReward = props.reward;
                updatedReward.name = name;
                value ? (updatedReward.value = value) : (updatedReward.value = null);
                updatedReward.redemptionInfo = redemptionInfo;
                updatedReward.sponsorId = sponsorId || null;
                await execute(() => updateRewardAction(dispatch, updatedReward));
                props.setOpen(false);
            }
            setBtnSafeLoading(false);
        }
    }

    async function createReward(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setErrorMessage(undefined);
        if (isFormValid()) {
            setBtnSafeLoading(true);
            await execute(() => createRewardAction(dispatch, name, redemptionInfo, value, sponsorId));
            props.setOpen(false);
            setBtnSafeLoading(false);
        }
    }

    function isFormValid() {
        if (name === '') {
            setErrorMessage('Name nicht vorhanden.');
            return false;
        }
        if (value && value < 0) {
            setErrorMessage('Ungültiger Wert');
            return false;
        }
        if (redemptionInfo === '') {
            setErrorMessage('Abholinformation nicht vorhanden');
            return false;
        }
        return true;
    }

    async function deleteReward(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        setErrorMessage(undefined);
        if (props.reward) {
            setBtnDeleteLoading(true);
            const updatedReward = props.reward;
            updatedReward.archived = true;
            await execute(() => updateRewardAction(dispatch, updatedReward));
            setBtnDeleteLoading(false);
            props.setOpen(false);
        }
    }

    function cancel() {
        setErrorMessage(undefined);
        props.setOpen(false);
    }

    return (
        <Modal
            className='reward-modal'
            onClose={() => {
                setErrorMessage(undefined);
                props.setOpen(false);
            }}
            onOpen={() => props.setOpen(true)}
            open={props.open}>
            <Modal.Header>{props.reward ? 'Preis bearbeiten' : 'Preis hinzufügen'}</Modal.Header>
            <Modal.Content>
                <Form error={!!errorMessage}>
                    <Form.Field required>
                        <label>Name:</label>
                        <Input value={name} placeholder='Name' onChange={(e) => setName(e.target.value)} name='name' />
                    </Form.Field>
                    <Form.Field>
                        <label>Wert:</label>
                        <Input type='number' value={value || ''} placeholder='Wert' onChange={(e) => setValue(e.target.valueAsNumber)} name='value' />
                    </Form.Field>
                    <Form.Field required>
                        <label>Abholinformation:</label>
                        <Input
                            value={redemptionInfo}
                            placeholder='Weise diesen Bildschirm ... vor, um deinen Preis zu erhalten.'
                            onChange={(e) => setRedemptionInfo(e.target.value)}
                            name='redemptionInfo'
                        />
                    </Form.Field>
                    <FormFieldSelectSponsor sponsorId={sponsorId} setSponsorId={setSponsorId} />
                    <Message error content={errorMessage} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={cancel}>Abbrechen</Button>
                {props.reward ? (
                    <>
                        <Button loading={btnDeleteLoading} onClick={deleteReward}>
                            Löschen
                        </Button>
                        <Button loading={btnSafeLoading} className='secondary' onClick={updateReward}>
                            Speichern
                        </Button>
                    </>
                ) : (
                    <Button loading={btnSafeLoading} className='secondary' onClick={createReward}>
                        Erstellen
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
}

export default RewardDetailModal;
