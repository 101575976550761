/* eslint-disable no-unused-vars */
import {
    Challenge,
    ChallengeApplication,
    ChallengeTip,
    Game,
    GamePlayer,
    GetIssuedRewardQuery,
    IssuedReward,
    Profile,
    Reward,
    RewardApplication,
    Sponsor,
} from '../../API';
import { IPlayer } from './player';
import { ISponsor } from './sponsor';
import { IUser } from './user';

export type AppState = {
    challenges: Challenge[] | undefined;
    newGame: Game | undefined;
    activeGame: Game | undefined;
    players: IPlayer[] | undefined;
    sponsors: ISponsor[] | undefined;
    rewards: Reward[] | undefined;
    issuedRewards: IssuedReward[] | undefined;
    user: IUser | undefined;
    ranking: Profile[] | undefined;
    imageSources: {
        gameSponsor: string;
        gamePlayers: {
            playerId: string;
            url: string;
        }[];
        challengeSponsors: {
            sponsorId: string;
            url: string;
        }[];
    };
    error: Error | undefined;
};

export enum ActionTypes {
    ADD_CHALLENGE = 'ADD_CHALLENGE',
    UPDATE_CHALLENGE = 'UPDATE_CHALLENGE',
    UPDATE_CHALLENGES = 'UPDATE_CHALLENGES',
    UPDATE_NEW_GAME = 'UPDATE_NEW_GAME',
    GAME_STARTED = 'GAME_STARTED',
    GAME_ENDED = 'GAME_ENDED',
    UPDATE_ACTIVE_GAME = 'UPDATE_ACTIVE_GAME',
    UPDATE_USER = 'UPDATE_USER',
    SIGN_OUT = 'SIGN_OUT',
    SET_CURRENT_USER = 'SET_CURRENT_USER',
    SET_CHALLENGE_TIP = 'SET_CHALLENGE_TIP',
    ADD_CHALLENGE_APPLICATION = 'ADD_CHALLENGE_APPLICATION',
    DELETE_CHALLENGE_APPLICATION = 'DELETE_CHALLENGE_APPLICATION',
    UPDATE_CHALLENGE_APPLICATION = 'UPDATE_CHALLENGE_APPLICATION',
    UPDATE_SPONSORS = 'UPDATE_SPONSORS',
    UPDATE_SPONSOR = 'UPDATE_SPONSOR',
    ADD_SPONSOR = 'ADD_SPONSOR',
    DELETE_SPONSOR = 'DELETE_SPONSOR',
    UPDATE_PLAYERS = 'UPDATE_PLAYERS',
    UPDATE_PLAYER = 'UPDATE_PLAYER',
    ADD_PLAYER = 'ADD_PLAYER',
    DELETE_PLAYER = 'DELETE_PLAYER',
    ADD_REWARD = 'ADD_REWARD',
    UPDATE_REWARD = 'UPDATE_REWARD',
    DELETE_REWARD = 'DELETE_REWARD',
    UPDATE_REWARDS = 'UPDATE_REWARDS',
    ADD_ISSUED_REWARD = 'ADD_ISSUED_REWARD',
    UPDATE_ISSUED_REWARDS = 'UPDATE_ISSUED_REWARDS',
    GET_ISSUED_REWARD = 'GET_ISSUED_REWARD',
    REDEEM_ISSUED_REWARD = 'REDEEM_ISSUED_REWARD',
    ADD_REWARD_APPLICATION = 'ADD_REWARD_APPLICATION',
    UPDATE_REWARD_APPLICATION = 'UPDATE_REWARD_APPLICATION',
    DELETE_REWARD_APPLICATION = 'DELETE_REWARD_APPLICATIONS',
    ADD_CHALLENGE_REWARD_APPLICATION = 'ADD_CHALLENGE_REWARD_APPLICATION',
    UPDATE_CHALLENGE_REWARD_APPLICATION = 'UPDATE_CHALLENGE_REWARD_APPLICATION',
    DELETE_CHALLENGE_REWARD_APPLICATION = 'DELETE_CHALLENGE_REWARD_APPLICATIONS',
    ADD_GAME_SPONSOR_URL = 'ADD_GAME_SPONSOR_URL',
    DELETE_GAME_SPONSOR_URL = 'DELETE_GAME_SPONSOR_URL',
    ADD_CHALLENGE_SPONSOR_URL = 'ADD_CHALLENGE_SPONSOR_URL',
    DELETE_CHALLENGE_SPONSOR_URL = 'DELETE_CHALLENGE_SPONSOR_URL',
    UPDATE_CHALLENGE_SPONSOR_URLS = 'UPDATE_CHALLENGE_SPONSOR_URLS',
    ADD_GAME_PLAYER_URL = 'ADD_GAME_PLAYER_URL',
    DELETE_GAME_PLAYER_URL = 'DELETE_GAME_PLAYER_URL',
    UPDATE_GAME_PLAYER_URLS = 'UPDATE_GAME_PLAYER_URLS',
    ADD_GAME_PLAYER = 'ADD_GAME_PLAYER',
    DELETE_GAME_PLAYER = 'DELETE_GAME_PLAYER',
    UPDATE_RANKING = 'UPDATE_RANKING',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
    SET_ERROR = 'SET_ERROR',
    RESET_ERROR = 'RESET_ERROR',
}

export type AppStateAction =
    | { type: ActionTypes.ADD_CHALLENGE; challenge: Challenge }
    | { type: ActionTypes.UPDATE_CHALLENGE; challenge: Challenge }
    | { type: ActionTypes.UPDATE_CHALLENGES; challenges: Challenge[] }
    | { type: ActionTypes.UPDATE_NEW_GAME; newGame: Game }
    | { type: ActionTypes.GAME_STARTED; activeGame: Game }
    | { type: ActionTypes.GAME_ENDED }
    | { type: ActionTypes.UPDATE_ACTIVE_GAME; activeGame: Game }
    | { type: ActionTypes.UPDATE_USER; user: IUser }
    | { type: ActionTypes.SIGN_OUT }
    | { type: ActionTypes.SET_CURRENT_USER; user: IUser }
    | { type: ActionTypes.SET_CHALLENGE_TIP; challengeTip: ChallengeTip }
    | { type: ActionTypes.ADD_CHALLENGE_APPLICATION; challengeApplication: ChallengeApplication }
    | { type: ActionTypes.DELETE_CHALLENGE_APPLICATION; challengeApplication: ChallengeApplication }
    | { type: ActionTypes.UPDATE_CHALLENGE_APPLICATION; challengeApplication: ChallengeApplication }
    | { type: ActionTypes.UPDATE_SPONSORS; sponsors: ISponsor[] }
    | { type: ActionTypes.UPDATE_SPONSOR; sponsor: ISponsor }
    | { type: ActionTypes.ADD_SPONSOR; sponsor: ISponsor }
    | { type: ActionTypes.DELETE_SPONSOR; sponsor: ISponsor }
    | { type: ActionTypes.UPDATE_PLAYERS; players: IPlayer[] }
    | { type: ActionTypes.UPDATE_PLAYER; player: IPlayer }
    | { type: ActionTypes.ADD_PLAYER; player: IPlayer }
    | { type: ActionTypes.DELETE_PLAYER; player: IPlayer }
    | { type: ActionTypes.ADD_REWARD; reward: Reward }
    | { type: ActionTypes.UPDATE_REWARD; reward: Reward }
    | { type: ActionTypes.DELETE_REWARD; reward: Reward }
    | { type: ActionTypes.UPDATE_REWARDS; rewards: Reward[] }
    | { type: ActionTypes.ADD_ISSUED_REWARD; issuedReward: IssuedReward }
    | { type: ActionTypes.UPDATE_ISSUED_REWARDS; issuedRewards: IssuedReward[] }
    | { type: ActionTypes.GET_ISSUED_REWARD; issuedReward: GetIssuedRewardQuery }
    | { type: ActionTypes.REDEEM_ISSUED_REWARD; issuedReward: IssuedReward }
    | { type: ActionTypes.ADD_REWARD_APPLICATION; rewardApplication: RewardApplication }
    | { type: ActionTypes.UPDATE_REWARD_APPLICATION; rewardApplication: RewardApplication }
    | { type: ActionTypes.DELETE_REWARD_APPLICATION; rewardApplication: RewardApplication }
    | { type: ActionTypes.ADD_CHALLENGE_REWARD_APPLICATION; rewardApplication: RewardApplication }
    | { type: ActionTypes.UPDATE_CHALLENGE_REWARD_APPLICATION; rewardApplication: RewardApplication }
    | { type: ActionTypes.DELETE_CHALLENGE_REWARD_APPLICATION; rewardApplication: RewardApplication }
    | { type: ActionTypes.ADD_GAME_SPONSOR_URL; gamesponsor: string }
    | { type: ActionTypes.ADD_CHALLENGE_SPONSOR_URL; challengeSponsor: Sponsor; url: string }
    | { type: ActionTypes.DELETE_CHALLENGE_SPONSOR_URL; challengeSponsor: Sponsor }
    | { type: ActionTypes.UPDATE_CHALLENGE_SPONSOR_URLS; challengeSponsors: { sponsorId: string; url: string }[] }
    | { type: ActionTypes.ADD_GAME_PLAYER_URL; gamePlayer: GamePlayer; url: string }
    | { type: ActionTypes.DELETE_GAME_PLAYER_URL; gamePlayer: GamePlayer }
    | { type: ActionTypes.DELETE_GAME_SPONSOR_URL }
    | { type: ActionTypes.ADD_GAME_PLAYER; gamePlayer: GamePlayer }
    | { type: ActionTypes.DELETE_GAME_PLAYER; gamePlayer: GamePlayer }
    | { type: ActionTypes.UPDATE_GAME_PLAYER_URLS; gamePlayers: { playerId: string; url: string }[] }
    | { type: ActionTypes.UPDATE_RANKING; ranking: Profile[] }
    | { type: ActionTypes.UPDATE_PROFILE; profile: Profile }
    | { type: ActionTypes.SET_ERROR; error: Error }
    | { type: ActionTypes.RESET_ERROR };
