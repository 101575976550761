import React, { FormEvent, MouseEvent, useContext, useEffect, useState } from 'react';
import { Button, CheckboxProps, Form, Input, Message, Modal } from 'semantic-ui-react';
import { createChallengeAction, updateChallengeAction } from '../../actions/gameActions';
import { Challenge, ChallengeType } from '../../API';
import Context from '../../Context/context';
import useAsync from '../../hooks/useAsync';
import { ActionTypes } from '../../ressources/types/state';
import FormFieldSelectSponsor from '../Form/FormFieldSelectSponsor/FormFieldSelectSponsor';

interface ChallengeDetailModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
    challenge?: Challenge;
}

function ChallengeDetailModal(props: ChallengeDetailModalProps) {
    const { dispatch } = useContext(Context);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState('');
    const [question, setQuestion] = useState('');
    const [points, setPoints] = useState(0);
    const [sponsorId, setSponsorId] = useState('');
    const [type, setType] = useState<ChallengeType | undefined>();
    const [btnSafeLoading, setBtnSafeLoading] = useState(false);
    const [btnDeleteLoading, setBtnDeleteLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (props.challenge) {
            setDescription(props.challenge.description || '');
            setQuestion(props.challenge.question);
            setPoints(props.challenge.points);
            setType(props.challenge.type);
            setSponsorId(props.challenge.sponsorId || '');
        } else {
            setDescription('');
            setQuestion('');
            setPoints(0);
            setType(undefined);
            setSponsorId('');
        }
    }, [props.open]);

    async function handleSubmit(e: MouseEvent<HTMLButtonElement>, saveAction: () => Promise<void>) {
        e.preventDefault();
        setErrorMessage(undefined);
        if (isFormValid()) {
            setBtnSafeLoading(true);
            await saveAction();
            setBtnSafeLoading(false);
        }
    }

    async function updateChallenge(e: MouseEvent<HTMLButtonElement>) {
        await handleSubmit(e, async () => {
            if (props.challenge && type) {
                const updatedChallenge = { ...props.challenge };
                updatedChallenge.description = description;
                updatedChallenge.question = question;
                updatedChallenge.points = points;
                updatedChallenge.type = type;
                updatedChallenge.sponsorId = sponsorId || null;
                await execute(() => updateChallengeAction(dispatch, updatedChallenge));
                props.setOpen(false);
            }
        });
    }

    async function createChallenge(e: MouseEvent<HTMLButtonElement>) {
        await handleSubmit(e, async () => {
            if (type) {
                await execute(() => createChallengeAction(dispatch, description, points, question, type, sponsorId));
                props.setOpen(false);
            }
        });
    }

    function isFormValid() {
        if (question === '') {
            setErrorMessage('Frage nicht vorhanden.');
            return false;
        }
        if (isNaN(points) || points <= 0) {
            setErrorMessage('Fehlende oder ungültige Punkte');
            return false;
        }
        if (type === undefined) {
            setErrorMessage('Antworttyp nicht definiert.');
            return false;
        }
        return true;
    }

    async function deleteChallenge(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setBtnDeleteLoading(true);
        setErrorMessage(undefined);
        if (props.challenge) {
            const updatedChallenge = { ...props.challenge };
            updatedChallenge.archived = true;
            await execute(() => updateChallengeAction(dispatch, updatedChallenge));
        }
        setBtnDeleteLoading(false);
        props.setOpen(false);
    }

    function cancel() {
        setErrorMessage(undefined);
        props.setOpen(false);
    }

    function handleTypeChange(e: FormEvent<HTMLInputElement>, data: CheckboxProps) {
        e.preventDefault();
        setType(ChallengeType[data.value as keyof typeof ChallengeType]);
    }

    return (
        <Modal className='challenge-modal' onClose={cancel} onOpen={() => props.setOpen(true)} open={props.open}>
            <Modal.Header>{props.challenge ? 'Challenge bearbeiten' : 'Challenge hinzufügen'}</Modal.Header>
            <Modal.Content>
                <Form error={!!errorMessage}>
                    <Form.Field required>
                        <label>Frage:</label>
                        <Input value={question} placeholder='Frage' onChange={(e) => setQuestion(e.target.value)} name='question' />
                    </Form.Field>
                    <Form.Field>
                        <label>Beschreibung:</label>
                        <Input value={description} placeholder='Beschreibung' onChange={(e) => setDescription(e.target.value)} name='description' />
                    </Form.Field>
                    <Form.Field required>
                        <label>Punkte:</label>
                        <Input
                            type='number'
                            value={points}
                            placeholder='Punkte'
                            onChange={(e) => setPoints(parseInt(e.target.value, 10))}
                            name='points'
                        />
                    </Form.Field>
                    <Form.Field required={true}>
                        <label>Antworttyp:</label>
                        <Form.Group inline>
                            <Form.Radio
                                label='Spieler'
                                value={ChallengeType.onePlayerChallenge}
                                checked={type === ChallengeType.onePlayerChallenge}
                                onChange={handleTypeChange}
                                name={ChallengeType.onePlayerChallenge}
                            />
                            <Form.Radio
                                label='Zahl (Schätzung)'
                                value={ChallengeType.estimationChallenge}
                                checked={type === ChallengeType.estimationChallenge}
                                onChange={handleTypeChange}
                                name={ChallengeType.estimationChallenge}
                            />
                            <Form.Radio
                                label='Resultat'
                                value={ChallengeType.resultChallenge}
                                checked={type === ChallengeType.resultChallenge}
                                onChange={handleTypeChange}
                                name={ChallengeType.resultChallenge}
                            />
                        </Form.Group>
                    </Form.Field>
                    <FormFieldSelectSponsor sponsorId={sponsorId} setSponsorId={setSponsorId} />
                    <Message error content={errorMessage} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={cancel}>Abbrechen</Button>
                {props.challenge ? (
                    <>
                        <Button loading={btnDeleteLoading} onClick={deleteChallenge}>
                            Löschen
                        </Button>
                        <Button loading={btnSafeLoading} className='secondary' onClick={updateChallenge}>
                            Speichern
                        </Button>
                    </>
                ) : (
                    <Button loading={btnSafeLoading} className='secondary' onClick={createChallenge}>
                        Erstellen
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
}

export default ChallengeDetailModal;
