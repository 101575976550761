import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Message, Modal } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { createSponsorAction, updateSponsorAction } from '../../actions/sponsorActions';
import { CreateSponsorInput } from '../../API';
import Context from '../../Context/context';
import './sponsorDetailModal.scss';
import DropZone from '../DropZone/DropZone';
import { ISponsor } from '../../ressources/types/sponsor';
import useAsync from '../../hooks/useAsync';
import { ActionTypes } from '../../ressources/types/state';

interface SponsorDetailModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
    sponsor?: ISponsor;
}

function SponsorDetailModal(props: SponsorDetailModalProps) {
    const { dispatch } = useContext(Context);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [sponsorName, setSponsorName] = useState('');
    const [website, setWebsite] = useState('');
    const [file, setFile] = useState<Blob | null>();
    const [fileDataURL, setFileDataURL] = useState<string>('');
    const [btnSafeLoading, setBtnSafeLoading] = useState(false);
    const [btnDeleteLoading, setBtnDeleteLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (props.sponsor) {
            setSponsorName(props.sponsor.name);
            setWebsite(props.sponsor.website);
            setFileDataURL(props.sponsor.s3url);
        } else {
            setFile(null);
            setFileDataURL('');
            setSponsorName('');
            setWebsite('');
        }
    }, [props.open]);

    async function updateSponsor(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setErrorMessage(undefined);
        switch (false) {
            case sponsorName !== '':
                setErrorMessage('Sponsor Name nicht vorhanden.');
                break;
            case website !== '':
                setErrorMessage('Webseite nicht vorhanden.');
                break;
            default:
                setBtnSafeLoading(true);
                if (props.sponsor) {
                    const updatedSponsor = props.sponsor;
                    updatedSponsor.name = sponsorName;
                    updatedSponsor.website = website;
                    if (file) {
                        await execute(() => updateSponsorAction(dispatch, updatedSponsor, file));
                    } else {
                        await execute(() => updateSponsorAction(dispatch, updatedSponsor));
                    }
                    props.setOpen(false);
                }
                setBtnSafeLoading(false);
                break;
        }
    }

    async function createSponsor(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setErrorMessage(undefined);
        let sponsor: CreateSponsorInput;
        switch (false) {
            case sponsorName !== '':
                setErrorMessage('Sponsor Name nicht vorhanden.');
                break;
            case website !== '':
                setErrorMessage('Webseite nicht vorhanden.');
                break;
            case file !== null:
                setErrorMessage('Bild nicht vorhanden');
                break;
            default:
                setBtnSafeLoading(true);
                sponsor = { name: sponsorName, website, image: uuidv4(), archived: false };
                if (file) {
                    await execute(() => createSponsorAction(dispatch, sponsor, file));
                    props.setOpen(false);
                }
                setBtnSafeLoading(false);
                break;
        }
    }

    async function deleteSponsor(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setBtnDeleteLoading(true);
        setErrorMessage(undefined);
        if (props.sponsor?.challenges2?.items.length) {
            setErrorMessage(
                `Entferne zuerst den Sponsor in ${props.sponsor?.challenges2.items.length === 1 ? 'der' : 'den'} ${
                    props.sponsor?.challenges2.items.length
                } Challenges`
            );
            setBtnDeleteLoading(false);
            return;
        }

        if (props.sponsor?.rewards?.items.length) {
            setErrorMessage(
                `Entferne zuerst den Sponsor in ${props.sponsor?.rewards.items.length === 1 ? 'der' : 'den'} ${
                    props.sponsor?.rewards.items.length
                } Preisen`
            );
            setBtnDeleteLoading(false);
            return;
        }

        if (props.sponsor) {
            const updatedSponsor = { ...props.sponsor };
            updatedSponsor.archived = true;
            await execute(() => updateSponsorAction(dispatch, updatedSponsor));
        }
        setBtnDeleteLoading(false);
        props.setOpen(false);
    }

    function cancel() {
        setErrorMessage(undefined);
        props.setOpen(false);
    }

    return (
        <Modal
            className='image-upload-modal sponsor-modal'
            onClose={() => {
                setErrorMessage(undefined);
                props.setOpen(false);
            }}
            onOpen={() => props.setOpen(true)}
            open={props.open}>
            <Modal.Header>{props.sponsor ? 'Sponsor bearbeiten' : 'Sponsor hinzufügen'}</Modal.Header>
            <Modal.Content>
                <div className='form'>
                    <Form error={!!errorMessage}>
                        <Form.Field required={true}>
                            <label>Sponsor Name:</label>
                            <Input
                                value={sponsorName}
                                placeholder='Sponsor Name'
                                onChange={(e) => setSponsorName(e.target.value)}
                                name='sponsorName'
                            />
                        </Form.Field>
                        <Form.Field required={true}>
                            <label>Webseite:</label>
                            <div className='website-input'>
                                <div className='https'>
                                    <span>https://</span>
                                </div>
                                <Input value={website} placeholder='Webseite' onChange={(e) => setWebsite(e.target.value)} name='website' />
                            </div>
                        </Form.Field>
                        {props.sponsor?.challenges2?.items.length ? (
                            <Form.Field>
                                <label>Verbundene Challenges:</label>
                                <ul>
                                    {props.sponsor.challenges2.items.map((challenge) => (
                                        <li>{challenge?.question}</li>
                                    ))}
                                    <div></div>
                                </ul>
                            </Form.Field>
                        ) : null}
                        {props.sponsor?.rewards?.items.length ? (
                            <Form.Field>
                                <label>Verbundene Preise:</label>
                                <ul>
                                    {props.sponsor?.rewards.items.map((reward) => (
                                        <li>{reward?.name}</li>
                                    ))}
                                    <div></div>
                                </ul>
                            </Form.Field>
                        ) : null}
                        <Message error content={errorMessage} />
                    </Form>
                </div>
                <div className='image-upload'>
                    <h2>Bildvorschau</h2>
                    <DropZone existingFileUrl={fileDataURL || undefined} setFile={setFile} maxImageWidth={300} imageFormat='PNG' />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={cancel}>Abbrechen</Button>
                {props.sponsor ? (
                    <>
                        <Button loading={btnDeleteLoading} onClick={deleteSponsor}>
                            Löschen
                        </Button>
                        <Button loading={btnSafeLoading} className='secondary' onClick={updateSponsor}>
                            Speichern
                        </Button>
                    </>
                ) : (
                    <Button loading={btnSafeLoading} className='secondary' onClick={createSponsor}>
                        Erstellen
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
}

export default SponsorDetailModal;
