import { createContext } from 'react';
import { AppState, AppStateAction } from '../ressources/types/state';

export const initialAppState: AppState = {
    challenges: [],
    newGame: undefined,
    activeGame: undefined,
    players: undefined,
    sponsors: undefined,
    rewards: undefined,
    issuedRewards: undefined,
    user: undefined,
    ranking: undefined,
    imageSources: {
        gameSponsor: '',
        gamePlayers: [],
        challengeSponsors: [],
    },
    error: undefined,
};

const Context = createContext<{ state: AppState; dispatch: React.Dispatch<AppStateAction> }>({
    state: initialAppState,
    dispatch: () => null,
});

export default Context;
