import React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import './textPlaceholder.scss';

interface TextPlaceholderProps {
    placeholderText: string;
    onClick?: () => void;
    icon?: SemanticICONS;
}

function TextPlaceholder(props: TextPlaceholderProps) {
    return (
        <div className='text-placeholder' onClick={() => props.onClick && props.onClick()}>
            <div className='text-placeholder-content'>
                <span className='text-placeholder-text'>{props.placeholderText}</span>
            </div>
            {props.icon && <Icon name={props.icon} />}
        </div>
    );
}

export default TextPlaceholder;
