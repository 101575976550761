import React, { useContext, useEffect, useState } from 'react';
import './playerSelect.scss';
import { SemanticICONS } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import Player from '../Player/Player';
import { IPlayer, nobody } from '../../ressources/types/player';
import PlayerPlaceholder from '../Player/PlayerPlaceholder/PlayerPlaceholder';
import { ChallengeApplication } from '../../API';
import { getPlayerById } from '../../utils/playerHelpers';
import Context from '../../Context/context';
import GeneralSelect from './GeneralSelect';

function PlayerSelect(props: { challenge: ChallengeApplication; answer?: string; disabled?: boolean; icon?: SemanticICONS }) {
    const { state } = useContext(Context);
    const navigate = useNavigate();
    const [selectedPlayer, setSelectedPlayer] = useState<IPlayer>();
    const [solutions, setSolutions] = useState<IPlayer[]>([]);

    useEffect(() => {
        if (props.answer && state.activeGame?.lineup) {
            getPlayerById(props.answer, state.activeGame.lineup.items).then((player) => {
                if (player) {
                    setSelectedPlayer(player);
                }
            });
        }
    }, [props.answer]);

    useEffect(() => {
        if (props.challenge.solution) {
            const solutions = props.challenge.solution.split(',');
            Promise.all(
                solutions.map(async (solution) => {
                    if (state.activeGame?.lineup) {
                        const player: IPlayer | undefined = await getPlayerById(solution, state.activeGame.lineup.items);
                        if (player) {
                            return player;
                        }
                    }
                })
            )
                .then((players) => {
                    return players.flatMap((player) => {
                        if (!player) {
                            return [];
                        }

                        return player;
                    });
                })
                .then((players) => {
                    setSolutions(players);
                });
        }
    }, [props.challenge.solution]);

    function handleClick() {
        !props.disabled && navigate('players', { replace: true });
    }

    function renderPlayer(player: IPlayer) {
        if (selectedPlayer === nobody) {
            return <PlayerPlaceholder placeholderText='Niemand' onClick={() => handleClick()} icon={props.icon} />;
        }
        return <Player player={player} action={() => handleClick()} icon={props.icon} />;
    }

    function getPlayerSelectText() {
        return props.disabled ? 'Keine Auswahl' : 'Spieler auswählen';
    }

    function getSolutionInfo() {
        if (solutions[0] === nobody) {
            return 'Lösung: Niemand';
        }
        const playerListAsString = solutions?.map((solution) => `#${solution.number} ${solution.lastname} ${solution.firstname}`).join(', ');

        return `Lösung: ${playerListAsString}`;
    }

    return (
        <GeneralSelect
            answer={props.answer}
            disabled={props.disabled}
            changeAction={
                <Link to='players' replace={true}>
                    Auswahl ändern
                </Link>
            }
            solutionInfo={solutions.length > 0 ? getSolutionInfo() : undefined}
            maxPointsInfo={`Korrekter Tipp: ${props.challenge.challenge.points} Punkte`}>
            {selectedPlayer ? (
                <>{renderPlayer(selectedPlayer)}</>
            ) : (
                <PlayerPlaceholder onClick={handleClick} placeholderText={getPlayerSelectText()} />
            )}
        </GeneralSelect>
    );
}

export default PlayerSelect;
