import React, { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Form, Input, Message, Popup } from 'semantic-ui-react';
import AdminHeader from '../../components/AdminHeader/AdminHeader';
import '../../ressources/styles/themes/admin.scss';
import Context from '../../Context/context';
import { isAdmin } from '../../utils/userHelpers';
import { setCurrentUserAction, signInAction } from '../../actions/userActions';
import CustomLoader from '../../components/CustomLoader/CustomLoader';
import { ActionTypes } from '../../ressources/types/state';
import { AuthExceptions } from '../../ressources/types/exceptions';
import { notAuthorized } from '../../utils/authenticationHelpers';

function AdminTheme() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const { state, dispatch } = useContext(Context);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const errorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (state.error) {
            setErrorMessage(state.error.message);
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
                dispatch({ type: ActionTypes.RESET_ERROR });
            }, 10000);
        } else {
            setHasError(false);
        }
    }, [state.error]);

    useEffect(() => {
        process.env.REACT_APP_REDIRECT_URL_KEY && localStorage.setItem(process.env.REACT_APP_REDIRECT_URL_KEY, 'false');
    }, []);

    useEffect(() => {
        if (!state.user) {
            setCurrentUserAction(dispatch);
            setLoading(false);
        }
    }, [state.user]);

    async function handleSubmit(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setError(undefined);
        setBtnLoading(true);

        signInAction(dispatch, email, password)
            .then(() => {
                setBtnLoading(false);
                if (state.user && !isAdmin(state.user)) {
                    setError(AuthExceptions.NOT_AUTHORIZED_EXCEPTION);
                }
            })
            .catch((err: any) => {
                setBtnLoading(false);
                setError(err.name);
            });
    }

    if (loading) {
        return <CustomLoader label='Seite wird geladen...' />;
    }

    return (
        <>
            {!state.user || (state.user && !isAdmin(state.user)) ? (
                <div className='admin-login-form'>
                    <img src='/img/logo.png' alt='SCRJ Lakers Logo' />
                    <Form error={!!error}>
                        <Form.Field>
                            <label>Email Adresse:</label>
                            <Input placeholder='Email' onChange={(e) => setEmail(e.target.value)} name='email' />
                        </Form.Field>
                        <Form.Field>
                            <label>Passwort:</label>
                            <Input placeholder='Passwort' type='password' onChange={(e) => setPassword(e.target.value)} name='password' />
                        </Form.Field>
                        <Button loading={btnLoading} type='submit' content='Anmelden' onClick={handleSubmit} />
                        {error && notAuthorized(error) ? (
                            <Message error content={'Keine Berechtigung.'} />
                        ) : (
                            <Message error content='Beim Login ist ein Fehler aufgetreten.' />
                        )}
                    </Form>
                </div>
            ) : (
                <div className='admin admin-theme'>
                    <AdminHeader />
                    <section id='content' ref={errorRef}>
                        <Outlet />
                    </section>
                    <Popup className='error-popup admin' context={errorRef} content={errorMessage} position='top center' open={hasError} />
                </div>
            )}
        </>
    );
}

export default AdminTheme;
