import sanitizeHtml from 'sanitize-html';
import { z } from 'zod';

export type NicknameValidationFilters = {
    profanityWords: boolean;
    url: boolean;
    email: boolean;
    html: boolean;
    length: {
        min?: number;
        max?: number;
    };
};

export default abstract class NicknameValidator {
    readonly filters: NicknameValidationFilters;

    static readonly defaultFilters = {
        profanityWords: true,
        url: true,
        email: true,
        html: true,
        length: {
            min: 3,
            max: 20,
        },
    };

    constructor(filters: Partial<NicknameValidationFilters> = NicknameValidator.defaultFilters) {
        const length = Object.keys(filters?.length || {}).length === 0 ? {} : { ...NicknameValidator.defaultFilters.length, ...filters?.length };
        this.filters = {
            ...NicknameValidator.defaultFilters,
            ...filters,
            length,
        };
    }

    async isValidAsync(nickname: string): Promise<boolean> {
        if (this.filters.html && NicknameValidator.containsHtmlSyntax(nickname)) {
            return false;
        }

        if (Object.keys(this.filters.length).length !== 0 && !this.hasValidLength(nickname)) {
            return false;
        }

        if (this.filters.email && NicknameValidator.isEmail(nickname)) {
            return false;
        }

        if (this.filters.url && NicknameValidator.isUrl(nickname)) {
            return false;
        }

        const profanityWordList = await this.getProfanityWordList();
        if (this.filters.profanityWords && NicknameValidator.containsProfanityWords(nickname, profanityWordList)) {
            return false;
        }

        return true;
    }

    private static containsHtmlSyntax(dirty: string): boolean {
        const clean = sanitizeHtml(dirty, {
            allowedTags: [],
            allowedAttributes: {},
        });
        return dirty !== clean;
    }

    private hasValidLength(nickname: string): boolean {
        return z
            .string()
            .min(this.filters.length.min || 1)
            .max(this.filters.length.max || 256)
            .safeParse(nickname).success;
    }

    private static isUrl(nickname: string): boolean {
        return z.string().url(nickname).safeParse(nickname).success;
    }

    private static containsProfanityWords(nickname: string, swearWords: string[]): boolean {
        return swearWords.some((word) => word && nickname.toLowerCase().includes(word.toLowerCase()));
    }

    private static isEmail(nickname: string) {
        return z.string().email().safeParse(nickname).success;
    }

    protected abstract getProfanityWordList(): Promise<string[]>;
}
