import React from 'react';

function Impressum() {
    return (
        <>
            <h1>Impressum</h1>
            <section className='text-content'>
                <h2> Verantwortlich für den Inhalt</h2>

                <p>
                    <strong>Lakers Sport AG</strong>
                    <br />
                    Walter Denzler Strasse 3<br />
                    CH-8640 Rapperswil-Jona
                    <br />
                </p>

                <p>
                    Telefon: +41 55 220 80 90 <br />
                    Telefax: +41 55 220 80 91
                </p>

                <p>
                    <a href='mailto:info@lakers.ch?subject=Lakers Tippspiel'>info@lakers.ch</a>
                </p>
            </section>
            <section className='text-content'>
                <h2> Verantwortlich für die Entwicklung</h2>

                <p>
                    <p>
                        <strong>OST</strong>
                        <br />
                        <strong>Ostschweizer Fachhochschule</strong>
                        <br />
                        IFS Institut für Software
                        <br />
                        Oberseestrasse 10
                        <br />
                        8640 Rapperswil
                        <br />
                    </p>

                    <p>Telefon: +41 58 257 47 46</p>

                    <p>
                        <a href='mailto:stefan.keller@ost.ch?subject=Lakers Tippspiel'>stefan.keller@ost.ch</a>
                    </p>
                </p>
            </section>
        </>
    );
}

export default Impressum;
