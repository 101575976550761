import React from 'react';
import { Link } from 'react-router-dom';
import './error404.scss';

function Error404() {
    return (
        <div id='page-not-found'>
            <h1>HOPPLA!</h1>
            <h2>
                Die Seite wurde nicht gefunden. <Link to='/'>Hier gehts zur Startseite.</Link>
            </h2>
        </div>
    );
}

export default Error404;
