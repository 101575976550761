import React, { RefObject, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { fetchIssuedRewardsAction } from '../../actions/rewardActions';
import Context from '../../Context/context';
import useAsync from '../../hooks/useAsync';
import { ActionTypes } from '../../ressources/types/state';
import { getAvailableRewards } from '../../utils/rewardHelpers';
import { isAnonymous } from '../../utils/userHelpers';
import './navigation.scss';

interface NavigationProps {
    errorRef?: RefObject<HTMLElement>;
}

function Navigation(props: NavigationProps) {
    const { state, dispatch } = useContext(Context);
    const [issuedRewards, setIssuedRewards] = useState(0);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (state.issuedRewards) {
            setIssuedRewards(getAvailableRewards(state.issuedRewards).length);
        } else if (state.user && !isAnonymous(state.user)) {
            execute(() => fetchIssuedRewardsAction(dispatch));
        }
    }, [state.issuedRewards]);

    const navigate = useNavigate();

    return (
        <nav id='footer-nav' ref={props.errorRef}>
            <div className='nav-item nav-item-left' onClick={() => navigate('/rewards')}>
                <Icon name='gift'></Icon>Preise
                {issuedRewards !== 0 && (
                    <div className='notification-counter'>
                        <p>{issuedRewards}</p>
                    </div>
                )}
            </div>

            <div className='nav-item nav-item-center' onClick={() => navigate('/game')}>
                <Icon name='game'></Icon>Tippspiel
            </div>
            <div className='nav-item nav-item-right' onClick={() => navigate('/ranking')}>
                <Icon name='trophy'></Icon>Rangliste
            </div>
            <div className='nav-bg'></div>
        </nav>
    );
}

export default Navigation;
