import { Dispatch } from 'react';
import { Auth } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
// eslint-disable-next-line import/no-self-import
import * as gameActions from './gameActions';
import { GraphqlInterceptor as API } from '../utils/graphqlInterceptor';
import {
    Challenge,
    ChallengeApplication,
    ChallengeTip,
    ChallengeType,
    CreateChallengeApplicationMutation,
    CreateChallengeApplicationMutationVariables,
    CreateChallengeInput,
    CreateChallengeMutation,
    CreateGameMutation,
    CreateGameMutationVariables,
    DeleteChallengeApplicationMutation,
    DeleteChallengeApplicationMutationVariables,
    EvaluateChallengeApplicationMutation,
    EvaluateChallengeApplicationMutationVariables,
    EvaluateGameMutation,
    EvaluateGameMutationVariables,
    Game,
    GamePlayer,
    ListChallengesQuery,
    ListGamesWithoutTipsQuery,
    listGamesWithTipsQuery,
    SetChallengeTipMutation,
    SetChallengeTipMutationVariables,
    UpdateChallengeApplicationInput,
    UpdateChallengeApplicationMutation,
    UpdateChallengeApplicationMutationVariables,
    UpdateChallengeInput,
    UpdateChallengeMutation,
    UpdateGameMutation,
    UpdateGameMutationVariables,
} from '../API';
import {
    createChallengeApplicationWithRequiredDepth,
    createGameWithRequiredDepth,
    evaluateChallengeApplicationWithRewardsAndTips,
    setCustomChallengeTip,
    updateChallengeApplicationWithRewards,
    updateGameWithRequiredDepth,
} from '../graphql/custom-mutations';
import { listChallengesWithSponsor, listGamesWithoutTips, listGamesWithTips } from '../graphql/custom-queries';
import { createChallenge, deleteChallengeApplication, evaluateGame, updateChallenge } from '../graphql/mutations';
import { ActionTypes, AppStateAction } from '../ressources/types/state';
import { isAnonymous, setMigrationFlag } from '../utils/userHelpers';
import { setGameSponsorUrl } from './sponsorActions';
import { setGamePlayerImageSources } from './playerActions';
import { ISponsor } from '../ressources/types/sponsor';
import { ChallengeSelection } from '../ressources/types/game';

export async function fetchChallengesAction(dispatch: Dispatch<AppStateAction>) {
    const response = await API.graphql<GraphQLQuery<ListChallengesQuery>>({
        query: listChallengesWithSponsor,
        variables: {
            filter: { archived: { eq: false } },
        },
    });
    dispatch({
        type: ActionTypes.UPDATE_CHALLENGES,
        challenges: response.data?.listChallenges?.items as Challenge[],
    });
}

export async function createChallengeAction(
    dispatch: Dispatch<AppStateAction>,
    description: string,
    points: number,
    question: string,
    type: ChallengeType,
    sponsorId?: string
) {
    const challenge: CreateChallengeInput = {
        description,
        points,
        question,
        type,
        sponsorId,
    };
    const response = await API.graphql<GraphQLQuery<CreateChallengeMutation>>({
        query: createChallenge,
        variables: { input: challenge },
    });
    if (response.data?.createChallenge) {
        dispatch({ type: ActionTypes.ADD_CHALLENGE, challenge: response.data.createChallenge as Challenge });
    }
}

export async function updateChallengeAction(dispatch: Dispatch<AppStateAction>, challenge: Challenge) {
    const input: UpdateChallengeInput = {
        id: challenge.id,
        description: challenge.description,
        question: challenge.question,
        points: challenge.points,
        type: challenge.type,
        archived: challenge.archived,
        sponsorId: challenge.sponsorId,
    };
    const response = await API.graphql<GraphQLQuery<UpdateChallengeMutation>>({
        query: updateChallenge,
        variables: { input },
    });

    if (response.data) {
        dispatch({ type: ActionTypes.UPDATE_CHALLENGE, challenge: response.data.updateChallenge as Challenge });
    }
}

export async function fetchActiveGameWithTipsAction(dispatch: Dispatch<AppStateAction>) {
    const gameData = await API.graphql<GraphQLQuery<ListGamesWithoutTipsQuery>>({
        query: listGamesWithTips,
        variables: {
            filter: {
                startTimestamp: { le: new Date(Date.now()).toISOString() },
                endTimestamp: { attributeExists: false },
            },
        },
    });

    if (gameData.data?.listGames?.items[0]?.sponsor) {
        setGameSponsorUrl(dispatch, gameData.data.listGames.items[0].sponsor as ISponsor);
    } else {
        dispatch({ type: ActionTypes.DELETE_GAME_SPONSOR_URL });
    }
    if (gameData.data?.listGames?.items[0]?.lineup) {
        setGamePlayerImageSources(dispatch, gameData.data.listGames.items[0].lineup.items as GamePlayer[]);
    }

    dispatch({
        type: ActionTypes.UPDATE_ACTIVE_GAME,
        activeGame: gameData?.data?.listGames?.items[0] as Game,
    });
}

export async function fetchActiveGameWithoutTipsAction(dispatch: Dispatch<AppStateAction>) {
    const gameData = await API.graphql<GraphQLQuery<listGamesWithTipsQuery>>({
        query: listGamesWithoutTips,
        variables: {
            filter: {
                startTimestamp: { le: new Date(Date.now()).toISOString() },
                endTimestamp: { attributeExists: false },
            },
        },
    });
    if (gameData?.data?.listGames?.items[0]?.lineup?.items.length) {
        setGamePlayerImageSources(dispatch, gameData.data.listGames.items[0].lineup.items as GamePlayer[]);
    }
    dispatch({
        type: ActionTypes.UPDATE_ACTIVE_GAME,
        activeGame: gameData?.data?.listGames?.items[0] as Game,
    });
}

export async function fetchNewGameAction(dispatch: Dispatch<AppStateAction>) {
    const gameData = await API.graphql<GraphQLQuery<ListGamesWithoutTipsQuery>>({
        query: listGamesWithoutTips,
        variables: { filter: { startTimestamp: { attributeExists: false } } },
    });
    dispatch({ type: ActionTypes.UPDATE_NEW_GAME, newGame: gameData?.data?.listGames?.items[0] as Game });
}

export async function createNewGameAction(dispatch: Dispatch<AppStateAction>) {
    const variables: CreateGameMutationVariables = { input: { automaticChallenges: true } };
    const response = await API.graphql<GraphQLQuery<CreateGameMutation>>({
        query: createGameWithRequiredDepth,
        variables,
    });
    if (response.data?.createGame) {
        dispatch({ type: ActionTypes.UPDATE_NEW_GAME, newGame: response.data.createGame as Game });
    }
}

export function updateNewGameAction(dispatch: Dispatch<AppStateAction>, newGame: Game) {
    dispatch({ type: ActionTypes.UPDATE_NEW_GAME, newGame });
}

export async function updateGameAction(dispatch: Dispatch<AppStateAction>, variables: UpdateGameMutationVariables) {
    const response = await API.graphql<GraphQLQuery<UpdateGameMutation>>({
        query: updateGameWithRequiredDepth,
        variables,
    });
    if (!response.data?.updateGame?.startTimestamp || parseInt(response.data?.updateGame?.startTimestamp, 10) > Date.now()) {
        dispatch({ type: ActionTypes.UPDATE_NEW_GAME, newGame: response.data?.updateGame as Game });
    } else {
        dispatch({ type: ActionTypes.UPDATE_ACTIVE_GAME, activeGame: response.data.updateGame as Game });
    }
}

export async function createChallengeTipAction(dispatch: Dispatch<AppStateAction>, challengeApplicationId: string, answer: string) {
    const variables: SetChallengeTipMutationVariables = {
        applicationId: challengeApplicationId,
        answer,
    };
    const response = await API.graphql<GraphQLQuery<SetChallengeTipMutation>>({
        query: setCustomChallengeTip,
        variables,
    });
    if (response.data?.setChallengeTip) {
        dispatch({ type: ActionTypes.SET_CHALLENGE_TIP, challengeTip: response.data.setChallengeTip as ChallengeTip });

        const user = await Auth.currentAuthenticatedUser();
        if (user && isAnonymous(user)) {
            setMigrationFlag(user.signInUserSession.accessToken.jwtToken);
        }
    }
}

export async function setChallengeSolutionAction(dispatch: Dispatch<AppStateAction>, solution: string, challengeApplicationId: string) {
    const variables: EvaluateChallengeApplicationMutationVariables = {
        applicationId: challengeApplicationId,
        solution,
    };
    const response = await API.graphql<GraphQLQuery<EvaluateChallengeApplicationMutation>>({
        query: evaluateChallengeApplicationWithRewardsAndTips,
        variables,
    });

    dispatch({
        type: ActionTypes.UPDATE_CHALLENGE_APPLICATION,
        challengeApplication: response.data?.evaluateChallengeApplication as ChallengeApplication,
    });
}

export async function startGameAction(dispatch: Dispatch<AppStateAction>, gameId: string) {
    const variables: UpdateGameMutationVariables = {
        input: { id: gameId, startTimestamp: new Date(Date.now()).toISOString() },
    };
    const response = await API.graphql<GraphQLQuery<UpdateGameMutation>>({
        query: updateGameWithRequiredDepth,
        variables,
    });

    dispatch({ type: ActionTypes.GAME_STARTED, activeGame: response.data?.updateGame as Game });
}

export async function evaluateGameAction(dispatch: Dispatch<AppStateAction>, gameId: string) {
    const variables: EvaluateGameMutationVariables = {
        gameId,
    };
    await API.graphql<GraphQLQuery<EvaluateGameMutation>>({ query: evaluateGame, variables });
    gameActions.endGameAction(dispatch, gameId);
}

export async function endGameAction(dispatch: Dispatch<AppStateAction>, gameId: string) {
    const variables: UpdateGameMutationVariables = {
        input: { id: gameId, endTimestamp: new Date(Date.now()).toISOString() },
    };
    await API.graphql<GraphQLQuery<UpdateGameMutation>>({ query: updateGameWithRequiredDepth, variables });
    dispatch({ type: ActionTypes.GAME_ENDED });
}

export async function createChallengeApplicationAction(dispatch: Dispatch<AppStateAction>, challenge: ChallengeSelection, gameId: string) {
    const variables: CreateChallengeApplicationMutationVariables = {
        input: { challengeId: challenge.challengeId, sponsorId: challenge.sponsorId, gameId },
    };
    const response = await API.graphql<GraphQLQuery<CreateChallengeApplicationMutation>>({
        query: createChallengeApplicationWithRequiredDepth,
        variables,
    });

    dispatch({
        type: ActionTypes.ADD_CHALLENGE_APPLICATION,
        challengeApplication: response.data?.createChallengeApplication as ChallengeApplication,
    });
}

export async function updateChallengeApplicationAction(dispatch: Dispatch<AppStateAction>, challenge: ChallengeSelection) {
    if (challenge.challengeApplicationId) {
        const input: UpdateChallengeApplicationInput = {
            id: challenge.challengeApplicationId,
            sponsorId: challenge.sponsorId,
        };
        const response = await API.graphql<GraphQLQuery<UpdateChallengeApplicationMutation>>({
            query: updateChallengeApplicationWithRewards,
            variables: { input },
        });
        dispatch({
            type: ActionTypes.UPDATE_CHALLENGE_APPLICATION,
            challengeApplication: response.data?.updateChallengeApplication as ChallengeApplication,
        });
    }
}

export async function deleteChallengeApplicationAction(dispatch: Dispatch<AppStateAction>, challengeApplicationId: string) {
    const variables: DeleteChallengeApplicationMutationVariables = {
        input: { id: challengeApplicationId },
    };
    const response = await API.graphql<GraphQLQuery<DeleteChallengeApplicationMutation>>({
        query: deleteChallengeApplication,
        variables,
    });
    dispatch({
        type: ActionTypes.DELETE_CHALLENGE_APPLICATION,
        challengeApplication: response.data?.deleteChallengeApplication as ChallengeApplication,
    });
}

export async function startChallengeApplicationAction(dispatch: Dispatch<AppStateAction>, challengeApplicationId: string) {
    const input: UpdateChallengeApplicationInput = {
        id: challengeApplicationId,
        startTimestamp: new Date(Date.now()).toISOString(),
    };
    const response = await API.graphql<GraphQLQuery<UpdateChallengeApplicationMutation>>({
        query: updateChallengeApplicationWithRewards,
        variables: { input },
    });
    dispatch({
        type: ActionTypes.UPDATE_CHALLENGE_APPLICATION,
        challengeApplication: response.data?.updateChallengeApplication as ChallengeApplication,
    });
}

export async function closeChallengeApplicationAction(dispatch: Dispatch<AppStateAction>, challengeApplicationId: string) {
    const variables: UpdateChallengeApplicationMutationVariables = {
        input: { id: challengeApplicationId, endTimestamp: new Date(Date.now()).toISOString() },
    };
    const response = await API.graphql<GraphQLQuery<UpdateChallengeApplicationMutation>>({
        query: updateChallengeApplicationWithRewards,
        variables,
    });
    dispatch({
        type: ActionTypes.UPDATE_CHALLENGE_APPLICATION,
        challengeApplication: response.data?.updateChallengeApplication as ChallengeApplication,
    });
}

export async function resolveChallengeApplicationAction(dispatch: Dispatch<AppStateAction>, challengeApplicationId: string, solution: string) {
    const variables: UpdateChallengeApplicationMutationVariables = {
        input: { id: challengeApplicationId, solution },
    };
    const response = await API.graphql<GraphQLQuery<UpdateChallengeApplicationMutation>>({
        query: updateChallengeApplicationWithRewards,
        variables,
    });
    dispatch({
        type: ActionTypes.UPDATE_CHALLENGE_APPLICATION,
        challengeApplication: response.data?.updateChallengeApplication as ChallengeApplication,
    });
}
