import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { passwordlessSignInAction } from '../../../actions/userActions';
import FinishSocialSignUp from '../../../components/Authenticator/SignUp/FinishSocialSignUp/FinishSocialSignUp';
import CustomLoader from '../../../components/CustomLoader/CustomLoader';
import Context from '../../../Context/context';
import { ActionTypes } from '../../../ressources/types/state';
import { migrateAnonymous, removeMigrationToken } from '../../../utils/userHelpers';

function Verify() {
    const { state, dispatch } = useContext(Context);
    const [loadingLabel, setLoadingLabel] = useState('Dein persönliches Profil wird geladen...');
    const [loading, setLoading] = useState(true);
    const searchParams = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams[0].get('token');
    const MIGRATION_TOKEN = localStorage.getItem('migrationToken');

    useEffect(() => {
        if (token && !state.user) {
            passwordlessSignInAction(dispatch, token)
                .then(() => {
                    navigate('/');
                })
                .catch((error) => {
                    dispatch({ type: ActionTypes.SET_ERROR, error });
                    setLoading(false);
                    navigate('/user');
                });
        } else if (state.user?.pool.userPoolId === process.env.REACT_APP_REGISTERED_USER_POOL_ID && MIGRATION_TOKEN) {
            setLoading(true);
            setLoadingLabel('Deine Tipps werden übernommen...');
            migrateAnonymous(MIGRATION_TOKEN).then(() => {
                removeMigrationToken();
                setLoading(false);
            });
        } else if (state.user?.profile?.nickname) {
            navigate('/');
        } else if (state.user) {
            setLoading(false);
        }
    }, [token, state.user, state.user?.profile, loading]);

    if (loading) {
        return <CustomLoader label={loadingLabel} />;
    }

    return <FinishSocialSignUp />;
}

export default Verify;
