import React, { useContext, useEffect, useState } from 'react';
import { createChallengeTipAction } from '../../actions/gameActions';
import { ChallengeType } from '../../API';
import Context from '../../Context/context';
import useAsync from '../../hooks/useAsync';
import { ActionTypes } from '../../ressources/types/state';
import ResultModal from '../ResultModal/ResultModal';
import EstimationModal from '../EstimationModal/EstimationModal';

interface AnswerModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
    challengeId: string;
    challengeType: ChallengeType;
    answer?: string;
}

function AnswerModal(props: AnswerModalProps) {
    const { state, dispatch } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    function cancel() {
        setErrorMessage(undefined);
        props.setOpen(false);
    }

    async function submitAnswer(answer: string) {
        setErrorMessage(undefined);
        if (state.user) {
            setLoading(true);
            if (answer) {
                await execute(() => createChallengeTipAction(dispatch, props.challengeId, answer)).then(() => {
                    props.setOpen(false);
                });
            }
            setLoading(false);
        }
    }

    function renderAnswerDetails(challengeType: ChallengeType) {
        switch (challengeType) {
            case ChallengeType.onePlayerChallenge:
                return <></>;
            case ChallengeType.estimationChallenge:
                return (
                    <EstimationModal
                        loading={loading}
                        open={props.open}
                        setOpen={props.setOpen}
                        heading={'Dein Tipp'}
                        submitResultText={'Speichern'}
                        submitEstimation={submitAnswer}
                        setErrorMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        cancel={cancel}
                        answer={props.answer}
                    />
                );
            case ChallengeType.resultChallenge:
                return (
                    <ResultModal
                        loading={loading}
                        open={props.open}
                        setOpen={props.setOpen}
                        heading={'Dein Tipp'}
                        submitResultText={'Speichern'}
                        submitResult={submitAnswer}
                        setErrorMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        cancel={cancel}
                        answer={props.answer}
                    />
                );
        }
    }

    return <div>{renderAnswerDetails(props.challengeType)}</div>;
}

export default AnswerModal;
