import { ActionTypes, AppState, AppStateAction } from '../ressources/types/state';
import {
    addChallengeApplication,
    addGamePlayer,
    addIssuedReward,
    addRewardApplication,
    deleteChallengeApplication,
    deleteGamePlayer,
    deleteRewardApplication,
    setChallengeTip,
    updateChallenge,
    updateChallengeApplication,
    updateReward,
    updateRewardApplication,
} from '../utils/gameHelpers';

export function reducer(state: AppState, action: AppStateAction): AppState {
    switch (action.type) {
        case ActionTypes.ADD_CHALLENGE:
            if (state.challenges && action.challenge) {
                return { ...state, challenges: [...state.challenges, action.challenge] };
            }
            if (action.challenge) {
                return { ...state, challenges: [action.challenge] };
            }
            return state;
        case ActionTypes.UPDATE_CHALLENGE:
            if (state.challenges) {
                return { ...state, challenges: { ...updateChallenge(state.challenges, action.challenge) } };
            }
            return state;
        case ActionTypes.UPDATE_CHALLENGES:
            return { ...state, challenges: action.challenges };
        case ActionTypes.UPDATE_NEW_GAME:
            return { ...state, newGame: action.newGame };
        case ActionTypes.GAME_STARTED:
            return { ...state, activeGame: action.activeGame, newGame: undefined };
        case ActionTypes.GAME_ENDED:
            return { ...state, activeGame: undefined, imageSources: { ...state.imageSources, gamePlayers: [] } };
        case ActionTypes.UPDATE_ACTIVE_GAME:
            return { ...state, activeGame: action.activeGame };
        case ActionTypes.UPDATE_USER:
            return {
                ...state,
                user: { ...action.user },
            };
        case ActionTypes.SIGN_OUT:
            return { ...state, user: undefined, activeGame: undefined, issuedRewards: undefined };
        case ActionTypes.SET_CURRENT_USER:
            return { ...state, user: action.user };
        case ActionTypes.SET_CHALLENGE_TIP:
            if (state.activeGame) {
                return {
                    ...state,
                    activeGame: {
                        ...setChallengeTip(state.activeGame, action.challengeTip),
                    },
                };
            }
            return state;
        case ActionTypes.ADD_CHALLENGE_APPLICATION:
            if (state.newGame) {
                return { ...state, newGame: { ...addChallengeApplication(state.newGame, action.challengeApplication) } };
            }
            if (state.activeGame) {
                return { ...state, activeGame: { ...addChallengeApplication(state.activeGame, action.challengeApplication) } };
            }
            return state;
        case ActionTypes.DELETE_CHALLENGE_APPLICATION:
            if (action.challengeApplication) {
                if (state.newGame) {
                    return { ...state, newGame: { ...deleteChallengeApplication(state.newGame, action.challengeApplication) } };
                }
                if (state.activeGame) {
                    return {
                        ...state,
                        activeGame: { ...deleteChallengeApplication(state.activeGame, action.challengeApplication) },
                    };
                }
            }
            return state;
        case ActionTypes.UPDATE_CHALLENGE_APPLICATION:
            if (state.newGame) {
                return { ...state, newGame: { ...updateChallengeApplication(state.newGame, action.challengeApplication) } };
            }
            if (state.activeGame) {
                return {
                    ...state,
                    activeGame: { ...updateChallengeApplication(state.activeGame, action.challengeApplication) },
                };
            }
            return state;
        case ActionTypes.UPDATE_SPONSORS:
            return { ...state, sponsors: action.sponsors };
        case ActionTypes.ADD_SPONSOR:
            if (state.sponsors && action.sponsor) {
                return { ...state, sponsors: [...state.sponsors, action.sponsor] };
            }
            if (action.sponsor) {
                return { ...state, sponsors: [action.sponsor] };
            }
            return state;
        case ActionTypes.UPDATE_SPONSOR:
            return {
                ...state,
                sponsors: state.sponsors?.map((sponsor) => (sponsor.id !== action.sponsor?.id ? sponsor : action.sponsor)),
            };
        case ActionTypes.DELETE_SPONSOR:
            return { ...state, sponsors: state.sponsors?.filter((sponsor) => sponsor.id !== action.sponsor?.id) };
        case ActionTypes.UPDATE_PLAYERS:
            return { ...state, players: action.players };
        case ActionTypes.ADD_PLAYER:
            if (state.players && action.player) {
                return { ...state, players: [...state.players, action.player] };
            }
            if (action.player) {
                return { ...state, players: [action.player] };
            }
            return state;
        case ActionTypes.UPDATE_PLAYER:
            return {
                ...state,
                players: state.players?.map((player) => (player.id !== action.player?.id ? player : action.player)),
            };
        case ActionTypes.DELETE_PLAYER:
            return { ...state, players: state.players?.filter((player) => player.id !== action.player?.id) };
        case ActionTypes.ADD_REWARD:
            if (state.rewards && action.reward) {
                return { ...state, rewards: [...state.rewards, action.reward] };
            }
            if (action.reward) {
                return { ...state, rewards: [action.reward] };
            }
            return state;
        case ActionTypes.UPDATE_REWARD:
            if (state.rewards) {
                return { ...state, rewards: { ...updateReward(state.rewards, action.reward) } };
            }
            return state;
        case ActionTypes.DELETE_REWARD:
            return { ...state, rewards: state.rewards?.filter((reward) => reward.id !== action.reward?.id) };
        case ActionTypes.UPDATE_REWARDS:
            return { ...state, rewards: action.rewards };
        case ActionTypes.ADD_ISSUED_REWARD:
            if (state.issuedRewards && action.issuedReward) {
                return { ...state, issuedRewards: [...addIssuedReward(state.issuedRewards, action.issuedReward)] };
            }
            return state;
        case ActionTypes.UPDATE_ISSUED_REWARDS:
            return { ...state, issuedRewards: action.issuedRewards };
        case ActionTypes.REDEEM_ISSUED_REWARD:
            return {
                ...state,
                issuedRewards: state.issuedRewards?.map((issuedReward) =>
                    issuedReward.id !== action.issuedReward?.id ? issuedReward : action.issuedReward
                ),
            };
        case ActionTypes.ADD_REWARD_APPLICATION:
            if (state.newGame) {
                return { ...state, newGame: { ...addRewardApplication(state.newGame, action.rewardApplication) } };
            }
            if (state.activeGame) {
                return { ...state, activeGame: { ...addRewardApplication(state.activeGame, action.rewardApplication) } };
            }
            return state;
        case ActionTypes.UPDATE_REWARD_APPLICATION:
            if (state.newGame) {
                return { ...state, newGame: { ...updateRewardApplication(state.newGame, action.rewardApplication) } };
            }
            if (state.activeGame) {
                return { ...state, activeGame: { ...updateRewardApplication(state.activeGame, action.rewardApplication) } };
            }
            return state;
        case ActionTypes.DELETE_REWARD_APPLICATION:
            if (state.newGame) {
                return { ...state, newGame: { ...deleteRewardApplication(state.newGame, action.rewardApplication) } };
            }
            if (state.activeGame) {
                return { ...state, activeGame: { ...deleteRewardApplication(state.activeGame, action.rewardApplication) } };
            }
            return state;
        case ActionTypes.ADD_GAME_SPONSOR_URL:
            return { ...state, imageSources: { ...state.imageSources, gameSponsor: action.gamesponsor } };
        case ActionTypes.DELETE_GAME_SPONSOR_URL:
            return { ...state, imageSources: { ...state.imageSources, gameSponsor: '' } };
        case ActionTypes.ADD_CHALLENGE_SPONSOR_URL:
            return {
                ...state,
                imageSources: {
                    ...state.imageSources,
                    challengeSponsors: [
                        ...state.imageSources.challengeSponsors,
                        {
                            sponsorId: action.challengeSponsor.id,
                            url: action.url,
                        },
                    ],
                },
            };
        case ActionTypes.DELETE_CHALLENGE_SPONSOR_URL:
            return {
                ...state,
                imageSources: {
                    ...state.imageSources,
                    challengeSponsors: state.imageSources.challengeSponsors.filter(({ sponsorId }) => sponsorId !== action.challengeSponsor.id),
                },
            };
        case ActionTypes.UPDATE_CHALLENGE_SPONSOR_URLS:
            return { ...state, imageSources: { ...state.imageSources, challengeSponsors: action.challengeSponsors } };
        case ActionTypes.ADD_GAME_PLAYER_URL:
            return {
                ...state,
                imageSources: {
                    ...state.imageSources,
                    gamePlayers: [...state.imageSources.gamePlayers, { playerId: action.gamePlayer.id, url: action.url }],
                },
            };
        case ActionTypes.DELETE_GAME_PLAYER_URL:
            return {
                ...state,
                imageSources: {
                    ...state.imageSources,
                    gamePlayers: state.imageSources.gamePlayers.filter(({ playerId }) => playerId !== action.gamePlayer.id),
                },
            };
        case ActionTypes.ADD_GAME_PLAYER:
            if (state.newGame) {
                return { ...state, newGame: { ...addGamePlayer(state.newGame, action.gamePlayer) } };
            }
            if (state.activeGame) {
                return { ...state, activeGame: { ...addGamePlayer(state.activeGame, action.gamePlayer) } };
            }
            return state;
        case ActionTypes.DELETE_GAME_PLAYER:
            if (state.newGame) {
                return { ...state, newGame: { ...deleteGamePlayer(state.newGame, action.gamePlayer) } };
            }
            if (state.activeGame) {
                return { ...state, activeGame: { ...deleteGamePlayer(state.activeGame, action.gamePlayer) } };
            }
            return state;
        case ActionTypes.UPDATE_GAME_PLAYER_URLS:
            return { ...state, imageSources: { ...state.imageSources, gamePlayers: action.gamePlayers } };
        case ActionTypes.UPDATE_RANKING:
            return { ...state, ranking: action.ranking };
        case ActionTypes.UPDATE_PROFILE:
            if (state.user) {
                const { user } = state;
                user.profile = action.profile;
                return { ...state, user };
            }
            return state;
        case ActionTypes.SET_ERROR:
            return { ...state, error: action.error };
        case ActionTypes.RESET_ERROR:
            return { ...state, error: undefined };
        default:
            return state;
    }
}
