import React from 'react';
import './challengeStatusMessage.scss';
import StyleMood from '../../utils/styleMood';

function ChallengeStatusMessage(props: { text: string; highlight?: string; styleMood: StyleMood }) {
    return (
        <>
            <div className='information-text'>
                <p className={props.styleMood}>
                    {props.text}
                    {props.highlight ? (
                        <>
                            <br />
                            <span className='gained-points'>{props.highlight}</span>
                        </>
                    ) : (
                        ''
                    )}
                </p>
            </div>
        </>
    );
}

export default ChallengeStatusMessage;
