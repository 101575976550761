import React from 'react';
import './home.scss';

function Home() {
    return (
        <div className='home-content'>
            <h1>SCRJ Lakers Tippspiel</h1>
            <img src='/img/logo.png' className='App-logo' alt='logo' />
        </div>
    );
}

export default Home;
