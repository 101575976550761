import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChallengeApplication } from '../../../API';
import Context from '../../../Context/context';
import './challenge.scss';
import { fetchActiveGameWithTipsAction } from '../../../actions/gameActions';
import ChallengeDetails from './ChallengeDetails/ChallengeDetails';
import useAction from '../../../hooks/useAction';
import { getChallengeApplicationById } from '../../../utils/gameHelpers';
import CustomLoader from '../../../components/CustomLoader/CustomLoader';
import { ActionTypes } from '../../../ressources/types/state';

function Challenge() {
    const { state, dispatch } = useContext(Context);
    const [challenge, setChallenge] = useState<ChallengeApplication | null>(null);
    const { challengeId } = useParams();

    const { loading, error } = useAction({ action: fetchActiveGameWithTipsAction, dispatch, condition: !state.activeGame && !!state.user });

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (state.activeGame) {
            setActiveChallenge();
        }
    });

    function setActiveChallenge() {
        if (state.activeGame) {
            const activeChallenge = getChallengeApplicationById(state.activeGame, challengeId);
            activeChallenge && setChallenge(activeChallenge);
        }
    }

    if (loading) {
        return <CustomLoader label='Challenge wird geladen...' />;
    }

    return <div id='challenge'>{challenge ? <ChallengeDetails challenge={challenge} /> : <h1>Ungültige Challenge</h1>}</div>;
}

export default Challenge;
