import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import PlayerList from '../../PlayerList/PlayerList';
import Context from '../../../Context/context';
import './setupPlayersModal.scss';
import { IPlayer } from '../../../ressources/types/player';
import { createGamePlayerAction, deleteGamePlayerAction, fetchPlayersAction } from '../../../actions/playerActions';
import { listLineupIds, saveChanges } from '../../../utils/gameHelpers';
import useAsync from '../../../hooks/useAsync';
import { ActionTypes } from '../../../ressources/types/state';

interface SetupPlayersModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
}

function SetupPlayersModal(props: SetupPlayersModalProps) {
    const { state, dispatch } = useContext(Context);
    const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (!state.players) {
            execute(() => fetchPlayersAction(dispatch));
        } else {
            setSelectedPlayers(getCurrentPlayers().map((player) => player.id));
        }
    }, [state.players]);

    function getCurrentPlayers() {
        if (state.newGame) {
            return listLineupIds(state.newGame);
        }
        if (state.activeGame) {
            return listLineupIds(state.activeGame);
        }
        return [];
    }

    async function handleSave() {
        const gameId = state.newGame?.id || state.activeGame?.id;
        if (gameId) {
            setLoading(true);
            await execute(() => saveChanges(getCurrentPlayers(), selectedPlayers, gameId, createGamePlayerAction, deleteGamePlayerAction, dispatch));
            setLoading(false);
            props.setOpen(false);
        }
    }

    async function onPlayerClick(player: IPlayer) {
        if (selectedPlayers.includes(player.id)) {
            setSelectedPlayers(selectedPlayers.filter((playerId) => playerId !== player.id));
        } else {
            setSelectedPlayers([...selectedPlayers, player.id]);
        }
    }

    return (
        <Modal className='setup-players-modal list-modal' onClose={() => props.setOpen(false)} onOpen={() => props.setOpen(true)} open={props.open}>
            <Modal.Header>Aufgebot bearbeiten</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    {state.players && (
                        <PlayerList
                            players={state.players}
                            selectedPlayerIds={selectedPlayers}
                            onPlayerClick={onPlayerClick}
                            groupByPositions={true}
                        />
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)}>Abbrechen</Button>
                <Button loading={loading} className='secondary' onClick={() => handleSave()}>
                    Speichern
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SetupPlayersModal;
