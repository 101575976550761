export const setCustomChallengeTip = /* GraphQL */ `
    mutation SetChallengeTip1($applicationId: String!, $answer: String!) {
        setChallengeTip(applicationId: $applicationId, answer: $answer) {
            id
            challengeApplicationId
            owner
            answer
            points
            createdAt
            updatedAt
        }
    }
`;

export const createGameWithRequiredDepth = /* GraphQL */ `
    mutation CreateGameWithRequiredDepth($input: CreateGameInput!, $condition: ModelGameConditionInput) {
        createGame(input: $input, condition: $condition) {
            id
            description
            startTimestamp
            endTimestamp
            evaluationTimestamp
            automaticChallenges
            challenges {
                items {
                    id
                    startTimestamp
                    endTimestamp
                    challengeId
                    challenge {
                        id
                        description
                        question
                        points
                        stadiumOnly
                        archived
                        type
                        createdAt
                        updatedAt
                    }
                    sponsor {
                        id
                        name
                        website
                        tagline
                        image
                        createdAt
                        updatedAt
                    }
                    sponsorId
                    tipOptions
                    solution
                    createdAt
                    updatedAt
                }
                nextToken
            }
            rewards {
                items {
                    id
                    amount
                    gameId
                    rewardId
                    reward {
                        id
                        name
                        value
                        archived
                        redemptionInfo
                        sponsor {
                            id
                            name
                            website
                            tagline
                            image
                            createdAt
                            updatedAt
                        }
                        sponsorId
                        createdAt
                        updatedAt
                    }
                    order
                    createdAt
                    updatedAt
                }
                nextToken
            }
            sponsorId
            sponsor {
                id
                name
                website
                tagline
                image
                createdAt
                updatedAt
            }
            lineup {
                items {
                    id
                    playerID
                    gameID
                    player {
                        id
                        firstname
                        lastname
                        position
                        number
                        image
                        createdAt
                        updatedAt
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;

export const updateGameWithRequiredDepth = /* GraphQL */ `
    mutation UpdateGameWithRequiredDepth($input: UpdateGameInput!, $condition: ModelGameConditionInput) {
        updateGame(input: $input, condition: $condition) {
            id
            description
            startTimestamp
            endTimestamp
            evaluationTimestamp
            automaticChallenges
            challenges {
                items {
                    id
                    startTimestamp
                    endTimestamp
                    challengeId
                    challenge {
                        id
                        description
                        question
                        points
                        stadiumOnly
                        archived
                        type
                        createdAt
                        updatedAt
                    }
                    sponsor {
                        id
                        name
                        website
                        tagline
                        image
                        createdAt
                        updatedAt
                    }
                    sponsorId
                    rewards {
                        items {
                            id
                            amount
                            challengeApplicationId
                            rewardId
                            reward {
                                id
                                name
                                value
                                archived
                                redemptionInfo
                                sponsor {
                                    id
                                    name
                                    website
                                    tagline
                                    image
                                    createdAt
                                    updatedAt
                                }
                                sponsorId
                                createdAt
                                updatedAt
                            }
                            order
                            createdAt
                            updatedAt
                        }
                    }
                    tipOptions
                    solution
                    challengeTips {
                        nextToken
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            rewards {
                items {
                    id
                    amount
                    gameId
                    rewardId
                    reward {
                        id
                        name
                        value
                        archived
                        redemptionInfo
                        createdAt
                        updatedAt
                    }
                    order
                    createdAt
                    updatedAt
                }
                nextToken
            }
            sponsorId
            sponsor {
                id
                name
                website
                tagline
                image
                createdAt
                updatedAt
            }
            lineup {
                items {
                    id
                    playerID
                    gameID
                    player {
                        id
                        firstname
                        lastname
                        position
                        number
                        image
                        createdAt
                        updatedAt
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;

export const createChallengeApplicationWithRequiredDepth = /* GraphQL */ `
    mutation CreateChallengeApplicationWithRequiredDepth(
        $input: CreateChallengeApplicationInput!
        $condition: ModelChallengeApplicationConditionInput
    ) {
        createChallengeApplication(input: $input, condition: $condition) {
            id
            startTimestamp
            endTimestamp
            challengeId
            challenge {
                id
                description
                question
                points
                stadiumOnly
                archived
                applications {
                    items {
                        id
                        startTimestamp
                        endTimestamp
                        challengeId
                        gameId
                        tipOptions
                        solution
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                type
                createdAt
                updatedAt
            }
            sponsor {
                id
                name
                website
                tagline
                image
                createdAt
                updatedAt
            }
            sponsorId
            gameId
            game {
                id
                description
                startTimestamp
                endTimestamp
                evaluationTimestamp
                automaticChallenges
                challenges {
                    items {
                        id
                        startTimestamp
                        endTimestamp
                        challengeId
                        gameId
                        tipOptions
                        solution
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                rewards {
                    items {
                        id
                        amount
                        gameId
                        rewardId
                        order
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                sponsorId
                sponsor {
                    id
                    name
                    website
                    tagline
                    games {
                        nextToken
                    }
                    image
                    createdAt
                    updatedAt
                }
                lineup {
                    items {
                        id
                        playerID
                        gameID
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            tipOptions
            solution
            challengeTips {
                items {
                    id
                    challengeApplicationId
                    challengeApplication {
                        id
                        startTimestamp
                        endTimestamp
                        challengeId
                        gameId
                        tipOptions
                        solution
                        createdAt
                        updatedAt
                    }
                    owner
                    answer
                    points
                    createdAt
                    updatedAt
                }
                nextToken
            }
            rewards {
                items {
                    id
                    amount
                    challengeApplicationId
                    rewardId
                    order
                    createdAt
                    updatedAt
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const updateChallengeApplicationWithRewards = /* GraphQL */ `
    mutation UpdateChallengeApplicationWithRewards($input: UpdateChallengeApplicationInput!, $condition: ModelChallengeApplicationConditionInput) {
        updateChallengeApplication(input: $input, condition: $condition) {
            id
            startTimestamp
            endTimestamp
            challengeId
            challenge {
                id
                description
                question
                points
                sponsorId
                stadiumOnly
                archived
                type
                createdAt
                updatedAt
            }
            gameId
            game {
                id
                description
                startTimestamp
                endTimestamp
                evaluationTimestamp
                automaticChallenges
                sponsorId
                createdAt
                updatedAt
            }
            tipOptions
            solution
            sponsorId
            sponsor {
                id
                name
                website
                tagline
                archived
                image
                createdAt
                updatedAt
            }
            challengeTips {
                nextToken
            }
            rewards {
                items {
                    id
                    amount
                    challengeApplicationId
                    rewardId
                    order
                    createdAt
                    updatedAt
                }

                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;
export const evaluateChallengeApplicationWithRewardsAndTips = /* GraphQL */ `
    mutation evaluateChallengeApplicationWithRewardsAndTips($applicationId: String!, $solution: String!) {
        evaluateChallengeApplication(applicationId: $applicationId, solution: $solution) {
            id
            startTimestamp
            endTimestamp
            challengeId
            challenge {
                id
                description
                question
                points
                sponsorId
                stadiumOnly
                archived
                type
                createdAt
                updatedAt
            }
            gameId
            game {
                id
                description
                startTimestamp
                endTimestamp
                evaluationTimestamp
                automaticChallenges
                sponsorId
                createdAt
                updatedAt
            }
            tipOptions
            solution
            sponsorId
            sponsor {
                id
                name
                website
                tagline
                archived
                image
                createdAt
                updatedAt
            }
            challengeTips {
                items {
                    id
                    challengeApplicationId
                    owner
                    answer
                    points
                    createdAt
                    updatedAt
                }
                nextToken
            }
            rewards {
                items {
                    id
                    amount
                    challengeApplicationId
                    rewardId
                    order
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;
