import React, { useContext, useState } from 'react';
import './rewardSettings.scss';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Context from '../../../Context/context';
import { fetchRewardsAction } from '../../../actions/rewardActions';
import { Reward } from '../../../API';
import Card from '../../../components/Card/Card';
import RewardDetailModal from '../../../components/RewardDetailModal/RewardDetailModal';
import useAction from '../../../hooks/useAction';
import CustomLoader from '../../../components/CustomLoader/CustomLoader';

function RewardSettings() {
    const { state, dispatch } = useContext(Context);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalReward, setModalReward] = useState<Reward | undefined>(undefined);

    const { loading } = useAction({ action: fetchRewardsAction, dispatch, condition: !state.rewards?.length });

    function openRewardModal(reward?: Reward) {
        setModalOpen(true);
        setModalReward(reward);
    }

    function renderRewards() {
        if (state.rewards && state.rewards.length) {
            return (
                <>
                    {state.rewards.map(
                        (reward: Reward) =>
                            reward.name && (
                                <Card
                                    icon='gift'
                                    title={reward.name}
                                    cornerText={reward.value ? `${reward.value.toString()} CHF` : ''}
                                    btnLabel='Bearbeiten'
                                    key={reward.id}
                                    onClick={() => openRewardModal(reward)}
                                />
                            )
                    )}
                    <div className='add-reward' onClick={() => openRewardModal()}>
                        <Icon name='plus' />
                        <p>Preis erstellen</p>
                    </div>
                </>
            );
        }
        return (
            <p className='placeholder-text'>
                Keine Preise verfügbar!{' '}
                <Link to='' onClick={() => openRewardModal()}>
                    Erstellen
                </Link>
            </p>
        );
    }

    return (
        <>
            <RewardDetailModal open={modalOpen} setOpen={setModalOpen} reward={modalReward} />
            <h1>Preise</h1>
            {loading ? <CustomLoader label='Preise werden geladen...' /> : <div className='reward-settings-rewards'>{renderRewards()}</div>}
        </>
    );
}

export default RewardSettings;
