import React, { useContext, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { fetchPlayersAction } from '../../../actions/playerActions';
import CustomLoader from '../../../components/CustomLoader/CustomLoader';
import PlayerDetailModal from '../../../components/PlayerDetailModal/PlayerDetailModal';
import PlayerList from '../../../components/PlayerList/PlayerList';
import Context from '../../../Context/context';
import useAction from '../../../hooks/useAction';
import { IPlayer } from '../../../ressources/types/player';
import './squad.scss';

function Squad() {
    const { state, dispatch } = useContext(Context);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPlayer, setModalPlayer] = useState<IPlayer | undefined>(undefined);

    const { loading } = useAction({ action: fetchPlayersAction, dispatch, condition: !state.players });

    async function openPlayerModal(player?: IPlayer) {
        setModalOpen(true);
        setModalPlayer(player);
    }

    if (loading) {
        return <CustomLoader label='Kader wird geladen...' />;
    }

    return (
        <div className='squad'>
            <PlayerDetailModal open={modalOpen} setOpen={setModalOpen} player={modalPlayer} />
            <h1>Kader</h1>
            {state.players && (
                <PlayerList onPlayerClick={(player: IPlayer) => openPlayerModal(player)} players={state.players} groupByPositions={true} />
            )}
            <Button content='Spieler hinzufügen' onClick={() => openPlayerModal()} />
        </div>
    );
}

export default Squad;
