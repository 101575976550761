import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import { Button, Message, Modal, Form } from 'semantic-ui-react';
import Context from '../../../../Context/context';
import { IPlayer, nobody } from '../../../../ressources/types/player';
import { createIPlayers } from '../../../../utils/playerHelpers';
import PlayerList from '../../../PlayerList/PlayerList';

interface OnePlayerSolutionModalProps {
    loading: boolean;
    submitSolution: (solution: string) => void;
    cancel: () => void;
    setErrorMessage: (text: string | undefined) => void;
    errorMessage: string | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
}

function OnePlayerSolutionModalDetails(props: OnePlayerSolutionModalProps) {
    const { state } = useContext(Context);

    const [players, setPlayers] = useState<IPlayer[]>();
    const [advancedSubmitOpen, setAdvancedSubmitOpen] = useState<boolean>(false);
    const [selectedPlayers, setSelectedPlayers] = useState<IPlayer[]>([]);

    useEffect(() => {
        if (state.activeGame?.lineup && state.imageSources && state.activeGame.lineup.items.length === state.imageSources.gamePlayers.length) {
            setPlayers(createIPlayers(state.activeGame.lineup.items, state.imageSources));
        }
    }, [state.activeGame, state.activeGame?.lineup, state.imageSources]);

    function cancelAdvancedSubmit() {
        props.cancel();
        setAdvancedSubmitOpen(false);
    }

    function backToMainModal() {
        props.setOpen(true);
        setAdvancedSubmitOpen(false);
    }

    function toggleNobodyPlayer() {
        selectedPlayers[0] === nobody ? setSelectedPlayers([]) : setSelectedPlayers([nobody]);
    }

    function removeExistingPlayer(player: IPlayer) {
        return selectedPlayers.filter((item) => item.id !== player.id);
    }

    async function onPlayerClick(player: IPlayer) {
        props.setErrorMessage(undefined);
        if (player === nobody) {
            toggleNobodyPlayer();
            return;
        }

        const result = removeExistingPlayer(player);
        if (result.length < selectedPlayers.length) {
            setSelectedPlayers(result);
            return;
        }

        const nobodySelected = selectedPlayers[0]?.id === nobody.id;

        setSelectedPlayers([...(nobodySelected ? [] : selectedPlayers), player]);
    }

    async function submitAnswer(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        if (selectedPlayers.length >= 2) {
            props.setOpen(false);
            setAdvancedSubmitOpen(true);
            return;
        }

        props.submitSolution(playerIdsToCommaSeperatedString());
    }

    function playerIdsToCommaSeperatedString() {
        return selectedPlayers.map((player) => player.id).join(',');
    }

    function renderPlayers() {
        if (players?.length) {
            return (
                <PlayerList
                    onPlayerClick={onPlayerClick}
                    players={players}
                    selectedPlayerIds={selectedPlayers.map((player) => player.id)}
                    nobodyEnabled
                    groupByPositions={true}
                />
            );
        }
        return <p className='placeholder-text'>Keine Spieler zur Auswahl</p>;
    }

    return (
        <>
            <Modal onClose={cancelAdvancedSubmit} open={advancedSubmitOpen} onOpen={() => setAdvancedSubmitOpen(true)}>
                <Modal.Header>Auswahl bestätigen</Modal.Header>
                <Modal.Content>
                    <PlayerList onPlayerClick={() => Promise.resolve()} players={selectedPlayers} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={cancelAdvancedSubmit}>Abbrechen</Button>
                    <Button onClick={backToMainModal}>Auswahl anpassen</Button>
                    <Button loading={props.loading} className='secondary' onClick={() => props.submitSolution(playerIdsToCommaSeperatedString())}>
                        Definitiv auflösen
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal className='edit-challenges-modal' onClose={props.cancel} onOpen={() => props.setOpen(true)} open={props.open}>
                <Modal.Header>Spieler auswählen</Modal.Header>
                <Modal.Content>
                    {<Modal.Description>{renderPlayers()}</Modal.Description>}
                    <Form error={!!props.errorMessage}>
                        <p></p>
                        <Message error content={props.errorMessage} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={props.cancel}>Abbrechen</Button>
                    <Button disabled={selectedPlayers.length === 0} loading={props.loading} className='secondary' onClick={submitAnswer}>
                        Auflösen
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default OnePlayerSolutionModalDetails;
