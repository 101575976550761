import React, { useContext, useEffect, useState } from 'react';
import { fetchActiveGameWithTipsAction } from '../../actions/gameActions';
import Card from '../../components/Card/Card';
import CustomLoader from '../../components/CustomLoader/CustomLoader';
import Context from '../../Context/context';
import useAction from '../../hooks/useAction';
import './pricePool.scss';
import { ChallengeApplication, ChallengeRewardApplication, RewardApplication } from '../../API';
import { getSponsor } from '../../utils/sponsorHelpers';
import { setChallengeSponsorUrl } from '../../actions/sponsorActions';

function PricePool() {
    const { state, dispatch } = useContext(Context);
    const [gamePrices, setGamePrices] = useState<RewardApplication[]>([]);
    const [challengePrices, setChallengePrices] = useState<ChallengeApplication[]>([]);

    const { loading } = useAction({ action: fetchActiveGameWithTipsAction, dispatch, condition: !state.activeGame });

    useEffect(() => {
        if (state.activeGame?.rewards?.items && state.activeGame.rewards.items.length > 0) {
            setGamePrices(getValuableRewards(state.activeGame.rewards.items));
        }

        const challengePool: ChallengeApplication[] = [];
        state.activeGame?.challenges?.items.forEach((challenge) => {
            if (!challenge?.rewards?.items.length) {
                return;
            }

            challengePool.push(challenge);
        });
        setChallengePrices(challengePool);
    }, [state.activeGame]);

    useEffect(() => {
        [
            ...gamePrices,
            ...challengePrices.flatMap((challenge) => {
                return getValuableRewards(challenge.rewards?.items);
            }),
        ].forEach((challenge) => {
            if (
                challenge?.reward.sponsor &&
                !state.imageSources.challengeSponsors.find((challenge2) => challenge2.sponsorId === challenge.reward.sponsorId)?.url
            ) {
                setChallengeSponsorUrl(dispatch, challenge.reward.sponsor);
            }
        });
    }, [gamePrices, challengePrices]);

    if (loading) {
        return <CustomLoader label='Preise werden geladen...' />;
    }

    function getValuableRewards<T extends RewardApplication | ChallengeRewardApplication>(rewards: (T | null)[] | undefined): T[] {
        if (!rewards?.length) {
            return [];
        }

        return rewards.flatMap((reward) => {
            if (!reward) {
                return [];
            }

            return reward;
        });
    }

    function renderList() {
        if (!gamePrices.length && !challengePrices.length) {
            return <p className='placeholder-text'>Es gibt keine Preise zu gewinnen.</p>;
        }

        return (
            <>
                {renderPriceGroup('Ganzes Spiel', gamePrices)}
                {challengePrices.map((challenge) => {
                    return renderPriceGroup(challenge.challenge.question, getValuableRewards(challenge.rewards?.items));
                })}
            </>
        );
    }

    function enrichTextWithLineBreaks(text: string) {
        const MAX_LENGTH = 20;
        if (text.length < MAX_LENGTH) {
            return text;
        }
        const words = text.split(' ');
        const news = [];
        let len = 0;
        for (const word of words) {
            if (len > MAX_LENGTH) {
                news.push(<br />);
                len = 0;
            }
            len += word.length + 1;
            news.push(` ${word}`);
        }
        return news;
    }

    function renderPriceGroup(groupName: string, rewards: (RewardApplication | ChallengeRewardApplication)[]) {
        if (!rewards.length) {
            return null;
        }

        return (
            <>
                <h2 id={groupName.replace(/\s/g, '-')} className='divider'>
                    {enrichTextWithLineBreaks(groupName)}
                </h2>
                {rewards.map((reward) => {
                    return (
                        <div className='prices' key={reward.id}>
                            {reward?.reward.name && (
                                <Card
                                    centerIcon='gift'
                                    title={reward.reward.name}
                                    cornerText={`${reward.amount}×`}
                                    key={reward.id}
                                    sponsorImageUrl={getSponsor(state, reward.reward)?.image}
                                />
                            )}
                        </div>
                    );
                })}
            </>
        );
    }

    return (
        <div id='price-pool'>
            <h1>Verlosung</h1>
            {renderList()}
        </div>
    );
}

export default PricePool;
