import './sponsors.scss';
import { useContext, useState } from 'react';
import { Button } from 'semantic-ui-react';
import Context from '../../../Context/context';
import { fetchActiveSponsorsAction } from '../../../actions/sponsorActions';
import SponsorDetailModal from '../../../components/SponsorsDetailModal/SponsorDetailModal';
import { ISponsor } from '../../../ressources/types/sponsor';
import Sponsor from '../../../components/Sponsor/Sponsor';
import useAction from '../../../hooks/useAction';
import CustomLoader from '../../../components/CustomLoader/CustomLoader';

function Sponsors() {
    const { state, dispatch } = useContext(Context);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSponsor, setModalSponsor] = useState<ISponsor | undefined>(undefined);

    const { loading } = useAction({ action: fetchActiveSponsorsAction, dispatch });

    function openSponsorModal(sponsor?: ISponsor) {
        setModalOpen(true);
        setModalSponsor(sponsor);
    }

    if (loading) {
        return <CustomLoader label='Sponsoren werden geladen...' />;
    }

    return (
        <>
            <SponsorDetailModal open={modalOpen} setOpen={setModalOpen} sponsor={modalSponsor} />
            <div>
                <h1>Sponsoren</h1>
                <div className='sponsors'>
                    {state.sponsors?.map((sponsor: ISponsor) => {
                        return (
                            <div className='sponsor-wrapper' key={sponsor.id}>
                                <Sponsor onClick={() => openSponsorModal(sponsor)} sponsorImageUrl={sponsor.s3url} key={sponsor.id} />
                                <button onClick={() => openSponsorModal(sponsor)}>
                                    <i className='icon pencil'></i>
                                </button>
                            </div>
                        );
                    })}
                    <Button className='secondary' icon='plus' content='Sponsor erstellen' onClick={() => openSponsorModal()} />
                </div>
            </div>
        </>
    );
}

export default Sponsors;
