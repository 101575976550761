import { Dispatch, useEffect, useState } from 'react';
import { AppStateAction } from '../ressources/types/state';

interface UseActionProps {
    action: (dispatch: Dispatch<AppStateAction>) => Promise<void>;
    dispatch: Dispatch<AppStateAction>;
    then?: () => void;
    condition?: boolean;
}

function useAction(props: UseActionProps) {
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.condition === undefined || props.condition) {
            (async function callAction() {
                try {
                    await props.action(props.dispatch);
                    props.then && props.then();
                } catch (err) {
                    setError(err as Error);
                } finally {
                    setLoading(false);
                }
            })();
        } else {
            setLoading(false);
        }
    }, [props.condition]);

    return { loading, error };
}

export default useAction;
