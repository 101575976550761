/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewsletterSubscription = /* GraphQL */ `
  mutation CreateNewsletterSubscription(
    $input: CreateNewsletterSubscriptionInput!
    $condition: ModelNewsletterSubscriptionConditionInput
  ) {
    createNewsletterSubscription(input: $input, condition: $condition) {
      id
      owner
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateNewsletterSubscription = /* GraphQL */ `
  mutation UpdateNewsletterSubscription(
    $input: UpdateNewsletterSubscriptionInput!
    $condition: ModelNewsletterSubscriptionConditionInput
  ) {
    updateNewsletterSubscription(input: $input, condition: $condition) {
      id
      owner
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewsletterSubscription = /* GraphQL */ `
  mutation DeleteNewsletterSubscription(
    $input: DeleteNewsletterSubscriptionInput!
    $condition: ModelNewsletterSubscriptionConditionInput
  ) {
    deleteNewsletterSubscription(input: $input, condition: $condition) {
      id
      owner
      email
      status
      createdAt
      updatedAt
    }
  }
`;
export const createChallenge = /* GraphQL */ `
  mutation CreateChallenge(
    $input: CreateChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    createChallenge(input: $input, condition: $condition) {
      id
      description
      question
      points
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      stadiumOnly
      archived
      applications {
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateChallenge = /* GraphQL */ `
  mutation UpdateChallenge(
    $input: UpdateChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    updateChallenge(input: $input, condition: $condition) {
      id
      description
      question
      points
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      stadiumOnly
      archived
      applications {
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteChallenge = /* GraphQL */ `
  mutation DeleteChallenge(
    $input: DeleteChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    deleteChallenge(input: $input, condition: $condition) {
      id
      description
      question
      points
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      stadiumOnly
      archived
      applications {
        nextToken
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const createPlayer = /* GraphQL */ `
  mutation CreatePlayer(
    $input: CreatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    createPlayer(input: $input, condition: $condition) {
      id
      firstname
      lastname
      position
      number
      image
      games {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePlayer = /* GraphQL */ `
  mutation UpdatePlayer(
    $input: UpdatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    updatePlayer(input: $input, condition: $condition) {
      id
      firstname
      lastname
      position
      number
      image
      games {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePlayer = /* GraphQL */ `
  mutation DeletePlayer(
    $input: DeletePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    deletePlayer(input: $input, condition: $condition) {
      id
      firstname
      lastname
      position
      number
      image
      games {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGame = /* GraphQL */ `
  mutation CreateGame(
    $input: CreateGameInput!
    $condition: ModelGameConditionInput
  ) {
    createGame(input: $input, condition: $condition) {
      id
      description
      startTimestamp
      endTimestamp
      evaluationTimestamp
      automaticChallenges
      challenges {
        nextToken
      }
      rewards {
        nextToken
      }
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      lineup {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGame = /* GraphQL */ `
  mutation UpdateGame(
    $input: UpdateGameInput!
    $condition: ModelGameConditionInput
  ) {
    updateGame(input: $input, condition: $condition) {
      id
      description
      startTimestamp
      endTimestamp
      evaluationTimestamp
      automaticChallenges
      challenges {
        nextToken
      }
      rewards {
        nextToken
      }
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      lineup {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGame = /* GraphQL */ `
  mutation DeleteGame(
    $input: DeleteGameInput!
    $condition: ModelGameConditionInput
  ) {
    deleteGame(input: $input, condition: $condition) {
      id
      description
      startTimestamp
      endTimestamp
      evaluationTimestamp
      automaticChallenges
      challenges {
        nextToken
      }
      rewards {
        nextToken
      }
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      lineup {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChallengeApplication = /* GraphQL */ `
  mutation CreateChallengeApplication(
    $input: CreateChallengeApplicationInput!
    $condition: ModelChallengeApplicationConditionInput
  ) {
    createChallengeApplication(input: $input, condition: $condition) {
      id
      startTimestamp
      endTimestamp
      challengeId
      challenge {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      tipOptions
      solution
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      challengeTips {
        nextToken
      }
      rewards {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChallengeApplication = /* GraphQL */ `
  mutation UpdateChallengeApplication(
    $input: UpdateChallengeApplicationInput!
    $condition: ModelChallengeApplicationConditionInput
  ) {
    updateChallengeApplication(input: $input, condition: $condition) {
      id
      startTimestamp
      endTimestamp
      challengeId
      challenge {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      tipOptions
      solution
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      challengeTips {
        nextToken
      }
      rewards {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChallengeApplication = /* GraphQL */ `
  mutation DeleteChallengeApplication(
    $input: DeleteChallengeApplicationInput!
    $condition: ModelChallengeApplicationConditionInput
  ) {
    deleteChallengeApplication(input: $input, condition: $condition) {
      id
      startTimestamp
      endTimestamp
      challengeId
      challenge {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      tipOptions
      solution
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      challengeTips {
        nextToken
      }
      rewards {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSponsor = /* GraphQL */ `
  mutation CreateSponsor(
    $input: CreateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    createSponsor(input: $input, condition: $condition) {
      id
      name
      website
      tagline
      games {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      challenges2 {
        nextToken
      }
      rewards {
        nextToken
      }
      archived
      image
      createdAt
      updatedAt
    }
  }
`;
export const updateSponsor = /* GraphQL */ `
  mutation UpdateSponsor(
    $input: UpdateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    updateSponsor(input: $input, condition: $condition) {
      id
      name
      website
      tagline
      games {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      challenges2 {
        nextToken
      }
      rewards {
        nextToken
      }
      archived
      image
      createdAt
      updatedAt
    }
  }
`;
export const deleteSponsor = /* GraphQL */ `
  mutation DeleteSponsor(
    $input: DeleteSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    deleteSponsor(input: $input, condition: $condition) {
      id
      name
      website
      tagline
      games {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      challenges2 {
        nextToken
      }
      rewards {
        nextToken
      }
      archived
      image
      createdAt
      updatedAt
    }
  }
`;
export const createRewardApplication = /* GraphQL */ `
  mutation CreateRewardApplication(
    $input: CreateRewardApplicationInput!
    $condition: ModelRewardApplicationConditionInput
  ) {
    createRewardApplication(input: $input, condition: $condition) {
      id
      amount
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateRewardApplication = /* GraphQL */ `
  mutation UpdateRewardApplication(
    $input: UpdateRewardApplicationInput!
    $condition: ModelRewardApplicationConditionInput
  ) {
    updateRewardApplication(input: $input, condition: $condition) {
      id
      amount
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteRewardApplication = /* GraphQL */ `
  mutation DeleteRewardApplication(
    $input: DeleteRewardApplicationInput!
    $condition: ModelRewardApplicationConditionInput
  ) {
    deleteRewardApplication(input: $input, condition: $condition) {
      id
      amount
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const createChallengeRewardApplication = /* GraphQL */ `
  mutation CreateChallengeRewardApplication(
    $input: CreateChallengeRewardApplicationInput!
    $condition: ModelChallengeRewardApplicationConditionInput
  ) {
    createChallengeRewardApplication(input: $input, condition: $condition) {
      id
      amount
      challengeApplicationId
      challengeApplication {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateChallengeRewardApplication = /* GraphQL */ `
  mutation UpdateChallengeRewardApplication(
    $input: UpdateChallengeRewardApplicationInput!
    $condition: ModelChallengeRewardApplicationConditionInput
  ) {
    updateChallengeRewardApplication(input: $input, condition: $condition) {
      id
      amount
      challengeApplicationId
      challengeApplication {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteChallengeRewardApplication = /* GraphQL */ `
  mutation DeleteChallengeRewardApplication(
    $input: DeleteChallengeRewardApplicationInput!
    $condition: ModelChallengeRewardApplicationConditionInput
  ) {
    deleteChallengeRewardApplication(input: $input, condition: $condition) {
      id
      amount
      challengeApplicationId
      challengeApplication {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const createReward = /* GraphQL */ `
  mutation CreateReward(
    $input: CreateRewardInput!
    $condition: ModelRewardConditionInput
  ) {
    createReward(input: $input, condition: $condition) {
      id
      name
      value
      applications {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      issues {
        nextToken
      }
      archived
      redemptionInfo
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReward = /* GraphQL */ `
  mutation UpdateReward(
    $input: UpdateRewardInput!
    $condition: ModelRewardConditionInput
  ) {
    updateReward(input: $input, condition: $condition) {
      id
      name
      value
      applications {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      issues {
        nextToken
      }
      archived
      redemptionInfo
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReward = /* GraphQL */ `
  mutation DeleteReward(
    $input: DeleteRewardInput!
    $condition: ModelRewardConditionInput
  ) {
    deleteReward(input: $input, condition: $condition) {
      id
      name
      value
      applications {
        nextToken
      }
      challengeApplications {
        nextToken
      }
      issues {
        nextToken
      }
      archived
      redemptionInfo
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createIssuedReward = /* GraphQL */ `
  mutation CreateIssuedReward(
    $input: CreateIssuedRewardInput!
    $condition: ModelIssuedRewardConditionInput
  ) {
    createIssuedReward(input: $input, condition: $condition) {
      id
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      owner
      redemptionTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateIssuedReward = /* GraphQL */ `
  mutation UpdateIssuedReward(
    $input: UpdateIssuedRewardInput!
    $condition: ModelIssuedRewardConditionInput
  ) {
    updateIssuedReward(input: $input, condition: $condition) {
      id
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      owner
      redemptionTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteIssuedReward = /* GraphQL */ `
  mutation DeleteIssuedReward(
    $input: DeleteIssuedRewardInput!
    $condition: ModelIssuedRewardConditionInput
  ) {
    deleteIssuedReward(input: $input, condition: $condition) {
      id
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      owner
      redemptionTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createObserver = /* GraphQL */ `
  mutation CreateObserver(
    $input: CreateObserverInput!
    $condition: ModelObserverConditionInput
  ) {
    createObserver(input: $input, condition: $condition) {
      id
      topic
      subscription
      createdAt
      updatedAt
    }
  }
`;
export const updateObserver = /* GraphQL */ `
  mutation UpdateObserver(
    $input: UpdateObserverInput!
    $condition: ModelObserverConditionInput
  ) {
    updateObserver(input: $input, condition: $condition) {
      id
      topic
      subscription
      createdAt
      updatedAt
    }
  }
`;
export const deleteObserver = /* GraphQL */ `
  mutation DeleteObserver(
    $input: DeleteObserverInput!
    $condition: ModelObserverConditionInput
  ) {
    deleteObserver(input: $input, condition: $condition) {
      id
      topic
      subscription
      createdAt
      updatedAt
    }
  }
`;
export const createGamePlayer = /* GraphQL */ `
  mutation CreateGamePlayer(
    $input: CreateGamePlayerInput!
    $condition: ModelGamePlayerConditionInput
  ) {
    createGamePlayer(input: $input, condition: $condition) {
      id
      playerID
      gameID
      player {
        id
        firstname
        lastname
        position
        number
        image
        createdAt
        updatedAt
      }
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGamePlayer = /* GraphQL */ `
  mutation UpdateGamePlayer(
    $input: UpdateGamePlayerInput!
    $condition: ModelGamePlayerConditionInput
  ) {
    updateGamePlayer(input: $input, condition: $condition) {
      id
      playerID
      gameID
      player {
        id
        firstname
        lastname
        position
        number
        image
        createdAt
        updatedAt
      }
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGamePlayer = /* GraphQL */ `
  mutation DeleteGamePlayer(
    $input: DeleteGamePlayerInput!
    $condition: ModelGamePlayerConditionInput
  ) {
    deleteGamePlayer(input: $input, condition: $condition) {
      id
      playerID
      gameID
      player {
        id
        firstname
        lastname
        position
        number
        image
        createdAt
        updatedAt
      }
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const setChallengeTip = /* GraphQL */ `
  mutation SetChallengeTip($applicationId: String!, $answer: String!) {
    setChallengeTip(applicationId: $applicationId, answer: $answer) {
      id
      challengeApplicationId
      challengeApplication {
        id
        startTimestamp
        endTimestamp
        challengeId
        gameId
        tipOptions
        solution
        sponsorId
        createdAt
        updatedAt
      }
      owner
      answer
      points
      createdAt
      updatedAt
    }
  }
`;
export const evaluateChallengeApplication = /* GraphQL */ `
  mutation EvaluateChallengeApplication(
    $applicationId: String!
    $solution: String!
  ) {
    evaluateChallengeApplication(
      applicationId: $applicationId
      solution: $solution
    ) {
      id
      startTimestamp
      endTimestamp
      challengeId
      challenge {
        id
        description
        question
        points
        sponsorId
        stadiumOnly
        archived
        type
        createdAt
        updatedAt
      }
      gameId
      game {
        id
        description
        startTimestamp
        endTimestamp
        evaluationTimestamp
        automaticChallenges
        sponsorId
        createdAt
        updatedAt
      }
      tipOptions
      solution
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      challengeTips {
        nextToken
      }
      rewards {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const evaluateGame = /* GraphQL */ `
  mutation EvaluateGame($gameId: String!) {
    evaluateGame(gameId: $gameId) {
      id
      description
      startTimestamp
      endTimestamp
      evaluationTimestamp
      automaticChallenges
      challenges {
        nextToken
      }
      rewards {
        nextToken
      }
      sponsorId
      sponsor {
        id
        name
        website
        tagline
        archived
        image
        createdAt
        updatedAt
      }
      lineup {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const migrateAnonymousUser = /* GraphQL */ `
  mutation MigrateAnonymousUser($accessToken: String!) {
    migrateAnonymousUser(accessToken: $accessToken)
  }
`;
export const generateRanking = /* GraphQL */ `
  mutation GenerateRanking {
    generateRanking {
      id
      nickname
      owner
      score
      visibility
      rank
      stats {
        participatedGames
        participatedChallengeTips
        successfulChallengeTips
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOwnProfile = /* GraphQL */ `
  mutation UpdateOwnProfile($nickname: String, $visibility: ProfileVisibility) {
    updateOwnProfile(nickname: $nickname, visibility: $visibility) {
      id
      nickname
      owner
      score
      visibility
      rank
      stats {
        participatedGames
        participatedChallengeTips
        successfulChallengeTips
      }
      createdAt
      updatedAt
    }
  }
`;
export const redeemIssuedReward = /* GraphQL */ `
  mutation RedeemIssuedReward($id: String!) {
    redeemIssuedReward(id: $id) {
      id
      rewardId
      reward {
        id
        name
        value
        archived
        redemptionInfo
        sponsorId
        createdAt
        updatedAt
      }
      owner
      redemptionTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegisteredUser = /* GraphQL */ `
  mutation DeleteRegisteredUser {
    deleteRegisteredUser
  }
`;
export const setNewsletterSubscriptionConfirmed = /* GraphQL */ `
  mutation SetNewsletterSubscriptionConfirmed {
    setNewsletterSubscriptionConfirmed
  }
`;
