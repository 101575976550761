import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { GraphqlInterceptor as API } from './graphqlInterceptor';
import { IUser } from '../ressources/types/user';
import { migrateAnonymousUser } from '../graphql/mutations';
import { generatePassword } from './authenticationHelpers';
import { validateEmail } from './validation';
import { ProfileStats, ProfileVisibility } from '../API';

export async function isAuthenticated() {
    try {
        setDefaultUserPool();
        await Auth.currentAuthenticatedUser();
        return true;
    } catch {
        try {
            setAnonymousUserPool();
            await Auth.currentAuthenticatedUser();
            return true;
        } catch {
            return false;
        }
    }
}

export function isAdmin(user: IUser) {
    return user.signInUserSession?.accessToken.payload['cognito:groups']?.includes('Admin');
}

export function isAnonymous(user: IUser) {
    return user.pool.userPoolId === process.env.REACT_APP_ANONYMOUS_USER_POOL_ID;
}

export function setDefaultUserPool() {
    Auth.configure({
        userPoolId: process.env.REACT_APP_REGISTERED_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_REGISTERED_USER_POOL_WEB_CLIENT_ID,
        region: 'eu-central-1',
        authenticationFlowType: 'USER_SRP_AUTH',
    });
}

export function setAnonymousUserPool() {
    Auth.configure({
        userPoolId: process.env.REACT_APP_ANONYMOUS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_ANONYMOUS_USER_POOL_WEB_CLIENT_ID,
        region: 'eu-central-1',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    });
}

export async function createAnonymousUser(): Promise<IUser> {
    setAnonymousUserPool();

    const username = uuidv4();
    const password = generatePassword();
    let user: IUser;
    try {
        user = await Auth.currentAuthenticatedUser();
    } catch {
        await Auth.signUp({
            username,
            password,
        });

        setAnonymousUserPool();
        user = await Auth.signIn({
            username,
            password,
        });
    }
    return user;
}

export function setMigrationFlag(migrationToken: string) {
    localStorage.setItem('migrationToken', migrationToken);
}

export function removeMigrationToken() {
    localStorage.removeItem('migrationToken');
}

export async function migrateAnonymous(migrationToken: string) {
    await API.graphql({
        query: migrateAnonymousUser,
        variables: { accessToken: migrationToken },
    });
}

export function toggleVisibility(visibility: ProfileVisibility) {
    if (visibility === ProfileVisibility.private) {
        return ProfileVisibility.public;
    }
    return ProfileVisibility.private;
}

export function getUsername(user: IUser) {
    if (validateEmail(user.username)) {
        return user.attributes.sub;
    }
    return user.username;
}

export function getNickname(nickname: string) {
    if (isSuperFan(nickname)) {
        return nickname.split('🔴⚪🔵');
    }
    return nickname;
}

export function isSuperFan(nickname: string) {
    return nickname.includes('🔴⚪🔵');
}

export function calculateCorrectTipRate(stats: ProfileStats) {
    if (stats.participatedChallengeTips <= 0) {
        return 0;
    }
    return ((stats.successfulChallengeTips / stats.participatedChallengeTips) * 100).toFixed(2);
}
