import React, { useContext, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import Context from '../../Context/context';
import Authenticator from '../../components/Authenticator/Authenticator';
import { signOutAction } from '../../actions/userActions';
import './user.scss';
import { calculateCorrectTipRate, getNickname } from '../../utils/userHelpers';

function User() {
    const { state, dispatch } = useContext(Context);
    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();

    function signOut() {
        setBtnLoading(true);
        signOutAction(dispatch).then(() => {
            navigate('/');
        });
    }

    return (
        <div id='user'>
            {state.user && state.user?.pool.userPoolId === process.env.REACT_APP_REGISTERED_USER_POOL_ID ? (
                <>
                    <h1> {state.user.profile?.nickname && getNickname(state.user.profile.nickname)}</h1>
                    {state.user.profile && (
                        <div className='user-stats'>
                            <div className='primary-stats'>
                                <div className='stat'>
                                    <h2>Punkte</h2>
                                    <p>{state.user.profile.score}</p>
                                </div>
                                <div className='stat'>
                                    <h2>Öffentliche Rangliste</h2>
                                    {state.user.profile.rank ? <p>Rang {state.user.profile.rank}</p> : <p>Keine Platzierung</p>}
                                </div>
                            </div>
                            <div className='secondary-stats'>
                                <div className='stat'>
                                    <h3>Beteiligte Tippspiele</h3>
                                    <p>{state.user.profile.stats.participatedGames || 0} Spiele</p>
                                </div>
                                <div className='stat'>
                                    <h3>Abgegebene Tipps</h3>
                                    <p>{state.user.profile.stats.participatedChallengeTips || 0} Tips</p>
                                </div>
                                <div className='stat'>
                                    <h3>Korrekte Tipps</h3>
                                    <p>
                                        {state.user.profile.stats.successfulChallengeTips || 0} Tips (
                                        {calculateCorrectTipRate(state.user.profile.stats)}
                                        %)
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='user-actions'>
                        <Button className='secondary' onClick={() => navigate('settings')}>
                            <Icon name='settings' />
                            Einstellungen
                        </Button>
                        <Button type='submit' loading={btnLoading} onClick={signOut}>
                            <Icon name='sign-out' />
                            Abmelden
                        </Button>
                    </div>
                </>
            ) : (
                <Authenticator />
            )}
        </div>
    );
}

export default User;
