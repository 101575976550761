import { API } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';
import { Dispatch } from 'react';
import Observable from 'zen-observable';
import { fetchActiveGameWithTipsAction } from '../actions/gameActions';
import { fetchIssuedRewardsAction } from '../actions/rewardActions';
import { fetchProfileByOwnerAction, fetchRankingAction } from '../actions/userActions';
import {
    ChallengeApplication,
    Game,
    OnCreateChallengeApplicationSubscription,
    OnDeleteChallengeApplicationSubscription,
    OnEvaluateChallengeApplicationSubscription,
    OnUpdateChallengeApplicationSubscription,
    onUpdateGameWithChallengeSubscription,
} from '../API';
import {
    onCreateChallengeApplicationWithChallengeTips,
    onEvaluateChallengeApplicationWithTips,
    onUpdateGameWithChallenge,
} from '../graphql/custom-subscriptions';
import { onDeleteChallengeApplication, onUpdateChallengeApplication } from '../graphql/subscriptions';
import { ActionTypes, AppStateAction } from '../ressources/types/state';
import { IUser } from '../ressources/types/user';

export function getCreateChallengeApplicationSubscription(dispatch: Dispatch<AppStateAction>) {
    const subscription = API.graphql<GraphQLSubscription<OnCreateChallengeApplicationSubscription>>({
        query: onCreateChallengeApplicationWithChallengeTips,
    });
    if (subscription instanceof Observable) {
        return subscription.subscribe({
            next: (eventData) => {
                const challengeAppliation = eventData.value.data?.onCreateChallengeApplication as ChallengeApplication;
                if (challengeAppliation) {
                    dispatch({ type: ActionTypes.ADD_CHALLENGE_APPLICATION, challengeApplication: challengeAppliation });
                }
            },
        });
    }
}

export function getDeleteChallengeApplicationSubscription(dispatch: Dispatch<AppStateAction>) {
    const subscription = API.graphql<GraphQLSubscription<OnDeleteChallengeApplicationSubscription>>({ query: onDeleteChallengeApplication });
    if (subscription instanceof Observable) {
        return subscription.subscribe({
            next: (eventData) => {
                const challengeAppliation = eventData.value.data?.onDeleteChallengeApplication as ChallengeApplication;
                dispatch({ type: ActionTypes.DELETE_CHALLENGE_APPLICATION, challengeApplication: challengeAppliation });
            },
        });
    }
}

export function getChallengeApplicationUpdateSubscription(dispatch: Dispatch<AppStateAction>) {
    const subscription = API.graphql<GraphQLSubscription<OnUpdateChallengeApplicationSubscription>>({ query: onUpdateChallengeApplication });
    if (subscription instanceof Observable) {
        return subscription.subscribe({
            next: (eventData) => {
                const challengeAppliation = eventData.value.data?.onUpdateChallengeApplication as ChallengeApplication;
                dispatch({ type: ActionTypes.UPDATE_CHALLENGE_APPLICATION, challengeApplication: challengeAppliation });
            },
        });
    }
}

export function getChallengeApplicationEvaluationSubscription(dispatch: Dispatch<AppStateAction>, user: IUser) {
    const subscription = API.graphql<GraphQLSubscription<OnEvaluateChallengeApplicationSubscription>>({
        query: onEvaluateChallengeApplicationWithTips,
    });
    if (subscription instanceof Observable) {
        return subscription.subscribe({
            next: () => {
                fetchRankingAction(dispatch);
                fetchProfileByOwnerAction(dispatch, user);
                fetchActiveGameWithTipsAction(dispatch);
            },
        });
    }
}

export function getGameUpdateSubscription(dispatch: Dispatch<AppStateAction>) {
    const subscription = API.graphql<GraphQLSubscription<onUpdateGameWithChallengeSubscription>>({ query: onUpdateGameWithChallenge });
    if (subscription instanceof Observable) {
        return subscription.subscribe({
            next: (eventData) => {
                const game = eventData.value.data?.onUpdateGame as Game;
                if (game.endTimestamp) {
                    fetchIssuedRewardsAction(dispatch);
                    dispatch({ type: ActionTypes.GAME_ENDED });
                } else if (game.startTimestamp) {
                    dispatch({ type: ActionTypes.GAME_STARTED, activeGame: game });
                    fetchActiveGameWithTipsAction(dispatch);
                }
            },
        });
    }
}
