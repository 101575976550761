import { Storage } from 'aws-amplify';
import { Dispatch } from 'react';
import { GraphQLQuery } from '@aws-amplify/api';
import { GraphqlInterceptor as API } from '../utils/graphqlInterceptor';
import {
    CreateSponsorInput,
    CreateSponsorMutation,
    DeleteSponsorMutation,
    ListSponsorsQuery,
    Sponsor,
    UpdateSponsorMutation,
    UpdateSponsorMutationVariables,
} from '../API';
import { createSponsor, deleteSponsor, updateSponsor } from '../graphql/mutations';
import { ISponsor } from '../ressources/types/sponsor';
import { ActionTypes, AppStateAction } from '../ressources/types/state';
import { listSponsorsWithChallengesAndRewards } from '../graphql/custom-queries';

const BUCKET_FOLDER = 'sponsors/';

export async function fetchActiveSponsorsAction(dispatch: Dispatch<AppStateAction>) {
    const response = await API.graphql<GraphQLQuery<ListSponsorsQuery>>({
        query: listSponsorsWithChallengesAndRewards,
        variables: {
            filter: { archived: { eq: false } },
        },
    });
    const result = await Promise.all(
        (response.data?.listSponsors?.items as ISponsor[]).map(async (sponsor: ISponsor) => {
            sponsor.s3url = await Storage.get(BUCKET_FOLDER + sponsor.image, { expires: 86400 });
            return sponsor;
        })
    );

    dispatch({
        type: ActionTypes.UPDATE_SPONSORS,
        sponsors: result,
    });
}

export async function createSponsorAction(dispatch: Dispatch<AppStateAction>, sponsorInput: CreateSponsorInput, file: Blob) {
    const response = await API.graphql<GraphQLQuery<CreateSponsorMutation>>({
        query: createSponsor,
        variables: { input: sponsorInput },
    });
    await Storage.put(BUCKET_FOLDER + sponsorInput.image, file, { contentType: 'image/*' });
    const sponsor: ISponsor = response.data?.createSponsor as ISponsor;
    sponsor.s3url = await Storage.get(BUCKET_FOLDER + sponsor.image, { expires: 86400 });
    dispatch({ type: ActionTypes.ADD_SPONSOR, sponsor });
}

export async function updateSponsorAction(dispatch: Dispatch<AppStateAction>, sponsor: ISponsor, file?: Blob) {
    const variables: UpdateSponsorMutationVariables = {
        input: {
            id: sponsor.id,
            name: sponsor.name,
            website: sponsor.website,
            tagline: sponsor.tagline,
            archived: sponsor.archived,
        },
    };

    if (file) {
        await Storage.put(BUCKET_FOLDER + sponsor.image, file, { contentType: 'image/*' });
        sponsor.s3url = await Storage.get(BUCKET_FOLDER + sponsor.image, { expires: 86400 });
    }
    await API.graphql<GraphQLQuery<UpdateSponsorMutation>>({
        query: updateSponsor,
        variables,
    });

    dispatch({ type: ActionTypes.UPDATE_SPONSOR, sponsor });
}

export async function deleteSponsorAction(dispatch: Dispatch<AppStateAction>, sponsor: ISponsor) {
    const response = await API.graphql<GraphQLQuery<DeleteSponsorMutation>>({
        query: deleteSponsor,
        variables: { input: { id: sponsor.id } },
    });
    await Storage.remove(BUCKET_FOLDER + (response.data?.deleteSponsor as Sponsor).image);
    dispatch({ type: ActionTypes.DELETE_SPONSOR, sponsor });
}

export async function setGameSponsorUrl(dispatch: Dispatch<AppStateAction>, sponsor: Sponsor) {
    const gamesponsor = await Storage.get(BUCKET_FOLDER + sponsor.image, {
        expires: 86400,
    });
    dispatch({ type: ActionTypes.ADD_GAME_SPONSOR_URL, gamesponsor });
}

export async function setChallengeSponsorUrl(dispatch: Dispatch<AppStateAction>, sponsor: Sponsor) {
    const challengeSponsor = await Storage.get(BUCKET_FOLDER + sponsor.image, {
        expires: 86400,
    });
    dispatch({ type: ActionTypes.ADD_CHALLENGE_SPONSOR_URL, challengeSponsor: sponsor, url: challengeSponsor });
}
