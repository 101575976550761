import React, { useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import './slider.scss';

interface SliderProps {
    initialLabel: string;
    finalLabel: string;
    redeemed: boolean;
    action: () => void;
}

function Slider(props: SliderProps) {
    useEffect(() => {
        const slider: any = document.getElementsByClassName('slider')[0];
        const sliderLatch: any = document.getElementsByClassName('slider-latch')[0];
        const sliderLabel: any = document.getElementsByClassName('slider-label')[0];
        const sliderWidth = slider.clientWidth;
        const sliderLatchWidth = sliderLatch.clientWidth;
        const endPos = sliderWidth - 4 - sliderLatchWidth;
        let startPos = 0;
        let stopPos = 0;

        function handleMouseMove(e: any) {
            const currentPos = e.pageX || e.touches[0].pageX;

            const progress = currentPos - startPos;
            sliderLatch.style.left = `${Math.min(Math.max(4, progress), endPos)}px`;
        }

        if (props.redeemed) {
            sliderLatch.style.left = `${endPos}px`;
            slider.classList.add('locked');
            sliderLabel.textContent = props.finalLabel;
        }

        function handleMouseDown(e: any) {
            sliderLatch.classList.add('sliding');
            startPos = e.pageX || e.touches[0].pageX;
            sliderLatch.addEventListener('mousemove', handleMouseMove);
            sliderLatch.addEventListener('touchmove', handleMouseMove);
        }

        function handleMouseUp(e: any) {
            stopPos = e.pageX;
            determineLocked(stopPos);
            sliderLatch.classList.remove('sliding');
            sliderLatch.removeEventListener('mousemove', handleMouseMove);
            sliderLatch.removeEventListener('touchmove', handleMouseMove);
        }

        function handleTouchEnd(e: any) {
            stopPos = e.changedTouches[0].pageX;
            determineLocked(stopPos);
            sliderLatch.classList.remove('sliding');
            sliderLatch.removeEventListener('mousemove', handleMouseMove);
            sliderLatch.removeEventListener('touchmove', handleMouseMove);
        }

        function determineLocked(pos: number) {
            if (pos - startPos > startPos + sliderWidth - pos) {
                sliderLatch.style.left = `${endPos}px`;
                slider.classList.add('locked');
                sliderLabel.textContent = props.finalLabel;
                props.action();
            } else {
                sliderLatch.style.left = `4px`;
            }
        }

        sliderLatch.addEventListener('mousedown', handleMouseDown);

        sliderLatch.addEventListener('mouseup', handleMouseUp);

        sliderLatch.addEventListener('touchstart', handleMouseDown);

        sliderLatch.addEventListener('touchend', handleTouchEnd);

        sliderLatch.addEventListener('mouseleave', handleMouseUp);
    }, [props.finalLabel]);
    return (
        <div className='slider'>
            <div className='slider-latch'>
                <Icon name='arrow alternate circle right' />
            </div>
            <div className='slider-label'>Wischen</div>
        </div>
    );
}

export default Slider;
