import React, { useCallback, useEffect, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { useDropzone } from 'react-dropzone';
import { Button, Icon, Message } from 'semantic-ui-react';
import './dropZone.scss';

interface DropZoneProps {
    existingFileUrl?: string;
    setFile: (file: Blob) => void;
    maxImageWidth?: number;
    maxImageHeight?: number;
    imageFormat?: 'JPEG' | 'PNG';
}

function DropZone(props: DropZoneProps) {
    const [error, setError] = useState<string | undefined>(undefined);
    const [fileDataURL, setFileDataURL] = useState<string>('');

    const resizeFile = (file: Blob) =>
        new Promise<Blob>((resolve) => {
            Resizer.imageFileResizer(
                file,
                props.maxImageWidth || 300,
                props.maxImageHeight || 300,
                props.imageFormat || 'JPEG',
                100,
                0,
                (uri) => {
                    resolve(uri as Blob);
                },
                'blob'
            );
        });

    useEffect(() => {
        if (props.existingFileUrl) {
            setFileDataURL(props.existingFileUrl);
        }

        return () => {
            setFileDataURL('');
        };
    }, [props.existingFileUrl]);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length) {
            setError('Datentyp nicht unterstützt');
        } else {
            setError(undefined);
        }
        acceptedFiles.forEach(async (file: Blob) => {
            const image = await resizeFile(file);
            props.setFile(image);
            const reader = new FileReader();
            reader.onload = () => {
                setFileDataURL(reader.result as string);
            };
            reader.readAsDataURL(file);
        });
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: 'image/png, image/jpg, image/jpeg' });

    return (
        <>
            <div className='dropZone' {...getRootProps()}>
                <input {...getInputProps()} />
                {fileDataURL ? (
                    <>
                        <div style={{ backgroundImage: `url("${fileDataURL}")` }} className='img-preview-wrapper'></div>
                        <Button icon='pencil' content='Bild ändern' />
                    </>
                ) : (
                    <div className='placeholder'>
                        <Icon name='cloud upload' />
                        <p>
                            <strong>Bild hochladen</strong>
                        </p>
                        <p>Per Klick oder Drag&#38;Drop</p>
                    </div>
                )}
            </div>
            <Message hidden={!error} error content={error}></Message>
        </>
    );
}

export default DropZone;
