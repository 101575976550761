import React, { FormEvent, MouseEvent, useContext, useEffect, useState } from 'react';
import { Button, CheckboxProps, Form, Input, Message, Modal } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { CreatePlayerInput, PlayerPosition } from '../../API';
import Context from '../../Context/context';
import './playerDetailModal.scss';
import DropZone from '../DropZone/DropZone';
import { IPlayer } from '../../ressources/types/player';
import { createPlayerAction, deletePlayerAction, updatePlayerAction } from '../../actions/playerActions';
import useAsync from '../../hooks/useAsync';
import { ActionTypes } from '../../ressources/types/state';

interface PlayerDetailModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
    player?: IPlayer;
}

function PlayerDetailModal(props: PlayerDetailModalProps) {
    const { dispatch } = useContext(Context);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [number, setNumber] = useState(0);
    const [position, setPosition] = useState<PlayerPosition | undefined>();
    const [file, setFile] = useState<Blob | null>();
    const [fileDataURL, setFileDataURL] = useState<string>('');
    const [btnSafeLoading, setBtnSafeLoading] = useState(false);
    const [btnDeleteLoading, setBtnDeleteLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (props.player) {
            setFirstname(props.player.firstname);
            setLastname(props.player.lastname);
            setNumber(props.player.number);
            setPosition(props.player.position);
            setFileDataURL(props.player.s3url);
        } else {
            setFile(null);
            setFileDataURL('');
            setFirstname('');
            setLastname('');
            setNumber(0);
            setPosition(undefined);
        }
    }, [props.open]);

    function isFormValid(ignoreFile: boolean = false) {
        if (firstname === '') {
            setErrorMessage('Vorname nicht vorhanden.');
            return false;
        }

        if (lastname === '') {
            setErrorMessage('Nachname nicht vorhanden.');
            return false;
        }

        if (isNaN(number) || number === undefined) {
            setErrorMessage('Nummer nicht vorhanden.');
            return false;
        }

        if (position === undefined) {
            setErrorMessage('Position nicht vorhanden.');
            return false;
        }

        if (!ignoreFile && file === null) {
            setErrorMessage('Bild nicht vorhanden');
            return false;
        }

        return true;
    }

    async function updatePlayer(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setErrorMessage(undefined);
        if (isFormValid(true)) {
            setBtnSafeLoading(true);
            if (props.player && number && position) {
                const updatedPlayer = props.player;
                updatedPlayer.firstname = firstname;
                updatedPlayer.lastname = lastname;
                updatedPlayer.number = number;
                updatedPlayer.position = position;
                if (file) {
                    await execute(() => updatePlayerAction(dispatch, updatedPlayer, file));
                } else {
                    await execute(() => updatePlayerAction(dispatch, updatedPlayer));
                }
                props.setOpen(false);
            }
            setBtnSafeLoading(false);
        }
    }

    async function createPlayer(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setErrorMessage(undefined);
        let player: CreatePlayerInput;
        if (isFormValid()) {
            setBtnSafeLoading(true);
            if (file && number && position) {
                player = { firstname, lastname, number, position, image: uuidv4() };
                execute(() => createPlayerAction(dispatch, player, file));
                props.setOpen(false);
            }

            setBtnSafeLoading(false);
        }
    }

    async function deletePlayer(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setBtnDeleteLoading(true);
        setErrorMessage(undefined);
        if (props.player) {
            const toBeDeleted = props.player;
            execute(() => deletePlayerAction(dispatch, toBeDeleted));
        }
        setBtnDeleteLoading(false);
        props.setOpen(false);
    }

    function handlePositionChange(e: FormEvent<HTMLInputElement>, data: CheckboxProps) {
        e.preventDefault();
        setPosition(PlayerPosition[data.value as keyof typeof PlayerPosition]);
    }

    return (
        <Modal
            className='image-upload-modal player-modal'
            onClose={() => {
                setErrorMessage(undefined);
                props.setOpen(false);
            }}
            onOpen={() => props.setOpen(true)}
            open={props.open}>
            <Modal.Header>{props.player ? 'Spieler bearbeiten' : 'Spieler hinzufügen'}</Modal.Header>
            <Modal.Content>
                <div className='form'>
                    <Form error={!!errorMessage}>
                        <Form.Field required={true}>
                            <label>Vorname:</label>
                            <Input value={firstname} placeholder='Vorname' onChange={(e) => setFirstname(e.target.value)} name='firstname' />
                        </Form.Field>
                        <Form.Field required={true}>
                            <label>Nachname:</label>
                            <Input value={lastname} placeholder='Nachname' onChange={(e) => setLastname(e.target.value)} name='lastname' />
                        </Form.Field>
                        <Form.Field required={true}>
                            <label>Nummer:</label>
                            <Input
                                type='number'
                                value={number}
                                placeholder='Nummer'
                                onChange={(e) => setNumber(e.target.valueAsNumber)}
                                name='number'
                            />
                        </Form.Field>
                        <Form.Field required={true}>
                            <label>Position:</label>
                            <Form.Group inline>
                                <Form.Radio
                                    label='Torhüter'
                                    value={PlayerPosition.goalkeeper}
                                    checked={position === PlayerPosition.goalkeeper}
                                    onChange={handlePositionChange}
                                    name='goalkeeper'
                                />
                                <Form.Radio
                                    label='Verteidiger'
                                    value={PlayerPosition.defence}
                                    checked={position === PlayerPosition.defence}
                                    onChange={handlePositionChange}
                                    name='defence'
                                />
                                <Form.Radio
                                    label='Stürmer'
                                    value={PlayerPosition.offence}
                                    checked={position === PlayerPosition.offence}
                                    onChange={handlePositionChange}
                                    name='offence'
                                />
                            </Form.Group>
                        </Form.Field>
                        <Message error content={errorMessage} />
                    </Form>
                </div>
                <div className='image-upload'>
                    <h2>Bildvorschau</h2>
                    <DropZone existingFileUrl={fileDataURL || undefined} setFile={setFile} maxImageWidth={80} imageFormat='JPEG' />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setErrorMessage(undefined);
                        props.setOpen(false);
                    }}>
                    Abbrechen
                </Button>
                {props.player ? (
                    <>
                        <Button loading={btnDeleteLoading} onClick={deletePlayer}>
                            Löschen
                        </Button>
                        <Button loading={btnSafeLoading} className='secondary' onClick={updatePlayer}>
                            Speichern
                        </Button>
                    </>
                ) : (
                    <Button loading={btnSafeLoading} className='secondary' onClick={createPlayer}>
                        Erstellen
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
}

export default PlayerDetailModal;
