/* eslint-disable no-unused-vars */

export enum AuthExceptions {
    USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
    USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException',
    NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
}

export enum ApiExceptions {}

export enum LambdaExceptions {
    USER_LAMBDA_VALIDATION_EXCEPTION = 'UserLambdaValidationException',
}
