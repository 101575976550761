import React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { IPlayer } from '../../ressources/types/player';
import './player.scss';

interface PlayerProps {
    player: IPlayer;
    action?: (player: IPlayer) => void;
    selected?: boolean;
    icon?: SemanticICONS;
}

function Player(props: PlayerProps) {
    async function handleAction() {
        props.action && props.action(props.player);
    }

    return (
        <div className={`player ${props.selected && 'selected'}`} onClick={() => props.action && handleAction()}>
            <div className='player-image'>
                <img src={`${props.player.s3url}`} alt={`${props.player.lastname} ${props.player.firstname}`} />
            </div>
            <div className='player-info'>
                <span className='player-select-number'>#{props.player.number}</span>
                <span className='player-select-name'>
                    {props.player.lastname} {props.player.firstname}
                </span>
            </div>
            {(props.selected || props.icon) && (
                <div className='player-icon'>
                    <Icon name={props.icon || 'check'} />
                </div>
            )}
        </div>
    );
}

export default Player;
