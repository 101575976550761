import React, { useContext, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './header.scss';
import Context from '../../Context/context';
import { getNickname } from '../../utils/userHelpers';

function Header() {
    const [shadow, setShadow] = useState(false);
    const [backTarget, setBackTarget] = useState<string>('');
    const { state } = useContext(Context);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getBackTarget();
    }, [location]);

    function getBackTarget() {
        const position = location.pathname.lastIndexOf('/');
        setBackTarget(location.pathname.substring(0, position));
    }

    window.onscroll = (e) => {
        if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
            setShadow(true);
        } else {
            setShadow(false);
        }
    };

    function back() {
        navigate(backTarget);
        getBackTarget();
    }
    return (
        <header className={`${state.user ? 'signed' : 'unsigned'}-header user-header ${shadow ? 'shadow' : ''}`}>
            {location.pathname === '/' && (
                <Link to='info'>
                    <Icon name='question circle outline' />
                </Link>
            )}
            <Icon name={`${backTarget ? 'arrow left' : 'home'}`} onClick={() => back()}></Icon>
            {state.user?.profile?.nickname ? (
                <Link to='/user' className='button secondary icon'>
                    <Icon name='user circle' onClick={() => navigate('user')}></Icon>
                    {getNickname(state.user.profile.nickname)}
                </Link>
            ) : (
                <Icon name='user circle' onClick={() => navigate('user')}></Icon>
            )}
        </header>
    );
}

export default Header;
