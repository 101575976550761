import React, { PropsWithChildren, ReactElement } from 'react';
import './answerSelect.scss';

interface GeneralSelectProps {
    answer?: string;
    disabled?: boolean;
    heading?: string;
    changeAction: ReactElement;
    solutionInfo?: string;
    maxPointsInfo: string;
}

function GeneralSelect(props: PropsWithChildren<GeneralSelectProps>) {
    function getFooter() {
        if (!props.disabled && props.answer) {
            return <div className='footer-select-actions'>{props.changeAction}</div>;
        }

        if (props.disabled && props.solutionInfo) {
            return (
                <div className='select-footer'>
                    <div>{props.solutionInfo}</div>
                    <div>{props.maxPointsInfo}</div>
                </div>
            );
        }
    }

    return (
        <>
            <div className='user-selection'>
                <h2>{props.heading ?? 'Dein aktueller Tipp'}</h2>
                <div className={`main-select ${props.disabled ? 'disabled' : 'active'}`}>{props.children}</div>
                {getFooter()}
            </div>
        </>
    );
}

export default GeneralSelect;
