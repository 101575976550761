import React, { useEffect, useReducer } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import Home from './pages/Home/Home';
import Theme from './themes/Theme/Theme';
import HomeTheme from './themes/HomeTheme/HomeTheme';
import AdminTheme from './themes/AdminTheme/AdminTheme';
import Game from './pages/Game/Game';
import Rewards from './pages/Rewards/Rewards';
import Ranking from './pages/Ranking/Ranking';
import User from './pages/User/User';
import Challenge from './pages/Game/Challenge/Challenge';
import Players from './pages/Players/Players';
import Challenges from './pages/Admin/Challenges/Challenges';
import GameSettings from './pages/Admin/GameSettings/GameSettings';
import Reward from './pages/Rewards/Reward/Reward';
import Sponsors from './pages/Admin/Sponsors/Sponsors';
import RewardSettings from './pages/Admin/RewardSettings/RewardSettings';
import Squad from './pages/Admin/Squad/Squad';
import Context, { initialAppState } from './Context/context';
import { reducer } from './reducers/reducer';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import Verify from './pages/User/Verify/Verify';
import { setCurrentUserAction } from './actions/userActions';
import PricePool from './pages/PricePool/PricePool';
import { updatedAwsConfig } from './utils/authenticationHelpers';
import { isAdmin, isAuthenticated } from './utils/userHelpers';
import UserSettings from './pages/User/UserSettings/UserSettings';
import Error404 from './pages/Error404/Error404';
import Information from './pages/Information/Information';
import Privacy from './pages/Privacy/Privacy';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import useAsync from './hooks/useAsync';
import { ActionTypes } from './ressources/types/state';
import Impressum from './pages/Impressum/Impressum';
import Redeem from './pages/Rewards/Reward/Redeem/Redeem';

Amplify.configure(updatedAwsConfig);

function App() {
    const [state, dispatch] = useReducer(reducer, initialAppState);

    function handleResize() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error: new Error('Hoppla') });
        }
    }, [error]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerHeight]);

    useEffect(() => {
        if (!state.user) {
            setCurrentUser();
        } else if (
            state.user &&
            state.user.pool.userPoolId === process.env.REACT_APP_REGISTERED_USER_POOL_ID &&
            !isAdmin(state.user) &&
            !state.user.profile?.nickname &&
            window.location.pathname !== '/user/verify/'
        ) {
            window.location.replace('/user/verify');
        }
    }, [window.location.pathname]);

    async function setCurrentUser() {
        if (await isAuthenticated()) {
            execute(() => setCurrentUserAction(dispatch));
        }
    }

    return (
        <Router>
            <Context.Provider value={{ state, dispatch }}>
                <Routes>
                    <Route path='/' element={<HomeTheme />}>
                        <Route path='' element={<Home />} />
                    </Route>
                    <Route path='/admin' element={<AdminTheme />}>
                        <Route path='' element={<Navigate to='challenges' />} />
                        <Route path='challenges' element={<Challenges />} />
                        <Route path='sponsors' element={<Sponsors />} />
                        <Route path='rewards' element={<RewardSettings />} />
                        <Route path='squad' element={<Squad />} />
                        <Route path='game' element={<GameSettings />} />
                        <Route path='*' element={<Error404 />} />
                    </Route>
                    <Route element={<Theme />}>
                        <Route path='/rewards'>
                            <Route path='' element={<Rewards />} />
                            <Route path=':rewardId' element={<Reward />} />
                            <Route path=':rewardId/redeem' element={<Redeem />} />
                        </Route>
                        <Route path='/game'>
                            <Route path='' element={<Game />} />
                            <Route path='challenge'>
                                <Route path='' element={<Navigate to='/game' />} />
                                <Route path=':challengeId' element={<Challenge />} />
                                <Route path=':challengeId/players' element={<Players />} />
                            </Route>
                            <Route path='price-pool' element={<PricePool />} />
                        </Route>
                        <Route path='/ranking' element={<Ranking />} />
                        <Route path='/user'>
                            <Route path='' element={<User />} />
                            <Route path='verify' element={<Verify />} />
                            <Route path='settings' element={<UserSettings />} />
                        </Route>
                        <Route path='/info'>
                            <Route path='' element={<Information />} />
                            <Route path='agb' element={<TermsAndConditions />} />
                            <Route path='privacy' element={<Privacy />} />
                            <Route path='impressum' element={<Impressum />} />
                            <Route path='about' element={<About />} />
                            <Route path='contact' element={<Contact />} />
                        </Route>
                        <Route path='*' element={<Error404 />} />
                    </Route>
                </Routes>
            </Context.Provider>
        </Router>
    );
}

export default App;
