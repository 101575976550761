import React, { useContext, useEffect, useState } from 'react';
import Card from '../../components/Card/Card';
import './game.scss';
import { ChallengeApplicationState } from '../../ressources/types/game';
import { ChallengeApplication, ChallengeType } from '../../API';
import Context from '../../Context/context';
import {
    getCardMood,
    getChallengeTipAnswer,
    getChallengeTipNumber,
    getClosedChallengeApplications,
    getOpenChallengeApplications,
    getResolvedChallengeApplications,
    getStateIcon,
} from '../../utils/gameHelpers';
import { fetchActiveGameWithTipsAction } from '../../actions/gameActions';
import useAction from '../../hooks/useAction';
import CustomLoader from '../../components/CustomLoader/CustomLoader';
import { askForNotificationPermission } from '../../utils/notificationHelpers';
import PricePoolLink from '../../components/PricePoolLink/PricePoolLink';
import { setChallengeSponsorUrl, setGameSponsorUrl } from '../../actions/sponsorActions';
import { getSponsor } from '../../utils/sponsorHelpers';

function Game() {
    const { state, dispatch } = useContext(Context);
    const [openChallenges, setOpenChallenges] = useState<(ChallengeApplication | null)[]>();
    const [closedChallenges, setClosedChallenges] = useState<(ChallengeApplication | null)[]>();
    const [resolvedChallenges, setResolvedChallenges] = useState<(ChallengeApplication | null)[]>();

    const { loading } = useAction({
        action: fetchActiveGameWithTipsAction,
        dispatch,
        condition: state.user && !state.activeGame,
    });

    useEffect(() => {
        state.activeGame?.challenges?.items.forEach((challenge) => {
            if (challenge?.sponsor && !state.imageSources.challengeSponsors.find((challenge2) => challenge2.sponsorId === challenge.sponsorId)?.url) {
                setChallengeSponsorUrl(dispatch, challenge.sponsor);
            }
        });

        if (state.activeGame?.sponsor && state.imageSources.gameSponsor === '') {
            setGameSponsorUrl(dispatch, state.activeGame.sponsor);
        }
    });

    useEffect(() => {
        const anchorLinks = document.querySelectorAll('a[href^="#"]');
        if (state.activeGame && anchorLinks.length) {
            anchorLinks.forEach((anchor) => {
                anchor.addEventListener('click', handleAnchorClick);
            });
        }
        return () => {
            anchorLinks.forEach((anchor) => {
                anchor.removeEventListener('click', handleAnchorClick);
            });
        };
    }, [state.activeGame, openChallenges, closedChallenges, resolvedChallenges]);

    useEffect(() => {
        setOpenChallenges(state.activeGame?.challenges ? getOpenChallengeApplications(state.activeGame.challenges.items) : []);
        setClosedChallenges(state.activeGame?.challenges ? getClosedChallengeApplications(state.activeGame.challenges.items) : []);
        setResolvedChallenges(state.activeGame?.challenges ? getResolvedChallengeApplications(state.activeGame.challenges.items) : []);
    }, [state.activeGame, state.user]);

    useEffect(() => {
        askForNotificationPermission();
    }, []);

    function handleAnchorClick(e: Event) {
        e.preventDefault();
        const { target } = e;
        if (target instanceof Element) {
            document.querySelector(target?.getAttribute('href') || '')?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }

    function getCardButtonLabel(challengeState: ChallengeApplicationState, tipExists: boolean) {
        switch (challengeState) {
            case ChallengeApplicationState.OPEN:
                return tipExists ? 'Ändern' : 'Tippen';
            case ChallengeApplicationState.CLOSED:
                return 'Anzeigen';
            case ChallengeApplicationState.RESOLVED:
                return 'Resultat anzeigen';
        }
    }

    function getCornerText(challenge: ChallengeApplication) {
        switch (challenge.challenge.type) {
            case ChallengeType.onePlayerChallenge:
                return getChallengeTipNumber(challenge, state.activeGame?.lineup?.items ?? []);
            default:
                return getChallengeTipAnswer(challenge);
        }
    }

    function renderChallengeApplications(challenges: (ChallengeApplication | null)[], challengeState: ChallengeApplicationState) {
        const challengeList: JSX.Element[] = [];
        challenges.forEach((challenge) => {
            challenge &&
                challengeList.push(
                    <Card
                        key={challenge.id}
                        title={challenge.challenge.question}
                        btnLabel={getCardButtonLabel(challengeState, !!challenge.challengeTips?.items[0]?.answer)}
                        cornerText={getCornerText(challenge)}
                        sponsorImageUrl={getSponsor(state, challenge)?.image}
                        icon={getStateIcon(challengeState, challenge)}
                        theme={getCardMood(challengeState, challenge)}
                        background='/img/team2.jpg'
                        target={`/game/challenge/${challenge.id}`}
                    />
                );
        });
        return challengeList.length ? challengeList : <p>Zurzeit keine Challenge</p>;
    }

    if (loading) {
        return <CustomLoader label='Tippspiel wird geladen...' />;
    }

    return (
        <div id='game'>
            <h1>Tippspiel</h1>

            {state.activeGame ? (
                <>
                    {state.activeGame?.description && <h2>{state.activeGame?.description}</h2>}
                    {state.activeGame.rewards && <PricePoolLink />}
                    <p className='state-anchor-links'>
                        <a href='#open' className='button secondary'>
                            Offen
                        </a>
                        <a href='#closed' className='button secondary'>
                            Geschlossen
                        </a>
                        <a href='#resolved' className='button secondary'>
                            Aufgelöst
                        </a>
                    </p>
                    <h2 id='open' className='divider'>
                        Offen
                    </h2>
                    <div className='open-challenges challenge-list'>
                        {openChallenges && renderChallengeApplications(openChallenges, ChallengeApplicationState.OPEN)}
                    </div>

                    <h2 id='closed' className='divider'>
                        Geschlossen
                    </h2>
                    <div className='closed-challenges challenge-list'>
                        {closedChallenges && renderChallengeApplications(closedChallenges, ChallengeApplicationState.CLOSED)}
                    </div>

                    <h2 id='resolved' className='divider'>
                        Aufgelöst
                    </h2>
                    <div className='resolved-challenges challenge-list'>
                        {resolvedChallenges && renderChallengeApplications(resolvedChallenges, ChallengeApplicationState.RESOLVED)}
                    </div>
                </>
            ) : (
                <h3>Zurzeit gibt es kein aktives Tippspiel</h3>
            )}
        </div>
    );
}

export default Game;
