import React from 'react';
import { Link } from 'react-router-dom';
import './information.scss';

function Information() {
    return (
        <div id='information' className='text-content'>
            <h1>SCRJ Lakers Tippspiel</h1>
            <h2>Warum sollte ich das Tippspiel nutzen?</h2>
            <p>
                Mit diesem Tippspiel hast du die Möglichkeit deine Kenntnisse zum Hockey und den SCRJ Lakers zu beweisen. Du kannst dich mit deinen
                Freunden messen, deine Statistiken einsehen und mit etwas Glück sogar Preise gewinnen.
            </p>
            <h2>Wie kann ich am Tippspiel teilnehmen?</h2>
            <p>
                Kurz vor einem Heimspiel der SCRJ Lakers wird das Tippspiel gestartet. Noch vor Anpfiff des Spiels und während den Drittelspausen
                werden Fragen aufgeschaltet, welche du beantworten kannst.
            </p>
            <h2>Wie funktioniert dieses Tippspiel?</h2>
            <p>
                Bei korrekter Tippabgabe bekommst du Punkte zugeschrieben. Am Ende jedes Spiels werden die Punkte gesammelt, ausgewertet und die
                Preise den Gewinnern zugeordnet. Um an der Verlosung der Preise teilzunehmen und deinen Fortschritt im Profil zu speichern musst du
                eingeloggt sein. - Ansonsten verlierst du deinen Fortschritt nach 30 Tagen. Einen Account zu erstellen geht ganz einfach via
                Email-Adresse, deinem Google oder Facebook Account.
            </p>
            <h2>Wer gewinnt die Preise?</h2>
            <p>
                Jede Frage gibt Punkte für (teils) richtige Antworten. Am Ende eines Spieles gewinnt derjenige oder diejenigen mit den meisten
                Punkten.
            </p>
            <h2>Wie kann ich das Tippspiel installieren?</h2>
            <p>Das Tippspiel ist als eigenständige Webseite verfügbar, die du aber auch als App installieren kannst.</p>
            <h3>IOS</h3>
            <ol>
                <li>Öffne das Tippspiel im Safari</li>
                <li>Klicke auf "Teilen" und anschliessend auf "Zum Home-Bildschirm"</li>
                <li>Gib der App einen Namen und schon hast du die Webseite auf deinem Handy installiert</li>
            </ol>
            <h3>Android</h3>
            <ol>
                <li>Öffne das Tippspiel im Chrome</li>
                <li>Klicke auf "Einstellungen" und dann auf "App installieren"</li>
                <li>Die Webseite ist nun als App auf deinem Handy verfügbar</li>
            </ol>
            <h2 className='center'>Weiterführende Links</h2>
            <div className='links'>
                <Link to='impressum'>Impressum</Link>
                <Link to='agb'>Allgemeine Nutzungsbedingungen</Link>
                <Link to='privacy'>Datenschutzerklärung</Link>
                <Link to='about'>Entwicklung</Link>
                <Link to='contact'>Kontakt</Link>
            </div>
        </div>
    );
}

export default Information;
