import { useCallback, useState } from 'react';

function useAsync<T>() {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<T | null>(null);
    const [error, setError] = useState<Error | null>(null);

    const execute = useCallback((asyncFunction: () => Promise<T>) => {
        setLoading(true);
        setValue(null);
        setError(null);
        return asyncFunction()
            .then((response: T) => {
                setValue(response);
            })
            .catch((e: Error) => {
                setError(e);
            })
            .finally(() => setLoading(false));
    }, []);

    return { execute, loading, value, error };
}

export default useAsync;
