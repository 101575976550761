import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchIssuedRewardsAction, redeemRewardAction } from '../../../actions/rewardActions';
import Slider from '../../../components/Slider/Slider';
import Context from '../../../Context/context';
import { IssuedReward } from '../../../API';
import './reward.scss';
import CustomLoader from '../../../components/CustomLoader/CustomLoader';
import useAsync from '../../../hooks/useAsync';
import { ActionTypes } from '../../../ressources/types/state';
import useAction from '../../../hooks/useAction';
import { getDateInSwissFormat, getTimeInSwissFormat } from '../../../utils/dateHelpers';
import { generateCode } from '../../../utils/rewardHelpers';

function Reward() {
    const { state, dispatch } = useContext(Context);
    const [issuedRewardLoading, setIssuedRewardLoading] = useState(true);
    const [issuedReward, setIssuedReward] = useState<IssuedReward | null>();
    const [redemptionDate, setRedemptionDate] = useState<string | null>();
    const [redemptionTime, setRedemptionTime] = useState<string | null>();
    const { rewardId } = useParams();

    const { execute, loading: redeeming, error } = useAsync();
    const { loading: fetchingRewards } = useAction({
        action: fetchIssuedRewardsAction,
        dispatch,
        condition: !state.issuedRewards && !!state.user,
    });

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (state.issuedRewards && rewardId) {
            setIssuedReward(
                state.issuedRewards.find((reward: IssuedReward) => {
                    return reward.id === rewardId;
                })
            );
            setIssuedRewardLoading(false);
        } else {
            setIssuedRewardLoading(false);
        }
    }, [state.issuedRewards, rewardId]);

    useEffect(() => {
        if (issuedReward?.redemptionTimestamp) {
            setRedemptionDate(getDateInSwissFormat(issuedReward.redemptionTimestamp));
            setRedemptionTime(getTimeInSwissFormat(issuedReward.redemptionTimestamp));
        }
    }, [issuedReward]);

    function redeemReward() {
        issuedReward && execute(() => redeemRewardAction(dispatch, issuedReward.id));
    }

    if (issuedRewardLoading || fetchingRewards || !state.issuedRewards) {
        return <CustomLoader label='Preis wird geladen...' />;
    }

    if (redeeming) {
        return <CustomLoader label='Preis wird eingelöst...' />;
    }

    return (
        <div className='redeem'>
            {issuedReward ? (
                <>
                    {issuedReward.redemptionTimestamp ? <h1>Preis abgeholt</h1> : <h1>Preis abholen</h1>}
                    <h2 className='gray_dark'>{issuedReward?.reward.name}</h2>
                    {issuedReward.redemptionTimestamp ? (
                        <p>
                            Der Preis wurde am {redemptionDate} um {redemptionTime} eingelöst.
                        </p>
                    ) : (
                        <p>{issuedReward.reward.redemptionInfo}</p> // Weise diesen Bildschirm an der Kasse vor, um deinen Preis zu erhalten.
                    )}
                    {rewardId && (
                        <p className='code'>
                            Code: <span className='negative'>{generateCode(rewardId).toUpperCase()}</span>
                        </p>
                    )}
                    <div className='redeem-slider'>
                        <Slider initialLabel='Wischen' finalLabel='Eingelöst' redeemed={!!issuedReward.redemptionTimestamp} action={redeemReward} />
                    </div>
                    {!issuedReward.redemptionTimestamp && <p>Vorsicht: Nach dem wischen ist dein Preis eingelöst.</p>}
                </>
            ) : (
                <p className='placeholder-text'>Ungültiger Preis</p>
            )}
        </div>
    );
}

export default Reward;
