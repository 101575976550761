import React, { MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import './card.scss';
import StyleMood from '../../utils/styleMood';

interface CardProps {
    title: string;
    btnLabel?: string;
    icon?: SemanticICONS;
    theme?: StyleMood;
    centerIcon?: SemanticICONS;
    cornerText?: string;
    sponsorImageUrl?: string;
    background?: string;
    target?: string;
    onButtonClick?: () => void;
    onClick?: () => void;
}

function Card(props: CardProps) {
    const navigate = useNavigate();

    function handleClick(e: MouseEvent) {
        if (props.onButtonClick && isButton(e)) {
            props.onButtonClick();
            return;
        }

        if (props.onClick) {
            props.onClick();
        } else if (props.target) {
            navigate(props.target);
        }
    }

    function isButton(e: MouseEvent) {
        return e.target instanceof HTMLElement && e.target.classList.contains('button');
    }

    return (
        <div
            className={`card ${props.background ? 'image' : ''}`}
            style={{ backgroundImage: `url('${props.background}')` }}
            onClick={(e) => handleClick(e)}>
            <div className='card-header'>
                <div className='card-corner-text'>{props.cornerText}</div>
                {props.sponsorImageUrl ? <img className={'card-sponsor'} src={props.sponsorImageUrl} alt='logo' /> : <div></div>}

                <div className='card-icon'>{props.icon && <i className={`${props.theme ?? StyleMood.neutral} ${props.icon} icon`}></i>}</div>
            </div>
            {props.centerIcon && (
                <div className='card-center-icon'>
                    <Icon name={props.centerIcon} />
                </div>
            )}
            <div className='card-title'>
                <h3>{props.title}</h3>
            </div>
            <div className='card-footer'>
                {props.btnLabel && (
                    <div className='card-button'>
                        <Link to='' className='button primary'>
                            {props.btnLabel}
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Card;
