import { Dispatch } from 'react';
import { GraphQLQuery } from '@aws-amplify/api';
import { GraphqlInterceptor as API } from '../utils/graphqlInterceptor';
import { ActionTypes, AppStateAction } from '../ressources/types/state';
import {
    CreateChallengeRewardApplicationInput,
    CreateRewardApplicationInput,
    CreateRewardApplicationMutation,
    CreateRewardInput,
    CreateRewardMutation,
    DeleteRewardApplicationInput,
    DeleteRewardApplicationMutation,
    IssuedReward,
    ListIssuedRewardsQuery,
    ListRewardsQuery,
    RedeemIssuedRewardMutation,
    Reward,
    RewardApplication,
    UpdateRewardApplicationInput,
    UpdateRewardApplicationMutation,
    UpdateRewardInput,
    UpdateRewardMutation,
} from '../API';
import {
    createChallengeRewardApplication,
    createReward,
    createRewardApplication,
    deleteChallengeRewardApplication,
    deleteRewardApplication,
    redeemIssuedReward,
    updateChallengeRewardApplication,
    updateReward,
    updateRewardApplication,
} from '../graphql/mutations';
import { listIssuedRewardsWithReward, listRewardsWithSponsor } from '../graphql/custom-queries';
import { RewardSelection } from '../ressources/types/game';

export async function fetchRewardsAction(dispatch: Dispatch<AppStateAction>) {
    const response = await API.graphql<GraphQLQuery<ListRewardsQuery>>({
        query: listRewardsWithSponsor,
        variables: { filter: { archived: { eq: false } } },
    });

    dispatch({
        type: ActionTypes.UPDATE_REWARDS,
        rewards: response.data?.listRewards?.items as Reward[],
    });
}

export async function createRewardAction(
    dispatch: Dispatch<AppStateAction>,
    name: string,
    redemptionInfo: string,
    value?: number,
    sponsorId?: string
) {
    const input: CreateRewardInput = {
        name,
        value,
        redemptionInfo,
        sponsorId,
    };
    const response = await API.graphql<GraphQLQuery<CreateRewardMutation>>({
        query: createReward,
        variables: { input },
    });
    dispatch({ type: ActionTypes.ADD_REWARD, reward: response.data?.createReward as Reward });
}

export async function updateRewardAction(dispatch: Dispatch<AppStateAction>, reward: Reward) {
    const input: UpdateRewardInput = {
        id: reward.id,
        name: reward.name,
        value: reward.value,
        archived: reward.archived,
        redemptionInfo: reward.redemptionInfo,
        sponsorId: reward.sponsorId,
    };
    const response = await API.graphql<GraphQLQuery<UpdateRewardMutation>>({
        query: updateReward,
        variables: { input },
    });
    dispatch({ type: ActionTypes.UPDATE_REWARD, reward: response.data?.updateReward as Reward });
}

export async function fetchIssuedRewardsAction(dispatch: Dispatch<AppStateAction>) {
    const response = await API.graphql<GraphQLQuery<ListIssuedRewardsQuery>>({
        query: listIssuedRewardsWithReward,
    });
    dispatch({
        type: ActionTypes.UPDATE_ISSUED_REWARDS,
        issuedRewards: response.data?.listIssuedRewards?.items as IssuedReward[],
    });
}

export async function redeemRewardAction(dispatch: Dispatch<AppStateAction>, issuedRewardId: string) {
    const response = await API.graphql<GraphQLQuery<RedeemIssuedRewardMutation>>({
        query: redeemIssuedReward,
        variables: { id: issuedRewardId },
    });
    dispatch({ type: ActionTypes.REDEEM_ISSUED_REWARD, issuedReward: response.data?.redeemIssuedReward as IssuedReward });
}

export async function createRewardApplicationAction(dispatch: Dispatch<AppStateAction>, rewardSelection: RewardSelection, gameId: string) {
    const input: CreateRewardApplicationInput = {
        gameId,
        rewardId: rewardSelection.rewardId,
        order: rewardSelection.order,
        amount: rewardSelection.amount,
    };
    const response = await API.graphql<GraphQLQuery<CreateRewardApplicationMutation>>({
        query: createRewardApplication,
        variables: { input },
    });
    dispatch({
        type: ActionTypes.ADD_REWARD_APPLICATION,
        rewardApplication: response.data?.createRewardApplication as RewardApplication,
    });
}

export async function updateRewardApplicationAction(dispatch: Dispatch<AppStateAction>, reward: RewardSelection) {
    if (reward.rewardApplicationId) {
        const input: UpdateRewardApplicationInput = {
            id: reward.rewardApplicationId,
            order: reward.order,
            amount: reward.amount,
        };
        const response = await API.graphql<GraphQLQuery<UpdateRewardApplicationMutation>>({
            query: updateRewardApplication,
            variables: { input },
        });
        dispatch({
            type: ActionTypes.UPDATE_REWARD_APPLICATION,
            rewardApplication: response.data?.updateRewardApplication as RewardApplication,
        });
    }
}

export async function deleteRewardApplicationAction(dispatch: Dispatch<AppStateAction>, rewardApplicationId: string) {
    const input: DeleteRewardApplicationInput = {
        id: rewardApplicationId,
    };
    const response = await API.graphql<GraphQLQuery<DeleteRewardApplicationMutation>>({
        query: deleteRewardApplication,
        variables: { input },
    });
    dispatch({
        type: ActionTypes.DELETE_REWARD_APPLICATION,
        rewardApplication: response.data?.deleteRewardApplication as RewardApplication,
    });
}

export async function createChallengeRewardApplicationAction(
    dispatch: Dispatch<AppStateAction>,
    rewardSelection: RewardSelection,
    challengeApplicationId: string
) {
    const input: CreateChallengeRewardApplicationInput = {
        challengeApplicationId,
        rewardId: rewardSelection.rewardId,
        order: rewardSelection.order,
        amount: rewardSelection.amount,
    };
    const response = await API.graphql<GraphQLQuery<CreateRewardApplicationMutation>>({
        query: createChallengeRewardApplication,
        variables: { input },
    });
    dispatch({
        type: ActionTypes.ADD_REWARD_APPLICATION,
        rewardApplication: response.data?.createRewardApplication as RewardApplication,
    });
}

export async function updateChallengeRewardApplicationAction(dispatch: Dispatch<AppStateAction>, reward: RewardSelection) {
    if (reward.rewardApplicationId) {
        const input: UpdateRewardApplicationInput = {
            id: reward.rewardApplicationId,
            order: reward.order,
            amount: reward.amount,
        };
        const response = await API.graphql<GraphQLQuery<UpdateRewardApplicationMutation>>({
            query: updateChallengeRewardApplication,
            variables: { input },
        });
        dispatch({
            type: ActionTypes.UPDATE_REWARD_APPLICATION,
            rewardApplication: response.data?.updateRewardApplication as RewardApplication,
        });
    }
}

export async function deleteChallengeRewardApplicationAction(dispatch: Dispatch<AppStateAction>, rewardApplicationId: string) {
    const input: DeleteRewardApplicationInput = {
        id: rewardApplicationId,
    };
    const response = await API.graphql<GraphQLQuery<DeleteRewardApplicationMutation>>({
        query: deleteChallengeRewardApplication,
        variables: { input },
    });
    dispatch({
        type: ActionTypes.DELETE_REWARD_APPLICATION,
        rewardApplication: response.data?.deleteRewardApplication as RewardApplication,
    });
}
