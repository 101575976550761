import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import './pricePoolLink.scss';

function PricePoolLink() {
    return (
        <p className='price-pool-link'>
            <Link to='/game/price-pool'>
                <Icon name='gift' />
                Heutige Verlosung
            </Link>
        </p>
    );
}

export default PricePoolLink;
