import React from 'react';
import '../../ressources/styles/themes/home.scss';
import { Outlet } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation';
import Header from '../../components/Header/Header';

function HomeTheme() {
    return (
        <div className='app home-layout'>
            <Header />
            <section id='content'>
                <Outlet />
            </section>
            <Navigation />
        </div>
    );
}

export default HomeTheme;
