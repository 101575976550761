import { Storage } from 'aws-amplify';
import { GamePlayer, Player } from '../API';
import { IPlayer, nobody } from '../ressources/types/player';

const BUCKET_FOLDER = 'players/';

export function getS3Url(player: Player): Promise<string> {
    return Storage.get(BUCKET_FOLDER + player.image, { expires: 86400 });
}

export function createIPlayers(gamePlayers: (GamePlayer | null)[] | undefined, imageSources: { gamePlayers: { playerId: string; url: string }[] }) {
    const temp: IPlayer[] = [];
    gamePlayers?.forEach((gamePlayer) => {
        if (gamePlayer) {
            const imageSource = imageSources.gamePlayers.find(({ playerId }) => playerId === gamePlayer.id);
            temp.push({ ...gamePlayer.player, s3url: imageSource?.url || '' });
        }
    });
    return temp;
}

export function sortPlayers(players: IPlayer[]) {
    return players.sort((a, b) => (a.number > b.number ? 1 : -1));
}

export async function getPlayerById(playerId: string, players: (GamePlayer | null)[]) {
    if (playerId === nobody.id) {
        return nobody;
    }
    let tippedPlayer: Player | undefined;
    let s3url: Promise<string> | undefined;
    for (const player of players) {
        if (player && playerId === player.player.id) {
            s3url = getS3Url(player.player);
            tippedPlayer = player.player;
        }
    }
    if (tippedPlayer && s3url) {
        return { ...tippedPlayer, s3url: await s3url };
    }
}
