import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import Context from '../../../../Context/context';
import useAsync from '../../../../hooks/useAsync';
import { redeemRewardAction } from '../../../../actions/rewardActions';
import { ActionTypes } from '../../../../ressources/types/state';
import CustomLoader from '../../../../components/CustomLoader/CustomLoader';

function Redeem() {
    const { dispatch } = useContext(Context);
    const { rewardId } = useParams();

    const { execute, loading: redeeming, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        redeemReward();
    }, []);

    function redeemReward() {
        rewardId && execute(() => redeemRewardAction(dispatch, rewardId));
    }

    if (redeeming) {
        return <CustomLoader label='Preis wird eingelöst...' />;
    }

    if (error) {
        return (
            <div className='redeem'>
                <p>
                    <Icon color='red' name='cancel' size='massive' />
                </p>
                <p>Der Preis konnte nicht eingelöst werden oder wurde bereits eingelöst.</p>
            </div>
        );
    }

    return (
        <div className='redeem'>
            <p>
                <Icon color='green' name='check circle outline' size='massive' />
            </p>
            <p>Der Preis wurde eingelöst.</p>
        </div>
    );
}

export default Redeem;
