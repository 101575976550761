import React from 'react';

function Privacy() {
    // Based on https://datenschutz-generator.de/
    return (
        <div id='privacy' className='text-content'>
            <h1>Datenschutzerklärung</h1>
            <h2 id='m14'>Einleitung</h2>
            <p>
                Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch
                kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns
                durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
                unseren Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile
                (nachfolgend zusammenfassend bezeichnet als "Onlineangebot").
            </p>
            <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
            <p>Stand: 18. Mai 2022</p>
            <h2>Inhaltsübersicht</h2>
            <ul className='index'>
                <li>
                    <a className='index-link' href='#m14'>
                        Einleitung
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m3'>
                        Verantwortlicher
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m11'>
                        Kontakt Datenschutzbeauftragter
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#mOverview'>
                        Übersicht der Verarbeitungen
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m13'>
                        Massgebliche Rechtsgrundlagen
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m27'>
                        Sicherheitsmassnahmen
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m25'>
                        Übermittlung von personenbezogenen Daten
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m24'>
                        Datenverarbeitung in Drittländern
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m12'>
                        Löschung von Daten
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m134'>
                        Einsatz von Cookies
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m225'>
                        Bereitstellung des Onlineangebotes und Webhosting
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m367'>
                        Registrierung, Anmeldung und Nutzerkonto
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m451'>
                        Single-Sign-On-Anmeldung
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m182'>
                        Kontakt- und Anfragenverwaltung
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m17'>
                        Newsletter und elektronische Benachrichtigungen
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m638'>
                        Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m237'>
                        Gewinnspiele und Wettbewerbe
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m263'>
                        Webanalyse, Monitoring und Optimierung
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m15'>
                        Änderung und Aktualisierung der Datenschutzerklärung
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m10'>
                        Rechte der betroffenen Personen
                    </a>
                </li>
                <li>
                    <a className='index-link' href='#m42'>
                        Begriffsdefinitionen
                    </a>
                </li>
            </ul>
            <h2 id='m3'>Verantwortlicher</h2>
            <p>
                Lakers Sport AG
                <br />
                Walter-Denzler-Str. 3<br />
                Postfach 1106
                <br />
                8640 Rapperswil
                <br />
                Schweiz
            </p>
            Telefon:
            <p>
                <a href='tel:+41 55 220 80 90'>+41 55 220 80 90</a>
            </p>
            E-Mail-Adresse:
            <p>
                <a href='mailto:info@lakers.ch'>info@lakers.ch</a>
            </p>
            <h2 id='m11'>Kontakt Datenschutzbeauftragter</h2>
            <p>
                <a href='mailto:info@lakers.ch'>info@lakers.ch</a>
            </p>
            <h2 id='mOverview'>Übersicht der Verarbeitungen</h2>
            <p>
                Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
                betroffenen Personen.
            </p>
            <h3>Arten der verarbeiteten Daten</h3>
            <ul>
                <li>Bestandsdaten.</li>
                <li>Kontaktdaten.</li>
                <li>Inhaltsdaten.</li>
                <li>Nutzungsdaten.</li>
                <li>Meta-/Kommunikationsdaten.</li>
            </ul>
            <h3>Kategorien betroffener Personen</h3>
            <ul>
                <li>Kommunikationspartner.</li>
                <li>Nutzer.</li>
                <li>Gewinnspiel- und Wettbewerbsteilnehmer.</li>
            </ul>
            <h3>Zwecke der Verarbeitung</h3>
            <ul>
                <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
                <li>Kontaktanfragen und Kommunikation.</li>
                <li>Sicherheitsmassnahmen.</li>
                <li>Direktmarketing.</li>
                <li>Reichweitenmessung.</li>
                <li>Verwaltung und Beantwortung von Anfragen.</li>
                <li>Durchführung von Gewinnspielen und Wettbewerben.</li>
                <li>Profile mit nutzerbezogenen Informationen.</li>
                <li>Anmeldeverfahren.</li>
                <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
            </ul>
            <h3 id='m13'>Massgebliche Rechtsgrundlagen</h3>
            <p>
                Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
                nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland
                gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen massgeblich sein, teilen wir Ihnen diese in der
                Datenschutzerklärung mit.
            </p>
            <ul>
                <li>
                    <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> - Die betroffene Person hat ihre Einwilligung in die Verarbeitung
                    der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
                </li>
                <li>
                    <strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)</strong> - Die Verarbeitung ist für die
                    Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Massnahmen
                    erforderlich, die auf Anfrage der betroffenen Person erfolgen.
                </li>
                <li>
                    <strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong> - Die Verarbeitung ist zur Erfüllung einer
                    rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt.
                </li>
                <li>
                    <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung ist zur Wahrung der berechtigten
                    Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten
                    der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
                </li>
            </ul>
            <p>
                Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in der Schweiz.
                Hierzu gehört insbesondere das Bundesgesetz zum Datenschutz (DSG). Das DSG gilt insbesondere dann, wenn keine EU/EWG-Bürger betroffen
                sind und z.B. nur Daten von Schweizer Bürgern verarbeitet werden.
            </p>
            <h2 id='m27'>Sicherheitsmassnahmen</h2>
            <p>
                Wir treffen nach Massgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der
                Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
                Ausmasses der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische Massnahmen, um ein
                dem Risiko angemessenes Schutzniveau zu gewährleisten.
            </p>
            <p>
                Zu den Massnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des
                physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung
                der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die
                Löschung von Daten und Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
                Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes,
                durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
            </p>
            <p>
                SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen, nutzen wir eine SSL-Verschlüsselung.
                Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
            </p>
            <h2 id='m25'>Übermittlung von personenbezogenen Daten</h2>
            <p>
                Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
                selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser
                Daten können z.B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden
                werden, gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben und schliessen insbesondere entsprechende Verträge bzw.
                Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
            </p>
            <h2 id='m24'>Datenverarbeitung in Drittländern</h2>
            <p>
                Sofern wir Daten in einem Drittland (d.h., ausserhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR))
                verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten
                an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.{' '}
            </p>
            <p>
                Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die
                Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln
                der EU-Kommission, beim Vorliegen von Zertifizierungen oder verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49
                DSGVO, Informationsseite der EU-Kommission:{' '}
                <a
                    href='https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de'
                    target='_blank'
                    rel='noreferrer'>
                    https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
                </a>
                ).
            </p>
            <h2 id='m12'>Löschung von Daten</h2>
            <p>
                Die von uns verarbeiteten Daten werden nach Massgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
                Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist
                oder sie für den Zweck nicht erforderlich sind).
            </p>
            <p>
                Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
                auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
                handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung
                von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.
            </p>
            <h2 id='m134'>Einsatz von Cookies</h2>
            <p>
                Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten speichern und Informationen aus den
                Endgeräten auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte
                oder verwendete Funktionen eines Onlineangebotes speichern. Cookies können ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B.
                zu Zwecken der Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.{' '}
            </p>
            <p>
                <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von
                den Nutzern eine vorhergehende Einwilligung ein, ausser wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere
                nicht notwendig, wenn das Speichern und das Auslesen der Informationen, also auch von Cookies, unbedingt erforderlich sind, um dem den
                Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die
                widerrufliche Einwilligung wird gegenüber den Nutzern deutlich kommuniziert und enthält die Informationen zu der jeweiligen
                Cookie-Nutzung.
            </p>
            <p>
                <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die
                personenbezogenen Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls
                die Nutzer einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die
                mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb
                unseres Onlineangebotes und Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen
                Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu welchen
                Zwecken die Cookies von uns verarbeitet werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
                Einwilligungs- und Verarbeitungsprozessen auf.
            </p>
            <p>
                <strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies unterschieden:
            </p>
            <ul>
                <li>
                    <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies werden spätestens gelöscht, nachdem
                    ein Nutzer ein Online-Angebot verlassen und sein Endgerät (z.B. Browser oder mobile Applikation) geschlossen hat.
                </li>
                <li>
                    <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schliessen des Endgerätes gespeichert. So können
                    beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut
                    besucht. Ebenso können die mit Hilfe von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet werden. Sofern wir
                    Nutzern keine expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung),
                    sollten Nutzer davon ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.
                </li>
            </ul>
            <p>
                <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong>Nutzer können die von ihnen abgegebenen Einwilligungen
                jederzeit Widerrufen und zudem einen Widerspruch gegen die Verarbeitung entsprechend den gesetzlichen Vorgaben im Art. 21 DSGVO
                einlegen (weitere Hinweise zum Widerspruch erfolgen im Rahmen dieser Datenschutzerklärung). Nutzer können Ihren Widerspruch mittels
                der Einstellungen Ihres Browsers erklären.
            </p>
            <h2 id='m225'>Bereitstellung des Onlineangebotes und Webhosting</h2>
            <p>
                Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von einem oder mehreren
                Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu
                diesen Zwecken können wir Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste sowie
                Sicherheitsleistungen und technische Wartungsleistungen in Anspruch nehmen.
            </p>
            <p>
                Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes
                betreffenden Angaben gehören, die im Rahmen der Nutzung und der Kommunikation anfallen. Hierzu gehören regelmässig die IP-Adresse, die
                notwendig ist, um die Inhalte von Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres Onlineangebotes oder von
                Webseiten getätigten Eingaben.
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte
                    Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                </li>
                <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
                </li>
                <li>
                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                </li>
            </ul>
            <p>
                <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Wir selbst (bzw. unser Webhostinganbieter) erheben Daten zu jedem
                    Zugriff auf den Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten
                    und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
                    das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider
                    gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der Server zu
                    vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der
                    Server und ihre Stabilität sicherzustellen; <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die Dauer von
                    maximal 180 Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
                    erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.
                </li>
                <li>
                    <strong>Content-Delivery-Network: </strong>Wir setzen ein "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein Dienst, mit dessen
                    Hilfe Inhalte eines Onlineangebotes, insbesondere grosse Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe regional
                    verteilter und über das Internet verbundener Server schneller und sicherer ausgeliefert werden können.
                </li>
                <li>
                    <strong>Amazon Web Services (AWS): </strong>Leistungen auf dem Gebiet der Bereitstellung von informationstechnischer Infrastruktur
                    und verbundenen Dienstleistungen (z.B. Speicherplatz und/oder Rechenkapazitäten); <strong>Dienstanbieter:</strong> Amazon Web
                    Services, Inc., 410 Terry Avenue North, Seattle WA 98109, USA; <strong>Website:</strong>{' '}
                    <a href='https://aws.amazon.com/de/' target='_blank' rel='noreferrer'>
                        https://aws.amazon.com/de/
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>
                    <a href='https://aws.amazon.com/de/privacy/?nc1=f_pr' target='_blank' rel='noreferrer'>
                        https://aws.amazon.com/de/privacy/?nc1=f_pr
                    </a>
                    ; <strong>Auftragsverarbeitungsvertrag:</strong>
                    <a href='https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf' target='_blank' rel='noreferrer'>
                        https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf
                    </a>
                    ; <strong>Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):</strong>{' '}
                    <a href='https://aws.amazon.com/de/service-terms/' target='_blank' rel='noreferrer'>
                        https://aws.amazon.com/de/service-terms/
                    </a>
                    .
                </li>
            </ul>
            <h2 id='m367'>Registrierung, Anmeldung und Nutzerkonto</h2>
            <p>
                Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und
                zu Zwecken der Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten
                gehören insbesondere die Login-Informationen (Nutzername, Passwort sowie eine E-Mail-Adresse).
            </p>
            <p>
                Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die
                IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als
                auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
                grundsätzlich nicht, es sei denn, sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung
                hierzu.
            </p>
            <p>
                Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z.B. technische Änderungen, per E-Mail informiert
                werden.
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern);
                    Inhaltsdaten (z.B. Eingaben in Onlineformularen); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                </li>
                <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice; Sicherheitsmassnahmen; Verwaltung
                    und Beantwortung von Anfragen.
                </li>
                <li>
                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Berechtigte
                    Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                </li>
            </ul>
            <p>
                <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Registrierung mit Pseudonymen: </strong>Nutzer dürfen statt Klarnamen Pseudonyme als Nutzernamen verwenden.
                </li>
                <li>
                    <strong>Profile der Nutzer sind öffentlich: </strong>Die Profile der Nutzer sind öffentlich sichtbar und zugänglich.
                </li>
                <li>
                    <strong>Einstellung der Sichtbarkeit von Profilen: </strong>Die Nutzer können mittels Einstellungen bestimmen, in welchem Umfang
                    ihre Profile für die Öffentlichkeit oder nur für bestimmte Personengruppen sichtbar, bzw. zugänglich sind.
                </li>
                <li>
                    <strong>Löschung von Daten nach Kündigung: </strong>Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im Hinblick
                    auf das Nutzerkonto, vorbehaltlich einer gesetzlichen Erlaubnis, Pflicht oder Einwilligung der Nutzer, gelöscht.
                </li>
                <li>
                    <strong>Keine Aufbewahrungspflicht für Daten: </strong>Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem
                    Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer gespeicherte Daten des Nutzers unwiederbringlich
                    zu löschen.
                </li>
            </ul>
            <h2 id='m451'>Single-Sign-On-Anmeldung</h2>
            <p>
                Als "Single-Sign-On" oder "Single-Sign-On-Anmeldung bzw. "-Authentifizierung" werden Verfahren bezeichnet, die es Nutzern erlauben,
                sich mit Hilfe eines Nutzerkontos bei einem Anbieter von Single-Sign-On-Verfahren (z.B. einem sozialen Netzwerk), auch bei unserem
                Onlineangebot, anzumelden. Voraussetzung der Single-Sign-On-Authentifizierung ist, dass die Nutzer bei dem jeweiligen
                Single-Sign-On-Anbieter registriert sind und die erforderlichen Zugangsdaten in dem dafür vorgesehenen Onlineformular eingeben, bzw.
                schon bei dem Single-Sign-On-Anbieter angemeldet sind und die Single-Sign-On-Anmeldung via Schaltfläche bestätigen.
            </p>
            <p>
                Die Authentifizierung erfolgt direkt bei dem jeweiligen Single-Sign-On-Anbieter. Im Rahmen einer solchen Authentifizierung erhalten
                wir eine Nutzer-ID mit der Information, dass der Nutzer unter dieser Nutzer-ID beim jeweiligen Single-Sign-On-Anbieter eingeloggt ist
                und eine für uns für andere Zwecke nicht weiter nutzbare ID (sog "User Handle"). Ob uns zusätzliche Daten übermittelt werden, hängt
                allein von dem genutzten Single-Sign-On-Verfahren ab, von den gewählten Datenfreigaben im Rahmen der Authentifizierung und zudem
                davon, welche Daten Nutzer in den Privatsphäre- oder sonstigen Einstellungen des Nutzerkontos beim Single-Sign-On-Anbieter freigegeben
                haben. Es können je nach Single-Sign-On-Anbieter und der Wahl der Nutzer verschiedene Daten sein, in der Regel sind es die
                E-Mail-Adresse und der Benutzername. Das im Rahmen des Single-Sign-On-Verfahrens eingegebene Passwort bei dem Single-Sign-On-Anbieter
                ist für uns weder einsehbar, noch wird es von uns gespeichert.{' '}
            </p>
            <p>
                Die Nutzer werden gebeten, zu beachten, dass deren bei uns gespeicherte Angaben automatisch mit ihrem Nutzerkonto beim
                Single-Sign-On-Anbieter abgeglichen werden können, dies jedoch nicht immer möglich ist oder tatsächlich erfolgt. Ändern sich z.B. die
                E-Mail-Adressen der Nutzer, müssen sie diese manuell in ihrem Nutzerkonto bei uns ändern.
            </p>
            <p>
                Die Single-Sign-On-Anmeldung können wir, sofern mit den Nutzern vereinbart, im Rahmen der oder vor der Vertragserfüllung einsetzen,
                soweit die Nutzer darum gebeten wurden, im Rahmen einer Einwilligung verarbeiten und setzen sie ansonsten auf Grundlage der
                berechtigten Interessen unsererseits und der Interessen der Nutzer an einem effektiven und sicheren Anmeldesystem ein.
            </p>
            <p>
                Sollten Nutzer sich einmal entscheiden, die Verknüpfung ihres Nutzerkontos beim Single-Sign-On-Anbieter nicht mehr für das
                Single-Sign-On-Verfahren nutzen zu wollen, müssen sie diese Verbindung innerhalb ihres Nutzerkontos beim Single-Sign-On-Anbieter
                aufheben. Möchten Nutzer deren Daten bei uns löschen, müssen sie ihre Registrierung bei uns kündigen.
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern);
                </li>
                <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice; Anmeldeverfahren.
                </li>
                <li>
                    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO); Vertragserfüllung und vorvertragliche Anfragen
                    (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                </li>
            </ul>
            <p>
                <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Apple Single-Sign-On: </strong>Authentifizierungsdienst; <strong>Dienstanbieter:</strong> Apple Inc., Infinite Loop,
                    Cupertino, CA 95014, USA; <strong>Website:</strong>{' '}
                    <a href='https://www.apple.com/de/' target='_blank' rel='noreferrer'>
                        https://www.apple.com/de/
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>{' '}
                    <a href='https://www.apple.com/legal/privacy/de-ww/' target='_blank' rel='noreferrer'>
                        https://www.apple.com/legal/privacy/de-ww/
                    </a>
                    .
                </li>
                <li>
                    <strong>Facebook Single-Sign-On: </strong>Authentifizierungsdienst der Plattform Facebook; <strong>Dienstanbieter:</strong> Meta
                    Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland; <strong>Website:</strong>{' '}
                    <a href='https://www.facebook.com' target='_blank' rel='noreferrer'>
                        https://www.facebook.com
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>
                    <a href='https://www.facebook.com/about/privacy' target='_blank' rel='noreferrer'>
                        https://www.facebook.com/about/privacy
                    </a>
                    ; <strong>Auftragsverarbeitungsvertrag:</strong>
                    <a href='https://www.facebook.com/legal/terms/dataprocessing' target='_blank' rel='noreferrer'>
                        https://www.facebook.com/legal/terms/dataprocessing
                    </a>
                    ; <strong>Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):</strong>{' '}
                    <a href='https://www.facebook.com/legal/EU_data_transfer_addendum' target='_blank' rel='noreferrer'>
                        https://www.facebook.com/legal/EU_data_transfer_addendum
                    </a>
                    .
                </li>
                <li>
                    <strong>Google Single-Sign-On: </strong>Authentifizierungsdienst; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
                    House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;{' '}
                    <strong>Website:</strong>{' '}
                    <a href='https://www.google.de' target='_blank' rel='noreferrer'>
                        https://www.google.de
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>
                    <a href='https://policies.google.com/privacy' target='_blank' rel='noreferrer'>
                        https://policies.google.com/privacy
                    </a>
                    ; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Einstellungen für die Darstellung von Werbeeinblendungen:{' '}
                    <a href='https://adssettings.google.com/authenticated' target='_blank' rel='noreferrer'>
                        https://adssettings.google.com/authenticated
                    </a>
                    .
                </li>
            </ul>
            <h2 id='m182'>Kontakt- und Anfragenverwaltung</h2>
            <p>
                Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender
                Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet soweit dies zur Beantwortung der
                Kontaktanfragen und etwaiger angefragter Massnahmen erforderlich ist.
            </p>
            <p>
                Die Beantwortung der Kontaktanfragen sowie die Verwaltung von Kontakt- und Anfragedaten im Rahmen von vertraglichen oder
                vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen
                Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an der Beantwortung der Anfragen und Pflege von Nutzer- bzw.
                Geschäftsbeziehungen.
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern);
                    Inhaltsdaten (z.B. Eingaben in Onlineformularen).
                </li>
                <li>
                    <strong>Betroffene Personen:</strong> Kommunikationspartner.
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Erbringung vertraglicher Leistungen und
                    Kundenservice.
                </li>
                <li>
                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO); Berechtigte
                    Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO).
                </li>
            </ul>
            <p>
                <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in Kontakt
                    treten, verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens. Zu diesem
                    Zweck verarbeiten wir personenbezogene Daten im Rahmen vorvertraglicher und vertraglicher Geschäftsbeziehungen, soweit dies zu
                    deren Erfüllung erforderlich ist und im Übrigen auf Grundlage unserer berechtigten Interessen sowie der Interessen der
                    Kommunikationspartner an der Beantwortung der Anliegen und unserer gesetzlichen Aufbewahrungspflichten.
                </li>
            </ul>
            <h2 id='m17'>Newsletter und elektronische Benachrichtigungen</h2>
            <p>
                Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen (nachfolgend "Newsletter") nur mit der Einwilligung der
                Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben
                werden, sind sie für die Einwilligung der Nutzer massgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren
                Leistungen und uns.
            </p>
            <p>
                Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch
                bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter, oder weitere Angaben, sofern diese für die Zwecke des Newsletters
                erforderlich sind, zu tätigen.
            </p>
            <p>
                <strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf
                Grundlage unserer berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu
                können. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein individueller
                Löschungsantrag ist jederzeit möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten
                zur dauerhaften Beachtung von Widersprüchen behalten wir uns die Speicherung der E-Mail-Adresse alleine zu diesem Zweck in einer
                Sperrliste (sogenannte "Blocklist") vor.
            </p>
            <p>
                Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zu Zwecken des Nachweises seines
                ordnungsgemässen Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails beauftragen, erfolgt dies auf Grundlage unserer
                berechtigten Interessen an einem effizienten und sicheren Versandsystem.
            </p>
            <p>
                <strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der Newsletter erfolgt auf Grundlage einer Einwilligung der Empfänger oder,
                falls eine Einwilligung nicht erforderlich ist, auf Grundlage unserer berechtigten Interessen am Direktmarketing, sofern und soweit
                diese gesetzlich, z.B. im Fall von Bestandskundenwerbung, erlaubt ist. Soweit wir einen Dienstleister mit dem Versand von E-Mails
                beauftragen, geschieht dies auf der Grundlage unserer berechtigten Interessen an einem effizienten und sicheren Versand. Das
                Registrierungsverfahren wird auf der Grundlage unserer berechtigten Interessen aufgezeichnet, um nachzuweisen, dass es in
                Übereinstimmung mit dem Gesetz durchgeführt wurde.
            </p>
            <strong>Inhalte:</strong> <p>Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
            <ul className='m-elements'>
                <li>
                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern);
                    Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                    Zugriffszeiten).
                </li>
                <li>
                    <strong>Betroffene Personen:</strong> Kommunikationspartner.
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder postalisch).
                </li>
                <li>
                    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                    lit. f. DSGVO).
                </li>
                <li>
                    <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre
                    Einwilligungen widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link zur Kündigung des Newsletters finden Sie entweder
                    am Ende eines jeden Newsletters oder können sonst eine der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu
                    nutzen.
                </li>
            </ul>
            <p>
                <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Messung von Öffnungs- und Klickraten: </strong>Die Newsletter enthalten einen sogenannte "web-beacon", d.h., eine
                    pixelgrosse Datei, die beim Öffnen des Newsletters von unserem Server, bzw., sofern wir einen Versanddienstleister einsetzen, von
                    dessen Server abgerufen wird. Im Rahmen dieses Abrufs werden zunächst technische Informationen, wie Informationen zum Browser und
                    Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt des Abrufs, erhoben. Diese Informationen werden zur technischen
                    Verbesserung unseres Newsletters anhand der technischen Daten oder der Zielgruppen und ihres Leseverhaltens auf Basis ihrer
                    Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls die
                    Feststellung, ob die Newsletter geöffnet werden, wann sie geöffnet werden.
                </li>
            </ul>
            <h2 id='m638'>Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2>
            <p>
                Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen Kommunikation, die über diverse Kanäle, wie z.B. E-Mail, Telefon,
                Post oder Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.
            </p>
            <p>
                Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen oder der werblichen Kommunikation jederzeit zu
                widersprechen.
            </p>
            <p>
                Nach Widerruf oder Widerspruch speichern wir die zum Nachweis der bisherigen Berechtigung erforderlichen Daten zur Kontaktaufnahme
                oder Zusendung bis zu drei Jahre nach Ablauf des Jahres des Widerrufs oder Widerspruchs auf der Grundlage unserer berechtigten
                Interessen. Die Verarbeitung dieser Daten ist auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Auf der Grundlage des
                berechtigten Interesses, den Widerruf bzw. Widerspruch der Nutzer dauerhaft zu beachten, speichern wir ferner die zur Vermeidung einer
                erneuten Kontaktaufnahme erforderlichen Daten (z.B. je nach Kommunikationskanal die E-Mail-Adresse, Telefonnummer, Name).
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern).
                </li>
                <li>
                    <strong>Betroffene Personen:</strong> Kommunikationspartner.
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder postalisch).
                </li>
                <li>
                    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                    lit. f. DSGVO).
                </li>
            </ul>
            <h2 id='m237'>Gewinnspiele und Wettbewerbe</h2>
            <p>
                Wir verarbeiten personenbezogene Daten der Teilnehmer von Gewinnspielen und Wettbewerben nur unter Einhaltung der einschlägigen
                Datenschutzbestimmungen, soweit die Verarbeitung zur Bereitstellung, Durchführung und Abwicklung des Gewinnspiels vertraglich
                erforderlich ist, die Teilnehmer in die Verarbeitung eingewilligt haben oder die Verarbeitung unseren berechtigten Interessen dient
                (z.B. an der Sicherheit des Gewinnspiels oder dem Schutz unserer Interessen vor Missbrauch durch mögliche Erfassung von IP-Adressen
                bei Einreichung von Gewinnspielbeiträgen).
            </p>
            <p>
                Falls im Rahmen der Gewinnspiele Beiträge der Teilnehmer veröffentlicht werden (z.B. im Rahmen einer Abstimmung oder Präsentation der
                Gewinnspielbeiträge bzw. der Gewinner oder der Berichterstattung zum Gewinnspiel), weisen wir darauf hin, dass die Namen der
                Teilnehmer in diesem Zusammenhang ebenfalls veröffentlicht werden können. Die Teilnehmer können dem jederzeit widersprechen.
            </p>
            <p>
                Findet das Gewinnspiel innerhalb einer Online-Plattform oder eines sozialen Netzwerks (z.B. Facebook oder Instagram, nachfolgend
                bezeichnet als "Online-Plattform") statt, gelten zusätzlich die Nutzungs- und Datenschutzbestimmungen der jeweiligen Plattformen. In
                diesen Fällen weisen wir darauf hin, dass wir für die im Rahmen des Gewinnspiels mitgeteilten Angaben der Teilnehmer verantwortlich
                sind und Anfragen im Hinblick auf das Gewinnspiel an uns zu richten sind.
            </p>
            <p>
                Sofern Daten im Rahmen des Gewinnspiels auch zu anderen Zwecken erhoben wurden, richten sich deren Verarbeitung und die
                Aufbewahrungsdauer nach den Datenschutzhinweisen zu dieser Nutzung (z.B. im Fall einer Anmeldung zum Newsletter im Rahmen eines
                Gewinnspiels).
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Inhaltsdaten (z.B. Eingaben in Onlineformularen).
                </li>
                <li>
                    <strong>Betroffene Personen:</strong> Gewinnspiel- und Wettbewerbsteilnehmer.
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung:</strong> Durchführung von Gewinnspielen und Wettbewerben.
                </li>
                <li>
                    <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
                </li>
            </ul>
            <h2 id='m263'>Webanalyse, Monitoring und Optimierung</h2>
            <p>
                Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der Auswertung der Besucherströme unseres Onlineangebotes und kann
                Verhalten, Interessen oder demographische Informationen zu den Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme Werte
                umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen oder
                Inhalte am häufigsten genutzt werden oder zur Wiederverwendung einladen. Ebenso können wir nachvollziehen, welche Bereiche der
                Optimierung bedürfen.{' '}
            </p>
            <p>
                Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B. unterschiedliche Versionen unseres Onlineangebotes oder seiner
                Bestandteile zu testen und optimieren.
            </p>
            <p>
                Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken Profile, d.h. zu einem Nutzungsvorgang zusammengefasste Daten
                angelegt und Informationen in einem Browser, bzw. in einem Endgerät gespeichert und aus diesem ausgelesen werden. Zu den erhobenen
                Angaben gehören insbesondere besuchte Webseiten und dort genutzte Elemente sowie technische Angaben, wie der verwendete Browser, das
                verwendete Computersystem sowie Angaben zu Nutzungszeiten. Sofern Nutzer in die Erhebung ihrer Standortdaten uns gegenüber oder
                gegenüber den Anbietern der von uns eingesetzten Dienste einverstanden erklärt haben, können auch Standortdaten verarbeitet werden.
            </p>
            <p>
                Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch
                Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von Webanalyse, A/B-Testings und Optimierung keine
                Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der
                eingesetzten Software kennen nicht die tatsächliche Identität der Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in deren
                Profilen gespeicherten Angaben.
            </p>
            <p>
                <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter bitten,
                ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer
                berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
                Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
                    Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                </li>
                <li>
                    <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li>
                    <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher);
                    Profile mit nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
                </li>
                <li>
                    <strong>Sicherheitsmassnahmen:</strong> IP-Masking (Pseudonymisierung der IP-Adresse).
                </li>
                <li>
                    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                    lit. f. DSGVO).
                </li>
            </ul>
            <p>
                <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
            </p>
            <ul className='m-elements'>
                <li>
                    <strong>Google Analytics: </strong>Webanalyse, Reichweitenmessung sowie Messung von Nutzerströmen;{' '}
                    <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google
                    LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; <strong>Website:</strong>{' '}
                    <a href='https://marketingplatform.google.com/intl/de/about/analytics/' target='_blank' rel='noreferrer'>
                        https://marketingplatform.google.com/intl/de/about/analytics/
                    </a>
                    ; <strong>Datenschutzerklärung:</strong>
                    <a href='https://policies.google.com/privacy' target='_blank' rel='noreferrer'>
                        https://policies.google.com/privacy
                    </a>
                    ; <strong>Auftragsverarbeitungsvertrag:</strong>
                    <a href='https://business.safety.google/adsprocessorterms' target='_blank' rel='noreferrer'>
                        https://business.safety.google/adsprocessorterms
                    </a>
                    ; <strong>Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):</strong>{' '}
                    <a href='https://business.safety.google/adsprocessorterms' target='_blank' rel='noreferrer'>
                        https://business.safety.google/adsprocessorterms
                    </a>
                    ; <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Opt-Out-Plugin:{' '}
                    <a href='https://tools.google.com/dlpage/gaoptout?hl=de' target='_blank' rel='noreferrer'>
                        https://tools.google.com/dlpage/gaoptout?hl=de
                    </a>
                    , Einstellungen für die Darstellung von Werbeeinblendungen:{' '}
                    <a href='https://adssettings.google.com/authenticated' target='_blank' rel='noreferrer'>
                        https://adssettings.google.com/authenticated
                    </a>
                    ; <strong>Weitere Informationen:</strong>{' '}
                    <a href='https://privacy.google.com/businesses/adsservices' target='_blank' rel='noreferrer'>
                        https://privacy.google.com/businesses/adsservices
                    </a>{' '}
                    (Arten der Verarbeitung sowie der verarbeiteten Daten).
                </li>
            </ul>
            <h2 id='m15'>Änderung und Aktualisierung der Datenschutzerklärung</h2>
            <p>
                Wir bitten Sie, sich regelmässig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an,
                sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die
                Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.
            </p>
            <p>
                Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben, bitten wir zu
                beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
            </p>
            <h2 id='m10'>Rechte der betroffenen Personen</h2>
            <p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:</p>
            <ul>
                <li>
                    <strong>
                        Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
                        Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                        Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
                        personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                        Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                        Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
                    </strong>
                </li>
                <li>
                    <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
                </li>
                <li>
                    <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
                    werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen
                    Vorgaben.
                </li>
                <li>
                    <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
                    betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
                </li>
                <li>
                    <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Massgabe der gesetzlichen Vorgaben das
                    Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Massgabe der gesetzlichen
                    Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
                </li>
                <li>
                    <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben,
                    nach Massgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren
                    Übermittlung an einen anderen Verantwortlichen zu fordern.
                </li>
                <li>
                    <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                    gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                    gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmasslichen Verstosses, wenn Sie der Ansicht sind, dass die
                    Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO verstösst.
                </li>
            </ul>
            <h2 id='m42'>Begriffsdefinitionen</h2>
            <p>
                In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der
                Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die gesetzlichen Definitionen sind verbindlich. Die
                nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch sortiert.
            </p>
            <ul className='glossary'>
                <li>
                    <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder
                    identifizierbare natürliche Person (im Folgenden "betroffene Person") beziehen; als identifizierbar wird eine natürliche Person
                    angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
                    Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann,
                    die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
                    dieser natürlichen Person sind.
                </li>
                <li>
                    <strong>Profile mit nutzerbezogenen Informationen:</strong> Die Verarbeitung von "Profilen mit nutzerbezogenen Informationen",
                    bzw. kurz "Profilen" umfasst jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese
                    personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen (je nach
                    Art der Profilbildung können dazu unterschiedliche Informationen betreffend die Demographie, Verhalten und Interessen, wie z.B.
                    die Interaktion mit Webseiten und deren Inhalten, etc.) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die
                    Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf einer Webseite oder den Aufenthaltsort). Zu Zwecken des
                    Profilings werden häufig Cookies und Web-Beacons eingesetzt.
                </li>
                <li>
                    <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web Analytics bezeichnet) dient der Auswertung der
                    Besucherströme eines Onlineangebotes und kann das Verhalten oder Interessen der Besucher an bestimmten Informationen, wie z.B.
                    Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu welcher Zeit Besucher
                    ihre Webseite besuchen und für welche Inhalte sie sich interessieren. Dadurch können sie z.B. die Inhalte der Webseite besser an
                    die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons
                    eingesetzt, um wiederkehrende Besucher zu erkennen und so genauere Analysen zur Nutzung eines Onlineangebotes zu erhalten.
                </li>
                <li>
                    <strong>Verantwortlicher:</strong> Als "Verantwortlicher" wird die natürliche oder juristische Person, Behörde, Einrichtung oder
                    andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                    entscheidet, bezeichnet.
                </li>
                <li>
                    <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder
                    jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang
                    mit Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.
                </li>
            </ul>
        </div>
    );
}

export default Privacy;
