import React, { useEffect } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import './errorModal.scss';

function ErrorModal(props: {
    open: boolean;
    setOpen: (state: boolean) => void;
    title: string;
    description: string;
    action?: () => void;
    positiveLabel?: string;
    negativeLabel?: string;
}) {
    useEffect(() => {
        props.setOpen(props.open);
    }, [props]);

    function handleAction() {
        props.setOpen(false);
        props.action && props.action();
    }

    return (
        <Modal basic onClose={() => props.setOpen(false)} open={props.open} size='tiny' className='error-modal information-modal'>
            <Modal.Content>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => props.setOpen(false)}>
                    <Icon name='remove' /> {props.negativeLabel ? props.negativeLabel : 'Abbrechen'}
                </Button>
                {props.action && (
                    <Button className='secondary' onClick={() => handleAction()}>
                        <Icon name='pencil' /> {props.positiveLabel ? props.positiveLabel : 'Bearbeiten'}
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
}

export default ErrorModal;
