import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './players.scss';
import Context from '../../Context/context';
import { createChallengeTipAction, fetchActiveGameWithTipsAction } from '../../actions/gameActions';
import PlayerList from '../../components/PlayerList/PlayerList';
import { IPlayer } from '../../ressources/types/player';
import { createIPlayers } from '../../utils/playerHelpers';
import useAction from '../../hooks/useAction';
import { getChallengeApplicationById } from '../../utils/gameHelpers';
import CustomLoader from '../../components/CustomLoader/CustomLoader';
import useAsync from '../../hooks/useAsync';
import { ActionTypes } from '../../ressources/types/state';

function Players() {
    const { state, dispatch } = useContext(Context);
    const [players, setPlayers] = useState<IPlayer[]>([]);
    const [selectedPlayer, setSelectedPlayer] = useState<string>();
    const [selecting, setSelecting] = useState(false);
    const { challengeId } = useParams();
    const navigate = useNavigate();

    const { loading } = useAction({ action: fetchActiveGameWithTipsAction, dispatch, condition: !state.activeGame && !!state.user });

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (state.activeGame) {
            const challenge = getChallengeApplicationById(state.activeGame, challengeId);
            if (challenge?.challengeTips?.items.length) {
                setSelectedPlayer(challenge.challengeTips.items[0]?.answer);
            }
        }
    }, [state.activeGame]);

    useEffect(() => {
        if (haveImagesLoaded() && !players.length) {
            setPlayers(createIPlayers(state.activeGame?.lineup?.items, state.imageSources));
        }
    }, [state.activeGame?.lineup, state.imageSources]);

    async function onPlayerClick(player: IPlayer) {
        setSelecting(true);
        challengeId && (await execute(() => createChallengeTipAction(dispatch, challengeId, player.id)));
        navigate(`/game/challenge/${challengeId}`, { replace: true });
    }

    function haveImagesLoaded() {
        return state.imageSources.gamePlayers.length === state.activeGame?.lineup?.items.length;
    }

    if (loading || selecting) {
        return <CustomLoader label={selecting ? 'Auswahl wird gespeichert...' : 'Spieler werden geladen...'} />;
    }

    return (
        <>
            <h1>Spielerwahl</h1>
            {state.activeGame?.lineup?.items.length ? (
                <PlayerList
                    onPlayerClick={onPlayerClick}
                    players={players}
                    selectedPlayerIds={selectedPlayer ? [selectedPlayer] : []}
                    nobodyEnabled
                    groupByPositions={true}
                />
            ) : (
                <p className='placeholder-text'>Keine Spieler zur Auswahl</p>
            )}
        </>
    );
}

export default Players;
