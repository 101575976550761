import { useContext, useEffect, useState } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import {
    createChallengeRewardApplicationAction,
    deleteChallengeRewardApplicationAction,
    fetchRewardsAction,
    updateChallengeRewardApplicationAction,
} from '../../../actions/rewardActions';
import { ChallengeApplication as ChallengeApplicationType, ChallengeRewardApplication } from '../../../API';
import Context from '../../../Context/context';
import useAsync from '../../../hooks/useAsync';
import { ChallengeApplicationState, RewardSelection } from '../../../ressources/types/game';
import { ActionTypes } from '../../../ressources/types/state';
import { getChallengeApplicationState, saveRewardChanges } from '../../../utils/gameHelpers';
import SetupRewardsModalList from '../SetupRewardsModal/SetupRewardsModalList';

interface ChallengeApplicationModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
    challenge: ChallengeApplicationType;
}

function ChallengeApplicationModal(props: ChallengeApplicationModalProps) {
    const { state, dispatch } = useContext(Context);
    const [selectedRewards, setSelectedRewards] = useState<RewardSelection[]>([]);
    const [loading, setLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (!state.rewards) {
            execute(() => fetchRewardsAction(dispatch));
        } else {
            setSelectedRewards(getCurrentRewards());
        }
    }, [state.rewards]);

    function getRewardSelection(rewardApplications: ChallengeRewardApplication[]) {
        return rewardApplications.map((rewardApplication) => {
            return {
                rewardId: rewardApplication.rewardId,
                rewardApplicationId: rewardApplication.id,
                order: rewardApplication.order || 1,
                amount: rewardApplication.amount || 1,
            };
        });
    }

    function getCurrentRewards() {
        if (props.challenge.rewards) {
            return getRewardSelection(
                props.challenge.rewards.items.flatMap((reward) => {
                    return reward || [];
                })
            );
        }
        return [];
    }

    async function handleSave() {
        setLoading(true);
        await execute(() =>
            saveRewardChanges(
                getCurrentRewards(),
                selectedRewards,
                props.challenge.id,
                createChallengeRewardApplicationAction,
                updateChallengeRewardApplicationAction,
                deleteChallengeRewardApplicationAction,
                dispatch
            )
        );
        setLoading(false);
        props.setOpen(false);
    }

    return (
        <Modal className='edit-rewards-modal list-modal' onClose={() => props.setOpen(false)} onOpen={() => props.setOpen(true)} open={props.open}>
            <Modal.Header>Challenge konfigurieren</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <Header>Details</Header>
                    <p>Frage: {props.challenge.challenge.question}</p>
                    <p>Punkte: {props.challenge.challenge.points}</p>
                    <Header>Sponsor</Header>
                    <p>{props.challenge.sponsor?.name || 'keiner'}</p>
                    <Header>Preise</Header>
                    {state.rewards && (
                        <SetupRewardsModalList
                            disabled={getChallengeApplicationState(props.challenge) === ChallengeApplicationState.RESOLVED}
                            selectedOnly={getChallengeApplicationState(props.challenge) === ChallengeApplicationState.RESOLVED}
                            rewards={state.rewards}
                            selectedRewards={selectedRewards}
                            setSelectedRewards={setSelectedRewards}
                        />
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)}>Abbrechen</Button>
                <Button loading={loading} className='secondary' onClick={() => handleSave()}>
                    Speichern
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ChallengeApplicationModal;
