import { Sponsor } from '../API';
import { AppState } from '../ressources/types/state';

export function getSponsor<T>(state: AppState, challenge: T & { sponsorId?: string | null; sponsor?: Sponsor | null }) {
    if (challenge.sponsor) {
        return {
            ...challenge.sponsor,
            image: state.imageSources.challengeSponsors.find((ch) => ch.sponsorId === challenge.sponsorId)?.url,
        };
    }

    if (state.activeGame?.sponsor) {
        return { ...state.activeGame.sponsor, image: state.imageSources.gameSponsor };
    }
}
