import React, { useContext } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Context from '../../Context/context';
import './ranking.scss';
import { fetchRankingAction } from '../../actions/userActions';
import { Profile, ProfileVisibility } from '../../API';
import useAction from '../../hooks/useAction';
import { getNickname, isSuperFan } from '../../utils/userHelpers';
import CustomLoader from '../../components/CustomLoader/CustomLoader';

function Ranking() {
    const { state, dispatch } = useContext(Context);

    const { loading } = useAction({ action: fetchRankingAction, dispatch, condition: state.user && !state.ranking });

    if (loading || !state.ranking) {
        return <CustomLoader label='Rangliste wird geladen...' />;
    }

    function isHighlighted(profile: Profile) {
        let classes = '';
        if (profile.id === state.user?.profile?.id) {
            classes += ' highlighted';
        }
        if (profile.nickname && isSuperFan(profile.nickname)) {
            classes += ' superfan';
        }
        return classes;
    }

    function renderRank(rank: number) {
        if (rank === 1 || rank === 2 || rank === 3) {
            return <Icon name='trophy' className={`trophy-${rank}`} />;
        }
        return rank || '-';
    }

    function renderProfileInTable(profile: Profile) {
        return (
            <Table.Row key={profile.id} className={`ranking-item ${isHighlighted(profile)}`}>
                <Table.Cell>{renderRank(profile.rank || 0)}</Table.Cell>
                <Table.Cell>{profile.nickname && getNickname(profile.nickname)}</Table.Cell>
                <Table.Cell>{profile.score}</Table.Cell>
            </Table.Row>
        );
    }

    return (
        <div className='ranking'>
            <h1>Öffentliche Rangliste</h1>
            <h2>Gesamte Saison </h2>
            {state.ranking ? (
                <>
                    <Table unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Rang</Table.HeaderCell>
                                <Table.HeaderCell>Benutzername</Table.HeaderCell>
                                <Table.HeaderCell>Punkte</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {state.ranking.map((profile: Profile) => renderProfileInTable(profile))}
                            {state.user?.profile &&
                                !state.ranking.find((profile) => profile.id === state.user?.profile?.id) &&
                                renderProfileInTable(state.user.profile)}
                        </Table.Body>
                    </Table>
                    {state.user?.profile?.visibility === ProfileVisibility.private && (
                        <p>
                            Um dein Ranking in der Öffentlichen Rangliste zu sehen, ändere deine{' '}
                            <Link to='/user/settings'>Benutzereinstellungen</Link>.
                        </p>
                    )}
                </>
            ) : (
                <p>Beim Laden der Daten ist ein Fehler aufgetreten.</p>
            )}
        </div>
    );
}

export default Ranking;
