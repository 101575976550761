import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { fetchIssuedRewardsAction } from '../../actions/rewardActions';
import { IssuedReward } from '../../API';
import Card from '../../components/Card/Card';
import Context from '../../Context/context';
import { getAvailableRewards, getRedeemedRewards } from '../../utils/rewardHelpers';
import useAction from '../../hooks/useAction';
import { isAnonymous } from '../../utils/userHelpers';
import CustomLoader from '../../components/CustomLoader/CustomLoader';
import PricePoolLink from '../../components/PricePoolLink/PricePoolLink';
import './rewards.scss';
import { setChallengeSponsorUrl } from '../../actions/sponsorActions';
import { getSponsor } from '../../utils/sponsorHelpers';

function Rewards() {
    const { state, dispatch } = useContext(Context);
    const [issuedRewards, setIssuedRewards] = useState<IssuedReward[]>();
    const [redeemedRewards, setRedeemedRewards] = useState<IssuedReward[]>();

    const { loading } = useAction({
        action: fetchIssuedRewardsAction,
        dispatch,
        condition: !state.issuedRewards && state.user && !isAnonymous(state.user),
    });

    useEffect(() => {
        issuedRewards?.forEach((issuedReward) => {
            if (
                issuedReward.reward?.sponsor &&
                !state.imageSources.challengeSponsors.find((challenge2) => challenge2.sponsorId === issuedReward.reward.sponsorId)?.url
            ) {
                setChallengeSponsorUrl(dispatch, issuedReward.reward.sponsor);
            }
        });
    }, [issuedRewards]);

    useEffect(() => {
        setIssuedRewards(state.issuedRewards ? getAvailableRewards(state.issuedRewards) : []);
        setRedeemedRewards(state.issuedRewards ? getRedeemedRewards(state.issuedRewards) : []);
    }, [state.issuedRewards]);

    if (loading) {
        return <CustomLoader label='Preise werden geladen...' />;
    }

    function renderIssuedReward(reward: IssuedReward, btnLabel: string) {
        return (
            <div className='rewards' key={reward.id}>
                {reward.reward.name && (
                    <Card
                        title={reward.reward.name}
                        btnLabel={btnLabel}
                        centerIcon='gift'
                        target={`/rewards/${reward.id}`}
                        sponsorImageUrl={getSponsor(state, reward.reward)?.image}
                    />
                )}
            </div>
        );
    }

    function renderRewards(rewards: IssuedReward[], placeholderText: string, btnLabel: string) {
        if (rewards.length) {
            return rewards.map((reward: IssuedReward) => {
                return renderIssuedReward(reward, btnLabel);
            });
        }
        return <p className='placeholder-text'>{placeholderText}</p>;
    }

    function renderIssuedRewards() {
        if (state.issuedRewards?.length) {
            return (
                <>
                    <h2 id='open' className='divider'>
                        Offen
                    </h2>
                    {issuedRewards && renderRewards(issuedRewards, 'Du hast keine einzulösenden Preise.', 'Einlösen')}
                    <h2 id='redeemed' className='divider'>
                        Eingelöst
                    </h2>
                    {redeemedRewards && renderRewards(redeemedRewards, 'Du hast noch keine Preise eingelöst.', 'Anzeigen')}
                </>
            );
        }
        return <p className='placeholder-text'>Du hast noch keine Preise gewonnen.</p>;
    }

    return (
        <>
            <h1>Meine Preise</h1>
            {state.activeGame?.rewards && <PricePoolLink />}
            {state.user && !isAnonymous(state.user) ? (
                <>{renderIssuedRewards()}</>
            ) : (
                <p className='placeholder-text'>
                    Um Preise gewinnen zu können, musst du <Link to={'/user'}>angemeldet</Link> sein.
                </p>
            )}
        </>
    );
}

export default Rewards;
