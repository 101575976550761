import React from 'react';

function About() {
    return (
        <div id='terms-and-conditions' className='text-content'>
            <h1>Entwicklung</h1>
            <h2>Geschichte</h2>
            <p>
                Es waren einmal drei tüchtige Informatikstudenten an der HSR. Die hatten sich für eine Bachelorarbeit zu entscheiden und da war dieses
                eine vorgestellte Projekt in Zusammenarbeit mit den SCRJ Lakers. "Abstimmungstool für online Bestplayer-Wahl" oder die Idee-Nr 35
                haben sie es genannt - ein skurriler Projekttitel wenn man bedenkt, was daraus schlussendlich geworden ist 😁. Natürlich gab es auch
                noch viele andere Projekte aber naja, die haben die drei Informatiker halt nicht mehr sonderlich interessiert 🥱. Ein spannend
                klingendes Projekt und eine gute Sache für die Lakers, was will man mehr als gestandener Lakers Fan.
            </p>
            <p>
                Das Abenteuer begann Ende Februar 2022. Voller Enthusiasmus wurden erste Ideen eingebracht, diskutiert und Pläne geschmiedet. Ein
                Tippspiel soll man spielen können, ohne grosse Hürden haben sie gesagt. Die besten sollen auch Preise gewinnen können ☑️ und ja
                natürlich für etwas Werbung soll es auch noch Platz haben. Ideen gab es genug, aber die Zeit für alles war einfach sehr knapp. Darum
                wurde priorisiert. Was kann man alles in diesem Zeitraum wirklich umsetzen, was geht eher nicht. Was darf auf keinen Fall fehlen.
                Schliesslich stand ein Masterplan und dann wurde fleissig in die Tasten gehauen.
            </p>
            <p>
                Woche für Woche, Tag für Tag wurde gerackert und gekämpft, wurden neue Funktionen hinzugefügt und bestehende umgebaut, wurden Fehler
                behoben und neue geschaffen, immer mit dem Ziel, die App noch ein weniger besser, da noch ein wenig stabiler und dort noch ein wenig
                intuitiver, zu machen. Innert den nächsten 17 Wochen flossen viel 🍺, Schweiss und 🩸. Aber keine Sorge, es geht mittlerweile allen
                wieder gut 😜. Dabei waren Ausdrücke wie "What the f***, wieso funktioniert das nöd?!?" oder "🤬, wie söll das gah!" keine Seltenheit.
                Aber auch erfreulichere Kommentare wie "Gömmer ga beache?", oder "Wer chunt an See?" sind besonders zur wärmeren Jahreszeit immer
                öfters gefallen. So ging die Zeit vorüber und am Ende stand es, das Tippspiel wie du es kennengelernt und hoffentlich lieben gelernt
                hast.
            </p>
            <p>
                Ich hoffe, wir konnten dir einen Eindruck geben, was hinter den Kulissen in letzter Zeit alles abgelaufen ist und welche Motivation
                hinter dem Projekt steckt. Natürlich gibt es noch vieles zu verbessern und optimieren, aber wir hoffen, dass wird dir bereits jetzt
                eine Freude machen konnten.
            </p>
            <h2>Unterstützung</h2>
            <p>
                Die Applikation konnte nur durch die tatkräftige Unterstützung unseres Betreuers und einige Lakers Mitarbeiter realisiert werden.
                Ihrem Einsatz gebührt ein grosses Dankeschön 👏.
            </p>
            <p>
                Des weiteren konnte auf viele bestehenden Open Source Lösungen aufgebaut werden, was die Entwicklung massiv vereinfacht und
                beschleunigt hat. Ohne diese wäre das Tippspiel heute nicht dort, wo es heute steht. Namentlich zu erwähnen sind ...
            </p>
            <ul>
                <li>
                    <a href='https://www.typescriptlang.org/'>TypeScript</a>
                </li>
                <li>
                    <a href='https://reactjs.org/'>React</a>
                </li>
                <li>
                    <a href='https://semantic-ui.com/'>Semantic UI</a>
                </li>
                <li>
                    <a href='https://graphql.org/'>GraphQL</a>
                </li>
                <li>
                    <a href='https://jestjs.io/'>Jest</a>
                </li>
                <li>
                    <a href='https://github.com/colinhacks/zod'>Zod</a>
                </li>
                <li>
                    <a href='https://www.npmjs.com/package/dotenv'>Dotenv</a>
                </li>
                <li>
                    <a href='https://webpack.js.org/'>Webpack</a>
                </li>
            </ul>
            <p>... und viele weitere. Vielen Dank, dass wir diese Vorarbeiten nutzen konnten 🙏.</p>
            <h2>Mit ❤️ 🤍💙, das Entwicklerteam</h2>
            <p>
                Joel Schaltegger <br />
                Philipp Emmenegger <br />
                Simon Hager
            </p>
        </div>
    );
}

export default About;
