import { Dispatch } from 'react';
import { Challenge, ChallengeApplication, ChallengeTip, Game, GamePlayer, IssuedReward, Reward, RewardApplication } from '../API';
import { ChallengeApplicationState, ChallengeSelection, RewardSelection } from '../ressources/types/game';
import { nobody } from '../ressources/types/player';
import { AppStateAction } from '../ressources/types/state';
import { StyleMood } from './styleMood';

const timeServerVarianceInMilliSeconds = 5000;

export function getActiveGame(games: Game[]) {
    for (const game of games) {
        if (game.startTimestamp && (!game.endTimestamp || Date.parse(game.endTimestamp) > Date.now())) {
            return game;
        }
    }
}

export function getChallengeApplicationState(challenge: ChallengeApplication) {
    if (challenge?.solution) {
        return ChallengeApplicationState.RESOLVED;
    }
    if (challenge?.endTimestamp && Date.parse(challenge.endTimestamp) <= Date.now() + timeServerVarianceInMilliSeconds) {
        return ChallengeApplicationState.CLOSED;
    }
    if (challenge?.startTimestamp && Date.parse(challenge.startTimestamp) <= Date.now() + timeServerVarianceInMilliSeconds) {
        return ChallengeApplicationState.OPEN;
    }
    return ChallengeApplicationState.HIDDEN;
}

export function getChallengeApplicationByState(challengeApplications: (ChallengeApplication | null)[], state: ChallengeApplicationState) {
    const challengeList: ChallengeApplication[] = [];
    challengeApplications.forEach((challenge) => {
        if (challenge && getChallengeApplicationState(challenge) === state) {
            challengeList.push(challenge);
        }
    });
    return challengeList;
}

export function isOpenChallenge(challengeApplication: ChallengeApplication) {
    return getChallengeApplicationState(challengeApplication) === ChallengeApplicationState.OPEN;
}

export function isClosedChallenge(challengeApplication: ChallengeApplication) {
    return getChallengeApplicationState(challengeApplication) === ChallengeApplicationState.CLOSED;
}

export function isResolvedChallenge(challengeApplication: ChallengeApplication) {
    return getChallengeApplicationState(challengeApplication) === ChallengeApplicationState.RESOLVED;
}

export function getHiddenChallengeApplications(challengeApplications: (ChallengeApplication | null)[]) {
    return getChallengeApplicationByState(challengeApplications, ChallengeApplicationState.HIDDEN);
}

export function getOpenChallengeApplications(challengeApplications: (ChallengeApplication | null)[]) {
    return sortChallengeApplicationsByStartTimestamp(getChallengeApplicationByState(challengeApplications, ChallengeApplicationState.OPEN), 1);
}

export function getClosedChallengeApplications(challengeApplications: (ChallengeApplication | null)[]) {
    return sortChallengeApplicationsByEndTimestamp(getChallengeApplicationByState(challengeApplications, ChallengeApplicationState.CLOSED), 1);
}

export function getResolvedChallengeApplications(challengeApplications: (ChallengeApplication | null)[]) {
    return sortChallengeApplicationsByEndTimestamp(getChallengeApplicationByState(challengeApplications, ChallengeApplicationState.RESOLVED), 1);
}

export function sortChallengeApplicationsByStartTimestamp(challengeApplications: ChallengeApplication[], direction: number) {
    return challengeApplications.sort((a, b) =>
        a.startTimestamp && b.startTimestamp && Date.parse(a.startTimestamp) <= Date.parse(b.startTimestamp) ? direction : -direction
    );
}

export function sortChallengeApplicationsByEndTimestamp(challengeApplications: ChallengeApplication[], direction: number) {
    return challengeApplications.sort((a, b) =>
        a.endTimestamp && b.endTimestamp && Date.parse(a.endTimestamp) <= Date.parse(b.endTimestamp) ? direction : -direction
    );
}

export function updateChallengeApplication(game: Game, updatedChallengeApplication?: ChallengeApplication) {
    game.challenges?.items.forEach((challengeApplication, index, challengeApplications) => {
        if (challengeApplication && challengeApplication.id === updatedChallengeApplication?.id) {
            const challengeTips = challengeApplications[index]?.challengeTips;
            updatedChallengeApplication.challengeTips = challengeTips;
            challengeApplications[index] = updatedChallengeApplication;
        }
    });
    return game;
}

export function setChallengeTip(game: Game, setChallengeTip?: ChallengeTip) {
    game.challenges?.items.forEach((challengeApplication, index, challengeApplications) => {
        if (challengeApplication && challengeApplication.id === setChallengeTip?.challengeApplicationId) {
            challengeApplication.challengeTips = {
                __typename: 'ModelChallengeTipConnection',
                items: [{ ...setChallengeTip }],
            };
            challengeApplications[index] = challengeApplication;
        }
    });
    return game;
}

export function updateChallenge(challenges: Challenge[], updatedChallenge: Challenge) {
    challenges?.forEach((challenge, index, challenges) => {
        if (challenge && challenge.id === updatedChallenge.id) {
            challenges[index] = updatedChallenge;
        }
    });
    return challenges;
}

export function addChallengeApplication(game: Game, challengeApplication: ChallengeApplication) {
    if (game.challenges?.items && !game.challenges.items.includes(challengeApplication)) {
        game.challenges.items.push(challengeApplication);
    }
    return game;
}

export function deleteChallengeApplication(game: Game, challengeApplication: ChallengeApplication) {
    if (game.challenges?.items) {
        game.challenges.items = game.challenges.items.filter(
            (currentChallengeApplication) => currentChallengeApplication?.id !== challengeApplication?.id
        );
    }
    return game;
}

export function addGamePlayer(game: Game, gamePlayer: GamePlayer) {
    if (game.lineup?.items && !game.lineup.items.includes(gamePlayer)) {
        game.lineup.items.push(gamePlayer);
    }
    return game;
}

export function deleteGamePlayer(game: Game, gamePlayer: GamePlayer) {
    if (game.lineup?.items) {
        game.lineup.items = game.lineup.items.filter((currentGamePlayer) => currentGamePlayer?.id !== gamePlayer?.id);
    }
    return game;
}

/* eslint-disable no-unused-vars */
export enum TipResultLevel {
    Wrong,
    Partial,
    Exact,
}

export function getCardMood(challengeState: ChallengeApplicationState, challenge?: ChallengeApplication) {
    if (challengeState === ChallengeApplicationState.OPEN || challengeState === ChallengeApplicationState.CLOSED) {
        return StyleMood.neutral;
    }

    if (challengeState === ChallengeApplicationState.RESOLVED && !challenge?.challengeTips?.items[0]) {
        return StyleMood.negative;
    }

    if (!challenge || !challenge?.challengeTips?.items[0]) {
        return StyleMood.neutral;
    }

    const level = getTipResultLevel(challenge, challenge?.challengeTips?.items[0]);
    switch (level) {
        case TipResultLevel.Exact:
            return StyleMood.positive;
        case TipResultLevel.Partial:
            return StyleMood.partialPositive;
        case TipResultLevel.Wrong:
            return StyleMood.negative;
    }
}

export function getStateIcon(challengeState: ChallengeApplicationState, challenge?: ChallengeApplication) {
    switch (challengeState) {
        case ChallengeApplicationState.OPEN:
            return 'lock open';
        case ChallengeApplicationState.CLOSED:
            return 'lock';
        case ChallengeApplicationState.RESOLVED:
            if (!challenge?.challengeTips?.items[0]) {
                return 'close';
            }

            return getResolvedStateIcon(getTipResultLevel(challenge, challenge?.challengeTips?.items[0])); // === TipResultLevel.Wrong ? 'close' : 'check';
    }
}

function getResolvedStateIcon(tipResultLevel: TipResultLevel) {
    switch (tipResultLevel) {
        case TipResultLevel.Wrong:
            return 'close';
        case TipResultLevel.Partial:
            return 'check';
        case TipResultLevel.Exact:
            return 'check';
    }
}

export function getTipResultLevel(challenge: ChallengeApplication, challengeTip: ChallengeTip) {
    const { points, answer } = challengeTip;

    if (!points || points === 0) {
        return TipResultLevel.Wrong;
    }

    if (points === challenge.challenge.points && answer === challenge.solution) {
        return TipResultLevel.Exact;
    }

    return TipResultLevel.Partial;
}

export function getChallengeApplicationById(game: Game, id?: string) {
    if (!id) return undefined;
    return game.challenges?.items.find((challenge) => challenge?.id === id);
}

export function getChallengeTipAnswer(challenge: ChallengeApplication) {
    return challenge.challengeTips?.items[0]?.answer ?? '';
}

export function getChallengeTipNumber(challenge: ChallengeApplication, players: (GamePlayer | null)[]) {
    if (challenge.challengeTips?.items[0]) {
        if (challenge.challengeTips?.items[0].answer === nobody.id) {
            return 'Niemand';
        }
        const player = players.find((gamePlayer) => gamePlayer && gamePlayer.player.id === challenge.challengeTips?.items[0]?.answer);
        if (player) {
            return `#${player.player.number}`;
        }
    }
    return '';
}

export function addIssuedReward(issuedRewards: IssuedReward[], issuedReward: IssuedReward) {
    if (!issuedRewards.includes(issuedReward)) {
        issuedRewards.push(issuedReward);
    }
    return issuedRewards;
}

export function updateReward(rewards: Reward[], updatedReward: Reward) {
    rewards?.forEach((reward, index, rewards) => {
        if (reward && reward.id === updatedReward.id) {
            rewards[index] = updatedReward;
        }
    });
    return rewards;
}

export function addRewardApplication(game: Game, rewardApplication: RewardApplication) {
    if (game.rewards?.items && !game.rewards.items.includes(rewardApplication)) {
        game.rewards.items.push(rewardApplication);
    }
    return game;
}

export function updateRewardApplication(game: Game, updatedRewardApplication: RewardApplication) {
    game.rewards?.items.forEach((rewardApplication, index, rewardApplications) => {
        if (rewardApplication && rewardApplication.id === updatedRewardApplication?.id) {
            rewardApplications[index] = updatedRewardApplication;
        }
    });
    return game;
}

export function deleteRewardApplication(game: Game, rewardApplication: RewardApplication) {
    if (game.rewards) {
        game.rewards.items = game.rewards.items.filter((currentRewardApplication) => currentRewardApplication?.id !== rewardApplication?.id);
    }
    return { ...game };
}

export function getChallengeSelection(challengeApplications: ChallengeApplication[]) {
    return challengeApplications.map((challengeApplication) => {
        return {
            challengeId: challengeApplication.challengeId,
            challengeApplicationId: challengeApplication.id,
            sponsorId: challengeApplication.sponsorId || undefined,
        };
    });
}

export function listChallengeApplicationIds(game: Game) {
    const currentChallengeApplications: ChallengeApplication[] = [];
    game.challenges?.items.forEach((challengeApplication) => {
        challengeApplication && currentChallengeApplications.push(challengeApplication);
    });
    return currentChallengeApplications;
}

export function listLineupIds(game: Game) {
    const currentPlayers: { id: string; applicationId: string }[] = [];
    game.lineup?.items.forEach((gamePlayer) => {
        gamePlayer && currentPlayers.push({ id: gamePlayer.player.id, applicationId: gamePlayer.id });
    });
    return currentPlayers;
}

export function listRewardApplications(game: Game) {
    const currentRewardApplications: RewardApplication[] = [];
    game.rewards?.items.forEach((rewardApplication) => {
        rewardApplication && currentRewardApplications.push(rewardApplication);
    });
    return currentRewardApplications;
}

export function hasRewardChanged(current: RewardSelection, previous: RewardSelection) {
    return !(current.rewardId === previous.rewardId && current.order === previous.order && current.amount === previous.amount);
}

export function hasChallengeChanged(current: ChallengeSelection, previous: ChallengeSelection) {
    return !(current.challengeId === previous.challengeId && current.sponsorId === previous.sponsorId);
}

export async function saveChanges(
    previous: { id: string; applicationId: string }[],
    selected: string[],
    gameId: string,
    createAction: (dispatch: Dispatch<AppStateAction>, id: string, gameId: string) => Promise<void>,
    deleteAction: (dispatch: Dispatch<AppStateAction>, id: string) => Promise<void>,
    dispatch: Dispatch<AppStateAction>
) {
    const promises: Promise<void>[] = [];
    const toBeDeleted: string[] = [];
    let toBeAdded: string[] = [];

    toBeAdded = selected;
    previous.forEach(({ id }) => {
        toBeAdded = toBeAdded.filter((addedId) => addedId !== id);
    });

    previous.forEach(({ id, applicationId }) => {
        if (!selected.includes(id)) {
            toBeDeleted.push(applicationId);
        }
    });

    toBeAdded.forEach((id) => {
        promises.push(createAction(dispatch, id, gameId));
    });

    toBeDeleted.forEach((id) => {
        promises.push(deleteAction(dispatch, id));
    });

    await Promise.all(promises);
}

export async function saveChangesChallenge(
    previous: ChallengeSelection[],
    selected: ChallengeSelection[],
    gameId: string,
    createAction: (dispatch: Dispatch<AppStateAction>, selection: ChallengeSelection, gameId: string) => Promise<void>,
    updateAction: (dispatch: Dispatch<AppStateAction>, selection: ChallengeSelection) => Promise<void>,
    deleteAction: (dispatch: Dispatch<AppStateAction>, id: string) => Promise<void>,
    dispatch: Dispatch<AppStateAction>
) {
    const promises: Promise<void>[] = [];
    let toBeAdded: ChallengeSelection[] = [];
    const toBeUpdated: ChallengeSelection[] = [];
    const toBeDeleted: string[] = [];

    toBeAdded = selected;
    previous.forEach(({ challengeId }) => {
        toBeAdded = toBeAdded.filter((challenge) => challenge.challengeId !== challengeId);
    });

    previous.forEach((previousReward) => {
        const selectedChallenge = selected.find((challenge) => challenge.challengeId === previousReward.challengeId);
        if (!selectedChallenge) {
            previousReward.challengeApplicationId && toBeDeleted.push(previousReward.challengeApplicationId);
        } else if (hasChallengeChanged(selectedChallenge, previousReward)) {
            toBeUpdated.push(selectedChallenge);
        }
    });

    toBeAdded.forEach((id) => {
        promises.push(createAction(dispatch, id, gameId));
    });

    toBeUpdated.forEach((challengeSelection) => {
        promises.push(updateAction(dispatch, challengeSelection));
    });

    toBeDeleted.forEach((challengeSelection) => {
        promises.push(deleteAction(dispatch, challengeSelection));
    });

    await Promise.all(promises);
}

export async function saveRewardChanges(
    previous: RewardSelection[],
    selected: RewardSelection[],
    relationId: string,
    createAction: (dispatch: Dispatch<AppStateAction>, selection: RewardSelection, gameId: string) => Promise<void>,
    updateAction: (dispatch: Dispatch<AppStateAction>, selection: RewardSelection) => Promise<void>,
    deleteAction: (dispatch: Dispatch<AppStateAction>, id: string) => Promise<void>,
    dispatch: Dispatch<AppStateAction>
) {
    const promises: Promise<void>[] = [];
    let toBeAdded = selected;
    const toBeUpdated: RewardSelection[] = [];
    const toBeDeleted: string[] = [];

    previous.forEach(({ rewardId }) => {
        toBeAdded = toBeAdded.filter((reward) => reward.rewardId !== rewardId);
    });

    previous.forEach((previousReward) => {
        const selectedReward = selected.find((reward) => reward.rewardId === previousReward.rewardId);
        if (!selectedReward) {
            previousReward.rewardApplicationId && toBeDeleted.push(previousReward.rewardApplicationId);
        } else if (hasRewardChanged(selectedReward, previousReward)) {
            toBeUpdated.push(selectedReward);
        }
    });

    toBeAdded.forEach((rewardSelection) => {
        promises.push(createAction(dispatch, rewardSelection, relationId));
    });

    toBeUpdated.forEach((rewardSelection) => {
        promises.push(updateAction(dispatch, rewardSelection));
    });

    toBeDeleted.forEach((rewardSelection) => {
        promises.push(deleteAction(dispatch, rewardSelection));
    });

    await Promise.all(promises);
}
