import React, { useEffect, useState } from 'react';
import { IPlayer, nobody, Nobody } from '../../ressources/types/player';
import Player from '../Player/Player';
import { PlayerPosition } from '../../API';
import { sortPlayers } from '../../utils/playerHelpers';
import PlayerPlaceholder from '../Player/PlayerPlaceholder/PlayerPlaceholder';
import CustomLoader from '../CustomLoader/CustomLoader';
import './playerList.scss';

interface PlayerListProps {
    onPlayerClick: (player: IPlayer | Nobody) => Promise<void>;
    players: IPlayer[];
    selectedPlayerIds?: string[];
    nobodyEnabled?: boolean;
    groupByPositions?: boolean;
}

function PlayerList(props: PlayerListProps) {
    const [sortedPlayers, setSortedPlayers] = useState<IPlayer[]>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (sortedPlayers) {
            setLoading(false);
        }
        setSortedPlayers(sortPlayers(props.players));
    }, [props.players, sortedPlayers]);

    function renderPlayers(position?: PlayerPosition) {
        const playerList: JSX.Element[] = [];
        sortedPlayers?.forEach((player: IPlayer) => {
            if (!position || player.position === position) {
                playerList.push(<Player key={player.id} action={props.onPlayerClick} player={player} selected={isSelected(player)} />);
            }
        });
        if (playerList.length) {
            return playerList;
        }
        return <p className='placeholder-text'>Keine Spieler zur Auswahl</p>;
    }

    function isSelected(player: IPlayer) {
        return props.selectedPlayerIds?.includes(player.id);
    }

    if (loading) {
        return <CustomLoader label='Spieler werden geladen...' />;
    }

    return (
        <div>
            {props.groupByPositions ? (
                <>
                    <h2 className='divider'>Goalkeeper</h2>
                    <div className='players goalkeepers'>{renderPlayers(PlayerPosition.goalkeeper)}</div>
                    <h2 className='divider'>Verteidiger</h2>
                    <div className='players defense'>{renderPlayers(PlayerPosition.defence)}</div>
                    <h2 className='divider'>Stürmer</h2>
                    <div className='players offense'>{renderPlayers(PlayerPosition.offence)}</div>
                    {props.nobodyEnabled && (
                        <>
                            <h2 className='divider'>Andere</h2>
                            <div className='players others'>
                                <PlayerPlaceholder
                                    placeholderText='Niemand'
                                    onClick={() => props.onPlayerClick(nobody)}
                                    selected={isSelected(nobody)}
                                />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className='players'>
                        {renderPlayers()}
                        {props.nobodyEnabled && (
                            <PlayerPlaceholder placeholderText='Niemand' onClick={() => props.onPlayerClick(nobody)} selected={isSelected(nobody)} />
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default PlayerList;
