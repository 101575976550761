import { useContext, useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { updateGameAction } from '../../../actions/gameActions';
import { fetchActiveSponsorsAction } from '../../../actions/sponsorActions';
import { Game, Sponsor, UpdateGameMutationVariables } from '../../../API';
import Context from '../../../Context/context';
import useAsync from '../../../hooks/useAsync';
import { ActionTypes } from '../../../ressources/types/state';
import './setupSponsorModal.scss';

interface SetupSponsorModalProps {
    open: boolean;
    setOpen: (state: boolean) => void;
}

function SetupSponsorModal(props: SetupSponsorModalProps) {
    const { state, dispatch } = useContext(Context);
    const [selectedSponsor, setSelectedSponsor] = useState<Sponsor | null>();
    const [loading, setLoading] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    useEffect(() => {
        if (!state.sponsors) {
            setLoading(true);
            execute(() => fetchActiveSponsorsAction(dispatch));
        }
        if (state.newGame) getCurrentSponsor(state.newGame);
        if (state.activeGame) getCurrentSponsor(state.activeGame);

        setLoading(false);
    }, [state.sponsors]);

    async function handleSave() {
        setLoading(true);
        const variables: UpdateGameMutationVariables = {
            input: {
                id: state.newGame?.id || state.activeGame?.id || '',
                sponsorId: selectedSponsor?.id || null,
            },
        };

        await execute(() => updateGameAction(dispatch, variables));

        setLoading(false);
        props.setOpen(false);
    }

    function getCurrentSponsor(game: Game) {
        setSelectedSponsor(game.sponsor);
    }

    function handleSponsorClick(sponsor: Sponsor) {
        if (selectedSponsor?.id === sponsor.id) {
            setSelectedSponsor(null);
        } else {
            setSelectedSponsor(sponsor);
        }
    }

    return (
        <Modal className='edit-sponsors-modal list-modal' onClose={() => props.setOpen(false)} onOpen={() => props.setOpen(true)} open={props.open}>
            <Modal.Header>Sponsor bearbeiten</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    {state.sponsors &&
                        state.sponsors.map((sponsor: Sponsor) => (
                            <div
                                className={`sponsor list-modal-item ${sponsor.id === selectedSponsor?.id && 'selected'}`}
                                onClick={() => handleSponsorClick(sponsor)}
                                key={sponsor.id}>
                                <h3>{sponsor.name}</h3>
                                {sponsor.id === selectedSponsor?.id && <Icon name='check' />}
                            </div>
                        ))}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)}>Abbrechen</Button>
                <Button loading={loading} className='secondary' onClick={() => handleSave()}>
                    Speichern
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SetupSponsorModal;
