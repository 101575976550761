import React, { useState, MouseEvent, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { passwordlessSignUpAction, preSignInAction, signOutAction } from '../../../actions/userActions';
import { ProfileVisibility } from '../../../API';
import Context from '../../../Context/context';
import { usernameExists, verificationMailSent } from '../../../utils/authenticationHelpers';
import { toggleVisibility } from '../../../utils/userHelpers';
import { validateEmail } from '../../../utils/validation';

import './signUp.scss';
import BrowserNicknameValidator from '../../../utils/browser-nickname-validator';

interface SignUpProps {
    setEmail: (email: string) => void;
    email: string;
    setMailSent: (mailSent: boolean) => void;
}

function SignUp(props: SignUpProps) {
    const { dispatch } = useContext(Context);
    const [email, setEmail] = useState('');
    const [nickname, setNickname] = useState('');
    const [visibility, setVisibility] = useState<ProfileVisibility>(ProfileVisibility.private);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [newsletterSubscription, setNewsletterSubscription] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
        setEmail(props.email);
    }, []);

    async function handleSubmit(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setError(undefined);
        switch (false) {
            case validateEmail(email):
                setError('Ungültige Email Adresse');
                break;
            case await new BrowserNicknameValidator().isValidAsync(nickname):
                setError('Der Benutzername muss 3-20 Zeichen lang sein und darf keine Kontaktdaten oder vulgären Ausdrücke enthalten');
                break;
            case termsAndConditions:
                setError('Bitte akzeptieren Sie die Nutzungsbedingungen');
                break;
            default:
                setBtnLoading(true);
                signUp();
        }
    }

    function signUp() {
        signOutAction(dispatch);
        passwordlessSignUpAction(email, nickname, visibility, newsletterSubscription.toString())
            .then(() => {
                preSignInAction(email).catch((e) => {
                    if (verificationMailSent(e.name)) {
                        setError(e.name);
                        localStorage.removeItem('migrationToken');
                    } else {
                        setError('Es ist ein Fehler aufgetreten.');
                    }
                    setBtnLoading(false);
                });
            })
            .catch((e) => {
                if (usernameExists(e.name)) {
                    setError('Dieser Benutzer existiert bereits.');
                } else {
                    setError('Registrierung fehlgeschlagen.');
                }
                setBtnLoading(false);
            });
    }

    if (error && verificationMailSent(error)) {
        props.setMailSent(true);
    }

    return (
        <div className='sign-up'>
            <Form error={!!error}>
                <Form.Field>
                    <label>Email Adresse:</label>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} name='email' />
                </Form.Field>
                <Form.Field>
                    <label>Benutzername:</label>
                    <Input placeholder='Benutzername' onChange={(e) => setNickname(e.target.value)} name='nickname' />
                </Form.Field>
                <Form.Checkbox
                    label='Mich in der öffentlichen Rangliste anzeigen'
                    onChange={() => setVisibility(toggleVisibility)}
                    name='visibility'
                />
                <Form.Checkbox
                    label='Newsletter abonnieren'
                    onChange={() => setNewsletterSubscription(!newsletterSubscription)}
                    name='newsletterSubscription'
                />
                <Form.Checkbox
                    label={
                        <label>
                            Ich erkläre mich mit den{' '}
                            <Link to='/info/agb' target='_blank'>
                                Nutzungsbedingungen
                            </Link>{' '}
                            der SCRJ Lakers einverstanden
                        </label>
                    }
                    onChange={() => setTermsAndConditions(!termsAndConditions)}
                    name='termsAndConditions'
                    required
                />
                <Button loading={btnLoading} type='submit' content='Registrieren' onClick={handleSubmit} />
                <Message error content={error} />
            </Form>
            <p>
                Du hast bereits einen Account?{' '}
                <Link to='' onClick={() => props.setEmail('')}>
                    Anmelden
                </Link>
            </p>
        </div>
    );
}

export default SignUp;
