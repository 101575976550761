import * as generator from 'generate-password-browser';
import { Auth } from 'aws-amplify';
import jwtdecode, { JwtPayload } from 'jwt-decode';
import awsExports from '../aws-exports';
import { AuthExceptions, LambdaExceptions } from '../ressources/types/exceptions';
import { migrateAnonymous, setDefaultUserPool } from './userHelpers';
import { ProfileVisibility } from '../API';

const [productionRedirectSignIn, developmentRedirectSignIn, localRedirectSignIn] = awsExports.oauth.redirectSignIn.split(',');

const [productionRedirectSignOut, developmentRedirectSignOut, localRedirectSignOut] = awsExports.oauth.redirectSignOut.split(',');

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const isDevelopment = Boolean(window.location.origin.startsWith('https://dev.'));

const getRedirectUrls: () => string[] = () => {
    if (isLocalhost) {
        return [localRedirectSignIn, localRedirectSignOut];
    }
    if (isDevelopment) {
        return [developmentRedirectSignIn, developmentRedirectSignOut];
    }
    return [productionRedirectSignIn, productionRedirectSignOut];
};

const [redirectSignIn, redirectSignOut] = getRedirectUrls();

export const updatedAwsConfig = {
    ...awsExports,
    oauth: {
        ...awsExports.oauth,
        redirectSignIn,
        redirectSignOut,
    },
};

export async function signUp(email: string, nickname: string, visibility: ProfileVisibility, newsletterSubscription: string) {
    const params = {
        username: email,
        password: generatePassword(),
        attributes: {
            nickname,
        },
        validationData: {
            visibility,
            newsletterSubscription,
        },
    };
    await Auth.signUp(params);
}

export function generatePassword() {
    return generator.generate({
        length: 24,
        numbers: true,
        symbols: true,
        lowercase: true,
        uppercase: true,
    });
}

export async function preSignIn(email: string, migrationToken: string | null) {
    if (migrationToken) {
        return Auth.signIn(email, 'passwordless!', {
            passwordless: 'true',
            migrationToken,
        });
    }
    return Auth.signIn(email, 'passwordless!', {
        passwordless: 'true',
    });
}

export async function signIn(token: string) {
    const jwt = decodeJWTToken(token);
    if (!jwt.exp || jwt.exp >= Date.now()) {
        throw new Error('Link ist abgelaufen');
    }
    const cognitoUser = await Auth.signIn(jwt.email);
    const { migrationToken } = jwt;
    setDefaultUserPool();
    const user = await Auth.sendCustomChallengeAnswer(cognitoUser, token);
    await Auth.currentSession();
    if (migrationToken) {
        await migrateAnonymous(migrationToken);
    }
    return user;
}

export function decodeJWTToken(token: string): JwtPayload & { email: string; migrationToken: string } {
    return jwtdecode(token);
}

export function verificationMailSent(error: string) {
    return error === LambdaExceptions.USER_LAMBDA_VALIDATION_EXCEPTION;
}

export function usernameExists(error: string) {
    return error === AuthExceptions.USERNAME_EXISTS_EXCEPTION;
}

export function userNotFound(error: string) {
    return error === AuthExceptions.USER_NOT_FOUND_EXCEPTION;
}

export function notAuthorized(error: string) {
    return error === AuthExceptions.NOT_AUTHORIZED_EXCEPTION;
}
