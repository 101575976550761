import React, { useEffect, useState } from 'react';
import { closeChallengeApplicationAction, startChallengeApplicationAction } from '../../../actions/gameActions';
import { ChallengeApplication as ChallengeApplicationType } from '../../../API';
import useAsync from '../../../hooks/useAsync';
import { ChallengeApplicationState } from '../../../ressources/types/game';
import { ActionTypes, AppStateAction } from '../../../ressources/types/state';
import { getChallengeApplicationState } from '../../../utils/gameHelpers';
import SolutionModal from './SolutionModal/SolutionModal';
import Card from '../../Card/Card';
import ChallengeApplicationModal from '../ChallengeApplicationModal/ChallengeApplicationModal';

interface ChallengeApplicationProps {
    challenge: ChallengeApplicationType;
    dispatch: React.Dispatch<AppStateAction>;
}

function ChallengeApplication(props: ChallengeApplicationProps) {
    const [solutionOpen, setSolutionOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);

    const { execute, error } = useAsync();

    useEffect(() => {
        if (error) {
            props.dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    function renderCard(challenge: ChallengeApplicationType) {
        switch (getChallengeApplicationState(challenge)) {
            case ChallengeApplicationState.HIDDEN:
                return (
                    <Card
                        title={props.challenge.challenge.question}
                        onClick={() => setDetailsOpen(true)}
                        btnLabel={'Starten'}
                        onButtonClick={() => execute(() => startChallengeApplicationAction(props.dispatch, challenge.id))}
                        icon={'pencil'}></Card>
                );

            case ChallengeApplicationState.OPEN:
                return (
                    <Card
                        title={props.challenge.challenge.question}
                        onClick={() => setDetailsOpen(true)}
                        btnLabel={'Schliessen'}
                        onButtonClick={() => execute(() => closeChallengeApplicationAction(props.dispatch, challenge.id))}
                        icon={'pencil'}></Card>
                );

            case ChallengeApplicationState.CLOSED:
                return (
                    <Card
                        title={props.challenge.challenge.question}
                        onClick={() => setDetailsOpen(true)}
                        btnLabel={'Auflösen'}
                        onButtonClick={() => setSolutionOpen(true)}
                        icon={'pencil'}></Card>
                );

            case ChallengeApplicationState.RESOLVED:
                return <Card title={props.challenge.challenge.question} onClick={() => setDetailsOpen(true)} icon={'eye'}></Card>;
        }
    }

    return (
        <>
            <SolutionModal
                open={solutionOpen}
                setOpen={setSolutionOpen}
                challengeId={props.challenge.id}
                challengeType={props.challenge.challenge.type}
            />
            <ChallengeApplicationModal open={detailsOpen} setOpen={setDetailsOpen} challenge={props.challenge} />
            {renderCard(props.challenge)}
        </>
    );
}

export default ChallengeApplication;
