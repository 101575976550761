/* eslint-disable default-param-last */

import React, { useContext, useEffect, useState } from 'react';
import './challenges.scss';
import { Icon, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Challenge } from '../../../API';
import Context from '../../../Context/context';
import { fetchChallengesAction } from '../../../actions/gameActions';
import ChallengeDetailModal from '../../../components/ChallengeDetailModal/ChallengeDetailModal';
import useAction from '../../../hooks/useAction';
import { ActionTypes } from '../../../ressources/types/state';

function Challenges() {
    const { state, dispatch } = useContext(Context);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalChallenge, setModalChallenge] = useState<Challenge | undefined>(undefined);

    const { loading, error } = useAction({
        action: fetchChallengesAction,
        dispatch,
        condition: !state.challenges?.length,
    });

    useEffect(() => {
        if (error) {
            dispatch({ type: ActionTypes.SET_ERROR, error });
        }
    }, [error]);

    function openChallengeModal(challenge?: Challenge) {
        setModalOpen(true);
        setModalChallenge(challenge);
    }

    function renderChallenges() {
        if (state.challenges && state.challenges.length) {
            return (
                <>
                    {state.challenges.map((challenge: Challenge) => (
                        <div className='challenge' key={challenge.id} onClick={() => openChallengeModal(challenge)}>
                            <div className='challenge-question'>{challenge.question}</div>
                            <div className='challenge-actions'>
                                <button className='primary icon'>
                                    <Icon name='pencil' />
                                    Bearbeiten
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className='challenge challenge-create' onClick={() => openChallengeModal()}>
                        Challenge erstellen
                        <Icon name='plus' />
                    </div>
                </>
            );
        }
        return (
            <h2>
                Keine Challenges verfügbar!{' '}
                <Link to='' onClick={() => openChallengeModal()}>
                    Erstellen
                </Link>
            </h2>
        );
    }

    return (
        <>
            <ChallengeDetailModal open={modalOpen} setOpen={setModalOpen} challenge={modalChallenge} />
            <h1>Challenges</h1>
            {loading ? <Loader active inline size='huge' /> : <div className='challenges'>{renderChallenges()}</div>}
        </>
    );
}

export default Challenges;
