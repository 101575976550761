export function getDateInSwissFormat(timestamp: string) {
    const date = new Date(Date.parse(timestamp));
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

export function getTimeInSwissFormat(timestamp: string) {
    const date = new Date(Date.parse(timestamp));
    const hours = date.getUTCHours() - date.getTimezoneOffset() / 60;
    const minutes = date.getMinutes();
    return `${hours}:${minutes}`;
}
