import { MouseEvent } from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import { Reward } from '../../../API';
import { RewardSelection } from '../../../ressources/types/game';
import './setupRewardsModalList.scss';

interface SetupRewardModalListProps {
    selectedOnly?: boolean;
    disabled?: boolean;
    rewards: Reward[];
    selectedRewards: RewardSelection[];
    setSelectedRewards: (r: RewardSelection[]) => void;
}

function SetupRewardsModalList(props: SetupRewardModalListProps) {
    function isAmountChangeButton(e: MouseEvent) {
        return e.target instanceof HTMLElement && e.target.classList.contains('icon');
    }

    function toggleAddReward(reward: Reward, e: MouseEvent) {
        if (isAmountChangeButton(e)) {
            return;
        }

        if (isRewardSelected(reward.id)) {
            const oldOrder = props.selectedRewards.find((selected) => selected.rewardId === reward.id)?.order;
            props.setSelectedRewards(
                props.selectedRewards
                    .filter((selectedReward) => selectedReward.rewardId !== reward.id)
                    .map((selectedReward) => {
                        if (oldOrder && oldOrder < selectedReward.order) {
                            selectedReward.order--;
                        }
                        return selectedReward;
                    })
            );
        } else {
            props.setSelectedRewards([
                ...props.selectedRewards,
                {
                    rewardId: reward.id,
                    order: props.selectedRewards.length + 1,
                    amount: 1,
                },
            ]);
        }
    }

    function isRewardSelected(rewardId: string) {
        for (const selectedReward of props.selectedRewards) {
            if (selectedReward.rewardId === rewardId) {
                return true;
            }
        }
        return false;
    }

    function increaseAmount(reward: Reward) {
        const selected = props.selectedRewards.find((selectedReward) => selectedReward.rewardId === reward.id);
        selected &&
            props.setSelectedRewards(
                props.selectedRewards.map((selectedReward) => {
                    if (selectedReward.rewardId === reward.id) {
                        selectedReward.amount++;
                    }
                    return selectedReward;
                })
            );
    }

    function decreaseAmount(reward: Reward) {
        const selected = props.selectedRewards.find((selectedReward) => selectedReward.rewardId === reward.id);
        selected &&
            props.setSelectedRewards(
                props.selectedRewards.map((selectedReward) => {
                    if (selectedReward.rewardId === reward.id) {
                        selectedReward.amount--;
                    }
                    return selectedReward;
                })
            );
    }

    function renderRewardDetails(reward: Reward) {
        const isSelected = isRewardSelected(reward.id);
        const selected = props.selectedRewards.find((selectedReward) => selectedReward.rewardId === reward.id);
        return (
            <>
                {isSelected && (
                    <>
                        <div className='reward-amount'>
                            {!props.disabled && selected?.amount && selected.amount > 1 && (
                                <Icon name='minus circle' onClick={() => decreaseAmount(reward)} />
                            )}
                            {!props.disabled && <Icon name='plus circle' onClick={() => increaseAmount(reward)} />}
                            <span className='number'>{selected?.amount}</span> Stück
                        </div>
                        <div className='reward-order'>
                            <span className='number'>{selected?.order}.</span> Prio
                        </div>
                    </>
                )}
                <Checkbox disabled={props.disabled} checked={isSelected} />
            </>
        );
    }

    function getRewardList() {
        if (props.selectedOnly) {
            return props.rewards.filter((reward) => props.selectedRewards.find((re) => re.rewardId === reward.id));
        }

        return props.rewards;
    }

    return (
        <>
            {getRewardList().length === 0
                ? 'keine'
                : getRewardList().map((reward: Reward) => (
                      <div
                          className={`reward list-modal-item ${isRewardSelected(reward.id) ? 'selected' : ''}`}
                          onClick={
                              props.disabled
                                  ? () => {}
                                  : (e) => {
                                        toggleAddReward(reward, e);
                                    }
                          }
                          key={reward.id}>
                          <h3>{reward.name}</h3>
                          <p>{reward.value && <span className='reward-value'>{reward.value} CHF</span>}</p>
                          <div className='list-modal-item-right'>{renderRewardDetails(reward)}</div>
                      </div>
                  ))}
        </>
    );
}

export default SetupRewardsModalList;
