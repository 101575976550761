import React, { MouseEvent, useState } from 'react';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { preSignInAction } from '../../../actions/userActions';
import { userNotFound, verificationMailSent } from '../../../utils/authenticationHelpers';
import { validateEmail } from '../../../utils/validation';
import './signIn.scss';

interface SignInProps {
    setEmail: (email: string) => void;
    setMailSent: (mailSent: boolean) => void;
}

function SignIn(props: SignInProps) {
    const [email, setEmail] = useState('');

    const [error, setError] = useState<string | undefined>(undefined);
    const [btnLoading, setBtnLoading] = useState(false);

    function signIn(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setError(undefined);
        setBtnLoading(true);

        if (validateEmail(email)) {
            preSignInAction(email).catch((e) => {
                setBtnLoading(false);
                if (userNotFound(e.name)) {
                    props.setEmail(email);
                } else if (verificationMailSent(e.name)) {
                    setError(e.name);
                    localStorage.removeItem('migrationToken');
                } else {
                    setError('Es ist ein Fehler aufgetreten.');
                }
                setBtnLoading(false);
            });
        } else {
            setError('Ungültige Email Adresse');
            setBtnLoading(false);
        }
    }

    if (error && verificationMailSent(error)) {
        props.setMailSent(true);
    }

    return (
        <div className='sign-in'>
            <Form error={!!error}>
                <Form.Field>
                    <label>Zugangslink per Email anfordern:</label>
                    <Input placeholder='Email' onChange={(e) => setEmail(e.target.value)} name='email' />
                </Form.Field>
                <div className='sign-in-actions'>
                    <Button loading={btnLoading} type='submit' content='weiter' onClick={signIn} />
                </div>
                <Message error content={error} />
            </Form>
        </div>
    );
}

export default SignIn;
