import { Storage } from 'aws-amplify';
import { Dispatch } from 'react';
import { GraphQLQuery } from '@aws-amplify/api';
import { GraphqlInterceptor as API } from '../utils/graphqlInterceptor';
import {
    CreateGamePlayerMutation,
    CreatePlayerInput,
    CreatePlayerMutation,
    DeleteGamePlayerMutation,
    DeletePlayerMutation,
    GamePlayer,
    ListPlayersQuery,
    Player,
    UpdatePlayerMutation,
    UpdatePlayerMutationVariables,
} from '../API';
import { listPlayers } from '../graphql/queries';
import { createGamePlayer, createPlayer, deleteGamePlayer, deletePlayer, updatePlayer } from '../graphql/mutations';
import { ActionTypes, AppStateAction } from '../ressources/types/state';
import { IPlayer } from '../ressources/types/player';
import { getS3Url } from '../utils/playerHelpers';

const BUCKET_FOLDER = 'players/';

export async function fetchPlayersAction(dispatch: Dispatch<AppStateAction>) {
    const response = await API.graphql<GraphQLQuery<ListPlayersQuery>>({
        query: listPlayers,
    });
    const result = await Promise.all(
        (response.data?.listPlayers?.items as IPlayer[]).map(async (player: IPlayer) => {
            player.s3url = await Storage.get(BUCKET_FOLDER + player.image, { expires: 86400 });
            return player;
        })
    );
    dispatch({
        type: ActionTypes.UPDATE_PLAYERS,
        players: result,
    });
}

export async function createPlayerAction(dispatch: Dispatch<AppStateAction>, playerInput: CreatePlayerInput, file: Blob) {
    const response = await API.graphql<GraphQLQuery<CreatePlayerMutation>>({
        query: createPlayer,
        variables: { input: playerInput },
    });
    await Storage.put(BUCKET_FOLDER + playerInput.image, file, { contentType: 'image' });
    const player: IPlayer = response.data?.createPlayer as IPlayer;
    player.s3url = await Storage.get(BUCKET_FOLDER + player.image, { expires: 86400 });
    dispatch({ type: ActionTypes.ADD_PLAYER, player });
}

export async function updatePlayerAction(dispatch: Dispatch<AppStateAction>, player: IPlayer, file?: Blob) {
    const variables: UpdatePlayerMutationVariables = {
        input: { id: player.id, firstname: player.firstname, lastname: player.lastname, number: player.number, position: player.position },
    };

    if (file) {
        await Storage.put(BUCKET_FOLDER + player.image, file, { contentType: 'image' });
        player.s3url = await Storage.get(BUCKET_FOLDER + player.image, { expires: 86400 });
    }
    await API.graphql<GraphQLQuery<UpdatePlayerMutation>>({
        query: updatePlayer,
        variables,
    });

    dispatch({ type: ActionTypes.UPDATE_PLAYER, player });
}

export async function deletePlayerAction(dispatch: Dispatch<AppStateAction>, player: IPlayer) {
    const response = await API.graphql<GraphQLQuery<DeletePlayerMutation>>({
        query: deletePlayer,
        variables: { input: { id: player.id } },
    });
    await Storage.remove(BUCKET_FOLDER + player.image);
    dispatch({ type: ActionTypes.DELETE_PLAYER, player: response.data?.deletePlayer as IPlayer });
}

export async function createGamePlayerAction(dispatch: Dispatch<AppStateAction>, playerId: string, gameId: string) {
    const response = await API.graphql<GraphQLQuery<CreateGamePlayerMutation>>({
        query: createGamePlayer,
        variables: { input: { gameID: gameId, playerID: playerId } },
    });
    const s3url = await getS3Url(response.data?.createGamePlayer?.player as Player);
    dispatch({ type: ActionTypes.ADD_GAME_PLAYER_URL, gamePlayer: response.data?.createGamePlayer as GamePlayer, url: s3url });
    dispatch({ type: ActionTypes.ADD_GAME_PLAYER, gamePlayer: response.data?.createGamePlayer as GamePlayer });
}

export async function deleteGamePlayerAction(dispatch: Dispatch<AppStateAction>, gamePlayerId: string) {
    const response = await API.graphql<GraphQLQuery<DeleteGamePlayerMutation>>({
        query: deleteGamePlayer,
        variables: { input: { id: gamePlayerId } },
    });
    dispatch({ type: ActionTypes.DELETE_GAME_PLAYER_URL, gamePlayer: response.data?.deleteGamePlayer as GamePlayer });
    dispatch({ type: ActionTypes.DELETE_GAME_PLAYER, gamePlayer: response.data?.deleteGamePlayer as GamePlayer });
}

export async function setGamePlayerImageSources(dispatch: Dispatch<AppStateAction>, gamePlayers: (GamePlayer | null)[]) {
    dispatch({ type: ActionTypes.UPDATE_GAME_PLAYER_URLS, gamePlayers: [] });
    await Promise.all(
        gamePlayers.map(
            (gamePlayer) =>
                gamePlayer &&
                getS3Url(gamePlayer.player).then((url) => {
                    dispatch({ type: ActionTypes.ADD_GAME_PLAYER_URL, gamePlayer, url });
                })
        )
    );
}
